import markoBumbasirevic from '../../assets/doctors/una-bolnica-marko-bumbasirevic.webp';
import anaMalivukovic from '../../assets/doctors/una-bolnica-ana-malivukovic.webp';
import nenadNovakovic from '../../assets/doctors/una-bolnica-nenad-novakovic.webp';
import ljubodragMinic from '../../assets/doctors/una-bolnica-ljubodrag-minic.webp';
import andrijaSavic from '../../assets/doctors/una-bolnica-andrija-savic.webp';
import ivicaMedenica from '../../assets/doctors/una-bolnica-ivica-medenica.webp';
import goranPavlicevic from '../doctors/una-bolnica-goran-pavlicevic.webp';
import dusanBijelic from '../doctors/una-bolnica-dusan-bijelic.webp';
import nenadArsovic from '../doctors/una-bolnica-nenad-arsovic.webp';
import aleksandarDimic from '../doctors/una-bolnica-aleksandar-dimic.webp';
import ljubomirPavicevic from '../doctors/una-bolnica-ljubomir-pavicevic.webp';
import milanErdoglija from '../doctors/una-bolnica-milan-erdoglija.webp';
import ivanDjordjevic from '../doctors/una-bolnica-ivan-djordjevic.webp';








import post1Image1 from '../post/post1/vratna-diskus-hernija-1.webp';
import post1thumb from '../post/post1/vratna-diskus-hernija-thumb.webp';

import post2Image1 from '../post/post2/naprsnuce-prsljena-vertebroplastika.webp';
import post2thumb from '../post/post2/naprsnuce-prsljena-vertebroplastika-thumb.webp';
import post2Image2 from '../post/post2/naprsnuce-prsljena-vertebroplastika1.webp';
import post2Image3 from '../post/post2/naprsnuce-prsljena-vertebroplastika2.webp';
import post2Image4 from '../post/post2/naprsnuce-prsljena-vertebroplastika3.webp';

import post3Image1 from '../post/post3/orl-1.webp';
import post3thumb from '../post/post3/orl-thumb.webp';

import post4Image1 from '../post/post4/diskus-hernija-1.webp';
import post4thumb from '../post/post4/diskus-hernija-thumb.webp';



import BlogPost1 from '../../pages/blog-posts/blog-post-1.js';
import BlogPost2 from '../../pages/blog-posts/blog-post-2.js';
import BlogPost3 from '../../pages/blog-posts/blog-post-3.js';
import BlogPost4 from '../../pages/blog-posts/blog-post-4.js';
import BlogPost5 from '../../pages/blog-posts/blog-post-5.js';
import BlogPost6 from '../../pages/blog-posts/blog-post-6.js';
import BlogPost7 from '../../pages/blog-posts/blog-post-7.js';
import BlogPost8 from '../../pages/blog-posts/blog-post-8.js';
import BlogPost9 from '../../pages/blog-posts/blog-post-9.js';
import BlogPost10 from '../../pages/blog-posts/blog-post-10.js';

// list of posts
const blogData = [



  {
    postId: 4,
    smallPostId: 4,
    link: '/diskus-hernija',
    fileName: <BlogPost4/>, 
    mainTitle: 'Diskus hernija', 
    postDate: '07.04.2024.',  
    image: post4thumb,
    alternative: '',
    field: ['neurohirurgija'],
    title: 'Diskus Hernija: Simptomi, Dijagnoza i Lečenje',
    paragraph: 
      'Saznajte sve o diskus herniji - uzroci, simptomi, dijagnoza i metode lečenja. Posetite Una bolnicu za stručno i efikasno lečenje diskus hernije.',
    seoInspector: {
      title: 'Diskus hernija',
      metaContent: 'Diskus hernija je stanje koje zahteva stručnu pažnju. Informišite se o simptomima, uzrocima i metodama lečenja u Una bolnici.',
      link: 'http://unabolnica.com/diskus-hernija',
      h1: 'Diskus Hernija: Simptomi, Dijagnoza i Lečenje',
      h2: 'Diskus hernija je stanje koje zahteva stručnu pažnju. Informišite se o simptomima, uzrocima i metodama lečenja u Una bolnici.',
      thumbnailImage: post4thumb,
    },
    postContent1: [
      {
        id: 1,
        type: 'image',
        content: post4Image1,
        alternative: ''
      },
      {
        id: 2,
        type: 'title',
        content: 'Šta je Diskus Hernija?'
      },
      {
        id: 3,
        type: 'text1',
        content: [
          {
            id: 1,
            paragraph: 
              'Diskus hernija je stanje u kojem dolazi do promena na međupršljenskim diskovima, mekim pločicama koje služe kao amortizeri između pršljenova kičme. Ove promene dovode do stvaranja "izbočenja" ili "džepa", što može prouzrokovati pritisak na okolna tkiva i izazvati bolove i druge simptome. Hernija može da se javi u bilo kom delu kičme, ali najčešće pogađa lumbalni (donji) i cervikalni (vratni) deo kičme.',
          },
        ]
      },

    ],
    reservationContainer: [ 
      {
        id: 1,
        content: nenadNovakovic
      },
      {
        id: 2,
        content: [
          {
            id: 1,
            paragraph: 'Degenerativne bolesti kičme',
          },
          {
            id: 2,
            paragraph: 'Traumatske povrede kičme',
          },
          {
            id: 3,
            paragraph: 'Deformiteti kičme',
          },
          {
            id: 4,
            paragraph: 'Tumori kičme',
          },
          {
            id: 5,
            paragraph: 'Infekcije kičme',
          },
          {
            id: 6,
            paragraph: 'Minimalno invazivna hirurgija',
          },
          {
            id: 7,
            paragraph: 'Rekonstruktivna spinalna hirurgija',
          },
        ]
      }
    ],
    postContent2: [ 
      {
        id: 1,
        type: 'title',
        content: 'Simptomi Diskus Hernije'
      },

      {
        id: 3,
        type: 'text1',
        content: [
          {
            id: 1,
            paragraph: 
              'Simptomi diskus hernije variraju u zavisnosti od lokacije i težine problema. Uobičajeni simptomi uključuju:',
          },
        ]
      },
      {
        id: 4,
        type: 'text2',
        content: [
          {
            id: 1,
            paragraph: 
              'Bol u leđima ili vratu: Intenzivan bol koji može zračiti u ruke ili noge.',
          },
          {
            id: 2,
            paragraph: 
              'Trnjenje i slabost: Trnci i slabost u udovima, često povezani sa pritisnutim nervima.',
          },
          {
            id: 3,
            paragraph: 
              'Ograničena pokretljivost: Smanjena sposobnost kretanja ili obavljanje svakodnevnih aktivnosti.',
          },
          {
            id: 4,
            paragraph: 
              'Mišićni spazam: Nevoljne kontrakcije mišića koje mogu biti bolne.',
          },
        ],
      },
      {
        id: 3,
        type: 'text1',
        content: [
          {
            id: 5,
            paragraph: 
              'Diskus hernija u lumbalnom delu kičme može izazvati bol koji se spušta niz nogu, često poznat kao išijas, dok hernija u cervikalnom delu može izazvati bol koji se širi niz ruku.',
          },
        ]
      },

    ],
    videoContent: [
      {
        id: 1,
        title: 'Dodatak: Kapi za nos kod akutnog rinitisa - dr Dušan Bijelić'
      },
      {
        id: 2,
        link: 'https://www.youtube.com/watch?v=kS3xqGhAD4E',
      }
    ],
    postContent3: [ 
      {
        id: 1,
        type: 'title',
        content: 'Dijagnoza Diskus Hernije'
      },
      {
        id: 2,
        type: 'text1',
        content: [
          {
            id: 1,
            paragraph: 
              'Dijagnoza diskus hernije se postavlja kroz kliničke preglede i slikovne metode:',
          },
        ]
      },
      {
        id: 3,
        type: 'text2',
        content: [
          {
            id: 1,
            paragraph: 
              'Magnetna rezonanca (MRI): Detaljan prikaz mekih tkiva, uključujući diskove i nervne strukture.',
          },
          {
            id: 2,
            paragraph: 
              'Kompjuterizovana tomografija (CT): Detaljni prikaz kostiju i mekih tkiva.',
          },
          {
            id: 3,
            paragraph: 
              'Rendgenski snimak: Koristi se za isključivanje drugih uzroka bola, ali ne može direktno prikazati herniju.',
          },
          {
            id: 4,
            paragraph: 
              'Elektromiografija (EMG): Test koji meri mišićnu aktivnost i funkciju nerva kako bi se utvrdilo da li je nerv oštećen.',
          }
        ],
      },
      {
        id: 4,
        type: 'text1',
        content: [
          {
            id: 1,
            paragraph: 
              'Lekar će takođe uzeti u obzir istoriju simptoma i obaviti fizički pregled kako bi procenio prisustvo hernije i njen uticaj na funkcionalnost pacijenta.',
          },
        ]
      },

    ],
    infographic: [
      {
        id: 1,
        image: post4Image1,
        alternative: '',
      }
    ],
    postContent4: [ 
      {
        id: 1,
        type: 'title',
        content: 'Lečenje Diskus Hernije'
      },
      {
        id: 2,
        type: 'text1',
        content: [
          {
            id: 1,
            paragraph: 
              'Lečenje diskus hernije može biti konzervativno ili hirurško, zavisno od težine simptoma i odgovora na terapiju:',
          },
        ]
      },
      {
        id: 3,
        type: 'text2',
        content: [
          {
            id: 1,
            paragraph: 
              'Fizikalna terapija: Vežbe i terapije koje pomažu u jačanju mišića oko kičme i poboljšanju fleksibilnosti.',
          },
          {
            id: 2,
            paragraph: 
              'Lekovi: Analgetici, antiinflamatorni lekovi i mišićni relaksanti mogu pomoći u ublažavanju bolova i smanjenju upale.',
          },
          {
            id: 3,
            paragraph: 
              'Injekcije: Kortikosteroidne injekcije mogu smanjiti upalu i bol direktno na mestu hernije.',
          },
          {
            id: 4,
            paragraph: 
              'Odmaranje i modifikacija aktivnosti: Izbegavanje aktivnosti koje pogoršavaju simptome i korišćenje pravilnih tehnika podizanja i držanja tela.',
          },
          {
            id: 5,
            paragraph: 
              'Hirurško lečenje: Kada konzervativne metode ne uspeju, hirurški zahvati mogu biti neophodni.',
          },
          {
            id: 6,
            paragraph: 
              'Mikrodiscektomija: Uklanjanje dela herniranog diska kako bi se smanjio pritisak na nerv.',
          },
          {
            id: 7,
            paragraph: 
              'Laminektomija: Uklanjanje dela kosti (lamine) kako bi se povećao prostor za nervne strukture.',
          },
        ],
      },
    ],
    postContent5: [ 
      {
        id: 1,
        type: 'title',
        content: 'Prevencija Diskus Hernije'
      },
      {
        id: 2,
        type: 'text2',
        content: [
          {
            id: 1,
            paragraph: 
              'Redovno vežbanje: Jačanje mišića leđa i abdomena može pomoći u održavanju zdrave kičme.',
          },
          {
            id: 2,
            paragraph: 
              'Ergonomsko radno okruženje: Korišćenje pravilnog nameštaja i alata za rad može smanjiti rizik od povreda kičme.',
          },
          {
            id: 3,
            paragraph: 
              'Pravilno dizanje tereta: Korišćenje nogu umesto leđa za podizanje teških predmeta smanjuje rizik od oštećenja diskova.',
          },
          {
            id: 4,
            paragraph: 
              'Održavanje zdrave težine: Smanjenje telesne težine smanjuje pritisak na kičmu.',
          },
          {
            id: 5,
            paragraph: 
              'Pravilno držanje tela: Pažnja na pravilno držanje tokom sedenja, stajanja i spavanja može pomoći u sprečavanju diskus hernije.',
          }
        ]
      },
      {
        id: 3,
        type: 'text1',
        content: [
          {
            id: 1,
            paragraph: 
              'Razumevanje uzroka, simptoma i opcija za lečenje diskus hernije može značajno poboljšati kvalitet života obolelih. Za više informacija ili zakazivanje pregleda, kontaktirajte naš medicinski centar. Naši stručnjaci su ovde da vam pomognu da povratite zdravlje vaše kičme i živite bez bolova.',
          },
         
        ]
      },
    ],
    team: [
      {
        id: 1,
        image: nenadNovakovic,
        alternative: '',
        link: '/nenad-novakovic',
        name: 'dr Nenad',
        surname: 'Novaković',
        description: 'specijalista neurohirugije'
      },
      {
        id: 2,
        image: goranPavlicevic,
        alternative: '',
        link: '/goran-pavlicevic',
        name: 'dr Goran',
        surname: 'Pavlićević',
        description: 'specijalista neurohirugije'
      },
      {
        id: 3,
        image: ljubodragMinic,
        alternative: '',
        link: '/ljubodrag-minic',
        name: 'dr Ljubodrag',
        surname: 'Minić',
        description: 'specijalista neurohirugije'
      },
      
    ],
  },
  {
    postId: 3,
    smallPostId: 3,
    link: '/orl',
    fileName: <BlogPost3/>, 
    mainTitle: 'Otorinolaringologija (ORL)', 
    postDate: '05.05.2024.',  
    image: post3thumb,
    alternative: '',
    field: ['otorinolaringologija'],
    title: 'Otorinolaringologija u Una bolnici',
    paragraph: 
      'Saznajte sve o otorinolaringologiji (ORL) u Bolnici Una u Beogradu. Nudimo savremenu dijagnostiku, lečenje i prevenciju bolesti uha, nosa i grla. Posetite nas za stručnu pomoć.',
    seoInspector: {
      title: 'Otorinolaringologija (ORL)',
      metaContent: 'Otorinolaringologija u Una bolnici - Sveobuhvatni vodič',
      link: 'http://unabolnica.com/orl',
      h1: 'Otorinolaringologija u Una bolnici - Sveobuhvatni vodič',
      h2: 'Otorinolaringologija u Una bolnici - Sveobuhvatni vodič',
      thumbnailImage: post3thumb,
    },
    postContent1: [
      {
        id: 1,
        type: 'image',
        content: post3Image1,
        alternative: ''
      },
      {
        id: 2,
        type: 'title',
        content: 'Otorinolaringologija u Una bolnici - Sveobuhvatni vodič'
      },
      {
        id: 3,
        type: 'text1',
        content: [
          {
            id: 1,
            paragraph: 
              'Otorinolaringologija je specijalistička grana medicine koja se bavi dijagnostikom, lečenjem i prevencijom bolesti i poremećaja uha, nosa i grla, kao i povezanih struktura glave i vrata. Ova medicinska disciplina je ključna za održavanje zdravlja respiratornog i auditivnog sistema, kao i za funkcionalnost glasnica i sposobnost pravilnog govora i gutanja.',
          },
          {
            id: 2,
            paragraph: 
              'Otorinolaringologija ima bogatu istoriju koja datira još od antičkih vremena. Prvi lekari su prepoznavali i tretirali bolesti uha, nosa i grla. Razvoj tehnologije i medicinske nauke kroz vekove doveo je do specijalizacije i usavršavanja dijagnostičkih i terapijskih metoda u ovoj oblasti. Danas, ORL je visoko razvijena medicinska specijalnost koja koristi napredne tehnologije, kao što su endoskopija, mikroskopija i radiološka dijagnostika, za precizno identifikovanje i lečenje različitih oboljenja.',
          },
        ]
      },

    ],
    reservationContainer: [ 
      {
        id: 1,
        content: dusanBijelic
      },
      {
        id: 2,
        content: [
          {
            id: 1,
            paragraph: 'Bolesti uha',
          },
          {
            id: 2,
            paragraph: 'Bolesti nosa i sinusa',
          },
          {
            id: 3,
            paragraph: 'Bolesti grla',
          },
          {
            id: 4,
            paragraph: 'Bolesti glave i vrata',
          },
          {
            id: 5,
            paragraph: 'Dijagnostičke metode u ORL',
          },
          {
            id: 6,
            paragraph: 'Devijacija nosnog septuma',
          },
          {
            id: 7,
            paragraph: 'Tinitus (zvonjenje u ušima)',
          },
        ]
      }
    ],
    postContent2: [ 
      {
        id: 1,
        type: 'title',
        content: 'Glavne oblasti otorinolaringologije'
      },

      {
        id: 3,
        type: 'text1',
        content: [
          {
            id: 1,
            paragraph: 
              'Bolesti uha (otologija/neurotologija): Ova oblast se fokusira na poremećaje sluha i ravnoteže, uključujući infekcije uha, otosklerozu, tinitus (zvonjenje u ušima), Menijerovu bolest i druge vestibularne poremećaje. Lečenje može uključivati medikamentoznu terapiju, fizikalnu terapiju ili hirurške zahvate, kao što su timpanoplastika i kohlearna implantacija.',
          },
          {
            id: 2,
            paragraph: 
              'Bolesti nosa i sinusa (rinologija): Rinologija obuhvata dijagnostiku i lečenje problema vezanih za nos i sinuse, kao što su rinitis (alergijski i nealergijski), sinusitis, polipi nosa, devijacija nosnog septuma i epistaksa (krvarenje iz nosa). Terapije mogu uključivati lekove, alergijske testove i tretmane, kao i funkcionalnu endoskopsku sinusnu hirurgiju (FESS).',
          },
          {
            id: 3,
            paragraph: 
              'Bolesti grla (laringologija): Laringologija se bavi bolestima grla, uključujući laringitis, tonzilitis, faringitis, poremećaje glasa i gutanja, kao i tumore grla. Dijagnostičke metode uključuju laringoskopiju, stroboskopiju i biopsiju, dok lečenje može uključivati medikamente, logopedske vežbe i hirurške intervencije.',
          },
          {
            id: 4,
            paragraph: 
              'Bolesti glave i vrata: Ova oblast pokriva dijagnostiku i lečenje benignih i malignih tumora glave i vrata, bolesti pljuvačnih žlezda, štitaste žlezde, kao i traume i deformitete lica i vrata. Lečenje često zahteva multidisciplinarni pristup, uključujući onkologe, plastične hirurge i radiologe.',
          },
        ]
      },

    ],
    videoContent: [
      {
        id: 1,
        title: 'Kapi za nos kod akutnog rinitisa - dr Dušan Bijelić'
      },
      {
        id: 2,
        link: 'https://www.youtube.com/watch?v=kS3xqGhAD4E',
      }
    ],
    postContent3: [ 
      {
        id: 1,
        type: 'title',
        content: 'Dijagnostičke metode u otorinolaringologiji'
      },
      {
        id: 2,
        type: 'text1',
        content: [
          {
            id: 1,
            paragraph: 
              'Dijagnostika u otorinolaringologiji koristi širok spektar tehnika kako bi se precizno identifikovali problemi i planiralo adekvatno lečenje. Neke od najčešće korišćenih dijagnostičkih metoda uključuju:',
          },
  

        ]
      },
      {
        id: 3,
        type: 'text2',
        content: [
          {
            id: 1,
            paragraph: 
              'Audiometrija: Testiranje sluha koje pomaže u identifikaciji gubitka sluha i određivanju njegovog stepena.',
          },
          {
            id: 2,
            paragraph: 
              'Endoskopija: Koristi se za pregled nosa, sinusa, grla i glasnica. Omogućava lekarima da vizualizuju unutrašnje strukture i identifikuju abnormalnosti.',
          },
          {
            id: 3,
            paragraph: 
              'Radiološke metode: Kao što su CT skeniranje i MRI, koje pružaju detaljne slike unutrašnjih struktura glave i vrata.',
          },
          {
            id: 4,
            paragraph: 
              'Timpanometrija: Testiranje funkcije srednjeg uha i mobilnosti bubne opne.',
          },
          {
            id: 5,
            paragraph: 
              'Biopsija: Uzorkovanje tkiva radi laboratorijske analize, često korišćeno za dijagnostikovanje tumora i drugih patoloških stanja.',
          },
        ],
      },

    ],
    infographic: [
      {
        id: 1,
        image: post3Image1,
        alternative: '',
      }
    ],
    postContent4: [ 
      {
        id: 1,
        type: 'title',
        content: 'Lečenje u otorinolaringologiji'
      },
      {
        id: 2,
        type: 'text1',
        content: [
          {
            id: 1,
            paragraph: 
              'Lečenje u otorinolaringologiji može biti konzervativno ili hirurško, u zavisnosti od prirode i težine problema. Konzervativno lečenje obuhvata upotrebu medikamenata, fizikalne terapije, logopedskih vežbi i različitih procedura kao što su ispiranje uha ili nazalna irigacija.',
          },
          {
            id: 2,
            paragraph: 
              'Hirurški zahvati u otorinolaringologiji mogu biti minimalno invazivni ili složeni. Neki od najčešćih zahvata uključuju:',
          }
        ]
      },
      {
        id: 3,
        type: 'text2',
        content: [
          {
            id: 1,
            paragraph: 
              'Miringotomija: Procedura koja uključuje postavljanje ventilacionih cevi u bubnu opnu kako bi se tretirale hronične infekcije srednjeg uha.',
          },
          {
            id: 2,
            paragraph: 
              'Septoplastika: Korekcija devijacije nosnog septuma radi poboljšanja disanja.',
          },
          {
            id: 3,
            paragraph: 
              'Endoskopska sinusna hirurgija (FESS): Minimalno invazivna operacija za lečenje hroničnog sinusitisa i polipa.',
          },
          {
            id: 4,
            paragraph: 
              'Tonzilektomija i adenoidektomija: Uklanjanje krajnika i adenoida kod pacijenata sa rekurentnim infekcijama.',
          },
          {
            id: 5,
            paragraph: 
              'Kohlearna implantacija: Ugradnja uređaja za poboljšanje sluha kod pacijenata sa teškim gubitkom sluha.',
          },
        ],
      },
    ],
    postContent5: [ 
      {
        id: 1,
        type: 'title',
        content: 'Edukacija i prevencija'
      },
      {
        id: 2,
        type: 'text1',
        content: [
          {
            id: 1,
            paragraph: 
              'Jedan od ključnih aspekata otorinolaringologije je edukacija pacijenata o prevenciji bolesti uha, nosa i grla. Redovni pregledi, pravilna higijena, izbegavanje alergena i pravovremeno lečenje infekcija su od suštinskog značaja za održavanje zdravlja. Naši specijalisti rade na podizanju svesti i pružanju saveta kako bi pacijenti mogli da preduzmu odgovarajuće korake za očuvanje svog zdravlja.',
          },
          {
            id: 2,
            paragraph: 
              'U Bolnici Una, ponosimo se pružanjem sveobuhvatne medicinske brige koja je prilagođena individualnim potrebama svakog pacijenta. Naš tim stručnjaka je tu da vas podrži na svakom koraku vašeg puta ka zdravlju, koristeći savremene tehnologije i metode lečenja u oblasti otorinolaringologije.',
          }
        ]
      },

    ],
    postContent5: [ 
      {
        id: 1,
        type: 'title',
        content: 'Najčešća pitanja pacijenata'
      },
      {
        id: 2,
        type: 'text1',
        content: [
          {
            id: 1,
            paragraph: 
              '1. Kada je potrebno posetiti otorinolaringologa?',
          },
          {
            id: 2,
            paragraph: 
              'Poseta otorinolaringologu je preporučljiva kod simptoma kao što su uporna bol u uhu, gubitak sluha, dugotrajan kašalj, problemi sa disanjem, česte infekcije sinusa, promuklost ili poteškoće sa gutanjem.',
          },
          {
            id: 3,
            paragraph: 
              '2. Kako se pripremiti za pregled kod otorinolaringologa?',
          },
          {
            id: 4,
            paragraph: 
              'Priprema za pregled obično uključuje detaljnu anamnezu simptoma, eventualno nošenje prethodnih medicinskih nalaza i spisak lekova koje trenutno uzimate.',
          },
          {
            id: 5,
            paragraph: 
              '3. Šta očekivati tokom pregleda otorinolaringologa?',
          },
          {
            id: 6,
            paragraph: 
              'Tokom pregleda, otorinolaringolog može koristiti različite dijagnostičke metode, uključujući endoskopiju, audiometriju, ili timpanometriju, kako bi precizno utvrdio uzrok vaših simptoma i preporučio odgovarajuće lečenje.',
          },
          {
            id: 7,
            paragraph: 
              '4. Koji su najčešći tretmani u otorinolaringologiji?',
          },
          {
            id: 8,
            paragraph: 
              'Najčešći tretmani uključuju medikamentoznu terapiju za infekcije, alergije i inflamacije, kao i različite hirurške zahvate za korekciju strukturnih problema, uklanjanje tumora ili poboljšanje sluha.',
          },
        ]
      },

    ],
    team: [
      {
        id: 1,
        image: dusanBijelic,
        alternative: '',
        link: '/dusan-bijelic',
        name: 'dr Dušan',
        surname: 'Bijelić',
        description: 'specijalista otorinolaringologije'
      },
      {
        id: 2,
        image: aleksandarDimic,
        alternative: '',
        link: '/aleksandar-dimic',
        name: 'dr Aleksandar',
        surname: 'Dimić',
        description: 'specijalista otorinolaringologije'
      },
      {
        id: 3,
        image: nenadArsovic,
        alternative: '',
        link: '/nenad-arsovic',
        name: 'dr Nenad',
        surname: 'Arsović',
        description: 'specijalista otorinolaringologije'
      },
      {
        id: 4,
        image: milanErdoglija,
        alternative: '',
        link: '/milan-erdoglija',
        name: 'dr Milan',
        surname: 'Erdoglija',
        description: 'specijalista otorinolaringologije'
      },
      {
        id: 5,
        image: ljubomirPavicevic,
        alternative: '',
        link: '/ljubomir-pavicevic',
        name: 'dr Ljubomir',
        surname: 'Pavićević',
        description: 'specijalista otorinolaringologije'
      },
      {
        id: 6,
        image: ivanDjordjevic,
        alternative: '',
        link: '/ivan-djordjevic',
        name: 'dr Ivan',
        surname: 'Đorđević',
        description: 'specijalista otorinolaringologije'
      },
    ],
  },

  {
    postId: 2,
    smallPostId: 2,
    link: '/naprsnuce-prsljena-vertebroplastika',
    fileName: <BlogPost2/>, 
    mainTitle: 'Naprsnuće pršljena - Spinalna hirurgija', 
    postDate: '28.01.2024.',  
    image: post2Image1,
    alternative: 'Una bolnica Beograd - Naprsnuće pršljena, operacija kičme - Vertebroplastika',
    field: ['neurohirurgija', 'ortopedija sa traumatologijom'],
    title: 'Naprsnuće pršljena, operacija kičme - Vertebroplastika',
    paragraph: 
      'Vertebroplastika je operacija kičme, odnosno operacija kičmenog pršljena bez hirurškog reza, minimalno invazivna hirurška procedura.',
    seoInspector: {
      title: 'Naprsnuće pršljena, operacija kičme - Vertebroplastika',
      metaContent: 'Vertebroplastika je operacija kičme, odnosno operacija kičmenog pršljena bez hirurškog reza, koja se svrstava u minimalno invazivne hirurške procedure.',
      link: 'http://unabolnica.com/naprsnuce-prsljena-vertebroplastika',
      h1: 'Naprsnuće pršljena, operacija kičme - Vertebroplastika',
      h2: 'Vertebroplastika je operacija kičme, odnosno operacija kičmenog pršljena bez hirurškog reza, minimalno invazivna hirurška procedura.',
      thumbnailImage: post2Image1,
    },
    postContent1: [
      {
        id: 1,
        type: 'image',
        content: post2Image2,
        alternative: ''
      },
      {
        id: 2,
        type: 'title',
        content: 'Šta je to vertebroplastika i kako se izvodi?'
      },
      {
        id: 3,
        type: 'text1',
        content: [
          {
            id: 1,
            paragraph: 
              'Vertebroplastika je operacija kičme, odnosno operacija kičmenog pršljena bez hirurškog reza, koja se svrstava u minimalno invazivne hirurške procedure. Tokom intervencije, pod kontrolom rentgena, uvodi se igla u pršljensko telo kroz koju se plasira koštani cement u pršljen i time tretira naprsnuće pršljena. Cement će očvrsnuti kroz 10 minuta i time se završava sama procedura.',
          },
          
        ]
      },
      {
        id: 1,
        type: 'title',
        content: 'Kada se izvodi operacija pršljena - vertebroplastika?'
      },
      {
        id: 3,
        type: 'text1',
        content: [
          {
            id: 1,
            paragraph: 
              'Koristi se za lečenje preloma pršljenskog tela nastalog kao posledica različitih patoloških procesa kao što su: osteoporoza, trauma kičme, tumor kičmenog stuba. Među najčešćim povredama su naprsnuće pršljena l1, povreda 12 pršljena i kompresivni prelom pršljena. Prelomi pršljenskih tela uzrokuju intenzivan bol u kičmi koji onemogućava pacijenta da se kreće što dovodi do prolongirane imobilizacije koja može uzrokovati komplikacije poput upale pluća, tromboze dubokih vena, plućne embolije. Pored toga upotreba veće količine nesteroidnih antireumatika za lečenje bola dovodi do gastričnih komplikacija.',
          },
        ],
      },
      {
        id: 1,
        type: 'image',
        content: post2Image3,
        alternative: ''
      },
    ],
    postContent3: [ 
  
    ],
    
    reservationContainer: [ 
      {
        id: 1,
        content: goranPavlicevic
      },
      {
        id: 2,
        content: [
          {
            id: 1,
            paragraph: 'Cervikalna diskus hernija - vratna',
          },
          {
            id: 2,
            paragraph: 'Kifoza vratne kičme',
          },
          {
            id: 3,
            paragraph: 'Povrede vratne kičme',
          },
          {
            id: 4,
            paragraph: 'Cervikalna laminektomija',
          },
          {
            id: 5,
            paragraph: 'Mikrodiscektomija cervikalnog diska sa spondilodezom autograftom',
          },
        ]
      }
    ],
    postContent2: [ 
      {
        id: 1,
        type: 'title',
        content: 'Postupak operacije kičmenog pršljena, efikasnost vertebroplastike i oporavak'
      },
      {
        id: 2,
        type: 'text1',
        content: [
          {
            id: 1,
            paragraph: 
              '"Ova procedura je veoma efikasna, kod 95% pacijenata se značajno redukuje bol nakon intervencije, pršljen se ojačava i postiže se bolja stabilnost kičme", kaže dr Goran Pavlićević, specijalista neurohirurgije i spinalni hirurg u opštoj bolnici Una i jedan od najboljih neurohirurga u regionu. Obzirom da je životni vek značajno produžen, vertebroplastika se sve više izvodi jer se povećava broj ljudi u populaciji starije životne dobi.',
          },
          {
            id: 2,
            paragraph: 
              'Pacijent bude otpušten neposredno nakon operacije, ili se u nekim slučajevima, kod intenzivnijeg bola kratko zadržava u bolnici. Uzdržavanje od težih fizičkih napora preporučuje se u trajanju od dva meseca, a puni benefit intervencije biće uočljiv kroz šest do dvanaest meseci.',
          },
        ]
      },
      {
        id: 1,
        type: 'image',
        content: post2Image4,
        alternative: ''
      },
    ],
    team: [
      {
        id: 1,
        image: ljubodragMinic,
        alternative: '',
        link: '/ljubodrag-minic',
        name: 'dr Ljubodrag',
        surname: 'Minić',
        description: 'specijalista neurohirurgije'
      },
      {
        id: 2,
        image: goranPavlicevic,
        alternative: '',
        link: '/goran-pavlicevic',
        name: 'dr Goran',
        surname: 'Pavlićević',
        description: 'specijalista neurohirurgije'
      },
      {
        id: 3,
        image: nenadNovakovic,
        alternative: '',
        link: '/nenad-novakovic',
        name: 'dr Nenad',
        surname: 'Novaković',
        description: 'specijalista neurohirurgije'
      },
    ],
  },










  {
    postId: 1,
    smallPostId: 1,
    link: '/vratna-diskus-hernija',
    fileName: <BlogPost1/>, 
    mainTitle: 'Vratna diskus hernija - Šta je i kako se leči?', 
    postDate: '15.12.2023.',  
    image: post1thumb,
    alternative: 'Una bolnica Beograd - Šta je vratna diskus hernija, kako se leči, šta znači dislokacija dela intervertebralnog diska i kada je potrebna operacija diskus hernije. Odgovore pronađite u sledećem tekstu.',
    field: ['neurohirurgija', 'ortopedija sa traumatologijom'],
    title: 'Vratna diskus hernija - Šta je i kako se leči?',
    paragraph: 
      'Diskus hernija je jedan od vidova degenerativne bolesti kičmenog stuba i predstavlja dislokaciju dela intervertebralnog diska u kičmeni kanal.',
    seoInspector: {
      title: 'Vratna diskus hernija - Šta je i kako se leči?',
      metaContent: 'Una bolnica Beograd - Šta je vratna diskus hernija, kako se leči, šta znači dislokacija dela intervertebralnog diska i kada je potrebna operacija diskus hernije.',
      link: 'http://unabolnica.com/vratna-diskus-hernija',
      h1: 'Operacija diskus hernije, vratna diskus hernija',
      h2: 'Una bolnica Beograd - Šta je vratna diskus hernija, kako se leči, šta znači dislokacija dela intervertebralnog diska i kada je potrebna operacija diskus hernije. Odgovore pronađite u sledećem tekstu.',
      thumbnailImage: post1Image1,
    },
    postContent1: [
      {
        id: 1,
        type: 'image',
        content: post1Image1,
        alternative: ''
      },
      {
        id: 2,
        type: 'title',
        content: 'Šta je vratna diskus hernija?'
      },
      {
        id: 3,
        type: 'text1',
        content: [
          {
            id: 1,
            paragraph: 
              'Diskus hernija je jedan od vidova degenerativne bolesti kičmenog stuba i predstavlja dislokaciju dela intervertebralnog diska u kičmeni kanal.',
          },
          {
            id: 2,
            paragraph: 
              'U najvećem broju slučajeva pomeren je središnji deo diska odnosno meko jedro koje se pomera iz svog normalnog položaja u kičmeni kanal kroz rascep koji se formira na fibroznom prstenu koji okružuje meko jedro. U vratnom delu kičme iza diska se nalazi kičmena moždina i nervni korenovi tako da pomeranje diska u kičmeni kanal dovodi do pritiska na kičmenu moždinu i/ili nervne korenove.',
          },
        ]
      },
      {
        id: 1,
        type: 'title',
        content: 'Kada nastaje vratna diskus hernija?'
      },
      {
        id: 3,
        type: 'text1',
        content: [
          {
            id: 1,
            paragraph: 
              'Diskus hernija nastaje kao posledica ponavljanih povreda vratnog dela kičmenog stuba, a predispoziciju imaju osobe koje imaju genetski manju elastičnost vezivnog tkiva tako da su ona podložnija pucanju i cepanju.',
          },
          {
            id: 1,
            paragraph: 
              'Pored toga vratni deo kičme je zbog velike pokretljivosti nestabilniji podložniji povredama u odnosu na grudni i slabinski deo kičme. Faktor rizika je i arterioskleroza koja dovodi do smanjenja prokrvljenosti diska i njegovog dodatnog oštećenja.',
          },
        ],
      },
      {
        id: 1,
        type: 'title',
        content: 'Koji su simptomi vratne diskus hernije?'
      },
      {
        id: 2,
        type: 'text1',
        content: [
          {
            id: 1,
            paragraph: 
              'Simptomi i znaci se razlikuju u zavisnosti da li se disk pomerio ka središnjem ili bočnom delu kičmenog kanala. Ukoliko se pomerio ka centralnom delu kanala dolazi do pritiska na kičmenu moždinu i razvoja slabosti u nogama. Stepen slabosti u nogama zavisi od stepena pritiska na kičmenu moždinu. Ukoliko se pomerio ka bočnom delu dominiraju znaci pritiska na nervni koren i pacijenti se žale na bol u vratu koji se širi duž jedne ruke a praćen je trnjenjem a ponekad i slabošću ruke.',
          },
        ]
      },
    ],
    postContent3: [ 

    ],
    
    reservationContainer: [ 
      {
        id: 1,
        content: goranPavlicevic
      },
      {
        id: 2,
        content: [
          {
            id: 1,
            paragraph: 'Cervikalna diskus hernija - vratna',
          },
          {
            id: 2,
            paragraph: 'Kifoza vratne kičme',
          },
          {
            id: 3,
            paragraph: 'Povrede vratne kičme',
          },
          {
            id: 4,
            paragraph: 'Cervikalna laminektomija',
          },
          {
            id: 5,
            paragraph: 'Mikrodiscektomija cervikalnog diska sa spondilodezom autograftom',
          },
        ]
      }
    ],
    videoContent: [
      {
        id: 1,
        title: 'Šta je discus hernia i da li vežbe mogu da nam pomognu?'
      },
      {
        id: 2,
        link: 'https://www.youtube.com/watch?v=JqcDQ1aUrqY',
      }
    ],
    postContent2: [ 
      {
        id: 1,
        type: 'title',
        content: 'Lečenje vratne diskus hernije'
      },
      {
        id: 2,
        type: 'text1',
        content: [
          {
            id: 1,
            paragraph: 
              'Lečenje se započinje u najvećem broju slučajeva neoperativno a tretman se zasniva za zaštiti obolelog diska od opterećenja i čekanja da pomereni deo diska “osuši” odnosno fibrozira. Čak i veliki fragmetni diska koji se nalaze u kičmenom kanalu mogu povoljno reagovati na ovakav tretman jer zadrže veliku količinu vode koja se vremenom smanjuje.',
          },
          {
            id: 1,
            paragraph: 
              'Kod vratne diskus hernije ukoliko bolesnik ima samo bol u vratu koji se širi ka ruci lečenje se započinje mirovanjem, analgeticima, miorelaksantima, antiinflamatornim lekovima (ibuprofen, naproksen), oralnim steroidima (deksazon). Kad pacijent počne da ustaje imoboliše se vrat rigidnim okovratnikom a korisne su i trakcije glave.',
          },
          {
            id: 1,
            paragraph: 
              'U fazi kada se značajno redukuje bolni sindrom započinje se sa vežbama za jačanje vratne muskulature a vratni mider se isključuje iz terapije.',
          },
        ]
      },
    ],
    postContent4: [ 
      {
        id: 1,
        type: 'title',
        content: 'Operacija vratne diskus hernije'
      },
      {
        id: 2,
        type: 'text1',
        content: [
          {
            id: 1,
            paragraph: 
              'Operacija je neophodna kod vratne diskus hernije ukoliko je disk pritisnuo kičmenu moždinu a ukoliko je došlo i do razvoja neurološkog deficita operaciju treba što pre obaviti.',
          },
          {
            id: 1,
            paragraph: 
              'Ukoliko se bol duž ruke održava i nakon mesec dana od početka tegoba razmatra se operativno lečenje radi prevencije oštećenja nerva nastalog kao posledica dugotrajnog pritiska na živac.',
          },
          {
            id: 1,
            paragraph: 
              'Operacija vratne kičme podrazumeva korišćenje mikrohirurške tehnike.',
          },
          {
            id: 1,
            paragraph: 
              'Disku se prilazi sa prednje strane, i uklanja se čitav disk. Operacija podrazumeva zamenu diska nakon njegovog vađena a ranije se to činilo koštanim graftom koji se uzimao najčešće sa karlične kosti. Danas se češće koriste titanijumski implatni koji se ispune veštačkom kosti uz fiksaciju susednih pršljenova prednjom vratnom pločom. Ovaj način operacija dovodi do srašćivanja dva pršljena nakon vađenja diska i gubitka pokretljivosti u tom segment. Zbog toga je razvijen koncept očuvanja pokretljivosti korišćenjem arteficijalnog ili veštačkog diska. Prilikom operacije vratnog diskusa mogućnost oštećenja kičmene moždine je minimalna obzirom da se čitava operacija odvija ispred njene ovojnice.',
          },
          {
            id: 1,
            paragraph: 
              'Operacija traje oko sat vremena.',
          },
          {
            id: 1,
            paragraph: 
              'Pacijent se vertikalizuje prvi dan nakon operacije, a nakon dve, tri nedelje može se vratiti uobičajenim dnevnim aktivnostima.',
          },
        ]
      },
    ],
    team: [
      {
        id: 1,
        image: ljubodragMinic,
        alternative: '',
        link: '/ljubodrag-minic',
        name: 'dr Ljubodrag',
        surname: 'Minić',
        description: 'specijalista neurohirurgije'
      },
      {
        id: 2,
        image: goranPavlicevic,
        alternative: '',
        link: '/goran-pavlicevic',
        name: 'dr Goran',
        surname: 'Pavlićević',
        description: 'specijalista neurohirurgije'
      },
      {
        id: 3,
        image: nenadNovakovic,
        alternative: '',
        link: '/nenad-novakovic',
        name: 'dr Nenad',
        surname: 'Novaković',
        description: 'specijalista neurohirurgije'
      },
    ],
  },

  










  










  











  
]

export default blogData;