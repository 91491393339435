import nenadNovakovic from '../../assets/doctors/una-bolnica-nenad-novakovic.webp';
import ljubodragMinic from '../../assets/doctors/una-bolnica-ljubodrag-minic.webp';
import ivicaMedenica from '../../assets/doctors/una-bolnica-ivica-medenica.webp';
import dusanBijelic from '../../assets/doctors/una-bolnica-ivica-medenica.webp';


// list of marketing posts
const blogMarketingData = [
  {
    id: 1,
    image: ljubodragMinic,
    altertnative: '',
    link: 'https://unabolnica.com/ljubodrag-minic'
  },
  {
    id: 2,
    image: ivicaMedenica,
    altertnative: 'dr Ivica Medenica',
    link: 'https://unabolnica.com/ivica-medenica'
  },
  {
    id: 3,
    image: dusanBijelic,
    altertnative: '',
    link: 'https://unabolnica.com/dusan-bijelic'
  },
]

export default blogMarketingData;