import React from "react";
import './home-service-card.css';
import { Link } from "react-router-dom";

export default function HomeServiceCard(props) {

  // part of code that renderes
  return (

    <div>

      {/* small screen home part 2 services card */}
      <Link to={props.url} style={{textDecoration: 'none'}}>
        <div className="ss-home-part-2-service-card-container">
          <p>{props.title}</p>
        </div>
      </Link>

      {/* large screen home part 2 services card */}
      <Link to={props.url} style={{textDecoration: 'none'}}>
        <div className="ls-home-part-2-service-card-container">
          <p>{props.title}</p>
        </div>
      </Link>

    </div>

  )
}