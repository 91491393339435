import React from "react";
import './post.css';
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import { Helmet } from "react-helmet-async";


import BlogHeader from "../blog/blog-header/blog-header.js";
import PostDoctorCard from "./post-doctor-card/post-doctor-card.js";
import BlogRightContainer from "../blog/blog-right-container/blog-right-container.js";
import HomeFooter from "../home/home-footer/home-footer.js";
import ArrowUpIcon from "../arrow-up-icon/arrow-up-icon.js";

import HomeHeader from '../home/home-header/home-header.js';
import HomeNavigationDropdown from "../home/home-navigation-dropdown/home-navigation-dropdown.js";

import decorationImage1 from '../../assets/services/una-bolnica-services-decoration-image-2.webp';
import decorationImage2 from '../../assets/services/una-bolnica-services-decoration-image-1.webp';
import xIcon from '../../assets/doctor-profile/una-bolnica-gallery-x-icon.svg';
import defaultThumbnailImage from '../../assets/home/una-bolnica-default-thumbnail-image.webp'

export default function Post({post}) {

  // this function devide parameter array into more arrays and every of those arrays contain some number of objects which is defined with chunkSize.
  function devideArray(array, chunkSize) {
    const chunks = [];
    let id = 1; // giving id to every group of objects (row) so we can map over them.
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push({
        id: id++,
        data: array.slice(i, i + chunkSize)
      })
    }
    return chunks;
  }

  // this const contains arrays with 3 objects devided from post.team array.
  const teamArrayDevided = devideArray(post.team, 3);

  const [infographicOn, setInfographicOn] = React.useState(false);

  function openInfographic() {
    setInfographicOn((prevState) => {
      return prevState = true
    })
  }

  function closeInfographic() {
    setInfographicOn((prevState) => {
      return prevState = false
    })
  }

    // state value that determine navigation dropdown visibility
    const [dropdown, setDropdown] = React.useState(false);

    // function changes dropdown state value
    function showDropdown() {
      setDropdown((prevState) => {
        return (
          !prevState
        )
      })
    }

    // function reloads page
    function reloadPostPage() {
      window.location.reload();
      window.scrollTo(0, 0);
    }

  // part of code that renders
  return (
    <>

      {post.seoInspector ? (
        <Helmet >
          <title>{post.seoInspector.title}</title>
          <meta
            name="description"
            content={post.seoInspector.metaContent}>
          </meta>
          <link rel="canonical" href={post.seoInspector.link}/>
          <meta property="og:image" content={post.seoInspector.thumbnailImage} />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </Helmet>) :

        (<Helmet>
          <title>Una bolnica Beograd, zakažite pregled na +381652024055</title>
          <meta
            name="description"
            content=
              "Opšta bolnica Una, spinalna hirurgija, plastična hirugija, ortopedija, ORL i druge. Vojvode Skopljanca 19, Zakažite pregled na +381652024055">
          </meta>
          <meta property="og:image" content={defaultThumbnailImage} />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </Helmet>)
      }

      {post.seoInspector &&
        <h1 style={{display: 'none'}}>{post.seoInspector.h1}</h1>
      }

      {post.seoInspector &&
        <h2 style={{display: 'none'}}>{post.seoInspector.h2}</h2>
      }


      <BlogHeader />

      {/* large screen post container */}
      <div className="ls-post-container">

        {/* large screen post decoration image 1 container */}
        <div className="ls-post-decoration-image-1-container">
          <img 
            src={decorationImage1}
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt="una-bolnica-dekoracija-slika"/>
        </div>

        {/* large screen post title 1 container */}
        <div className="ls-post-title-1-container">
          <p>{post.mainTitle.charAt(0).toUpperCase() + post.mainTitle.slice(1)}</p>
        </div>

        {/* large screen post container container */}
        <div className="ls-post-container-container">

          {/* large screen post left container */}
          <div className="ls-post-left-container">

            {/* large screen post left container path */}
            <p className="ls-post-left-container-path" style={{position: 'relative'}}>
              <span>
                <Link to='/' style={{textDecoration: 'none', color: '#7A0020'}}>Naslovna</Link> 
                &nbsp;/&nbsp; 
                <Link to='/blog' style={{textDecoration: 'none', color: '#7A0020'}}>Blog sekcija</Link> 
                &nbsp;/&nbsp;
                <span onClick={reloadPostPage} style={{cursor: 'pointer'}}>
                {post.mainTitle.charAt(0).toUpperCase() + post.mainTitle.slice(1)}
                </span>
              </span>
              <span 
                style={{position: 'absolute', right: '0'}}>
                  {post.postDate}
              </span>
            </p>

            {/* large screen post left rendering objects from blog-data postContent1 */}
            {post.postContent1.map((item) => {
              if (item.type === 'text1' && Array.isArray(item.content)) {
                return (
                  <div 
                    key={item.id}
                    className="ls-post-left-text-1-container">
                      {item.content.map((paragraph) => {
                        return (
                          <p 
                            key={paragraph.id}
                            className="ls-post-left-container-text-1">
                            {paragraph.paragraph}
                          </p>
                        )
                      })}
                </div>
                )
              } if (item.type === 'text2' && Array.isArray(item.content)) {
                return (
                  <ul 
                    key={item.id}
                    className="ls-post-left-text-2-container">
                      {item.content.map((paragraph) => {
                        return (
                          <li 
                            key={paragraph.id}
                            className="ls-post-left-container-text-2">
                              {paragraph.paragraph}
                          </li>
                        )
                      })}
                  </ul>
                )
              } if (item.type === 'title') {
                return (
                  <div 
                    key={item.id}
                    className="ls-post-left-title-container">
                      <p>{item.content}</p>
                  </div>
                )
              } if (item.type === 'image') {
                return (
                  <img 
                    className="ls-post-left-container-image"
                    key={item.id}
                    src={item.content}
                    alt={item.aleternative}
                    onContextMenu={(e) => {e.preventDefault(e)}}/>
                )
              }
            })}

            {/* large screen post left reservation container */}
            {post.reservationContainer &&
              <div className="ls-post-left-reservation-container">

                {/* large screen post left reservation image container */}
                <div className="ls-post-left-reservation-image-container">
                  <img 
                    src={post.reservationContainer[0].content}
                    onContextMenu={(e) => {e.preventDefault(e)}}
                    alt={post.reservationContainer[0].aleternative}/>
                </div>

                {/* large screen post left reservation text & button container */}
                <div className="ls-post-left-reservation-text-button-container">

                  <ul>
                    {post.reservationContainer[1].content.map((paragraph) => {
                      return (
                        <li 
                          key={paragraph.id}
                          className="ls-post-left-reservation-text">
                            {paragraph.paragraph}
                        </li>
                      )
                    })}
                  </ul>
                  
                  {/* large screen post left reservation button */}
                  <Link to='/contact' style={{textDecoration: 'none'}}>
                    <div className="ls-post-left-reservation-button">
                      <p>Zakažite pregled</p>
                    </div>
                  </Link>

                </div>
              </div>
            }

            {/* large screen post left rendering objects from blog-data postContent2 */}
            {post.postContent2 && post.postContent2.map((item) => {
              if (item.type === 'text1' && Array.isArray(item.content)) {
                return (
                  <div 
                    key={item.id}
                    className="ls-post-left-text-1-container">
                      {item.content.map((paragraph) => {
                        return (
                          <p 
                            key={paragraph.id}
                            className="ls-post-left-container-text-1">
                            {paragraph.paragraph}
                          </p>
                        )
                      })}
                </div>
                )
              } if (item.type === 'text2' && Array.isArray(item.content)) {
                return (
                  <ul 
                    key={item.id}
                    className="ls-post-left-text-2-container">
                      {item.content.map((paragraph) => {
                        return (
                          <li 
                            key={paragraph.id}
                            className="ls-post-left-container-text-2">
                              {paragraph.paragraph}
                          </li>
                        )
                      })}
                  </ul>
                )
              } if (item.type === 'title') {
                return (
                  <div 
                    key={item.id}
                    className="ls-post-left-title-container">
                      <p>{item.content}</p>
                  </div>
                )
              } if (item.type === 'image') {
                return (
                  <img 
                    className="ls-post-left-container-image"
                    key={item.id}
                    src={item.content}
                    alt={item.aleternative}
                    onContextMenu={(e) => {e.preventDefault(e)}}/>
                )
              }
            })}

            {/* large screen post left rendering objects from blog-data videoContent */}
            {post.videoContent &&
              // large screen post left video container
              <div className="ls-post-left-video-container">
                <p className="ls-post-left-video-title">
                  <span>
                    Pogledajte video:
                  </span> &nbsp; 
                  {post.videoContent[0].title}
                </p>
                <ReactPlayer 
                  url={post.videoContent[1].link}
                  controls
                  width={53.333 + 'vw'}
                  height={30 + 'vw'}/>
              </div>
            }

            {/* large screen post left rendering objects from blog-data postContent3 */}
            {post.postContent3 && post.postContent3.map((item) => {
              if (item.type === 'text1' && Array.isArray(item.content)) {
                return (
                  <div 
                    key={item.id}
                    className="ls-post-left-text-1-container">
                      {item.content.map((paragraph) => {
                        return (
                          <p 
                            key={paragraph.id}
                            className="ls-post-left-container-text-1">
                            {paragraph.paragraph}
                          </p>
                        )
                      })}
                </div>
                )
              } if (item.type === 'text2' && Array.isArray(item.content)) {
                return (
                  <ul 
                    key={item.id}
                    className="ls-post-left-text-2-container">
                      {item.content.map((paragraph) => {
                        return (
                          <li 
                            key={paragraph.id}
                            className="ls-post-left-container-text-2">
                              {paragraph.paragraph}
                          </li>
                        )
                      })}
                  </ul>
                )
              } if (item.type === 'title') {
                return (
                  <div 
                    key={item.id}
                    className="ls-post-left-title-container">
                      <p>{item.content}</p>
                  </div>
                )
              } if (item.type === 'image') {
                return (
                  <img 
                    className="ls-post-left-container-image"
                    key={item.id}
                    src={item.content}
                    alt={item.aleternative}
                    onContextMenu={(e) => {e.preventDefault(e)}}/>
                )
              }
            })}

            {/* large screen post left rendering infographic */}
            {post.infographic &&
              <img
                className="ls-post-left-infographic-image" 
                src={post.infographic[0].image}
                onContextMenu={(e) => {e.preventDefault(e)}}
                alt={post.infographic[0].aleternative}
                onClick={openInfographic}/>
            }

            {/* large screen post left infographic large image */}
            {infographicOn && 
              <div 
                className="ls-post-left-infographic-background-container"
                onClick={closeInfographic}>

              {/* large screen post left infographic large image */}
              <div className="ls-post-left-infographic-large-image-container">
                <img 
                  src={post.infographic[0].image}
                  onContextMenu={(e) => {e.preventDefault(e)}}
                  alt={post.infographic[0].aleternative}/>
                
                {/* large screen post left infographic x container */}
                <div className="ls-post-left-infographic-x-container">
                  <img 
                    src={xIcon}
                    onContextMenu={(e) => {e.preventDefault(e)}}
                    alt='una-bolnica-x-ikonica'
                    onClick={closeInfographic}/>
                </div>
              </div>

              </div>
            }

            {/* large screen post left rendering objects from blog-data postContent4 */}
            {post.postContent4 && post.postContent4.map((item) => {
              if (item.type === 'text1' && Array.isArray(item.content)) {
                return (
                  <div 
                    key={item.id}
                    className="ls-post-left-text-1-container">
                      {item.content.map((paragraph) => {
                        return (
                          <p 
                            key={paragraph.id}
                            className="ls-post-left-container-text-1">
                            {paragraph.paragraph}
                          </p>
                        )
                      })}
                </div>
                )
              } if (item.type === 'text2' && Array.isArray(item.content)) {
                return (
                  <ul 
                    key={item.id}
                    className="ls-post-left-text-2-container">
                      {item.content.map((paragraph) => {
                        return (
                          <li 
                            key={paragraph.id}
                            className="ls-post-left-container-text-2">
                              {paragraph.paragraph}
                          </li>
                        )
                      })}
                  </ul>
                )
              } if (item.type === 'title') {
                return (
                  <div 
                    key={item.id}
                    className="ls-post-left-title-container">
                      <p>{item.content}</p>
                  </div>
                )
              } if (item.type === 'image') {
                return (
                  <img 
                    className="ls-post-left-container-image"
                    key={item.id}
                    src={item.content}
                    alt={item.aleternative}
                    onContextMenu={(e) => {e.preventDefault(e)}}/>
                )
              }
            })}

            {/* large screen post left rendering objects from blog-data postContent5 */}
            {post.postContent5 && post.postContent5.map((item) => {
              if (item.type === 'text1' && Array.isArray(item.content)) {
                return (
                  <div 
                    key={item.id}
                    className="ls-post-left-text-1-container">
                      {item.content.map((paragraph) => {
                        return (
                          <p 
                            key={paragraph.id}
                            className="ls-post-left-container-text-1">
                            {paragraph.paragraph}
                          </p>
                        )
                      })}
                </div>
                )
              } if (item.type === 'text2' && Array.isArray(item.content)) {
                return (
                  <ul 
                    key={item.id}
                    className="ls-post-left-text-2-container">
                      {item.content.map((paragraph) => {
                        return (
                          <li 
                            key={paragraph.id}
                            className="ls-post-left-container-text-2">
                              {paragraph.paragraph}
                          </li>
                        )
                      })}
                  </ul>
                )
              } if (item.type === 'title') {
                return (
                  <div 
                    key={item.id}
                    className="ls-post-left-title-container">
                      <p>{item.content}</p>
                  </div>
                )
              } if (item.type === 'image') {
                return (
                  <img 
                    className="ls-post-left-container-image"
                    key={item.id}
                    src={item.content}
                    alt={item.aleternative}
                    onContextMenu={(e) => {e.preventDefault(e)}}/>
                )
              }
            })}

            {/* large screen post left rendering objects from blog-data postContent6 */}
            {post.postContent6 && post.postContent6.map((item) => {
              if (item.type === 'text1' && Array.isArray(item.content)) {
                return (
                  <div 
                    key={item.id}
                    className="ls-post-left-text-1-container">
                      {item.content.map((paragraph) => {
                        return (
                          <p 
                            key={paragraph.id}
                            className="ls-post-left-container-text-1">
                            {paragraph.paragraph}
                          </p>
                        )
                      })}
                </div>
                )
              } if (item.type === 'text2' && Array.isArray(item.content)) {
                return (
                  <ul 
                    key={item.id}
                    className="ls-post-left-text-2-container">
                      {item.content.map((paragraph) => {
                        return (
                          <li 
                            key={paragraph.id}
                            className="ls-post-left-container-text-2">
                              {paragraph.paragraph}
                          </li>
                        )
                      })}
                  </ul>
                )
              } if (item.type === 'title') {
                return (
                  <div 
                    key={item.id}
                    className="ls-post-left-title-container">
                      <p>{item.content}</p>
                  </div>
                )
              } if (item.type === 'image') {
                return (
                  <img 
                    className="ls-post-left-container-image"
                    key={item.id}
                    src={item.content}
                    alt={item.aleternative}
                    onContextMenu={(e) => {e.preventDefault(e)}}/>
                )
              }
            })}

            {/* large screen post left rendering objects from blog-data postContent7 */}
            {post.postContent7 && post.postContent7.map((item) => {
              if (item.type === 'text1' && Array.isArray(item.content)) {
                return (
                  <div 
                    key={item.id}
                    className="ls-post-left-text-1-container">
                      {item.content.map((paragraph) => {
                        return (
                          <p 
                            key={paragraph.id}
                            className="ls-post-left-container-text-1">
                            {paragraph.paragraph}
                          </p>
                        )
                      })}
                </div>
                )
              } if (item.type === 'text2' && Array.isArray(item.content)) {
                return (
                  <ul 
                    key={item.id}
                    className="ls-post-left-text-2-container">
                      {item.content.map((paragraph) => {
                        return (
                          <li 
                            key={paragraph.id}
                            className="ls-post-left-container-text-2">
                              {paragraph.paragraph}
                          </li>
                        )
                      })}
                  </ul>
                )
              } if (item.type === 'title') {
                return (
                  <div 
                    key={item.id}
                    className="ls-post-left-title-container">
                      <p>{item.content}</p>
                  </div>
                )
              } if (item.type === 'image') {
                return (
                  <img 
                    className="ls-post-left-container-image"
                    key={item.id}
                    src={item.content}
                    alt={item.aleternative}
                    onContextMenu={(e) => {e.preventDefault(e)}}/>
                )
              }
            })}

            {/* large screen post left rendering objects from blog-data postContent8 */}
            {post.postContent8 && post.postContent8.map((item) => {
              if (item.type === 'text1' && Array.isArray(item.content)) {
                return (
                  <div 
                    key={item.id}
                    className="ls-post-left-text-1-container">
                      {item.content.map((paragraph) => {
                        return (
                          <p 
                            key={paragraph.id}
                            className="ls-post-left-container-text-1">
                            {paragraph.paragraph}
                          </p>
                        )
                      })}
                </div>
                )
              } if (item.type === 'text2' && Array.isArray(item.content)) {
                return (
                  <ul 
                    key={item.id}
                    className="ls-post-left-text-2-container">
                      {item.content.map((paragraph) => {
                        return (
                          <li 
                            key={paragraph.id}
                            className="ls-post-left-container-text-2">
                              {paragraph.paragraph}
                          </li>
                        )
                      })}
                  </ul>
                )
              } if (item.type === 'title') {
                return (
                  <div 
                    key={item.id}
                    className="ls-post-left-title-container">
                      <p>{item.content}</p>
                  </div>
                )
              } if (item.type === 'image') {
                return (
                  <img 
                    className="ls-post-left-container-image"
                    key={item.id}
                    src={item.content}
                    alt={item.aleternative}
                    onContextMenu={(e) => {e.preventDefault(e)}}/>
                )
              }
            })}

            {/* large screen post left horizontal rule */}
            <hr className="ls-post-left-horizontal-rule" />
            
            {/* large screen post left team title */}
            <p className="ls-post-left-team-title">Naš tim</p>

            {/* large screen post left rendering team */}
            <div className="ls-post-left-team-container">
              {post.team && 
                teamArrayDevided.map((row) => {
                return (
                  <div 
                    key={row.id}
                    className="ls-post-left-team-row-container">
                      {row.data.map((card, index) => {
                        return (
                          <PostDoctorCard 
                            key={index}
                            card={card} // passing object info represented as card to PostDoctorCard component 
                          />
                        )
                      })}
                  </div>
                )
              }) 
              }
            </div>

          </div>

          <BlogRightContainer />
        </div> 
      </div>










      {/* small screen post container */}
      <div className="ss-post-container">
        
        <HomeHeader 
          showDropdown={showDropdown} // passing showDropdown function to HomeHeader component
          dropdownValue={dropdown} // passing dropdown state value to HomeHeader component
        />

        {dropdown && <HomeNavigationDropdown />}

        {/* small screen blog decoration image 1 container */}
        <div className="ss-blog-decoration-image-1-container">
          <img
            src={decorationImage2}
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt="una-bolnica-dekoracija-slika"/>
        </div>

        {/* small screen blog title 1 container */}
        <div className="ss-blog-title-1-container">
          <p>{post.mainTitle.charAt(0).toUpperCase() + post.mainTitle.slice(1)}</p>
        </div>

        {/* small screen post content 1 container */}
        <div className="ss-post-content-1-container">
          {/* small screen post rendering objects from blog-data postContent1 */}
          {post.postContent1.map((item) => {
            if (item.type === 'text1' && Array.isArray(item.content)) {
              return (
                <div 
                  key={item.id}
                  className="ss-post-text-1-container">
                    {item.content.map((paragraph) => {
                      return (
                        <p 
                          key={paragraph.id}
                          className="ss-post-container-text-1">
                          {paragraph.paragraph}
                        </p>
                      )
                    })}
              </div>
              )
            } if (item.type === 'text2' && Array.isArray(item.content)) {
              return (
                <ul 
                  key={item.id}
                  className="ss-post-text-2-container">
                    {item.content.map((paragraph) => {
                      return (
                        <li 
                          key={paragraph.id}
                          className="ss-post-container-text-2">
                            {paragraph.paragraph}
                        </li>
                      )
                    })}
                </ul>
              )
            } if (item.type === 'title') {
              return (
                <div 
                  key={item.id}
                  className="ss-post-title-container">
                    <p>{item.content}</p>
                </div>
              )
            } if (item.type === 'image') {
              return (
                <img 
                  className="ss-post-container-image"
                  key={item.id}
                  src={item.content}
                  alt={item.aleternative}
                  onContextMenu={(e) => {e.preventDefault(e)}}/>
              )
            }
          })}
        </div>

        {/* small screen post reservation container */}
        {post.reservationContainer &&
        <div className="ss-post-reservation-container">

          {/* small screen post reservation image container */}
          <div className="ss-post-reservation-image-container">
            <img 
              src={post.reservationContainer[0].content}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt={post.reservationContainer[0].aleternative}/>
          </div>

          <ul>
            {post.reservationContainer[1].content.map((paragraph) => {
              return (
                <li 
                  key={paragraph.id}
                  className="ls-post-reservation-text">
                    {paragraph.paragraph}
                </li>
              )
            })}
          </ul>
          
          {/* small screen post reservation button */}
          <Link to='/contact' style={{textDecoration: 'none'}}>
            <div className="ls-post-reservation-button">
              <p>Zakažite pregled</p>
            </div>
          </Link>
        </div>
        }

        {/* small screen post content 2 container */}
        {post.postContent2 && 
        <div className="ss-post-content-2-container">
            {/* small screen post rendering objects from blog-data postContent2 */}
            {post.postContent2.map((item) => {
              if (item.type === 'text1' && Array.isArray(item.content)) {
                return (
                  <div 
                    key={item.id}
                    className="ss-post-text-1-container">
                      {item.content.map((paragraph) => {
                        return (
                          <p 
                            key={paragraph.id}
                            className="ss-post-container-text-1">
                            {paragraph.paragraph}
                          </p>
                        )
                      })}
                </div>
                )
              } if (item.type === 'text2' && Array.isArray(item.content)) {
                return (
                  <ul 
                    key={item.id}
                    className="ss-post-text-2-container">
                      {item.content.map((paragraph) => {
                        return (
                          <li 
                            key={paragraph.id}
                            className="ss-post-container-text-2">
                              {paragraph.paragraph}
                          </li>
                        )
                      })}
                  </ul>
                )
              } if (item.type === 'title') {
                return (
                  <div 
                    key={item.id}
                    className="ss-post-title-container">
                      <p>{item.content}</p>
                  </div>
                )
              } if (item.type === 'image') {
                return (
                  <img 
                    className="ss-post-container-image"
                    key={item.id}
                    src={item.content}
                    alt={item.aleternative}
                    onContextMenu={(e) => {e.preventDefault(e)}}/>
                )
              }
            })}
          </div>}

          {/* small screen post rendering objects from blog-data videoContent */}
          {post.videoContent &&
          // small screen post video container
          <div className="ss-post-video-container">
            <p className="ss-post-video-title">
              <span>
                Pogledajte video:
              </span> &nbsp; 
              {post.videoContent[0].title}
            </p>
            <ReactPlayer 
              url={post.videoContent[1].link}
              controls
              width={100 + '%'}
              height={62.222 + 'vw'}/>
          </div>
          }

          {/* small screen post content 3 container */}
          {post.postContent3 && 
          <div className="ss-post-content-3-container">
            {/* small screen post rendering objects from blog-data postContent3 */}
            {post.postContent3.map((item) => {
              if (item.type === 'text1' && Array.isArray(item.content)) {
                return (
                  <div 
                    key={item.id}
                    className="ss-post-text-1-container">
                      {item.content.map((paragraph) => {
                        return (
                          <p 
                            key={paragraph.id}
                            className="ss-post-container-text-1">
                            {paragraph.paragraph}
                          </p>
                        )
                      })}
                </div>
                )
              } if (item.type === 'text2' && Array.isArray(item.content)) {
                return (
                  <ul 
                    key={item.id}
                    className="ss-post-text-2-container">
                      {item.content.map((paragraph) => {
                        return (
                          <li 
                            key={paragraph.id}
                            className="ss-post-container-text-2">
                              {paragraph.paragraph}
                          </li>
                        )
                      })}
                  </ul>
                )
              } if (item.type === 'title') {
                return (
                  <div 
                    key={item.id}
                    className="ss-post-title-container">
                      <p>{item.content}</p>
                  </div>
                )
              } if (item.type === 'image') {
                return (
                  <img 
                    className="ss-post-container-image"
                    key={item.id}
                    src={item.content}
                    alt={item.aleternative}
                    onContextMenu={(e) => {e.preventDefault(e)}}/>
                )
              }
            })}
          </div>}

          {/* small screen post rendering infographic */}
          {post.infographic &&
          <img
            className="ss-post-infographic-image" 
            src={post.infographic[0].image}
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt={post.infographic[0].aleternative}
            onClick={openInfographic}/>
          }

          {/* small screen post infographic large image */}
          {infographicOn && 
          <div 
            className="ss-post-infographic-background-container"
            onClick={closeInfographic}>

            {/* small screen post infographic large image */}
            <div className="ss-post-infographic-large-image-container">
              <img 
                src={post.infographic[0].image}
                onContextMenu={(e) => {e.preventDefault(e)}}
                alt={post.infographic[0].aleternative}/>
              
              {/* small screen post infographic x container */}
              <div className="ss-post-infographic-x-container">
                <img 
                  src={xIcon}
                  onContextMenu={(e) => {e.preventDefault(e)}}
                  alt='una-bolnica-x-ikonica'
                  onClick={closeInfographic}/>
              </div>
            </div>
          </div>
          }

          {/* small screen post content 4 container */}
          {post.postContent4 && 
          <div className="ss-post-content-4-container">
            {/* small screen post rendering objects from blog-data postContent4 */}
            {post.postContent4.map((item) => {
              if (item.type === 'text1' && Array.isArray(item.content)) {
                return (
                  <div 
                    key={item.id}
                    className="ss-post-text-1-container">
                      {item.content.map((paragraph) => {
                        return (
                          <p 
                            key={paragraph.id}
                            className="ss-post-container-text-1">
                            {paragraph.paragraph}
                          </p>
                        )
                      })}
                </div>
                )
              } if (item.type === 'text2' && Array.isArray(item.content)) {
                return (
                  <ul 
                    key={item.id}
                    className="ss-post-text-2-container">
                      {item.content.map((paragraph) => {
                        return (
                          <li 
                            key={paragraph.id}
                            className="ss-post-container-text-2">
                              {paragraph.paragraph}
                          </li>
                        )
                      })}
                  </ul>
                )
              } if (item.type === 'title') {
                return (
                  <div 
                    key={item.id}
                    className="ss-post-title-container">
                      <p>{item.content}</p>
                  </div>
                )
              } if (item.type === 'image') {
                return (
                  <img 
                    className="ss-post-container-image"
                    key={item.id}
                    src={item.content}
                    alt={item.aleternative}
                    onContextMenu={(e) => {e.preventDefault(e)}}/>
                )
              }
            })}
          </div>}

          {/* small screen post content 5 container */}
          {post.postContent5 && 
          <div className="ss-post-content-5-container">
            {/* small screen post rendering objects from blog-data postContent5 */}
            {post.postContent5.map((item) => {
              if (item.type === 'text1' && Array.isArray(item.content)) {
                return (
                  <div 
                    key={item.id}
                    className="ss-post-text-1-container">
                      {item.content.map((paragraph) => {
                        return (
                          <p 
                            key={paragraph.id}
                            className="ss-post-container-text-1">
                            {paragraph.paragraph}
                          </p>
                        )
                      })}
                </div>
                )
              } if (item.type === 'text2' && Array.isArray(item.content)) {
                return (
                  <ul 
                    key={item.id}
                    className="ss-post-text-2-container">
                      {item.content.map((paragraph) => {
                        return (
                          <li 
                            key={paragraph.id}
                            className="ss-post-container-text-2">
                              {paragraph.paragraph}
                          </li>
                        )
                      })}
                  </ul>
                )
              } if (item.type === 'title') {
                return (
                  <div 
                    key={item.id}
                    className="ss-post-title-container">
                      <p>{item.content}</p>
                  </div>
                )
              } if (item.type === 'image') {
                return (
                  <img 
                    className="ss-post-container-image"
                    key={item.id}
                    src={item.content}
                    alt={item.aleternative}
                    onContextMenu={(e) => {e.preventDefault(e)}}/>
                )
              }
            })}
          </div>}

          {/* small screen post content 6 container */}
          {post.postContent6 && 
          <div className="ss-post-content-6-container">
            {/* small screen post rendering objects from blog-data postContent6 */}
            {post.postContent6.map((item) => {
              if (item.type === 'text1' && Array.isArray(item.content)) {
                return (
                  <div 
                    key={item.id}
                    className="ss-post-text-1-container">
                      {item.content.map((paragraph) => {
                        return (
                          <p 
                            key={paragraph.id}
                            className="ss-post-container-text-1">
                            {paragraph.paragraph}
                          </p>
                        )
                      })}
                </div>
                )
              } if (item.type === 'text2' && Array.isArray(item.content)) {
                return (
                  <ul 
                    key={item.id}
                    className="ss-post-text-2-container">
                      {item.content.map((paragraph) => {
                        return (
                          <li 
                            key={paragraph.id}
                            className="ss-post-container-text-2">
                              {paragraph.paragraph}
                          </li>
                        )
                      })}
                  </ul>
                )
              } if (item.type === 'title') {
                return (
                  <div 
                    key={item.id}
                    className="ss-post-title-container">
                      <p>{item.content}</p>
                  </div>
                )
              } if (item.type === 'image') {
                return (
                  <img 
                    className="ss-post-container-image"
                    key={item.id}
                    src={item.content}
                    alt={item.aleternative}
                    onContextMenu={(e) => {e.preventDefault(e)}}/>
                )
              }
            })}
          </div>}

          {/* small screen post content 7 container */}
          {post.postContent7 && 
          <div className="ss-post-content-7-container">
            {/* small screen post rendering objects from blog-data postContent7 */}
            {post.postContent7.map((item) => {
              if (item.type === 'text1' && Array.isArray(item.content)) {
                return (
                  <div 
                    key={item.id}
                    className="ss-post-text-1-container">
                      {item.content.map((paragraph) => {
                        return (
                          <p 
                            key={paragraph.id}
                            className="ss-post-container-text-1">
                            {paragraph.paragraph}
                          </p>
                        )
                      })}
                </div>
                )
              } if (item.type === 'text2' && Array.isArray(item.content)) {
                return (
                  <ul 
                    key={item.id}
                    className="ss-post-text-2-container">
                      {item.content.map((paragraph) => {
                        return (
                          <li 
                            key={paragraph.id}
                            className="ss-post-container-text-2">
                              {paragraph.paragraph}
                          </li>
                        )
                      })}
                  </ul>
                )
              } if (item.type === 'title') {
                return (
                  <div 
                    key={item.id}
                    className="ss-post-title-container">
                      <p>{item.content}</p>
                  </div>
                )
              } if (item.type === 'image') {
                return (
                  <img 
                    className="ss-post-container-image"
                    key={item.id}
                    src={item.content}
                    alt={item.aleternative}
                    onContextMenu={(e) => {e.preventDefault(e)}}/>
                )
              }
            })}
          </div>}

          {/* small screen post content 8 container */}
          {post.postContent8 && 
          <div className="ss-post-content-8-container">
            {/* small screen post rendering objects from blog-data postContent8 */}
            {post.postContent8.map((item) => {
              if (item.type === 'text1' && Array.isArray(item.content)) {
                return (
                  <div 
                    key={item.id}
                    className="ss-post-text-1-container">
                      {item.content.map((paragraph) => {
                        return (
                          <p 
                            key={paragraph.id}
                            className="ss-post-container-text-1">
                            {paragraph.paragraph}
                          </p>
                        )
                      })}
                </div>
                )
              } if (item.type === 'text2' && Array.isArray(item.content)) {
                return (
                  <ul 
                    key={item.id}
                    className="ss-post-text-2-container">
                      {item.content.map((paragraph) => {
                        return (
                          <li 
                            key={paragraph.id}
                            className="ss-post-container-text-2">
                              {paragraph.paragraph}
                          </li>
                        )
                      })}
                  </ul>
                )
              } if (item.type === 'title') {
                return (
                  <div 
                    key={item.id}
                    className="ss-post-title-container">
                      <p>{item.content}</p>
                  </div>
                )
              } if (item.type === 'image') {
                return (
                  <img 
                    className="ss-post-container-image"
                    key={item.id}
                    src={item.content}
                    alt={item.aleternative}
                    onContextMenu={(e) => {e.preventDefault(e)}}/>
                )
              }
            })}
          </div>}

          {/* small screen post horizontal rule */}
          <div className="ss-post-horizontal-rule"></div>

          {/* small screen post rendering team */}
          {post.team &&
          <div className="ss-post-team-container">
            {post.team.map((card) => {
              return (
                <PostDoctorCard 
                  key={card.id}
                  card={card}
                />
              )
            })
            }
          </div>}
      </div>

      <HomeFooter />

      <ArrowUpIcon />
    </>
  )
}