import React from "react";
import './home-header.css';
import { Link } from "react-router-dom";

import unaBolnicaLogo from '../../../assets/home/una-bolnica-logo-1.svg';
import phoneIcon from '../../../assets/home/una-bolnica-phone-icon-1.svg';
import navigationIcon1 from '../../../assets/home/una-bolnica-navigation-icon-1.svg';
import navigationIcon2 from '../../../assets/home/una-bolnica-navigation-icon-2.svg';

export default function HomeHeader(props) {

  /* part of code that renders */
  return (

    <div>
      
      {/* small screen home header container */}
      <div className="ss-home-header-container">
        
        {/* small screen home header logo container */}
        <Link to='/'>
          <div 
            className="ss-home-header-logo-container"
            onClick={props.reloadHomePage}> {/* receiving reloadHomePage function from Home component trough HomePart1 componnet */}
            <img 
              src={unaBolnicaLogo}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-bolnica-logo"/>
          </div>
        </Link>

        {/* small screen home header phone button container */}
        <a href="tel:+3816520240555">
          <div className="ss-home-header-phone-button-container">
            <img 
              src={phoneIcon}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-bolnica-telefon"/>
          </div>
        </a>

        {/* small screen home header navigation button container */}
        <div 
          className="ss-home-header-navigation-button-container"
          onClick={props.showDropdown}>
            <img 
              src={props.dropdownValue ? navigationIcon2 : navigationIcon1}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-bolnica-padajući-meni"/>
        </div>
      </div>









      {/* large screen home header container */}
      <div className="ls-home-header-container">

        {/* large screen home header container container */}
        <div className="ls-home-header-container-container">

          {/* large screen home header logo container */}
          <Link to='/'>
            <div 
              className="ls-home-header-logo-container"
              onClick={props.reloadHomePage}> {/* receiving reloadHomePage function from Home component trough HomePart1 componnet */}
              <img 
                src={unaBolnicaLogo}
                onContextMenu={(e) => {e.preventDefault(e)}}
                alt="una-bolnica-logo"/>
            </div>
          </Link>

          {/* large screen home navigation container */}
          <div className="ls-home-navigation-container">

            {/* large screen home navigation list container */}
            <ul>
              <Link to='/' style={{textDecoration: 'none'}}>
                <li onClick={props.reloadHomePage}>Naslovna</li>
              </Link>

              <Link style={{textDecoration: 'none'}}>
                <li>O nama</li>
              </Link>
              
              <Link to='/doctors' style={{textDecoration: 'none'}}>
                <li>Tim</li>
              </Link>
              
              <Link to='/services' style={{textDecoration: 'none'}}>
                <li>Usluge</li>
              </Link>

              <Link to='/blog' style={{textDecoration: 'none'}}>
                <li>Blog</li>
              </Link>
            </ul>

            {/* large screen home navigation contact button container */}
            <Link to='/contact' state={{textDecoration: 'none'}}>
              <div className="ls-home-navigation-contact-button-container">
                <p>Kontaktirajte nas</p>
              </div>
            </Link>

          </div>
        
        </div>

      </div>
    
    </div>

  )
}