import React from "react";
import './services.css';
import { Helmet } from "react-helmet-async";

import HomeHeader from '../../components/home/home-header/home-header.js';
import HomeNavigationDropdown from "../../components/home/home-navigation-dropdown/home-navigation-dropdown.js";
import ServicesServiceCard from '../../components/services/services-service-card/services-service-card.js';
import servicesData from "../../assets/services-data/services-data.js";
import HomeFooter from '../../components/home/home-footer/home-footer.js';
import ArrowUpIcon from '../../components/arrow-up-icon/arrow-up-icon.js';

import decorationImage1 from '../../assets/services/una-bolnica-services-decoration-image-1.webp';
import decorationImage2 from '../../assets/home/una-bolnica-home-decoration-2.svg';
import decorationImage3 from '../../assets/home/una-bolnica-home-decoration-3.svg';
import decorationImage4 from '../../assets/services/una-bolnica-services-decoration-image-2.webp';
import phoneButton from '../../assets/services/una-bolnica-services-phone-button.svg';
import defaultThumbnailImage from '../../assets/home/una-bolnica-default-thumbnail-image.webp';

export default function Services() {

  // state value that determine navigation dropdown visibility
  const [dropdown, setDropdown] = React.useState(false)

  // function changes dropdown state value
  function showDropdown() {
    setDropdown((prevState) => {
      return (
        !prevState
      )
    })
  }

  // reload page function
  function reloadServicesPage() {
    window.location.reload();
  }

  
  // serviceDataState state contains every card inforamtion that is passed to ServicesServiceCard component
  const [serviceDataState, setServiceDataState] = React.useState([
    {
      cardId: 1,
      isOn: false,
      background: '#f8f8f8',
      title: 'OPŠTA I ABDOMINALNA HIRURGIJA',
      color: '#7A0020'
    },
    {
      cardId: 2,
      isOn: false,
      backgroundImage: 'linear-gradient(to right, rgba(250, 200, 200, 1), rgba(250, 200, 200, 1))',
      title: 'ORTOPEDIJA SA TRAUMATOLOGIJOM',
      color: 'white'
    },
    {
      cardId: 3,
      isOn: false,
      background: '#f8f8f8',
      title: 'VASKULARNA HIRURGIJA',
      color: '#7A0020'
    },
    {
      cardId: 4,
      isOn: false,
      background: '#f8f8f8',
      title: 'TORAKALNA HIRURGIJA',
      color: '#7A0020'
    },
    {
      cardId: 5,
      isOn: false,
      backgroundImage: 'linear-gradient(to right, rgba(250, 200, 200, 1), rgba(250, 200, 200, 1))',
      title: 'NEUROHIRURGIJA',
      color: 'white'
    },
    {
      cardId: 6,
      isOn: false,
      background: '#f8f8f8',
      title: 'UROLOGIJA',
      color: '#7A0020'
    },
    {
      cardId: 7,
      isOn: false,
      background: '#f8f8f8',
      title: 'MAKSILOFACIJALNA HIRURGIJA',
      color: '#7A0020'
    },
    {
      cardId: 8,
      isOn: false,
      background: '#f8f8f8',
      title: 'DEČIJA HIRURGIJA',
      color: '#7A0020'
    },
    {
      cardId: 9,
      isOn: false,
      background: '#f8f8f8',
      title: 'ANESTEZIOLOGIJA',
      color: '#7A0020'
    },
    {
      cardId: 10
    },
    {
      cardId: 11,
      isOn: false,
      backgroundImage: 'linear-gradient(to right, rgba(250, 200, 200, 1), rgba(250, 200, 200, 1))',
      title: 'HIRURGIJA DOJKE',
      color: 'white'
    },
    {
      cardId: 12,
      isOn: false,
      background: '#f8f8f8',
      title: 'LIPOSUKCIJA',
      color: '#7A0020'
    },
    {
      cardId: 13,
      isOn: false,
      background: '#f8f8f8',
      title: 'ABDOMINOPLASTIKA',
      color: '#7A0020'
    },
    {
      cardId: 14,
      isOn: false,
      background: '#f8f8f8',
      title: 'KOREKCIJA KAPAKA',
      color: '#7A0020'
    },
    {
      cardId: 15,
      isOn: false,
      background: '#f8f8f8',
      title: 'KOREKCIJA UŠIJU',
      color: '#7A0020'
    },
    {
      cardId: 16,
      isOn: false,
      backgroundImage: 'linear-gradient(to right, rgba(250, 200, 200, 1), rgba(250, 200, 200, 1))',
      title: 'KOREKCIJA NOSA',
      color: 'white'
    },
    {
      cardId: 17,
      isOn: false,
      background: '#f8f8f8',
      title: 'FACE LIFTING',
      color: '#7A0020'
    },
    {
      cardId: 18
    },
    {
      cardId: 19,
      isOn: false,
      background: '#f8f8f8',
      title: 'KARDIOLOGIJA',
      color: '#7A0020'
    },
    {
      cardId: 20,
      isOn: false,
      background: '#f8f8f8',
      title: 'PULMOLOGIJA',
      color: '#7A0020'
    },
    {
      cardId: 21,
      isOn: false,
      background: '#f8f8f8',
      title: 'ENDOKRINOLOGIJA',
      color: '#7A0020'
    },
    {
      cardId: 22,
      isOn: false,
      background: '#f8f8f8',
      title: 'REUMATOLOGIJA',
      color: '#7A0020'
    },
    {
      cardId: 23,
      isOn: false,
      background: '#f8f8f8',
      title: 'HEMATOLOGIJA',
      color: '#7A0020'
    },
    {
      cardId: 24,
      isOn: false,
      background: '#f8f8f8',
      title: 'GASTROENTEROLOGIJA',
      color: '#7A0020'
    },
    {
      cardId: 25
    },
    {
      cardId: 26,
      isOn: false,
      backgroundImage: 'linear-gradient(to right, rgba(250, 200, 200, 1), rgba(250, 200, 200, 1))',
      title: 'OTORINOLARINGOLOGIJA',
      color: 'white'
    },
    {
      cardId: 27,
      isOn: false,
      background: '#f8f8f8',
      title: 'NEUROLOGIJA',
      color: '#7A0020'
    },
    {
      cardId: 28,
      isOn: false,
      background: '#f8f8f8',
      title: 'GINEKOLOGIJA',
      color: '#7A0020'
    },
    {
      cardId: 29,
      isOn: false,
      background: '#f8f8f8',
      title: 'FIZIKALNA MEDICINA',
      color: '#7A0020'
    },
    {
      cardId: 30,
      isOn: false,
      background: '#f8f8f8',
      title: 'RADIOLOGIJA',
      color: '#7A0020'
    },
    {
      cardId: 31,
      isOn: false,
      background: '#f8f8f8',
      title: 'PEDIJATRIJA',
      color: '#7A0020'
    }
  ])

  // showCardContent function changing isOn value in card state for card that is cliced and returning isOn: false for every other card
  const showCardContent = (id) => {
    setServiceDataState((prevState) => {
      return prevState.map((card) => {
        if (card.cardId === id) {
          return {...card, isOn: !card.isOn};
        } else {
          return {...card, isOn: false};
        }
      })
    })
  } 

  // part of code that renders
  return (

    <>

      <Helmet>
        <title>Una bolnica Beograd, zakažite pregled na +381652024055</title>
        <meta
          name="description"
          content=
            "Opšta bolnica Una, spinalna hirurgija, plastična hirugija, ortopedija, ORL i druge. Vojvode Skopljanca 19, Zakažite pregled na +381652024055">
        </meta>
        <link rel="canonical" href="https://unabolnica.com/services"/>
        <meta property="og:image" content={defaultThumbnailImage} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>

      {/* small screen services container */}
      <div className="ss-services-container">
        
        <HomeHeader 
          reloadPage={reloadServicesPage} // passing reloadServicesPage function from Service component to HomeHeader component 
          showDropdown={showDropdown} // passing showDropdown function to HomeHeader component
          dropdownValue={dropdown} // passing dropdown state value to HomeHeader component
        />

        {dropdown && 
          <HomeNavigationDropdown
            reloadServicesPage={reloadServicesPage} // passing realodServicesPage to HomeNavigationDropdown component
          /> 
        } 

        {/* small screen services decoration image 1 container */}
        <div className="ss-services-decoration-image-1-container">
          <img 
            src={decorationImage1}
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt="una-bolnica-dekoracija-slika"/>
        </div> 

        {/* small screen services title 1 container */}
        <div className="ss-services-title-1-container">
          <h1>Usluge</h1>
        </div>    

        {/* small screen services text 1 container */}
        <div className="ss-services-text-1-container">
          
          {/* small screen services text 1 decoration 1 container */}
          <div className="ss-services-text-1-decoration-container">
            <img 
              src={decorationImage2}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-bolnica-dekoracija"/>
          </div>

          {/* small screen services text 1 text container */}
          <div className="ss-services-text-1-text-container">

            <h2>Široka zdravstvena nega u Una bolnici</h2>

            <p>
              Od trenutka kada smo počeli sa radom, 1997. godine, vratili smo osmehe na lica mnogih pacijenata i njihovih najbližih. Uspešno smo izveli veliki broj zahtevnih i teških operacija, tretirali pacijente otpuštene sa drugih klinika, gradeći tako reputaciju klinike koja uliva poverenje.
            </p>

            <p>
              Tri operacione sale opremljene su dovodima za centralne gasove i kompletnim sistemom monitoringa. Bolnica poseduje sobu intezivne terapije i 12 bolesničkih soba sa 20 kreveta, koje poseduju zasebna kupatila, internet, klima uređaje i kablovsku televiziju.
            </p>
          
          </div>

          {/* small screen services text 1 decoration 2 container */}
          <div className="ss-services-text-1-decoration-container ss-services-text-1-decoration-2-container">
            <img 
              src={decorationImage3}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-bolnica-dekoracija"/>
          </div>

        </div> 

        {/* small screen services title 2 container */}
        <div className="ss-services-title-2-container">
          <h2>Hirurgija</h2>
        </div>

        {/* mapping over state array objects and displaying ServicesServiceCard component for every object in array */}
        {serviceDataState.map((card, index) => {
          if (card.cardId === 10) {
            return (
              <div 
                className="ss-services-title-2-container"
                key={card.cardId}>
                <h2>Plastična hirurgija</h2>
              </div>
            )
          } else if (card.cardId === 18) {
              return (
                <div 
                  className="ss-services-title-2-container"
                  key={card.cardId}>
                  <h2>Interna medicina</h2>
                </div>
              )
          } else if (card.cardId === 25) {
              return (
                <div 
                  className="ss-services-title-2-container"
                  key={card.cardId}>
                  <h2></h2>
                </div>
              )
          }
          return (
            <ServicesServiceCard 
              key={card.cardId}
              showCardContent={() => showCardContent(card.cardId)}
              stateData={card}
              servicesData={servicesData[index]}/>
          )
        })}

        {/* small screen services phone button container */}
        <div className="ss-services-phone-button-container">
          <a href="tel:+3816520240555">
            <img 
              src={phoneButton}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-bolnica-telefon"/>
          </a>
        </div>
      </div>










      {/* large screen services container */}
      <div className="ls-services-container">

        <HomeHeader 
          reloadPage={reloadServicesPage} // passing reloadServicesPage function from Service component to HomeHeader component 
        />

        {/* large screen services decoration image 1 container */}
        <div className="ls-services-decoration-image-1-container">
          <img 
            src={decorationImage4}
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt="una-bolnica-dekoracija-slika"/>
        </div> 

        {/* large screen services title 1 container */}
        <div className="ls-services-title-1-container">
          <h1>Usluge</h1>
        </div>

        {/* large screen services title 2 container */}
        <div className="ls-services-title-2-container">
          <h2>Hirurgija</h2>
        </div>

        {/* mapping over state array objects and displaying ServicesServiceCard component for every object in array */}
        {serviceDataState.map((card, index) => {
          if (card.cardId === 10) {
            return (
              <div 
                className="ls-services-title-2-container"
                key={card.cardId}>
                <h2>Plastična hirurgija</h2>
              </div>
            )
          } else if (card.cardId === 18) {
              return (
                <div 
                  className="ls-services-title-2-container"
                  key={card.cardId}>
                  <h2>Interna medicina</h2>
                </div>
              )
          } else if (card.cardId === 25) {
              return (
                <div 
                  className="ls-services-title-2-container"
                  key={card.cardId}>
                  <h2></h2>
                </div>
              )
          }
          return (
            <ServicesServiceCard 
              key={card.cardId}
              showCardContent={() => showCardContent(card.cardId)}
              stateData={card}
              servicesData={servicesData[index]}/>
          )
        })}

        {/* large screen services phone button container */}
        <div className="ls-services-phone-button-container">
          <a href="tel:+3816520240555">
            <img 
              src={phoneButton}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-bolnica-telefon"/>
          </a>
        </div>
      </div>

      <HomeFooter />

      <ArrowUpIcon /> 

    </>

  )
}