import React, { useRef, useEffect } from 'react';
import './home-part-5.css';

import HomeCommentBox from "../home-comment-box/home-comment-box.js";

import DeaDjurdjevicImage from '../../../assets/home/una-bolnica-home-dea-djurdjevic-1.webp';
import SofijaMiljusImage from '../../../assets/home/una-bolnica-home-sofija-miljus-1.webp';
import JelenaBjelajacImage from '../../../assets/home/una-bolnica-home-jelena-bjelajac-1.webp';
import UrosOstojicImage from '../../../assets/home/una-bolnica-home-uros-ostojic-1.webp';
import GoranSipeticImage from '../../../assets/home/una-bolnica-home-goran-sipetic-1.webp';
import BojanCiricImage from '../../../assets/home/una-bolnica-home-bojan-ciric-1.webp';

export default function HomePart5() {

  // commentData state that is passed to HomeCommentBox component
  const [commentData, setCommentData] = React.useState(
    [
      {
        id: 1,
        image: DeaDjurdjevicImage,
        alternative: 'una-bolnica-dea-djurdjević',
        name: 'Dea',
        surname: 'Đurđević',
        description: 'pacijentkinja',
        comment: '"Zahvaljujem se i fantastičnom osoblju @bolnicauna počevši od doktora, anestetičara, sestara... Hvala vam na divnoj nezi i svim osmesima."'
      },
      {
        id: 2,
        image: SofijaMiljusImage,
        alternative: 'una-bolnica-sofija-miljuš',
        name: 'Sofija',
        surname: 'Miljuš',
        description: 'pacijentkinja',
        comment:
          '"Moram da vam od srca preporučim @dr_ana_malivukovic koja me je maksimalno opustila i bila tu za sva moja pitanja, nedoumice, da nije bilo nje i da nisam uživo videla žene sa kojima je radila, ne bih se odlučila na ovaj korak."'
      },
      {
        id: 3,
        image: JelenaBjelajacImage,
        alternative: 'una-bolnica-jelena-bjelajac',
        name: 'Jelena',
        surname: 'Bjelajac',
        description: 'pacijentkinja',
        comment: '"Izuzetno profesionalni, topli i humani, dobro organizovani a među njima ističe se Dr Igor Maljković i kao čovek i kao vrstan stručnjak, maestro."'
      },
      {
        id: 4,
        image: UrosOstojicImage,
        alternative: 'una-bolnica-uroš-ostojić',
        name: 'Uroš',
        surname: 'Ostojić',
        description: 'pacijent',
        comment:
          '"Za brži oporavak svakog pacijenta (pored stručnosti lekara) izuzetno je važno da ima kvalitetnu negu, brigu i podršku! Moje iskustvo sa "Unom" je upravo tako, pa je po istom i preporučujem."'
      },
      {
        id: 5,
        image: GoranSipeticImage,
        alternative: 'una-bolnica-goran-sipetić',
        name: 'Goran',
        surname: 'Sipetić',
        description: 'pacijent',
        comment:
          '"Vrhunska usluga, izuzetno prijatno osoblje. Dr Medenica mi je radio butnu kost. Sve pohvale i preporuke za ovu ustanovu."'
      },
      {
        id: 6,
        image: BojanCiricImage,
        alternative: 'una-bolnica-bojan-ćirić',
        name: 'dr Bojan',
        surname: 'Ćirić',
        description: 'doktor',
        comment:
          '"Profesionalizam i iskustvo lekara, najsavremeniji aparati, posvećenost osoblja i tim vrhunskih stručnjaka svrstavaju bolnicu u sam vrh privatne medicinske prakse na ovim prostorima"',
        marginRight: 0.625
      }
    ]
  )

  // state changing it's value depending on if mouse is hovering scrollable div
  const [isHovering, setIsHovering] = React.useState(false)
  // function changing isHovering state to true
  function mouseEnter() {
    setIsHovering(true)
  }
  // function changing isHovering state to false
  function mouseLeave() {
    setIsHovering(false)
  }
  
  useEffect(() => {
    const handleScroll = (event) => {
      if (isHovering) {
        // Horizontal scroll behavior
        event.preventDefault();
        const delta = event.deltaY || event.detail || event.wheelDelta;

        // Change the 'scrollLeft' property of your target element for horizontal scrolling
        const element = document.querySelector('.ls-home-part-5-comments-container');
        if (element) {
          element.scrollLeft += delta;
        }
      } else {
        // Vertical scroll behavior (default)
        // You can implement your vertical scrolling logic here
      }
    };

    window.addEventListener('wheel', handleScroll, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [isHovering]);

  // part of code that renders
  return (
    
    <div>

      {/* small screen home part 5 container */}
      <div className="ss-home-part-5-container">
        <div className="ss-home-part-5-container-container">

          {/* small screen home part 5 title container */}
          <div className="ss-home-part-5-title-container">
            <h1>Pacijenti o nama</h1>
          </div>

          {/* small screen home part 5 comments container */}
          <div 
            className="ss-home-part-5-comments-container">
            
            {/* rendering HomeCommentBox component for every objet in commentData state array - passing atributes from objects to HomeCommentBox component */}
            {commentData.map(item => {
              return (
                <HomeCommentBox
                  key={item.id}
                  image={item.image}
                  alternative={item.alternative}
                  name={item.name}
                  surname={item.surname}
                  description={item.description}
                  comment={item.comment}
                  marginRight={item.marginRight}/>
              )
            })}

          </div>

        </div>
      </div>
        









      {/* large screen home part 5 container */}
      <div className="ls-home-part-5-container">

        {/* large screen home part 5 title container */}
        <div className="ls-home-part-5-title-container">
          <h1>Pacijenti<br />o nama</h1>
        </div>
        
        {/* large screen home part 5 comments container */}
        <div className="ls-home-part-5-comments-container"
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}>
            
            {/* rendering HomeCommentBox component for every objet in commentData state array - passing atributes from objects to HomeCommentBox component */}
            {commentData.map(item => {
              return (
                <HomeCommentBox
                  key={item.id}
                  image={item.image}
                  alternative={item.alternative}
                  name={item.name}
                  surname={item.surname}
                  description={item.description}
                  comment={item.comment}
                  marginRight={item.marginRight}/>
              )
            })}

          </div>

      </div>
    
    </div>

  )
}