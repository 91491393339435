import React from "react";
import './blog-header.css';
import { Link } from "react-router-dom";

import unaLogo from '../../../assets/home/una-bolnica-logo-1.svg';

export default function BlogHeader() {
  return (
    <>
      {/* large screen blog header container */}
      <div className="ls-blog-header-container">

        {/* large screen blog header container container */}
        <div className="ls-blog-header-container-container">

          {/* large screen blog header logo container */}
          <div className="ls-blog-header-logo-container">
            <Link to='/'>
              <img 
                src={unaLogo} 
                onContextMenu={(e) => e.preventDefault(e)}
                alt="una-bolnica-logo"/>
            </Link>
          </div>

          {/* large screen blog header navigation container */}
          <div className="ls-blog-header-navigation-container">

            <ul>
              <Link to='/'><li>Naslovna</li></Link>
              <Link to='#'><li>O nama</li></Link>
              <Link to='/doctors'><li>Tim</li></Link>
              <Link to='/services'><li>Usluge</li></Link>
              <Link to='/blog'><li>Blog</li></Link>  
            </ul>

            {/* large screen blog header button container */}
            <Link to="/contact">
              <div className="ls-blog-header-button-container">

                {/* large screen blog header button image container */}
                <div className="ls-blog-header-button-image-container">
                  
                </div>

                {/* large screen blog header button container container */}
                <div className="ls-blog-header-button-container-container">
                  <p>Kontaktirajte nas</p>
                </div>
              </div>
            </Link>

          </div>
        </div>
      </div>
    </>
  )
};