import React from "react";
import './home-doctor-card.css';
import { Link } from "react-router-dom";

import decoration2Image from '../../../assets/home/una-bolnica-home-decoration-2.svg';
import decoration3Image from '../../../assets/home/una-bolnica-home-decoration-3.svg';

export default function HomeDoctorCard(props) {

  // part of code that renders
  return (

    <div>

      {/* small screen home doctor card container */}
      <Link to={props.url}>
        <div className="ss-home-doctor-card-container">

          {/* small screen home doctor card image container */}
          <div className="ss-home-doctor-card-image-container">
            <img 
              src={props.image} 
              onContextMenu={(e) => e.preventDefault(e)}
              alt={props.alternative}/>
          </div>

          {/* small screen home doctor card text container */}
          <div className="ss-home-doctor-card-text-container">
            <h3>{props.name}<br />{props.surname}</h3>
            <p>{props.description}</p>
          
            <div className="ss-home-doctor-card-decoration-1-container">
              <img 
                src={decoration3Image}
                onContextMenu={(e) => e.preventDefault(e)}
                alt="una-bolnica-dekorojacija"/>
            </div>

            <div className="ss-home-doctor-card-decoration-2-container">
              <img 
                  src={decoration2Image}
                  onContextMenu={(e) => e.preventDefault(e)}
                  alt="una-bolnica-dekorojacija"/>
            </div>

          </div>

        </div>
      </Link>










      {/* large screen home doctor card container */}
      <Link to={props.url}>
        <div className="ls-home-doctor-card-container">

          {/* large screen home doctor card image container */}
          <div className="ls-home-doctor-card-image-container">
            <img 
              src={props.image} 
              onContextMenu={(e) => e.preventDefault(e)}
              alt={props.alternative}/>
          </div>

          <div className="ls-home-doctor-card-decoration-2-container">
            <img 
                src={decoration2Image}
                onContextMenu={(e) => e.preventDefault(e)}
                alt="una-bolnica-dekorojacija"/>
          </div>

          {/* large screen home doctor card text container */}
          <div className="ls-home-doctor-card-text-container">
            <h3>{props.name}<br />{props.surname}</h3>
            <p>{props.description}</p>
          
            <div className="ls-home-doctor-card-decoration-1-container">
              <img 
                src={decoration3Image}
                onContextMenu={(e) => e.preventDefault(e)}
                alt="una-bolnica-dekorojacija"/>
            </div>

          </div>

        </div>
      </Link>
      
    </div>

  )
}