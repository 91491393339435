import React from "react";

import DoctorProfile from "../../components/doctor-profile/doctor-profile.js";
import doctorProfileData from "../../assets/doctor-profile-data/doctor-profile-data.js";

export default function MiroslavMitrovic() {

  // part of code that renders
  return (

    <div>
      {doctorProfileData.map((profile, index) => {
        if (profile.doctorId === 'miroslavMitrovic') {
          return (
            
            <DoctorProfile
              key={profile.doctorId} 
              doctorProfileData={doctorProfileData[index]}
            />
             
          )
        }
      })}
    </div>

  )
}