import React from "react";
import './home-part-1.css';

import HomeHeader from "../home-header/home-header.js";
import HomeNavigationDropdown from "../home-navigation-dropdown/home-navigation-dropdown.js";

import image1 from "../../../assets/home/una-bolnica-home-part-1-img-1.webp";
import image2 from "../../../assets/home/una-bolnica-home-part-1-img-2.webp";
import decoration1 from "../../../assets/home/una-bolnica-home-decoration-1.svg";
import decoration2 from "../../../assets/home/una-bolnica-home-decoration-2.svg";

export default function HomePart1(props) {

  // state value that determine navigation dropdown visibility
  const [dropdown, setDropdown] = React.useState(false)

  // function changes dropdown state value
  function showDropdown() {
    setDropdown((prevState) => {
      return (
        !prevState
      )
    })
  }

  // part of code that renders
  return (

    <div>
    
      {/* small screen home part 1 container */} 
      <div className="ss-home-part-1-container">

        <HomeHeader 
          reloadHomePage={props.reloadHomePage} // passing reloadHomePage function from Home component to HomeHeader component 
          showDropdown={showDropdown} // passing showDropdown function to HomeHeader component
          dropdownValue={dropdown} // passing dropdown state value to HomeHeader component
        /> 
        
        <div className="ss-home-part-1-image-textbox-container">
          {/* small screen home part 1 image 1 container */}
          <div className="ss-home-part-1-img-1-container">
            <img 
              src={image1}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-bolnica-naslovna-slika"
            />
          </div>

          {/* small screen home part 1 textbox 1 container*/}
          <div className="ss-home-part-1-textbox-1-container">

              {/* small screen home part 1 textbox 1 text container */}
              <div className="ss-home-part-1-textbox-1-text-container">
                <h3>20 godina brinemo<br />o vašam zdravlju</h3>
                <p>
                  Bolnica Una je renomirana zdravstvena ustanova u Beogradu sa preko 20 godina rada i tradicije u lečenju pacijenata. Plastična hirugija, estetska hirurgija, rekonstruktivna hirurgija, neurohirurgija, otorinolaringologija, ortopedija i mnoge druge specijalističke ordinacije dostupne su u sklopu ZU bolnica Una na lokaciji Vojvode Skopljanca 19, Beograd.
                </p>
              </div>

            {/* small screen home part 1 textbox 1 button */}
            <a href="tel:+3816520240555">
              <div className="ss-home-part-1-textbox-1-button">
                <p>Kontaktirajte nas</p>
              </div>
            </a>

          </div>
        </div>

        {/* small screen home part 1 textbox 2 container */}
        <div className="ss-home-part-1-textbox-2-container">

          {/* small screen home part 1 textbox 2 container container */}
          <div className="ss-home-part-1-textbox-2-container-container">

            {/* small screen home part 1 textbox 2 decoration 1 container */}
            <div className="ss-home-part-1-textbox-2-decoration-1-container">
              <img 
                src={decoration1}
                onContextMenu={(e) => {e.preventDefault(e)}}
                alt="una-bolnica-dekoracija"/>
            </div>

            {/* small screen home part 1 textbox 2 text container */}
            <div className="ss-home-part-1-textbox-2-text-container">
              <h1>Opšta bolnica Una</h1>
              <p>
                <a 
                  href="https://www.google.com/maps/place/Una+bolnica/@44.7830382,20.4772767,17z/data=!3m1!4b1!4m6!3m5!1s0x475a70673a075c59:0x814445fbdeae7a0f!8m2!3d44.7830382!4d20.4798516!16s%2Fg%2F11c1q8s5hk?entry=ttu" 
                  target="_blank">
                    Vojvode Skopljanca 19, Beograd<br />
                </a>

                <a href="tel:+381117858777">
                  +381 11 78 58 777<br />
                </a>

                <a href="tel:+381652024055">
                  +381 65 20 24 05<br />
                </a>

                /Pon-Ned/ 00-24h
              </p>
            </div>

            {/* small screen home part 1 textbox 2 decoration 2 container */}
            <div className="ss-home-part-1-textbox-2-decoration-2-container">
              <img 
                src={decoration2}
                onContextMenu={(e) => {e.preventDefault(e)}}
                alt="una-bolnica-dekoracija"/>
            </div>

          </div>

        </div>
    
        {dropdown && 
          <HomeNavigationDropdown 
            reloadHomePage={props.reloadHomePage} // passing reloadPage function to HomeNavigationDropdown component
          /> 
        }
      </div>
      









      {/* large screen home part 1 container */}
      <div className="ls-home-part-1-contaner">
        
        <HomeHeader 
          reloadHomePage={props.reloadHomePage} // passing reloadHomePage function from Home component to HomeHeader component 
        />

        {/* large screen home part 1 image & textbox container */}
        <div className="ls-home-part-1-image-textbox-container">
          
          {/* large screen home part 1 image */}
          <img 
            src={image2}
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt="una-bolnica-naslovna-slika"/>

          {/* large screen home part 1 textbox container */}
          <div className="ls-home-part-1-textbox-container">

            {/* large screen home part 1 textbox text container */}
            <div className="ls-home-part-1-textbox-text-container">
              <h3>20 godina brinemo<br />o vašam zdravlju</h3>
              <p>
                Bolnica Una je renomirana zdravstvena ustanova u Beogradu sa preko 20 godina rada i tradicije u lečenju pacijenata. Plastična hirugija, estetska hirurgija, rekonstruktivna hirurgija, neurohirurgija, otorinolaringologija, ortopedija i mnoge druge specijalističke ordinacije dostupne su u sklopu ZU bolnica Una na lokaciji Vojvode Skopljanca 19, Beograd.
              </p>
            </div>
            
            {/* large screen home part 1 textbox button */}
            <a href="tel:+381652024055">
              <div className="ls-home-part-1-textbox-button">
                <p>Kontaktirajte nas</p>
              </div>
            </a>
          
          </div>

        </div>

      </div>

    </div>
  
  )

}