import React from "react";
import './home-comment-box.css';

import decoration2 from '../../../assets/home/una-bolnica-home-decoration-2.svg';
import decoration3 from '../../../assets/home/una-bolnica-home-decoration-3.svg';

export default function HomeCommentBox(props) {

  // part of code that renders
  return (

    <>

      {/* small screen home comment box container */}
      <div 
        className="ss-home-comment-box-container" 
        style={{marginRight: props.marginRight + 'vh'}}>

          {/* small screen home comment box image & name container */}
          <div className="ss-home-comment-box-image-name-container">

            {/* small screen home comment box image */}  
            <img 
              className="ss-home-comment-box-image"
              src={props.image}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt={props.alternative}/>

            {/* small screen home comment box name container */}  
            <div className="ss-home-comment-box-name-container">
              <h3>{props.name}<br />{props.surname}</h3>
              <p>{props.description}</p>
            </div>

          </div>

          {/* small screen home comment box decoration 1 container */}
          <div className="ss-home-comment-box-decoration-1-container">
            <img 
              src={decoration2}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-bolnica-dekoracija"/>
          </div>

          {/* small screen home comment box decoration 2 container */}
          <div className="ss-home-comment-box-decoration-2-container">
          <img 
              src={decoration3}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-bolnica-dekoracija"/>
          </div>

          {/* small screen home comment box text container */}
          <div className="ss-home-comment-box-text-container">
            <p>{props.comment}</p>
          </div>

      </div>










      {/* large screen home comment box container */}
      <div className="ls-home-comment-box-container">
        
        {/* large screen home comment box image & name container */}
        <div className="ls-home-comment-box-image-name-container">

          {/* large screen home comment box image */}  
          <img 
              className="ls-home-comment-box-image"
              src={props.image}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt={props.alternative}/>

          {/* large screen home comment box name container */}  
          <div className="ls-home-comment-box-name-container">
            <h3>{props.name}<br />{props.surname}</h3>
            <p>{props.description}</p>
          </div>

        </div>

        {/* large screen home comment box decoration 1 container */}
        <div className="ls-home-comment-box-decoration-1-container">
          <img 
            src={decoration2}
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt="una-bolnica-dekoracija"/>
        </div>

        {/* large screen home comment box decoration 2 container */}
        <div className="ls-home-comment-box-decoration-2-container">
        <img 
            src={decoration3}
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt="una-bolnica-dekoracija"/>
        </div>

        {/* large screen home comment box text container */}
        <div className="ls-home-comment-box-text-container">
          <p>{props.comment}</p>
        </div>

      </div>
    
    </>

  )
}