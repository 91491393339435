import React from "react";
import './home-part-3.css';

import decorationImage2 from '../../../assets/home/una-bolnica-home-decoration-2.svg';
import decorationImage3 from '../../../assets/home/una-bolnica-home-decoration-3.svg';

export default function HomePart3() {

  // part of code that renders
  return (

    <div>

      {/* small screen home part 3 container */}
      <div className="ss-home-part-3-container">

        {/* small screen home part 3 container container */}
        <div className="ss-home-part-3-container-container">

          {/* small screen home part 3 decoration 1 container */}
          <div className="ss-home-part-3-decoration-1-container">
            <img 
              src={decorationImage3}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-bolnica-dekoracija"/>
          </div>

          {/* small screen home part 3 text container */}
          <div className="ss-home-part-3-text-container">
            <p>
              Renomirana imena naših doktora mnogih oblasti medicine daju poverenje i garantuju sigurno mesto za vaše lečenje. Empatija sredine, umirujuće okruženje, razumevanje i lepa reč osoblja, važne su stavke u procesu lečenja i oporavka. U našoj bolnici očekujte topao tretman, znanje i iskustvo kompletnog tima i spokojan put ka ozdravljenju. Sve će biti u redu!
            </p>
          </div>

          {/* small screen home part 3 decoration 2 container */}
          <div className="ss-home-part-3-decoration-2-container">
            <img 
              src={decorationImage2}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-bolnica-dekoracija"/>
          </div>

        </div>

      </div>









      {/* large screen home part 3 container */}
      <div className="ls-home-part-3-container">

        {/* large screen home part 3 container container */}
        <div className="ls-home-part-3-container-container">

          {/* large screen home part 3 decoration 1 container */}
          <div className="ls-home-part-3-decoration-1-container">
            <img 
              src={decorationImage3}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-bolnica-dekoracija"/>
          </div>

          {/* large screen home part 3 text container */}
          <div className="ls-home-part-3-text-container">
            <p>
              Renomirana imena naših doktora mnogih oblasti medicine daju poverenje i garantuju sigurno mesto za vaše lečenje. Empatija sredine, umirujuće okruženje, razumevanje i lepa reč osoblja, važne su stavke u procesu lečenja i oporavka. U našoj bolnici očekujte topao tretman, znanje i iskustvo kompletnog tima i spokojan put ka ozdravljenju. Sve će biti u redu!
            </p>
          </div>

          {/* large screen home part 3 decoration 2 container */}
          <div className="ls-home-part-3-decoration-2-container">
            <img 
              src={decorationImage2}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-bolnica-dekoracija"/>
          </div>

        </div>
    
    </div>

  </div>

  )
}
