import React from "react";
import './home-part-6.css';
import { Link } from "react-router-dom";

import HomeDoctorCard from "../home-doctor-card/home-doctor-card.js";

import anaMalivukovicImage from '../../../assets/home/una-bolnica-home-part-6-ana-malivukovic-1.webp';
import markoBumbasirevicImage from '../../../assets/home/una-bolnica-home-part-6-marko-bumbasirevic-1.webp';
import ljubodragMinicImage from '../../../assets/home/una-bolnica-home-part-6-ljubodrag-minic-1.webp';
import dusanBijelicImage from '../../../assets/home/una-bolnica-home-part-6-dusan-bijelic-1.webp';
import ivicaMedenicaImage from '../../../assets/home/una-bolnica-home-part-6-ivica-medenica-1.webp';
import igorMaljkovicImage from '../../../assets/home/una-bolnica-home-part-6-igor-maljkovic-1.webp';
import zivoradNikolicImage from '../../../assets/home/una-bolnica-home-part-6-zivorad-nikolic-1.webp';
import slavkoTomicImage from '../../../assets/home/una-bolnica-home-part-6-slavko-tomic-1.webp';

export default function HomePart6() {

  // doctorData state that is passed to HomeDoctorCard component
  const [doctorData, setDoctorData] = React.useState(
    [
      {
        rowId: 1,
        list: [
          {
            doctorId: 1,
            url: '/ana-malivukovic',
            image: anaMalivukovicImage,
            alternative: 'una-bolnica-ana-malivuković',
            name: 'dr Ana',
            surname: 'Malivuković',
            description: 'specijalista estetske i rekonstruktivne hirurgije'
          },
          {
            doctorId: 2,
            url: '/marko-bumbasirevic',
            image: markoBumbasirevicImage,
            alternative: 'una-bolnica-marko-bumbaširević',
            name: 'dr Marko',
            surname: 'Bumbaširević',
            description: 'specijalista ortopedske hirurgije'
          }
        ]
      }, {
        rowId: 2,
        list: [
          {
            doctorId: 3,
            url: '/ljubodrag-minic',
            image: ljubodragMinicImage,
            alternative: 'una-bolnica-ljubodrag-minić',
            name: 'dr Ljubodrag',
            surname: 'Minić',
            description: 'specijalista neurohirurgije'
          },
          {
            doctorId: 4,
            url: '/dusan-bijelic',
            image: dusanBijelicImage,
            alternative: 'una-bolnica-dušan-bijelić',
            name: 'dr Dušan',
            surname: 'Bijelić',
            description: 'specijalista ORL'
          }
        ]
      }, {
        rowId: 3,
        list: [
          {
            doctorId: 5,
            url: '/ivica-medenica',
            image: ivicaMedenicaImage,
            alternative: 'una-bolnica-ivica-medenica',
            name: 'dr Ivica',
            surname: 'Medenica',
            description: 'specijalista ortopedije'
          },
          {
            doctorId: 6,
            url: '/igor-maljkovic',
            image: igorMaljkovicImage,
            alternative: 'una-bolnica-igor-maljković',
            name: 'dr Igor',
            surname: 'Maljković',
            description: 'specijalista plastične hirurgije'
          }
        ]
      }, {
        rowId: 4,
        list: [
          {
            doctorId: 7,
            url: '/zivorad-nikolic',
            image: zivoradNikolicImage,
            alternative: 'una-bolnica-živorad-nikolić',
            name: 'dr Živorad',
            surname: 'Nikolić',
            description: 'specijalista maksilofacijalne hirurgije'
          },
          {
            doctorId: 8,
            url: '/slavko-tomic',
            image: slavkoTomicImage,
            alternative: 'una-bolnica-slavko-tomić',
            name: 'dr Slavko',
            surname: 'Tomić',
            description: 'specijalista ortopedije'
          }
        ]
      }
    ]
  )

  // Merge all doctorData arrays into one array.
  const mergedDoctorData = doctorData.flatMap(row => row.list);
    
  // part of code that renders
  return (

    <div>

      {/* small screen home part 6 container */}
      <div className="ss-home-part-6-container">
        {/* small screen home part 6 container container */}
        <div className="ss-home-part-6-container-container">

            {/* small screen home part 6 title container */}
            <div className="ss-home-part-6-title-container">
              <h1>Doktori</h1>
            </div>

            {/* small screen home part 6 doctors container */}
            <div className="ss-home-part-6-doctors-container">
              <table className="ss-home-part-6-doctors-table">
                <tbody>
                  {/* rendering one row for every object in doctorsData array, and then one card of every object in list atribute in that row - passing values to HomeDoctorCard componnet */}
                  {doctorData.map(row => {
                    return (
                      <tr key={row.rowId}>
                        {row.list.map(card => {
                          return (
                            <td key={card.doctorId}>
                              <HomeDoctorCard
                                url={card.url} 
                                image={card.image}
                                alternative={card.alternative}
                                name={card.name}
                                surname={card.surname}
                                description={card.description}/>
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>

            {/* small screen home part 6 button */}
            <Link to='/doctors' style={{textDecoration: 'none'}}>
              <div className="ss-home-part-6-button">
                <p>Sekcija Doktori</p>
              </div>
            </Link>

        </div>
      </div>










      {/* large screen home part 6 container */}
      <div className="ls-home-part-6-container">
        {/* large screen home part 6 container container */}
        <div className="ls-home-part-6-container-container">
                
          {/* large screen home part 6 doctors container */}
          <table className="ls-home-part-6-doctors-container">
            <tbody>
              <tr>
                {mergedDoctorData.slice(0, 4).map((card) => {
                  return (
                    <td key={card.doctorId}>
                      <HomeDoctorCard 
                        url={card.url} 
                        image={card.image}
                        alternative={card.alternative}
                        name={card.name}
                        surname={card.surname}
                        description={card.description}/>
                    </td>
                  )
                })}
              </tr>

              <tr>
                {mergedDoctorData.slice(4, 8).map((card) => {
                  return (
                    <td key={card.doctorId}>
                      <HomeDoctorCard 
                        url={card.url} 
                        image={card.image}
                        alternative={card.alternative}
                        name={card.name}
                        surname={card.surname}
                        description={card.description}/>
                    </td>
                  )
                })}
              </tr>
            </tbody>
          </table>

          {/* large screen home part 6 button */}
          <Link to='/doctors' style={{textDecoration: 'none'}}>
            <div className="ls-home-part-6-button">
              <p>Sekcija Doktori</p>
            </div>
          </Link>

        </div>
      </div>

    </div>

  )
}