import React from "react";
import './home-part-4.css';

import unaBolnicaLogo from '../../../assets/home/una-bolnica-logo-1.svg';

export default function HomePart4(props) {

  // part of code that renders
  return (

    <div>

      {/* small screen home part 4 container */}
      <div className="ss-home-part-4-container">
        <div className="ss-home-part-4-container-container">

          {/* small screen home part 4 logo container */}
          <div className="ss-home-part-4-logo-container">
            <img 
              src={unaBolnicaLogo}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-bolnica-logo"
              onClick={props.reloadHomePage} // passing reloadHomePage from Home component
            />
          </div>

          {/* small screen home part 4 text container */}
          <div className="ss-home-part-4-text-container">
            
            <h1>Opšta bolnica Una</h1>

            <p>
              <a 
                href="https://www.google.com/maps/place/Una+bolnica/@44.7830382,20.4772767,17z/data=!3m1!4b1!4m6!3m5!1s0x475a70673a075c59:0x814445fbdeae7a0f!8m2!3d44.7830382!4d20.4798516!16s%2Fg%2F11c1q8s5hk?entry=ttu"
                target="_blank">
                  Vojvode Skopljanca 19, Beograd<br />
              </a>

              <a href="tel:+381117858777">
                +381 11 78 58 777<br />
              </a>

              <a href="tel:+381652024055">
                +381 65 20 24 055<br />
              </a>

              /Pon-Ned/ 00-24h

            </p>

          </div>

        </div>
      </div>










      {/* large screen home part 4 container */}
      <div className="ls-home-part-4-container">

        {/* large screen home part 4 logo container */}
        <div className="ls-home-part-4-logo-container">
          <img 
            src={unaBolnicaLogo}
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt="una-bolnica-logo"
            onClick={props.reloadHomePage} // passing reloadHomePage from Home component
          />
        </div>

        {/* large screen home part 4 text container */}
        <div className="ls-home-part-4-text-container">
          
          <h1>Opšta bolnica Una</h1>

          <p>
            <a 
              href="https://www.google.com/maps/place/Una+bolnica/@44.7830382,20.4772767,17z/data=!3m1!4b1!4m6!3m5!1s0x475a70673a075c59:0x814445fbdeae7a0f!8m2!3d44.7830382!4d20.4798516!16s%2Fg%2F11c1q8s5hk?entry=ttu"
              target="_blank">
                Vojvode Skopljanca 19, Beograd<br />
            </a>

            <a href="tel:+381117858777">
              +381 11 78 58 777<br />
            </a>

            <a href="tel:+381652024055">
              +381 65 20 24 055<br />
            </a>

            /Pon-Ned/ 00-24h

          </p>

        </div>

      </div>

    </div>

  )
}