import React from "react";
import './doctor-profile-slideshow.css';

import galleryArrowLeft from '../../assets/doctor-profile/una-bolnica-doctor-profile-gallery-arrow-left.svg';
import galleryArrowRight from '../../assets/doctor-profile/una-bolnica-doctor-profile-gallery-arrow-right.svg';
import galleryXIcon from '../../assets/doctor-profile/una-bolnica-gallery-x-icon.svg';

export default function DoctorProfileSlideshow({doctorProfileData, closeSlide, startIndex}) {

  // merging objects (images) from all row arrays into one array
  const mergedArrays = doctorProfileData.gallery.flatMap(row => row.rowContent);

  // state value that determine which image will be the first one in slideshow - passed from DoctorProfile component
  const [currentIndex, setCurrentIndex] = React.useState(startIndex);

  // function is changing currentIndex state value to determine which image will be shown in slideshow
  function previousImage() {
    const newIndex = currentIndex === 0 ? mergedArrays.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  }

  // function is changing currentIndex state value to determine which image will be shown in slideshow
  function nextImage() {
    const newIndex = currentIndex === mergedArrays.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }

  // part of code that renders
  return (

    <>
      <div className="ss-doctor-profile-slideshow-background-container">
        {/* small screen doctor profile slideshow container */}
        <div 
          className="ss-doctor-profile-slideshow-container"
          style={{backgroundImage: `url(${mergedArrays[currentIndex].image})`}}>

              {/* small screen doctor profile slideshow left arrow container */}
              <div 
                className="ss-doctor-profile-slideshow-arrow ss-doctor-profile-slideshow-left-arrow">
                  <img 
                    src={galleryArrowLeft}
                    onClick={previousImage}
                    onContextMenu={(e) => {e.preventDefault(e)}}
                    alt="una-bolnica-strelica"/>
              </div>

              {/* small screen doctor profile non visible image container (just determines the height of slideshow container) */}
              <img
                className="ss-doctor-profile-slideshow-container-hiddne-image" 
                src={mergedArrays[currentIndex].image} />

              {/* small screen doctor profile slideshow right arrow container */}
              <div 
                className="ss-doctor-profile-slideshow-arrow ss-doctor-profile-slideshow-right-arrow">
                  <img 
                    src={galleryArrowRight}
                    onClick={nextImage}
                    onContextMenu={(e) => {e.preventDefault(e)}}
                    alt="una-bolnica-strelica"/>
              </div>

              {/* small screen doctor profile slideshow x icon  container */}
              <div 
                className="ss-doctor-profile-slideshow-x-icon-container">
                  <img 
                    src={galleryXIcon}
                    onClick={closeSlide}
                    onContextMenu={(e) => {e.preventDefault(e)}}
                    alt="una-bolnica-ikonica-x"/>
              </div>
        </div>
      </div>









      <div className="ls-doctor-profile-slideshow-background-container">
        {/* large screen doctor profile slideshow container */}
        <div 
          className="ls-doctor-profile-slideshow-container"
          style={{backgroundImage: `url(${mergedArrays[currentIndex].image})`}}>
            
            {/* large screen doctor profile slideshow left arrow container */}
            <div 
                className="ls-doctor-profile-slideshow-arrow ss-doctor-profile-slideshow-left-arrow">
                  <img 
                    src={galleryArrowLeft}
                    onClick={previousImage}
                    onContextMenu={(e) => {e.preventDefault(e)}}
                    alt="una-bolnica-strelica"/>
            </div>

            {/* large screen doctor profile non visible image container (just determines the height of slideshow container) */}
            <img
              className="ls-doctor-profile-slideshow-container-hiddne-image" 
              src={mergedArrays[currentIndex].image} />

            {/* large screen doctor profile slideshow right arrow container */}
            <div 
              className="ls-doctor-profile-slideshow-arrow ss-doctor-profile-slideshow-right-arrow">
                <img 
                    src={galleryArrowRight}
                    onClick={nextImage}
                    onContextMenu={(e) => {e.preventDefault(e)}}
                    alt="una-bolnica-strelica"/>
            </div>

            {/* large screen doctor profile slideshow x icon  container */}
            <div 
              className="ls-doctor-profile-slideshow-x-icon-container">
                <img 
                  src={galleryXIcon}
                  onClick={closeSlide}
                  onContextMenu={(e) => {e.preventDefault(e)}}
                    alt="una-bolnica-ikonica-x"/>
            </div>

        </div>
      </div>
    </>

  )
}