import React from "react";
import './contact.css';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import HomeHeader from "../../components/home/home-header/home-header.js";
import HomeNavigationDropdown from "../../components/home/home-navigation-dropdown/home-navigation-dropdown.js";
import HomeForm from "../../components/home/home-form/home-form.js";
import HomeFooter from "../../components/home/home-footer/home-footer.js";
import ArrowUpIcon from "../../components/arrow-up-icon/arrow-up-icon.js";

import unaBolnicaLogo from '../../assets/home/una-bolnica-logo-1.svg';
import decorationImage1 from '../../assets/services/una-bolnica-services-decoration-image-2.webp';
import defaultThumbnailImage from '../../assets/home/una-bolnica-default-thumbnail-image.webp';

export default function Contact() {

  // state value that determine navigation dropdown visibility
  const [dropdown, setDropdown] = React.useState(false);

  // function changes dropdown state value
  function showDropdown() {
    setDropdown((prevState) => {
      return (
        !prevState
      )
    })
  }

  // reload page function
  function reloadContactPage() {
    window.location.reload();
  }

  // part of code that renders
  return (

    <>
      <Helmet>
        <title>Una bolnica Beograd, zakažite pregled na +381652024055</title>
        <meta
          name="description"
          content=
            "Opšta bolnica Una, spinalna hirurgija, plastična hirugija, ortopedija, ORL i druge. Vojvode Skopljanca 19, Zakažite pregled na +381652024055">
        </meta>
        <link rel="canonical" href="https://unabolnica.com/contact"/>
        <meta property="og:image" content={defaultThumbnailImage} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>

      {/* small screen contact container */}
      <div className="ss-contact-container">

        <HomeHeader 
          showDropdown={showDropdown} // passing showDropdown function to HomeHeader component
          dropdownValue={dropdown} // passing dropdown state value to HomeHeader component
        />

        {dropdown &&
          <HomeNavigationDropdown 
            reloadContactPage={reloadContactPage} // passing realodContactPage to HomeNavigationDropdown component
          />
        }

        {/* small screen contact logo & text & map container */}
        <div className="ss-contact-logo-text-map-container">

          {/* small screen contact logo container */}
          <div className="ss-contact-logo-container">
            <Link to='/' className="ss-contact-logo-container-container">
              <img 
                src={unaBolnicaLogo}
                onContextMenu={(e) => {e.preventDefault(e)}}
                alt="una-bolnica-logo"/>
            </Link>
          </div>

          {/* small screen contact text container */}
          <div className="ss-contact-text-container">
            <p>
              <a 
                href="https://www.google.com/maps/place/Una+bolnica/@44.7830382,20.4772767,17z/data=!3m1!4b1!4m6!3m5!1s0x475a70673a075c59:0x814445fbdeae7a0f!8m2!3d44.7830382!4d20.4798516!16s%2Fg%2F11c1q8s5hk?entry=ttu" 
                target="_blank">
                  Vojvode Skopljanca 19, Beograd<br /><br />
              </a>

              <a href="tel:+381117858777">
                  +381 11 78 58 777<br />
              </a>

              <a href="tel:+381652024055">
                  +381 65 20 24 055<br /><br />
              </a>

              /Pon-Ned/ 00-24H<br /><br />

              <a href="mailto:office@unaklinika.rs">
                office@unaklinika.rs
              </a>
            </p>
          </div>
          
          {/* small screen contact map container */}
          <div className="ss-contact-map-container">
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d707.9710579931264!2d20.480182738736175!3d44.78316544680777!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a70673a075c59%3A0x814445fbdeae7a0f!2sUna%20bolnica!5e0!3m2!1sen!2srs!4v1699961000369!5m2!1sen!2srs" 
              style={{border:0}} 
              allowFullScreen="" 
              loading="lazy" 
              referrerPolicy="no-referrer-when-downgrade">
            </iframe>
          </div>

        </div>

        <HomeForm 
          marginTop={5}
          marginBottom={10}/>
      </div>










      {/* large screen contact container */}
      <div className="ls-contact-container">
        
        <HomeHeader />

        {/* large screen contact decoration image 1 container */}
        <div className="ls-contact-decoration-image-1-container">
          <img 
            src={decorationImage1}
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt="una-bolnica-dekoracija-slika"/>
        </div>

        {/* large screen contact title 1 container */}
        <div className="ls-contact-title-1-container">
          <h1>Kontakt</h1>
        </div>

        {/* large screen contact logo & text & map container */}
        <div className="ls-contact-logo-text-map-container">

          {/* large screen contact logo & text container */}
          <div className="ls-contact-logo-text-container">
            <Link to='/' style={{textDecoration: 'none'}}>
              <img 
                src={unaBolnicaLogo}
                onContextMenu={(e) => {e.preventDefault(e)}}
                alt="una-bolnica-logo"/>
            </Link>

            {/* large screen contact text container */}
            <div className="ls-contact-text-container">
              <p>
                <a 
                  href="https://www.google.com/maps/place/Una+bolnica/@44.7830382,20.4772767,17z/data=!3m1!4b1!4m6!3m5!1s0x475a70673a075c59:0x814445fbdeae7a0f!8m2!3d44.7830382!4d20.4798516!16s%2Fg%2F11c1q8s5hk?entry=ttu" 
                  target="_blank">
                    Vojvode Skopljanca 19, Beograd<br /><br />
                </a>

                <a href="tel:+381117858777">
                    +381 11 78 58 777<br />
                </a>

                <a href="tel:+381652024055">
                    +381 65 20 24 055<br /><br />
                </a>

                /Pon-Ned/ 00-24H<br /><br />

                <a href="mailto:office@unaklinika.rs">
                  office@unaklinika.rs
                </a>
              </p>
            </div>
          </div>

          {/* large screen contact map container */}
          <div className="ls-contact-map-container">
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d707.9710579931264!2d20.480182738736175!3d44.78316544680777!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a70673a075c59%3A0x814445fbdeae7a0f!2sUna%20bolnica!5e0!3m2!1sen!2srs!4v1699961000369!5m2!1sen!2srs" 
              style={{border:0}} 
              allowFullScreen="" 
              loading="lazy" 
              referrerPolicy="no-referrer-when-downgrade">
            </iframe>
          </div>
        </div>

        {/* large screen contact form container */}
        <div className="ls-contact-form-container">
          <HomeForm />
        </div>
      </div>

      <HomeFooter />

      <ArrowUpIcon />

    </>

    

  )
}