import React from "react";
import './home-form.css';

import unaBolnicaLogo from '../../../assets/home/una-bolnica-logo-1.svg';
import decoration1Image from '../../../assets/home/una-bolnica-home-decoration-2.svg';
import decoration2Image from '../../../assets/home/una-bolnica-home-decoration-4.svg';
import decoration3Image from '../../../assets/home/una-bolnica-home-decoration-3.svg';

export default function HomeForm(props) {

  // part of code that renders
  return (
    
    <div>

      {/* small screen home form container */}
      <div 
        className="ss-home-form-container"
        style={{marginTop: props.marginTop + 'vh', marginBottom: props.marginBottom + 'vh' }}>
        {/* small screen home form container container */}
        <div className="ss-home-form-container-container">

          {/* small screen home form logo container */}
          <div className="ss-home-form-logo-container">
            <img 
              src={unaBolnicaLogo}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-bolnica-logo"
              onClick={props.reloadHomePage} // passing reloadHomePage from Home component
            />
          </div>

          {/* small screen home form text & decoration 1 container */}
          <div className="ss-home-form-text-decoration-1-container">

            {/* small screen home form text */}
            <h3 className="ss-home-form-text-container">
              Postavite<br />nam <span style={{color: '#E6BE96'}}>pitanje</span>
            </h3>

            {/* small screen home form decoration 1 container */}
            <div className="ss-home-form-decoration-1-container">
              <img 
                src={decoration1Image}
                onContextMenu={(e) => {e.preventDefault(e)}}
                alt="una-bolnica-dekoracija"/>
            </div>

          </div>

          {/* small screen home form form container */}
          <form 
            className="ss-home-form-form-container"
            action="https://formsubmit.co/office@unaklinika.rs" 
            method="POST">
            
            <input 
              type="text"
              placeholder="Ime"
              name="Ime"/>

            <input
              type="text"
              placeholder="Prezime"
              name="Prezime"/>

            <input
              type="text"
              placeholder="Email adresa"
              name="Email"/>

            <textarea
              type="text"
              placeholder="Tekst poruke"
              name="Poruka"/>

            {/* small screen home form form decoration 2 & button container */}
            <div className="ss-home-form-form-decoration-2-button-container">

              {/* small screen home form form decoration 2 */}
              <img 
                src={decoration2Image}
                onContextMenu={(e) => {e.preventDefault(e)}}
                alt="una-bolnica-dekoracija"/>

              {/* small screen home form form button */}
              <button
                type="submit">
                  Pošalji
              </button>

            </div>     

          </form>

        </div>
      </div>









      {/* large screen home form container */}
      <div className="ls-home-form-container">

        {/* large screen home form container container */}
        <div className="ls-home-form-container-container">

          {/* large screen home form logo & text container */}
          <div className="ls-home-form-logo-text-container">
            
            {/* large screen home form logo container */}
            <div className="ls-home-form-logo-container">    
              <img
                src={unaBolnicaLogo}
                onContextMenu={(e) => {e.preventDefault(e)}}
                alt="una-bolnica-logo"
                onClick={props.reloadHomePage} // passing reloadHomePage from Home component 
              />
            </div>

            {/* large screen home form text */}
            <p className="ls-home-form-text">Postavite<br />nam<br /><span>pitanje</span></p>
          
          </div>
          
          {/* large screen home form form container */}
          <form 
            className="ls-home-form-form-container"
            action="https://formsubmit.co/office@unaklinika.rs" 
            method="POST">

            {/* large screen home form name & surname container */}
            <div className="ls-home-form-name-surname-container">
              <input 
                className="ls-home-form-input ls-home-form-input-short"
                type="text"
                placeholder="Ime"
                name="Ime"/>

              <input 
                className="ls-home-form-input ls-home-form-input-short"
                type="text"
                placeholder="Prezime"
                name="Prezime"/>
            </div>

            <input 
              className="ls-home-form-input ls-home-form-input-wide"
              type="text"
              placeholder="Email adresa"
              name="Email"/>

            <textarea 
              className="ls-home-form-textarea"
              type="text"
              placeholder="Tekst poruke"
              name="Poruka"/>

            <button 
              className="ls-home-form-button"
              type="submit">
                Pošalji
            </button>

          </form>
        </div>

        {/* large screen home form decoration 1 container */}
        <div className="ls-home-form-decoration-1-container">
          <img 
            src={decoration3Image}
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt="una-bolnica-dekoracija"/>
        </div>

        {/* large screen home form decoration 2 container */}
        <div className="ls-home-form-decoration-2-container">
          <img 
            src={decoration1Image}
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt="una-bolnica-dekoracija"/>
        </div>

      </div>

    </div>
  
  )
}