const servicesData = [
  {
    serviceId: 1,
    serviceName: 'opšta i abdominalna hirurgija',
    employes: [
      {
        employeId: 1,
        employeName: 'Dr Boško Milev',
        employeLink: '/bosko-milev'
      },
      {
        employeId: 2,
        employeName: 'Dr Miroslav Mitrović',
        employeLink: '/miroslav-mitrovic'
      }
    ],
    text: [
      {
        contentId: 1,
        content: 'Opšta hirurgija Una bolnica bavi se otvorenim i zatvorenim metodama hirurgije na organima grudnog pojasa sa abdomenom, uz lirurško lečenje širokog opsega zdravstvenih problema od malih promena na koži do onkologije i odstranjivanja oboljelih tela. Neke od fokusnih tačaka opšte hirurgije su:',
        contentList: [
          {
            listItemId: 1,
            listItem: 
              'male kožne promene,'
          },
          {
            listItemId: 2,
            listItem: 
              'hirurško odstranjivanje ožiljaka,'
          },
          {
            listItemId: 3,
            listItem: 
              'hernije,'
          },
          {
            listItemId: 4,
            listItem: 
              'operacije štitaste žlezde,'
          },
          {
            listItemId: 5,
            listItem: 
              'operacije na organima probavnog sistema kao pto je operacija jednjaka, operacija tankog i debelog creva, operacija želuca, operacija rektuma, operacija jetre i žučne kese,'
          },
          {
            listItemId: 6,
            listItem: 
              'uklanjanje malignih i benignih tela sa dojke,'
          },
          {
            listItemId: 7,
            listItem: 
              'onkologija,'
          },
          {
            listItemId: 8,
            listItem: 
              'vene,'
          },
          {
            listItemId: 9,
            listItem: 
              'transplantacije,'
          },
          {
            listItemId: 10,
            listItem: 
              'srce i krvotok,'
          },
          {
            listItemId: 11,
            listItem: 
              'hirurški zahvati na organima grudnog koša, pluća, uključujući rak pluća.'
          }
        ]
      },
      {
        contentId: 2,
        content: <><strong>ABDOMINALNA HIRURGIJA:</strong> Operacija kile, operacija jetre, žučnih vodova, gušterače, hirurgija debelog creva i analne regije.</>
      },
    ] 
  },
  {
    serviceId: 2,
    serviceName: 'ortopedija i traumatologija',
    employes: [
      {
        employeId: 1,
        employeName: 'Prof. dr Marko Bumbaširević - Ortoped',
        employeLink: '/marko-bumbasirevic'
      },
      {
        employeId: 2,
        employeName: 'Dr Ivica Medenica - Ortoped',
        employeLink: '/ivica-medenica'
      },
      {
        employeId: 3,
        employeName: 'Prof dr Slavko Tomić - Ortoped',
        employeLink: '/slavko-tomic'
      },
      {
        employeId: 4,
        employeName: 'Dr Marko Ilić - Ortoped',
        employeLink: '/marko-ilic'
      },
      {
        employeId: 5,
        employeName: 'Dr Dejan Ristić - Ortoped',
        employeLink: '/dejan-ristic'
      },
      {
        employeId: 6,
        employeName: 'Prim. dr Slađan Timotijević - Specijalista ortopedije',
        employeLink: '/sladjan-timotijevic'
      },
      {
        employeId: 8,
        employeName: 'Dr Aleksandar Radunović - Ortoped',
        employeLink: '/aleksandar-radunovic'
      },
      {
        employeId: 9,
        employeName: 'Dr Aleksandar Vojvodić - Ortoped',
        employeLink: '/aleksandar-vojvodic'
      },
      {
        employeId: 10,
        employeName: 'Dr Mladen Maksić - Ortoped',
        employeLink: '/mladen-maksic'
      },
      {
        employeId: 11,
        employeName: 'Dr Vuk Vučić - Ortoped',
        employeLink: '/vuk-vucic'
      }
    ],
    text: [ 
      {
        contentId: 1,
        content: 
          'Ortopedija i traumatologija su povezane discipline koje se bave dijagnostikom, lečenjem i rehabilitacijom povreda i oboljenja mišićno-koštanog sistema. Stručnjaci u ovim oblastima očuvaju pokretljivost, funkcionalnost i kvalitet života pacijenata.'
      },
      {
        contentId: 2,
        content: 
          'Ortopedija se bavi prelomima, deformitetima kičme, artritisom, sportskim povredama i drugim patologijama mišićno-koštanog sistema. Ortopedi koriste konzervativne terapije, kao što su fizikalna terapija i ortopedska pomagala, ali i hirurške intervencije.'
      },
      {
        contentId: 3,
        content: 
          'Traumatologija se fokusira na hitno lečenje povreda usled nesreća i traumatskih događaja. Traumatolozi pružaju brzu i efikasnu negu pacijentima sa prelomima kostiju i drugim urgentnim stanjima.'
      },
      {
        contentId: 4,
        content: 'Spisak najčešćih oboljenja:',
        contentList: [
          {
            listItemId: 1,
            listItem: 
              'Prelomi kostiju: Prelomi su česti i mogu se javiti u različitim delovima tela, uključujući ruke, noge, kičmu i kukove. Mogu biti posledica pada, sportskih povreda, saobraćajnih nesreća i drugih trauma.'
          },
          {
            listItemId: 2,
            listItem: 
              'Osteoartritis: Osteoartritis je degenerativno oboljenje zglobova koje se javlja kada se hrskavica koja štiti zglobove istroši. To može dovesti do bola, otoka i smanjene pokretljivosti zglobova.'
          },
          {
            listItemId: 3,
            listItem: 
              'Sportske povrede: Sportske povrede mogu obuhvatati povrede mišića, ligamenata, tetiva i zglobova. Najčešće su povezane sa aktivnostima kao što su fudbal, košarka, trčanje i tenis.'
          },
          {
            listItemId: 4,
            listItem: 
              'Degenerativne bolesti kičme: Degenerativne bolesti kičme, poput degenerativne diskopatije i spinalne stenoze, mogu uzrokovati bol u leđima, ograničenu pokretljivost i probleme sa kičmom.'
          },
          {
            listItemId: 5,
            listItem: 
              'Reumatoidni artritis: Reumatoidni artritis je autoimuna bolest koja uzrokuje upalu zglobova, što može dovesti do bola, otoka i deformiteta zglobova.'
          },
          {
            listItemId: 6,
            listItem: 
              'Tendinitis: Tendinitis je upala tetiva koja se javlja kao rezultat prenaprezanja ili povreda. Najčešće pogađa tetive u rukama, ramenima, laktovima i koljenima.'
          },
          {
            listItemId: 7,
            listItem: 
              'Meniskusna povreda: Povrede meniskusa u kolenu često se javljaju prilikom rotacionih ili prekomernih pokreta, a mogu uzrokovati bol, otok i ograničenu pokretljivost.'
          },
          {
            listItemId: 8,
            listItem: 
              'Burzitis: Burzitis je upala burse, koja su male tečno ispunjene kesice koje se nalaze između kostiju, mišića i tetiva. Najčešće se javlja u ramenima, kolenima i kukovima.'
          },
          {
            listItemId: 9,
            listItem: 
              'Plantarni fasciitis: Plantarni fasciitis je upala plantarne fascije, vezivnog tkiva koje se proteže preko luka stopala. To može uzrokovati bol u peti i stopalu, posebno pri ustajanju iz kreveta ili produženom stajanju.'
          },
          {
            listItemId: 10,
            listItem: 
              'Ruptura ahilove tetive: Ruptura ahilove tetive je povreda koja se javlja kada se ahilova tetiva, koja povezuje mišiće potkolenice i petu, delimično ili potpuno pokida. To može biti praćeno jakim bolom i nemogućnošću normalnog hodanja.'
          },
        ]
      },
    ] 
  },
  {
    serviceId: 3,
    serviceName: 'vaskularna hirurgija',
    employes: [
      {
        employeId: 1,
        employeName: 'Dr Ivan Leković',
        employeLink: '/ivan-lekovic'
      },
      {
        employeId: 2,
        employeName: 'Prof. dr Božina Radević',
        employeLink: '/bozina-radevic'
      }
    ],
    text: [
      {
        contentId: 1,
        content: 
          'Vaskularna hirurgija obuhvata dijagnostiku, lečenje i operacije krvnih sudova, uključujući operacije vena. Operacija vena je postupak koji se primenjuje u slučajevima proširenih vena, tromboze ili venske insuficijencije. Kroz različite tehnike i minimalno invazivne procedure, vaskularni hirurzi rešavaju oštećene vene i poboljšavaju protok krvi.'
      },
      {
        contentId: 2,
        content: 
          'Una bolnica pruža vaskularnu hirurgiju i operacije vena, uključujući inovativne minimalno invazivne metode. Flebektomija i endovaskularne metode su uobičajeni pristupi koji se koriste tokom operacije vena, omogućavajući pacijentima brži oporavak, manje ožiljaka i minimalne neugodnosti.'
      },
      {
        contentId: 3,
        content: 
          'Ako se suočavate sa problemima vena, konsultacija sa našim vaskularnim hirurgom pružiće vam informacije o različitim opcijama lečenja, uključujući minimalno invazivne operacije vena. Naš tim stručnjaka za vaskularnu hirurgiju posvećen je vašem zdravlju i pruža kvalitetnu negu i pažnju tokom celog procesa.'
      },
      {
        contentId: 4,
        content: 
          'Za vaskularnu hirurgiju i operacije vena, kontaktirajte Una bolnicu kako bismo vam pružili sve informacije i zakazali pregled kod našeg vaskularnog hirurga. Mi smo tu da vam pružimo najbolju moguću negu i rezultate.'
      },
      {
        contentId: 5,
        content: 'Spisak najčešćih zahvata:',
        contentList: [
          {
            listItemId: 1,
            listItem: 
              'Angioplastika: Angioplastika je postupak koji se koristi za otvaranje suženih ili blokiranih krvnih sudova. Kroz mali kateter se uvodi balon koji se naduva kako bi se proširio suženi deo krvnog suda i poboljšao protok krvi.'
          },
          {
            listItemId: 2,
            listItem: 
              'Bypass operacija: Bypass operacija se koristi za zaobilaženje suženih ili blokiranih arterija. Uzima se deo krvnog suda (obično vena sa noge) i koristi se kao presadak da bi se stvorio novi put za protok krvi oko suženog dela.'
          },
          {
            listItemId: 3,
            listItem: 
              'Endarterektomija: Endarterektomija je postupak uklanjanja plaka (naslaga masnoće, kalcijuma i drugih materijala) iz unutrašnjosti arterija. To pomaže u poboljšanju protoka krvi i smanjenju rizika od srčanog udara ili moždanog udara.'
          },
          {
            listItemId: 4,
            listItem: 
              'Stentiranje: Stentiranje je postupak u kojem se u suženi ili blokirani krvni sud postavlja mali metalni okvir (stent) koji pomaže u održavanju otvorenog protoka krvi. Stent se može koristiti kao deo angioplastike ili kao samostalni postupak.'
          },
          {
            listItemId: 5,
            listItem: 
              'Aneurizma popravka: Aneurizma je izbočenje ili proširenje na zidu krvnog suda. Aneurizme mogu biti opasne jer postoji rizik od pucanja i unutrašnjeg krvarenja. Aneurizma popravka može uključivati ​​postavljanje grafta (presadka) kako bi se ojačao i popravio zid aneurizme i sprečilo njeno pucanje.'
          }
        ]
      },
    ]  
  },
  {
    serviceId: 4,
    serviceName: 'torakalna hirurgija',
    text: [
      {
        contentId: 1,
        content: 
          'Torakalna hirurgija je medicinska specijalnost koja se fokusira na dijagnostiku, lečenje i operativne zahvate u grudnom košu. Ova grana hirurgije se bavi oboljenjima pluća, grudnog zida, jednjaka, bronha i drugih organa unutar grudnog koša.'
      },
      {
        contentId: 2,
        content: 
          'U našoj bolnici, tim stručnih torakalnih hirurga pruža kvalitetnu negu i tretman pacijentima sa različitim torakalnim oboljenjima. Koristeći napredne tehnike i minimalno invazivne procedure, naši hirurzi efikasno leče stanja kao što su tumori pluća, karcinomi jednjaka, emfizem, pneumotoraks i mnoge druge bolesti grudnog koša.'
      },
      {
        contentId: 3,
        content: 
          'Saradnja sa drugim stručnjacima kao što su pulmolozi, onkolozi i anesteziolozi omogućava nam sveobuhvatnu i personalizovanu negu za svakog pacijenta. Naša bolnica je opremljena modernom opremom i pruža sigurno okruženje za izvođenje torakalnih hirurških zahvata.'
      }
    ]   
  },
  {
    serviceId: 5,
    serviceName: 'neurohirurgija',
    employes: [
      {
        employeId: 1,
        employeName: 'Prof. dr Ljubodrag Minić',
        employeLink: '/ljubodrag-minic'
      },
      {
        employeId: 2,
        employeName: 'Dr Goran Pavlićević',
        employeLink: '/goran-pavlicevic'
      },
      {
        employeId: 3,
        employeName: 'Dr Nenad Novaković',
        employeLink: '/nenad-novakovic'
      },
      {
        employeId: 4,
        employeName: 'Prim. dr sc. med. Miljan Mihajlović',
        employeLink: '/miljan-mihajlovic'
      }
    ],
    text: [
      {
        contentId: 1,
        content: 
          'Neurohirurgija je medicinska specijalnost koja se bavi dijagnostikom, lečenjem i upravljanjem operativnim postupcima vezanim za bolesti i povrede centralnog nervnog sistema. Stručnjaci u neurohirurgiji imaju ključnu ulogu u pružanju vrhunske nege pacijentima sa neurološkim stanjima, kao što su tumori mozga, epilepsija, cerebrovaskularni poremećaji i povrede kičme.'
      },
      {
        contentId: 2,
        content: 'Spisak najčešćih zahvata:',
        contentList: [
          {
            listItemId: 1,
            listItem: 
              'Uklanjanje tumora mozga: Operacija uklanjanja tumora mozga je jedna od najčešćih neurohirurških procedura. Cilj ove operacije je ukloniti što veći deo tumora kako bi se smanjio pritisak na mozak i poboljšala funkcionalnost.'
          },
          {
            listItemId: 2,
            listItem: 
              'Dekompresija kičmene moždine: Ova operacija se izvodi radi oslobađanja pritiska na kičmenu moždinu. Često se izvodi kod pacijenata sa spinalnom stenozom ili hernijom diska, kako bi se smanjio pritisak na nervne strukture i ublažili simptomi.'
          },
          {
            listItemId: 3,
            listItem: 
              'Hirurgija kičmenog stuba: Operacije kičmenog stuba se izvode radi lečenja deformiteta kičme, kao što su skolioza ili spondilolistezis. Cilj operacije je ispravljanje deformiteta i stabilizacija kičme kako bi se poboljšala funkcija i smanjila bol.'
          },
          {
            listItemId: 4,
            listItem: 
              'Cerebrovaskularne operacije: Ove operacije se izvode na krvnim sudovima mozga i obuhvataju lečenje aneurizmi, arteriovenskih malformacija ili ishemijskih moždanih udara. Cilj operacije je smanjenje rizika od rupture aneurizme ili obnavljanje protoka krvi u mozgu.'
          },
          {
            listItemId: 5,
            listItem: 
              'Stereotaktička neurohirurgija: Stereotaktička neurohirurgija koristi precizne trodimenzionalne koordinate za ciljano lečenje neuroloških poremećaja kao što su Parkinsonova bolest ili epilepsija. Ova operacija se obavlja uz pomoć naprednih navigacionih sistema i ima za cilj regulisanje abnormalne aktivnosti u mozgu.'
          }
        ]
      },
    ]   
  },
  {
    serviceId: 6,
    serviceName: 'urologija', 
    employes: [
      {
        employeId: 1,
        employeName: 'Prof. dr Predrag Aleksić',
        employeLink: '/predrag-aleksic'
      },
      {
        employeId: 2,
        employeName: 'Prof. dr Aleksandar Spasić',
        employeLink: '/aleksandar-spasic'
      },
      {
        employeId: 3,
        employeName: 'Prof. dr Borislav Stjelja',
        employeLink: '/borislav-stjelja'
      },
      {
        employeId: 4,
        employeName: 'Prof. dr Miodrag Lazić',
        employeLink: '/miodrag-lazic'
      }
    ],
    text: [
      {
        contentId: 1,
        content: 
          'Urologija je medicinska specijalnost koja se bavi dijagnozom, lečenjem i prevencijom bolesti urinarnog sistema kod muškaraca i žena. Urolozi su stručnjaci koji se bave različitim stanjima kao što su infekcije mokraćnih puteva, kamenci u bubrezima, benigna hiperplazija prostate, rak mokraćnog sistema i mnoge druge bolesti.'
      },
      {
        contentId: 2,
        content: 
          'Kada se suočite sa problemom vezanim za urinarni sistem, urolog će vam pružiti sveobuhvatnu medicinsku negu. Ova medicinska disciplina koristi najsavremenije dijagnostičke metode kao što su ultrazvuk, CT i MRI skeniranje, kao i endoskopiju, kako bi postavila tačnu dijagnozu.'
      },
      {
        contentId: 3,
        content: 
          'Urologija takođe obuhvata i nehirurške tretmane kao što su lekovi i terapije zasnovane na zračenju. Međutim, u slučaju potrebe za hirurškim zahvatom, urolozi su visoko kvalifikovani da obavljaju operacije mokraćnih puteva, bubrega, bešike i prostate.'
      },
      {
        contentId: 4,
        content: 
          'Ako imate simptome kao što su bol pri mokrenju, učestalo mokrenje, krv u mokraći ili bilo koji drugi problem vezan za urinarni sistem, važno je posetiti urologa radi pregleda. Urolozi pružaju specijalizovanu negu kako bi vam pomogli u otkrivanju, lečenju i upravljanju ovim zdravstvenim stanjima.'
      },
      {
        contentId: 5,
        content: 'Spisak najčešćih oboljenja:',
        contentList: [
          {
            listItemId: 1,
            listItem: 
              'Benigna hiperplazija prostate (BHP): BHP je uobičajeno oboljenje kod starijih muškaraca, koje uzrokuje povećanje prostate i otežano mokrenje. Urolozi mogu preporučiti terapiju lekovima ili hirurške procedure kako bi se ublažili simptomi i poboljšao protok urina.'
          },
          {
            listItemId: 2,
            listItem: 
              'Infekcije urinarnog trakta (IUT): Infekcije urinarnog trakta, uključujući urinarnu infekciju mokraćnog mehura (cistitis) i infekciju bubrega (pielonefritis), često se javljaju kod muškaraca i žena. Urolozi se bave dijagnostikom i lečenjem ovih infekcija, obično propisivanjem antibiotika.'
          },
          {
            listItemId: 3,
            listItem: 
              'Kamenci u bubregu: Kamenci u bubregu su česti problem koji može uzrokovati bol i otežano mokrenje. Urolozi se bave dijagnostikom kamena, a zatim preporučuju terapiju koja može uključivati promenu ishrane, lekove ili hirurške procedure za uklanjanje kamena.'
          },
          {
            listItemId: 4,
            listItem: 
              'Rak prostate: Rak prostate je jedan od najčešćih malignih oboljenja kod muškaraca. Urolozi se bave dijagnostikom, lečenjem i praćenjem raka prostate. Terapija može uključivati ​​hormonsku terapiju, radioterapiju, hirurško uklanjanje prostate ili druge metode zavisno od stadijuma i agresivnosti tumora.'
          },
          {
            listItemId: 5,
            listItem: 
              'Impotencija: Impotencija, takođe poznata kao erektilna disfunkcija, je stanje u kojem muškarac ima poteškoća u postizanju ili održavanju erekcije. Urolozi mogu pružiti dijagnostiku i terapiju za impotenciju, uključujući lekove, terapiju vakuumom, injekcije ili hirurške procedure poput implanta penisa.'
          }
        ]
      },
    ]  
  },
  {
    serviceId: 7,
    serviceName: 'maksilofacijalna hirurgija', 
    employes: [
      {
        employeId: 1,
        employeName: 'Dr Živorad Nikolić',
        employeLink: '/zivorad-nikolic'
      },
      {
        employeId: 2,
        employeName: 'Prof. dr Srboljub Stošić',
        employeLink: '/srboljub-stosic'
      }
    ],
    text: [
      {
        contentId: 1,
        content: 
          'Maksilofacijalna hirurgija je specijalnost koja se bavi lečenjem problema lica, vilice i čeljusti. Ova grana hirurgije obuhvata dijagnostiku, rekonstrukciju i tretman različitih stanja kao što su deformacije vilica, prelomi lica, tumori vilica i disfunkcionalni zglobovi vilice.'
      },
      {
        contentId: 2,
        content: 
          'Maksilofacijalni hirurzi koriste napredne tehnike i tehnologije za pružanje vrhunske zdravstvene nege. U saradnji sa stomatolozima i ortodontima, oni obavljaju rekonstrukciju vilica, implantaciju dentalnih proteza i korekciju nepravilnosti vilica.'
      },
      {
        contentId: 3,
        content: 
          'Ovi hirurški zahvati poboljšavaju funkcionalnost, estetiku i samopouzdanje pacijenata. Ako imate probleme sa vilicom, licem ili pripadajućim strukturama, posetite maksilofacijalnog hirurga radi konsultacije i pravilne dijagnoze.'
      },
      {
        contentId: 4,
        content: 
          'Maksilofacijalna hirurgija pruža specijalizovanu negu za vaše oralno zdravlje i poboljšanje kvaliteta života. Zakazivanje pregleda kod ovog stručnjaka je važan korak ka rešavanju problema i povratku funkcionalnosti i estetike vašeg lica.'
      },
      {
        contentId: 5,
        content: 'Spisak najčešćih oboljenja:',
        contentList: [
          {
            listItemId: 1,
            listItem: 
              'Operacija korekcije vilica: Ovaj zahvat se obavlja radi ispravljanja nepravilnosti u vilicama, poput nepravilnog zagrižaja ili asimetrije. Može uključivati ​​redukciju ili augmentaciju vilica, kako bi se postigla harmonija i funkcionalnost u vilicama.'
          },
          {
            listItemId: 2,
            listItem: 
              'Operacija korekcije nosa (rinoplastika): Rinoplastika je hirurški zahvat koji se koristi za korekciju estetskih ili funkcionalnih problema nosa, poput devijacije septuma, promene oblika nosa ili problema sa disanjem. Ovaj zahvat se često izvodi u maksilofacijalnoj hirurgiji.'
          },
          {
            listItemId: 3,
            listItem: 
              'Operacija rekonstrukcije vilice: Rekonstrukcija vilice se obavlja kod pacijenata koji su pretrpeli povredu vilice ili imaju urođene deformitete vilice. Ovaj zahvat ima za cilj obnovu normalne funkcionalnosti vilice i poboljšanje estetskog izgleda lica.'
          },
          {
            listItemId: 4,
            listItem: 
              'Uklanjanje tumora vilice: Maksilofacijalni hirurzi se bave uklanjanjem tumora koji se javljaju u vilici. To mogu biti benigni tumori, maligni tumori ili cistične formacije. Uklanjanje tumora vilice zahteva preciznost i pažljivu rekonstrukciju kako bi se očuvala funkcija vilice i estetika lica.'
          },
          {
            listItemId: 5,
            listItem: 
              'Hirurško lečenje temporomandibularnih poremećaja (TMD): TMD su stanja koja utiču na zglobove vilice i mišiće koji omogućavaju žvakanje i otvaranje usta. Maksilofacijalni hirurzi mogu izvoditi hirurške zahvate, poput artroskopije ili rekonstrukcije zglobova, kako bi se olakšali simptomi i poboljšala funkcionalnost temporomandibularnog zgloba.'
          }
        ]
      },
    ]  
  },
  {
    serviceId: 8,
    serviceName: 'dečija hirurgija', 
    employes: [
      {
        employeId: 1,
        employeName: 'Dr Marko Bogosavljević',
        employeLink: '/marko-bogosavljevic'
      }
    ],
    text: [
      {
        contentId: 1,
        content: 
          'Dečija hirurgija je medicinska disciplina koja se posvećuje hirurškom lečenju dece. Specijalizovani dečiji hirurzi tretiraju različite hirurške probleme kod dece, uključujući urološke, gastrointestinalne, ortopedske, kardiovaskularne i druge.'
      },
      {
        contentId: 2,
        content: 
          'Dečiji hirurzi pružaju specijalizovanu negu prilagođenu dečijem uzrastu. Koriste posebne tehnike i tehnologije za siguran i efikasan tretman.'
      },
      {
        contentId: 3,
        content: 
          'Postupci dečije hirurgije obuhvataju operacije slepog creva, hernije, uklanjanje tumora i druge intervencije. Dečiji hirurzi rade u timu sa drugim stručnjacima kako bi postigli najbolje rezultate.'
      },
      {
        contentId: 4,
        content: 
          'Za hirurške probleme kod dece, konsultacija sa dečijim hirurgom je važna. Pružaju bezbednu i saosećajnu negu za brz oporavak i zdrav razvoj.'
      }
    ]  
  },
  {
    serviceId: 9,
    serviceName: 'anesteziologija', 
    employes: [
      {
        employeId: 1,
        employeName: 'Mr sc. med. dr Gordan Malivuković',
        employeLink: '/gordan-malivukovic'
      },
      {
        employeId: 2,
        employeName: 'Dr Duško Jovanović',
        employeLink: '/dusko-jovanovic'
      }
    ],
    text: [
      {
        contentId: 1,
        content: 
          'Anesteziologija je medicinska specijalnost koja se bavi pružanjem anestezije tokom hirurških zahvata i drugih procedura. Anesteziolozi su stručnjaci koji osiguravaju bezbolno iskustvo pacijentima i održavaju vitalne funkcije tela tokom postupka.'
      },
      {
        contentId: 2,
        content: 
          'Koristeći različite vrste anestezije, anesteziolozi smanjuju bol i stres pacijentima tokom intervencija. Oni rade u timu sa hirurzima kako bi postigli optimalne rezultate.'
      },
      {
        contentId: 3,
        content: 
          'Anesteziologija takođe obuhvata upravljanje boli nakon operacije i pružanje postoperativne nege. Anesteziolozi prate pacijente tokom oporavka kako bi osigurali sigurnost i udobnost.'
      }
    ]  
  },
  {
    serviceId: 10, 
  },
  {
    serviceId: 11,
    serviceName: 'hirurgija dojke',
    employes: [
      {
        employeId: 1,
        employeName: 'Dr Ana Malivuković',
        employeLink: '/ana-malivukovic'
      },
      {
        employeId: 2,
        employeName: 'Dr Igor Maljković',
        employeLink: '/igor-maljkovic'
      },
      {
        employeId: 3,
        employeName: 'Dr Biljana Mitrić',
        employeLink: '/biljana-mitric'
      },
      {
        employeId: 4,
        employeName: 'Dr Miloš Gačević',
        employeLink: '/milos-gacevic'
      },
      {
        employeId: 5,
        employeName: 'Dr Miloš Vučetić',
        employeLink: '/milos-vucetic'
      },
      {
        employeId: 6,
        employeName: 'Dr Simon Pandjaitan',
        employeLink: '/simon-pandjatan'
      },
      {
        employeId: 7,
        employeName: 'Dr Uroš Radivojčević',
        employeLink: '/uros-radivojcevic'
      },
      {
        employeId: 8,
        employeName: 'Dr Željko Ćurčić',
        employeLink: '/zeljko-curcic'
      },
      {
        employeId: 9,
        employeName: 'Dr Željko Stanojlović',
        employeLink: '/zeljko-stanojlovic'
      }
    ],
    text: [
      {
        contentId: 1,
        content: 
          'Hirurgija dojke je stručna grana koja se bavi rekonstrukcijom i tretmanom dojki. Ova oblast obuhvata različite postupke poput povećanja, smanjenja i rekonstrukcije dojki nakon mastektomije.'
      },
      {
        contentId: 2,
        content: 
          'Hirurzi dojke su visoko kvalifikovani stručnjaci koji koriste napredne tehnike za ostvarivanje željenih rezultata. Njihova glavna svrha je poboljšanje izgleda, simetrije i samopouzdanja pacijentkinja.'
      },
      {
        contentId: 3,
        content: 
          'Proceduri hirurgije dojke omogućavaju rešavanje estetskih i funkcionalnih problema dojki. Bez obzira na vrstu intervencije, hirurzi pristupaju pacijentkinjama individualno, uzimajući u obzir njihove specifične potrebe i želje.'
      }
    ]
  },
  {
    serviceId: 12,
    serviceName: 'liposukcija',
    text: [
      {
        contentId: 1,
        content: 
          'Liposukcija je popularna estetska procedura za oblikovanje tela i uklanjanje viška masnog tkiva. Koristi se za ciljano uklanjanje masnih naslaga sa stomaka, bokova, butina i drugih područja.'
      },
      {
        contentId: 2,
        content: 
          'Liposukcija je minimalno invazivan postupak s brzim oporavkom. Pruža vidljive rezultate i povećava samopouzdanje. Ipak, važno je znati da liposukcija nije metoda za gubitak težine, već za oblikovanje tela i uklanjanje lokalizovanih masnih naslaga.'
      }
    ]  
  },
  {
    serviceId: 13,
    serviceName: 'abdominoplastika',
    employes: [
      {
        employeId: 1,
        employeName: 'Dr Ana Malivuković',
        employeLink: '/ana-malivukovic'
      },
      {
        employeId: 2,
        employeName: 'Dr Igor Maljković',
        employeLink: '/igor-maljkovic'
      },
      {
        employeId: 3,
        employeName: 'Dr Biljana Mitrić',
        employeLink: '/biljana-mitric'
      },
      {
        employeId: 4,
        employeName: 'Dr Miloš Gačević',
        employeLink: '/milos-gacevic'
      },
      {
        employeId: 5,
        employeName: 'Dr Miloš Vučetić',
        employeLink: '/milos-vucetic'
      },
      {
        employeId: 6,
        employeName: 'Dr Simon Pandjaitan',
        employeLink: '/simon-pandjatan'
      },
      {
        employeId: 7,
        employeName: 'Dr Uroš Radivojčević',
        employeLink: '/uros-radivojcevic'
      },
      {
        employeId: 8,
        employeName: 'Dr Željko Ćurčić',
        employeLink: '/zeljko-curcic'
      },
      {
        employeId: 9,
        employeName: 'Dr Željko Stanojlović',
        employeLink: '/zeljko-stanojlovic'
      }
    ],
    text: [
      {
        contentId: 1,
        content: 
          'Abdominoplastika, poznata i kao trbušna plastika ili "tummy tuck", je hirurški postupak koji se koristi za poboljšanje izgleda stomaka. Ova procedura ima za cilj uklanjanje viška kože i masnog tkiva sa donjeg dela stomaka, kao i zatezanje trbušnih mišića.'
      },
      {
        contentId: 2,
        content: 
          'Abdominoplastika je posebno korisna za osobe koje su imale značajan gubitak težine ili porođaj, jer se često javlja opuštenost kože i mišića stomaka. Ovaj postupak može rezultirati značajnim poboljšanjem konture stomaka, smanjenjem opuštenosti kože i stvaranjem ravnoteže proporcija.'
      }
    ]  
  },
  {
    serviceId: 14,
    serviceName: 'korekcija kapaka',
    employes: [
      {
        employeId: 1,
        employeName: 'Dr Ana Malivuković',
        employeLink: '/ana-malivukovic'
      },
      {
        employeId: 2,
        employeName: 'Dr Igor Maljković',
        employeLink: '/igor-maljkovic'
      },
      {
        employeId: 3,
        employeName: 'Dr Biljana Mitrić',
        employeLink: '/biljana-mitric'
      },
      {
        employeId: 4,
        employeName: 'Dr Miloš Gačević',
        employeLink: '/milos-gacevic'
      },
      {
        employeId: 5,
        employeName: 'Dr Miloš Vučetić',
        employeLink: '/milos-vucetic'
      },
      {
        employeId: 6,
        employeName: 'Dr Simon Pandjaitan',
        employeLink: '/simon-pandjatan'
      },
      {
        employeId: 7,
        employeName: 'Dr Uroš Radivojčević',
        employeLink: '/uros-radivojcevic'
      },
      {
        employeId: 8,
        employeName: 'Dr Željko Ćurčić',
        employeLink: '/zeljko-curcic'
      },
      {
        employeId: 9,
        employeName: 'Dr Željko Stanojlović',
        employeLink: '/zeljko-stanojlovic'
      }
    ],
    text: [
      {
        contentId: 1,
        content: 
          'Korekcija kapaka, poznata i kao blefaroplastika, je estetski hirurški postupak koji se koristi za poboljšanje izgleda kapaka. Ova procedura se obično izvodi radi uklanjanja viška kože, masnog tkiva ili mišića oko očiju.'
      },
      {
        contentId: 2,
        content: 
          'Korekcija kapaka može značajno poboljšati izgled očiju, smanjiti naduvenost kapaka i umanjiti vidljive znakove starenja. Osim estetskih koristi, ovaj postupak takođe može poboljšati vid kod pacijenata čija je vidljivost ometena zbog opuštenih kapaka.'
      }
    ]  
  },
  {
    serviceId: 15,
    serviceName: 'korekcija ušiju',
    employes: [
      {
        employeId: 1,
        employeName: 'Dr Ana Malivuković',
        employeLink: '/ana-malivukovic'
      },
      {
        employeId: 2,
        employeName: 'Dr Igor Maljković',
        employeLink: '/igor-maljkovic'
      },
      {
        employeId: 3,
        employeName: 'Dr Biljana Mitrić',
        employeLink: '/biljana-mitric'
      },
      {
        employeId: 4,
        employeName: 'Dr Miloš Gačević',
        employeLink: '/milos-gacevic'
      },
      {
        employeId: 5,
        employeName: 'Dr Miloš Vučetić',
        employeLink: '/milos-vucetic'
      },
      {
        employeId: 6,
        employeName: 'Dr Simon Pandjaitan',
        employeLink: '/simon-pandjatan'
      },
      {
        employeId: 7,
        employeName: 'Dr Uroš Radivojčević',
        employeLink: '/uros-radivojcevic'
      },
      {
        employeId: 8,
        employeName: 'Dr Željko Ćurčić',
        employeLink: '/zeljko-curcic'
      },
      {
        employeId: 9,
        employeName: 'Dr Željko Stanojlović',
        employeLink: '/zeljko-stanojlovic'
      }
    ],
    text: [
      {
        contentId: 1,
        content: 
          'Korekcija ušiju, poznata i kao otoplastika, je hirurški postupak koji se koristi za ispravljanje deformiteta ili nepravilnosti ušiju. Ovaj postupak se često izvodi za ispravljanje izbočenih ili velikih ušiju.'
      },
      {
        contentId: 2,
        content: 
          'Korekcija ušiju može značajno poboljšati izgled ušiju i pomoći pacijentima da se osećaju samopouzdano. Otoplastika se izvodi uz pomoć posebnih tehnika koje omogućavaju oblikovanje hrskavice ušiju i njihovo postavljanje u željeni položaj.'
      }
    ]  
  },
  {
    serviceId: 16,
    serviceName: 'korekcija nosa',
    employes: [
      {
        employeId: 1,
        employeName: 'Dr Ana Malivuković',
        employeLink: '/ana-malivukovic'
      },
      {
        employeId: 2,
        employeName: 'Dr Igor Maljković',
        employeLink: '/igor-maljkovic'
      },
      {
        employeId: 3,
        employeName: 'Dr Biljana Mitrić',
        employeLink: '/biljana-mitric'
      },
      {
        employeId: 4,
        employeName: 'Dr Miloš Gačević',
        employeLink: '/milos-gacevic'
      },
      {
        employeId: 5,
        employeName: 'Dr Miloš Vučetić',
        employeLink: '/milos-vucetic'
      },
      {
        employeId: 6,
        employeName: 'Dr Simon Pandjaitan',
        employeLink: '/simon-pandjatan'
      },
      {
        employeId: 7,
        employeName: 'Dr Uroš Radivojčević',
        employeLink: '/uros-radivojcevic'
      },
      {
        employeId: 8,
        employeName: 'Dr Željko Ćurčić',
        employeLink: '/zeljko-curcic'
      },
      {
        employeId: 9,
        employeName: 'Dr Željko Stanojlović',
        employeLink: '/zeljko-stanojlovic'
      }
    ],
    text: [
      {
        contentId: 1,
        content: 
          'Rinoplastika, poznata kao korekcija nosa, je estetski hirurški postupak koji se fokusira na transformaciju nosa kako bi se postigao harmoničan i uravnotežen izgled lica. Ova delikatna procedura omogućava prilagođavanje veličine, oblika i proporcija nosa prema individualnim željama pacijenta.'
      },
      {
        contentId: 2,
        content: 
          'Korekcija nosa ima dubok uticaj na samopouzdanje i celokupnu estetiku lica. Kroz precizne tehnike i pažljivo planiranje, hirurzi postižu rezultate koji su prirodni i skladni s ostalim crtama lica. Osim estetskih poboljšanja, rinoplastika može rešiti i funkcionalne probleme kao što su disajni problemi ili defekti nosne strukture.'
      },
      {
        contentId: 3,
        content: 
          'Važan korak u korekciji nosa je konsultacija s iskusnim hirurgom koji će pažljivo saslušati vaše želje i pružiti vam informacije o mogućnostima i očekivanjima. Tokom ovog procesa, hirurg će analizirati strukturu nosa, proceniti anatomiju i predložiti najbolji pristup kako bi se postigao željeni izgled.'
      },
      {
        contentId: 4,
        content: 
          'Zakazivanje konsultacije za rinoplastiku je prvi korak prema transformaciji nosa i postizanju estetskog skladu. U rukama stručnjaka, vaš nos može dobiti novi oblik koji će vam doneti samopouzdanje i ravnotežu u izgledu lica.'
      }
    ]  
  },
  {
    serviceId: 17,
    serviceName: 'face lifting',
    employes: [
      {
        employeId: 1,
        employeName: 'Dr Ana Malivuković',
        employeLink: '/ana-malivukovic'
      },
      {
        employeId: 2,
        employeName: 'Dr Igor Maljković',
        employeLink: '/igor-maljkovic'
      },
      {
        employeId: 3,
        employeName: 'Dr Biljana Mitrić',
        employeLink: '/biljana-mitric'
      },
      {
        employeId: 4,
        employeName: 'Dr Miloš Gačević',
        employeLink: '/milos-gacevic'
      },
      {
        employeId: 5,
        employeName: 'Dr Miloš Vučetić',
        employeLink: '/milos-vucetic'
      },
      {
        employeId: 6,
        employeName: 'Dr Simon Pandjaitan',
        employeLink: '/simon-pandjatan'
      },
      {
        employeId: 7,
        employeName: 'Dr Uroš Radivojčević',
        employeLink: '/uros-radivojcevic'
      },
      {
        employeId: 8,
        employeName: 'Dr Željko Ćurčić',
        employeLink: '/zeljko-curcic'
      },
      {
        employeId: 9,
        employeName: 'Dr Željko Stanojlović',
        employeLink: '/zeljko-stanojlovic'
      }
    ],
    text: [
      {
        contentId: 1,
        content: 
          'Face lifting, takođe poznat kao rhytidectomy, je estetski hirurški postupak koji ima za cilj podizanje i zatezanje kože lica i vrata. Ova popularna procedura pomaže u redukciji vidljivih znakova starenja kao što su opuštena koža, bore i gubitak tonusa.'
      },
      {
        contentId: 2,
        content: 
          'Face lifting se izvodi uz pomoć pažljivo planiranih rezova koji omogućavaju pristup dubljim slojevima kože i mišića. Tokom postupka, hirurg zateže kožu i potkožno tkivo, uklanja višak kože i oblikuje lice kako bi se postigao prirodan i osvežen izgled.'
      },
      {
        contentId: 3,
        content: 
          'Ovaj postupak može imati značajan uticaj na celokupnu estetiku lica, pružajući dugotrajne rezultate. Konsultacija sa iskusnim hirurgom je ključna pre face liftinga, gde će se razgovarati o vašim ciljevima, pružiti vam informacije o postupku i potencijalnim rizicima.'
      },
      {
        contentId: 4,
        content: 
          'Face lifting je personalizovana procedura koja se prilagođava individualnim potrebama pacijenta. Pouzdanost i stručnost hirurga su od presudnog značaja kako bi se postigli prirodni i zadovoljavajući rezultati.'
      }
    ]  
  },
  {
    serviceId: 18,
  },
  {
    serviceId: 19,
    serviceName: 'kardiologija',
    employes: [
      {
        employeId: 1,
        employeName: 'Dr Borko Josifovski',
        employeLink: '/borko-josifovski'
      }
    ],
    text: [
      {
        contentId: 1,
        content: 
          'Kardiologija se bavi proučavanjem, dijagnostikovanjem i lečenjem srčanih oboljenja, kao i prevencijom srčanih problema. Stručnjaci kardiolozi koriste različite metode kao što su EKG, ehokardiografija i koronarna angiografija kako bi pravilno procenili zdravlje srca.'
      },
      {
        contentId: 2,
        content: 
          'Redovne posete kardiologu mogu igrati ključnu ulogu u očuvanju srčanog zdravlja. Preventivni pregledi omogućavaju rano otkrivanje potencijalnih srčanih problema i sprečavanje ozbiljnijih komplikacija. Uz to, usvajanje zdravih životnih navika poput uravnotežene ishrane, redovne fizičke aktivnosti i prestanka pušenja može značajno smanjiti rizik od srčanih bolesti.'
      },
      {
        contentId: 3,
        content: 
          'Kardiolozi su obučeni za tretiranje različitih stanja srca kao što su koronarna bolest srca, aritmije, srčani udar i zatajenje srca. Uz pomoć savremenih tehnologija i terapija, oni pružaju sveobuhvatnu negu pacijentima sa srčanim oboljenjima.'
      },
      {
        contentId: 4,
        content: 
          'Kardiologija je ključna grana medicine koja se bavi očuvanjem srčanog zdravlja. Redovni pregledi kod kardiologa i usvajanje zdravih životnih navika mogu biti od vitalnog značaja za prevenciju srčanih oboljenja i održavanje zdravog srca.'
      },
      {
        contentId: 5,
        content: 'Spisak najčešćih oboljenja:',
        contentList: [
          {
            listItemId: 1,
            listItem: 
              'Koronarna bolest srca: Koronarna bolest srca je stanje koje se javlja kada krvne žile koje snabdevaju srce kiseonikom postanu sužene ili blokirane. To može dovesti do angine pektoris (bol u grudima) ili srčanog udara.'
          },
          {
            listItemId: 2,
            listItem: 
              'Visok krvni pritisak (hipertenzija): Visok krvni pritisak je stanje u kojem krvni pritisak u arterijama ostaje povišen duže vreme. To može povećati rizik od srčanih bolesti, srčanog udara, moždanog udara i drugih komplikacija.'
          },
          {
            listItemId: 3,
            listItem: 
              'Srčani udar (infarkt miokarda): Srčani udar nastaje kada dotok krvi u srčani mišić bude blokiran, obično usled začepljenja koronarnih arterija. Ovo može oštetiti srčani mišić i dovesti do ozbiljnih posledica.'
          },
          {
            listItemId: 4,
            listItem: 
              'Srčana insuficijencija: Srčana insuficijencija je stanje u kojem srce ne može efikasno pumpati dovoljno krvi da zadovolji potrebe tela. To može izazvati simptome poput umora, otežanog disanja, oticanja nogu i slabljenja srčane funkcije.'
          },
          {
            listItemId: 5,
            listItem: 
              'Aritmije: Aritmije su poremećaji srčanog ritma, koji mogu uključivati brzo, sporo ili nepravilno kucanje srca. To može izazvati simptome poput lupanja srca, vrtoglavice, gubitka svesti ili nedostatka daha.'
          }
        ]
      },
    ]  
  },
  {
    serviceId: 20,
    serviceName: 'pulmologija',
    employes: [
      {
        employeId: 1,
        employeName: 'Prof. dr Goran Plavec',
        employeLink: '/goran-plavec'
      }
    ],
    text: [
      {
        contentId: 1,
        content: 
          'Pulmologija je medicinska specijalnost koja se fokusira na dijagnozu, lečenje i prevenciju bolesti disajnih puteva i pluća. Pluća su vitalni organi odgovorni za disanje i unos kiseonika u organizam. Pulmolozi su stručnjaci koji se bave problemima kao što su astma, hronična opstruktivna bolest pluća (HOBP), pneumonija, bronhitis i mnoge druge respiratorne bolesti.'
      },
      {
        contentId: 2,
        content: 
          'Redovni poseti pulmologu može pomoći u otkrivanju i praćenju stanja pluća. Pulmolog koristi različite dijagnostičke procedure, uključujući spirometriju, rendgenske snimke pluća, kompjuterizovanu tomografiju (CT) i bronhoskopiju kako bi procenio funkciju pluća i identifikovao potencijalne probleme.'
      },
      {
        contentId: 3,
        content: 
          'Prevencija je takođe važan aspekt pulmologije. Edukacija o pravilnom disanju, izbegavanje izloženosti štetnim supstancama poput duvanskog dima ili zagađenog vazduha, kao i vakcinacija protiv respiratornih infekcija mogu smanjiti rizik od razvoja respiratornih bolesti.'
      },
      {
        contentId: 4,
        content: 
          'Pulmolozi pružaju sveobuhvatnu negu pacijentima sa respiratornim problemima. Oni mogu preporučiti terapiju lekovima, inhalatore, fizikalnu terapiju ili čak hirurške intervencije u određenim slučajevima. Individualizovani plan lečenja se prilagođava svakom pacijentu, uzimajući u obzir specifične potrebe i dijagnoze.'
      },
      {
        contentId: 5,
        content: 
          'Ako imate simptome kao što su dugotrajni kašalj, nedostatak daha ili bol u grudima, važno je posetiti pulmologa radi dijagnoze i lečenja. Rano otkrivanje i pravilan tretman mogu poboljšati kvalitet života i sprečiti komplikacije.'
      },
      {
        contentId: 6,
        content: 'Spisak najčešćih oboljenja:',
        contentList: [
          {
            listItemId: 1,
            listItem: 
              'Hronična opstruktivna bolest pluća (HOBP): HOBP je progresivna bolest koja otežava protok vazduha iz pluća. Uzrokovana je dugotrajnim izlaganjem štetnim česticama poput duvanskog dima i može dovesti do simptoma poput kašlja, otežanog disanja i smanjenja fizičke aktivnosti.'
          },
          {
            listItemId: 2,
            listItem: 
              'Astma: Astma je hronična upalna bolest disajnih puteva koja uzrokuje suženje disajnih puteva, otežavajući protok vazduha. Osobe s astmom mogu iskusiti napade kašlja, gušenja, otežanog disanja i zviždanja u grudima.'
          },
          {
            listItemId: 3,
            listItem: 
              'Pneumonija: Infekcija pluća koja može biti uzrokovana različitim mikroorganizmima, poput bakterija, virusa ili gljivica. Simptomi pneumonije mogu uključivati groznicu, kašalj, bol u grudima, zamor i otežano disanje.'
          },
          {
            listItemId: 4,
            listItem: 
              'Rak pluća: Rak pluća je ozbiljna bolest u kojoj maligne ćelije nastaju u tkivu pluća. To može dovesti do različitih simptoma, uključujući kašalj, iskašljavanje krvi, gubitak težine i otežano disanje.'
          },
          {
            listItemId: 5,
            listItem: 
              'Apneja spavanja: Apneja spavanja je poremećaj u kojem osoba ima prekide u disanju tokom spavanja. Najčešći tip apneje spavanja je opstruktivna apneja, u kojoj se disajni putevi zatvaraju tokom sna. Simptomi uključuju glasno hrkanje, prekide u disanju, umor tokom dana i problem sa koncentracijom.'
          }
        ]
      },
    ]  
  },
  {
    serviceId: 21,
    serviceName: 'endokrinologija',
    text: [
      {
        contentId: 1,
        content: 
          'Endokrinologija je medicinska specijalnost koja se bavi proučavanjem, dijagnostikom i lečenjem poremećaja endokrinog sistema. Endokrini sistem je odgovoran za proizvodnju i regulaciju hormona u organizmu, koji igraju ključnu ulogu u mnogim biološkim procesima. Endokrinolozi su stručnjaci koji se bave bolestima štitne žlezde, dijabetesom, poremećajima nadbubrežne žlezde, polnih hormona i drugim stanjima vezanim za endokrine žlezde.'
      },
      {
        contentId: 2,
        content: 
          'Poseta endokrinologu može biti od vitalnog značaja za pacijente sa hormonalnim poremećajima. Endokrinolog će obaviti temeljnu dijagnostiku koja uključuje analizu krvi, hormonske testove i druge specifične pretrage. Na osnovu rezultata, endokrinolog će postaviti dijagnozu i razviti individualizovani plan lečenja.'
      },
      {
        contentId: 3,
        content: 
          'Prevencija i edukacija su takođe važni aspekti endokrinologije. Endokrinolog može pružiti smernice za zdravu ishranu, fizičku aktivnost i upravljanje telesnom težinom kako bi se smanjio rizik od razvoja endokrinih poremećaja poput dijabetesa tipa 2. Redovni pregledi kod endokrinologa takođe su važni za praćenje stanja i pravovremeno reagovanje na eventualne promene.'
      },
      {
        contentId: 4,
        content: 
          'Lečenje endokrinih poremećaja može uključivati terapiju lekovima, insulinsku terapiju, hormonsku supstituciju ili hirurške intervencije, u zavisnosti od specifične dijagnoze. Endokrinolog će raditi u saradnji sa pacijentom kako bi postigao optimalne rezultate i poboljšao kvalitet života.'
      },
      {
        contentId: 5,
        content: 
          'Ako primetite simptome kao što su promene telesne težine, umor, poremećaji raspoloženja ili druge manifestacije vezane za hormonalne poremećaje, važno je potražiti medicinsku pomoć. Endokrinolog će vam pružiti stručnu dijagnostiku, lečenje i podršku.'
      },
      {
        contentId: 6,
        content: 'Spisak najčešćih oboljenja:',
        contentList: [
          {
            listItemId: 1,
            listItem: 
              'Dijabetes tipa 2: Hronična bolest koja se karakteriše povišenim nivoom šećera u krvi usled nedovoljne proizvodnje insulina ili otpornosti na insulin. Dijabetes tipa 2 obično se razvija u odrasloj dobi i često je povezan sa nezdravim načinom života i gojaznošću.'
          },
          {
            listItemId: 2,
            listItem: 
              'Hipotireoza: Smanjena funkcija štitne žlezde koja dovodi do nedovoljne proizvodnje hormona štitne žlezde. Simptomi hipotireoze mogu uključivati umor, promene raspoloženja, usporen metabolizam i povećanje telesne težine.'
          },
          {
            listItemId: 3,
            listItem: 
              'Hipertireoza (Basedowova bolest): Povećana funkcija štitne žlezde koja dovodi do prekomerne proizvodnje hormona štitne žlezde. Simptomi hipertireoze mogu uključivati ubrzan rad srca, gubitak telesne težine, nervozu i drhtanje ruku.'
          },
          {
            listItemId: 4,
            listItem: 
              'Polikistični jajnici: Endokrini poremećaj koji se javlja kod žena i karakteriše ga pojava cista na jajnicima. Ovaj poremećaj može dovesti do poremećaja menstrualnog ciklusa, neplodnosti, povećanog rasta dlaka i promena raspoloženja.'
          },
          {
            listItemId: 5,
            listItem: 
              'Osteoporoza: Bolest koja uzrokuje smanjenje gustine kostiju i povećava rizik od preloma. Osteoporoza se često javlja kod starijih osoba, posebno žena u postmenopauzi, kao rezultat smanjene proizvodnje hormona estrogena.'
          }
        ]
      },
    ]  
  },
  {
    serviceId: 22,
    serviceName: 'reumatologija',
    text: [
      {
        contentId: 1,
        content: 
          'Reumatologija je medicinska specijalnost koja se bavi dijagnostikom i lečenjem raznih poremećaja mišićno-koštanog sistema, uključujući reumatoidni artritis, osteoartritis, sistemski eritemski lupus i giht.'
      },
      {
        contentId: 2,
        content: 
          'Reumatolozi su stručnjaci koji pružaju sveobuhvatnu brigu pacijentima, koristeći različite terapijske pristupe kao što su farmakoterapija, fizikalna terapija, rehabilitacija i hirurški zahvati, prilagođeni individualnim potrebama pacijenta.'
      },
      {
        contentId: 3,
        content: 
          'Saradnja sa multidisciplinarnim timom zdravstvenih stručnjaka, uključujući fizioterapeute, nutricioniste i psihologe, takođe doprinosi sveobuhvatnom pristupu u reumatologiji. Ovaj timski pristup omogućava holistički pristup pacijentu i optimalnu brigu za sve aspekte njihovog zdravlja.'
      },
      {
        contentId: 4,
        content: 'Spisak najčešćih oboljenja:',
        contentList: [
          {
            listItemId: 1,
            listItem: 
              'Osteoartritis: Degenerativna bolest zglobova koja uzrokuje propadanje hrskavice i bol u zglobovima. Najčešće se javlja kod starijih osoba.'
          },
          {
            listItemId: 2,
            listItem: 
              'Reumatoidni artritis: Autoimuna bolest koja uzrokuje hroničnu upalu zglobova. Može se javiti u bilo kojem životnom dobu i obično pogađa više zglobova istovremeno.'
          },
          {
            listItemId: 3,
            listItem: 
              'Sistemski lupus eritematozus: Autoimuna bolest koja može uticati na različite delove tela, uključujući zglobove, kožu, bubrege, srce i pluća. Simptomi mogu varirati, ali uključuju umor, bol u zglobovima, osip i poteškoće sa organima.'
          },
          {
            listItemId: 4,
            listItem: 
              'Fibromialgija: Hronični poremećaj koji karakterišu široko rasprostranjena bol, umor, poremećaji spavanja i osetljivost na pritisak. Njegov uzrok nije potpuno razumljiv.'
          },
          {
            listItemId: 5,
            listItem: 
              'Giht: Upalna bolest uzrokovana nakupljanjem kristala mokraćne kiseline u zglobovima, što dovodi do iznenadnih napada jakog bola, otoka i crvenila. Obično zahvata veliki nožni palac.'
          }
        ]
      },
    ]  
  },
  {
    serviceId: 23,
    serviceName: 'hematologija',
    text: [
      {
        contentId: 1,
        content: 
          'Hematologija je grana medicine koja se bavi proučavanjem krvi, koštane srži i povezanih poremećaja. Hematolozi su stručnjaci koji dijagnostikuju i leče bolesti kao što su anemija, leukemija, limfomi i poremećaji koagulacije krvi.'
      },
      {
        contentId: 2,
        content: 
          'Poseta hematologu je važna za pacijente sa simptomima kao što su umor, neobjašnjive modrice, povećane ili smanjene vrednosti krvnih ćelija ili sumnja na maligne bolesti krvi. Hematolog će sprovesti detaljan pregled, uzeti anamnezu i naručiti laboratorijske analize kao deo dijagnostičkog procesa.'
      },
      {
        contentId: 3,
        content: 
          'Lečenje u hematologiji može uključivati različite terapije kao što su transfuzija krvi, hemoterapija, imunoterapija ili transplantacija koštane srži. Redovno praćenje stanja je ključno za praćenje efikasnosti terapije i održavanje zdravlja.'
      },
      {
        contentId: 4,
        content: 
          'Hematologija je multidisciplinarna oblast u kojoj hematolozi često sarađuju sa drugim medicinskim stručnjacima kao što su onkolozi, radiolozi i patolozi.'
      },
      {
        contentId: 5,
        content: 'Spisak najčešćih oboljenja:',
        contentList: [
          {
            listItemId: 1,
            listItem: 
              'Anemija: Smanjenje broja crvenih krvnih zrnaca ili niske koncentracije hemoglobina, što dovodi do umora, slabosti i nedostatka energije.'
          },
          {
            listItemId: 2,
            listItem: 
              'Hemofilija: Nasledni poremećaj koagulacije krvi koji uzrokuje produženo krvarenje i poteškoće u zaustavljanju krvarenja.'
          },
          {
            listItemId: 3,
            listItem: 
              'Leukemija: Rak koštane srži koji utiče na proizvodnju abnormalnih belih krvnih zrnaca, što može dovesti do slabosti, čestih infekcija i drugih simptoma.'
          },
          {
            listItemId: 4,
            listItem: 
              'Limfom: Rak limfnog sistema koji može zahvatiti limfne čvorove, slezinu, koštanu srž i druge delove tela, manifestujući se kao otoci, umor, gubitak telesne težine i drugi simptomi.'
          },
          {
            listItemId: 5,
            listItem: 
              'Tromboza: Stvaranje krvnog ugruška unutar krvnih sudova koji može blokirati protok krvi, što može dovesti do srčanog udara, moždanog udara ili drugih ozbiljnih komplikacija.'
          },
          {
            listItemId: 6,
            listItem: 
              'Talasemija: Nasledni poremećaj koji dovodi do smanjenja ili abnormalnosti proizvodnje hemoglobina, što može rezultirati anemijom i drugim zdravstvenim problemima.'
          },
          {
            listItemId: 7,
            listItem: 
              'Imun trombocitopenijska purpura (ITP): Autoimuna bolest u kojoj imunološki sistem napada i uništava trombocite, što dovodi do povećanog rizika od krvarenja.'
          },
          {
            listItemId: 8,
            listItem: 
              'Mijelodisplastični sindrom (MDS): Grupa poremećaja koštane srži u kojima dolazi do nepravilne proizvodnje krvnih ćelija, što može dovesti do anemije, infekcija i krvarenja.'
          },
          {
            listItemId: 9,
            listItem: 
              'Policitemija vera: Poremećaj u kojem dolazi do prekomernog stvaranja crvenih krvnih zrnaca, što može dovesti do zgrušavanja krvi i povećanog rizika od srčanih i moždanih problema.'
          },
          {
            listItemId: 10,
            listItem: 
              'Sideroblastična anemija: Poremećaj u kojem crvene krvne ćelije sadrže prekomernu količinu gvožđa, što može rezultirati anemijom i drugim simptomima.'
          }
        ]
      },
    ]  
  },
  {
    serviceId: 24,
    serviceName: 'gastroenterologija',
    employes: [
      {
        employeId: 1,
        employeName: 'Dr Jamal Al Kiswani',
        employeLink: '/jamal-al-kiswani'
      },
      {
        employeId: 2,
        employeName: 'Dr Nebojša Manojlović',
        employeLink: '/nebojsa-manojlovic'
      }
    ],
    text: [
      {
        contentId: 1,
        content: 
          'Gastroenterologija je medicinska specijalnost koja se bavi dijagnostikom, lečenjem i prevencijom poremećaja digestivnog sistema. Gastroenterolozi su stručnjaci za bolesti želuca, creva, jetre, pankreasa i žučne kese.'
      },
      {
        contentId: 2,
        content: 
          'Poseta gastroenterologu je važna za pacijente koji imaju simptome kao što su gorušica, bolovi u stomaku, promene u stolici ili problemi sa varenjem. Gastroenterolog će sprovesti detaljan pregled, uzeti anamnezu i naručiti relevantne dijagnostičke testove kako bi postavio tačnu dijagnozu.'
      },
      {
        contentId: 3,
        content: 
          'Lečenje u gastroenterologiji može uključivati farmakoterapiju, promene u ishrani, terapiju za kontrolu simptoma ili hirurške zahvate, u zavisnosti od vrste i ozbiljnosti bolesti. Redovne posete gastroenterologu su ključne za praćenje stanja, prilagođavanje terapije i rano otkrivanje komplikacija.'
      },
      {
        contentId: 4,
        content: 
          'Prevencija je takođe važna u gastroenterologiji. Redovni preventivni pregledi, kao što su kolonoskopija za otkrivanje polipa ili ranih znakova kolorektalnog karcinoma, mogu pomoći u sprečavanju ozbiljnih bolesti i poboljšanju prognoze.'
      },
      {
        contentId: 5,
        content: 'Spisak najčešćih oboljenja:',
        contentList: [
          {
            listItemId: 1,
            listItem: 
              'Gastroezofagealna refluksna bolest (GERB): Stanje koje se javlja kada se želudačna kiselina vraća u jednjak, izazivajući simptome poput gorušice, žarenja u grudima i regurgitacije.'
          },
          {
            listItemId: 2,
            listItem: 
              'Peptički ulkus: Oštećenje sluznice želuca ili dvanaestopalačnog creva, obično uzrokovano bakterijom Helicobacter pylori ili upotrebom nesteroidnih antiinflamatornih lekova (NSAIL).'
          },
          {
            listItemId: 3,
            listItem: 
              'Upalna bolest creva (UC i CD): Kronične autoimune bolesti koje zahvataju crevni sistem. Ulcerozni kolitis (UC) karakteriše upala sluznice debelog creva, dok Kronova bolest (CD) može uticati na bilo koji deo digestivnog trakta.'
          },
          {
            listItemId: 4,
            listItem: 
              'Hepatitis: Upala jetre koja može biti uzrokovana virusnim infekcijama (npr. hepatitis A, B, C) ili drugim faktorima poput alkohola, lekova ili autoimunih poremećaja.'
          },
          {
            listItemId: 5,
            listItem: 
              'Ciroza jetre: Napredno oštećenje jetre koje rezultira zamjenom normalnog tkiva jetre ožiljnim tkivom. To može biti posledica dugotrajnog alkoholizma, virusnog hepatitisa, masne jetre i drugih bolesti.'
          },
          {
            listItemId: 6,
            listItem: 
              'Holecistitis: Upala žučne kese, obično uzrokovana začepljenjem žučnih puteva kamenom. Simptomi mogu uključivati bol u gornjem desnom delu stomaka, mučninu i povraćanje.'
          },
          {
            listItemId: 7,
            listItem: 
              'Pankreatitis: Upala pankreasa koja može biti akutna ili hronična. Akutni pankreatitis često je izazvan alkoholom ili žučnim kamenjem, dok je hronični pankreatitis dugotrajno stanje koje može biti posledica dugotrajne upale.'
          },
          {
            listItemId: 8,
            listItem: 
              'Divertikuloza i divertikulitis: Divertikuloza se odnosi na prisustvo malih džepova (divertikula) duž debelog creva. Ako se ti džepovi upale ili inficiraju, može se razviti divertikulitis.'
          },
          {
            listItemId: 9,
            listItem: 
              'Sindrom iritabilnog creva (IBS): Funkcionalni poremećaj creva koji karakterišu simptomi kao što su bol u stomaku, nadutost, promene u stolici (proliv ili zatvor) i nelagoda.'
          },
          {
            listItemId: 10,
            listItem: 
              'Rak debelog creva: Zloćudni tumor koji se razvija u debelom crevu ili rektumu. Rano otkrivanje putem redovnih pregleda kolonoskopijom je ključno za uspešno lečenje.'
          },
        ]
      },
    ]  
  },
  {
    serviceId: 25
  },
  {
    serviceId: 26,
    serviceName: 'otorinolaringologija',
    employes: [
      {
        employeId: 1,
        employeName: 'Prof. dr Nenad Arsović',
        employeLink: '/nenad-arsovic'
      },
      {
        employeId: 2,
        employeName: 'Dr Dušan Bijelić',
        employeLink: '/dusan-bijelic'
      },
      {
        employeId: 3,
        employeName: 'Dr Ljubomir Pavićević',
        employeLink: '/ljubomir-pavicevic'
      },
      {
        employeId: 4,
        employeName: 'Dr Aleksandar Dimić',
        employeLink: '/aleksandar-dimic'
      },
      {
        employeId: 5,
        employeName: 'Dr Ivan Đorđević',
        employeLink: '/ivan-djordjevic'
      },
      {
        employeId: 6,
        employeName: 'Dr Ljiljana Čvorović',
        employeLink: '/ljiljana-cvorovic'
      },
      {
        employeId: 7,
        employeName: 'Dr Milan Erdoglija',
        employeLink: '/milan-erdoglija'
      },
      {
        employeId: 8,
        employeName: 'Dr Milorad Adžić',
        employeLink: '/milorad-adzic'
      },
      {
        employeId: 9,
        employeName: 'dr Nikola Mirčetić',
        employeLink: '/nikola-mircetic'
      }
    ],
    text: [
      {
        contentId: 1,
        content: 
          'Otorinolaringologija, poznata i kao ORL ili otolaringologija, je medicinska specijalnost koja se bavi dijagnostikom, lečenjem i prevencijom bolesti ušiju, nosa, grla i povezanih struktura glave i vrata.'
      },
      {
        contentId: 2,
        content: 
          'Poseta otorinolaringologu je važna za pacijente sa simptomima kao što su problemi sa sluhom, sinusi, upale grla, smetnje disanja ili promene glasa. Otorinolaringolog će izvršiti pregled, uzeti anamnezu i preporučiti odgovarajuće dijagnostičke testove kao što su audiometrija, endoskopija ili skener sinusa.'
      },
      {
        contentId: 3,
        content: 
          'Lečenje u otorinolaringologiji može uključivati terapiju lekovima, hirurške zahvate, rekonstrukcije ili rehabilitaciju. Otorinolaringolozi su takođe stručnjaci za alergije, ronjenje i poremećaje ravnoteže.'
      },
      {
        contentId: 4,
        content: 
          'Redovni preventivni pregledi, pravilna higijena ušiju, zaštita sluha i izbegavanje štetnih faktora poput duvanskog dima mogu pomoći u očuvanju zdravlja ORL sistema.'
      },
      {
        contentId: 5,
        content: 'Spisak najčešćih oboljenja:',
        contentList: [
          {
            listItemId: 1,
            listItem: 
              'Sinusitis: Upala sinusa koja može uzrokovati zapušen nos, glavobolju, bol u licu i probleme sa disanjem.'
          },
          {
            listItemId: 2,
            listItem: 
              'Otitis media: Infekcija srednjeg uha, često povezana sa simptomima kao što su bol u uhu, osećaj punoće, gubitak sluha i povišena temperatura.'
          },
          {
            listItemId: 3,
            listItem: 
              'Tonzilitis: Upala krajnika koja se može javiti kao akutna ili hronična infekcija. Simptomi mogu uključivati upalu grla, poteškoće pri gutanju i oticanje krajnika.'
          },
          {
            listItemId: 4,
            listItem: 
              'Vertigo: Osećaj vrtoglavice i gubitka ravnoteže koji može biti uzrokovan problemima u unutrašnjem uhu ili centralnom nervnom sistemu.'
          },
          {
            listItemId: 5,
            listItem: 
              'Glasovni poremećaji: Problemi sa glasom kao što su promuklost, grubi glas ili gubitak glasa, koji mogu biti uzrokovani inflamacijom, polipima na glasilkama ili drugim strukturnim abnormalnostima.'
          },
          {
            listItemId: 6,
            listItem: 
              'Nazalna polipoza: Rast benignih polipa u nosu koji mogu izazvati začepljenje nosa, otežano disanje i gubitak mirisa.'
          },
          {
            listItemId: 7,
            listItem: 
              'Apneja za vreme spavanja: Poremećaj disanja tokom sna koji se karakteriše prestankom disanja na određeno vreme, često uzrokovan blokiranim disajnim putevima.'
          },
          {
            listItemId: 8,
            listItem: 
              'Hronični sinusni polipi: Produženi rast polipa u sinusima koji mogu izazvati hronične simptome sinusitisa i otežano disanje.'
          },
          {
            listItemId: 9,
            listItem: 
              'Meniereova bolest: Stanje unutrašnjeg uha koje dovodi do ponavljajućih napada vrtoglavice, gubitka sluha, zujanja u ušima i osećaja punoće u uhu.'
          },
          {
            listItemId: 10,
            listItem: 
              'Rak glave i vrata: Tumori koji se javljaju u regiji glave i vrata, uključujući rak usne šupljine, grla, ždrela, nosa i sinusa.'
          },
        ]
      },
    ]  
  },
  {
    serviceId: 27,
    serviceName: 'neurologija',
    text: [
      {
        contentId: 1,
        content: 
          'Neurologija je medicinska specijalnost koja se bavi proučavanjem, dijagnostikom, lečenjem i prevencijom poremećaja nervnog sistema. Neurolozi su stručnjaci za bolesti mozga, kičmene moždine, nerava i mišića.'
      },
      {
        contentId: 2,
        content: 
          'Poseta neurologu je važna za pacijente sa simptomima kao što su glavobolje, vrtoglavica, gubitak svesti, promene raspoloženja, problemi sa pokretom ili poremećaji spavanja. Neurolog će sprovesti detaljan neurološki pregled, uzeti anamnezu i naručiti relevantne dijagnostičke testove kao što su elektroencefalografija (EEG), magnetska rezonanca (MRI) ili neurološke testove funkcija.'
      },
      {
        contentId: 3,
        content: 
          'Ukratko, neurologija je specijalnost koja se bavi poremećajima nervnog sistema. Poseta neurologu je važna za dijagnozu, lečenje i prevenciju neuroloških stanja. Prevencija, rano otkrivanje i saradnja sa multidisciplinarnim timom stručnjaka su ključni za očuvanje zdravlja nervnog sistema i poboljšanje kvaliteta života.'
      },
      {
        contentId: 4,
        content: 'Spisak najčešćih oboljenja:',
        contentList: [
          {
            listItemId: 1,
            listItem: 
              'Moždani udar: Oštećenje mozga usled nedovoljnog dotoka krvi, najčešće izazvano začepljenjem ili pucanjem krvnog suda u mozgu.'
          },
          {
            listItemId: 2,
            listItem: 
              'Epilepsija: Hronični neurološki poremećaj koji karakterišu ponavljajući epileptički napadi. Napadi mogu biti različitog intenziteta i tipa.'
          },
          {
            listItemId: 3,
            listItem: 
              'Multiple skleroza: Autoimuna bolest koja oštećuje mijelinsku ovojnicu nervnih vlakana, što dovodi do problema u provođenju nervnih signala i raznih neuroloških simptoma.'
          },
          {
            listItemId: 4,
            listItem: 
              'Parkinsonova bolest: Degenerativni poremećaj koji dovodi do postepenog propadanja neurona koji su odgovorni za kontrolu pokreta. Karakteriše ga tremor, usporenost pokreta i drhtavica.'
          },
          {
            listItemId: 5,
            listItem: 
              'Neuropatija: Oštećenje perifernih nerava koje može izazvati simptome poput trnjenja, utrnulosti, slabosti mišića i bola.'
          },
          {
            listItemId: 6,
            listItem: 
              'Migrene: Recidivirajući napadi glavobolje koji mogu biti praćeni mučninom, povraćanjem i osetljivošću na svetlost i zvuk.'
          },
          {
            listItemId: 7,
            listItem: 
              'Alzheimerova bolest: Progresivna neurodegenerativna bolest koja dovodi do postepenog gubitka memorije, kognitivnog propadanja i promena ponašanja.'
          },
          {
            listItemId: 8,
            listItem: 
              'Guillain-Barréov sindrom: Rijedak neurološki poremećaj koji uzrokuje slabost mišića, trnjenje i ukočenost, često započinjući u nogama i šireći se prema gore.'
          },
          {
            listItemId: 9,
            listItem: 
              'Huntingtonova bolest: Nasledni poremećaj koji dovodi do postepenog propadanja neurona u određenim delovima mozga, izazivajući motorne, kognitivne i emocionalne probleme.'
          },
          {
            listItemId: 10,
            listItem: 
              'Cerebralna paraliza: Grupa poremećaja pokreta i mišićne kontrole koji se javljaju usled oštećenja mozga pre, tokom ili nakon porođaja.'
          },
        ]
      },
    ]  
  },
  {
    serviceId: 28,
    serviceName: 'ginekologija',
    employes: [
      {
        employeId: 1,
        employeName: 'Dr Slavica Pavlović',
        employeLink: '/slavica-pavlovic'
      },
      {
        employeId: 2,
        employeName: 'Dr Jovan Rudić',
        employeLink: '/jovan-rudic'
      }
    ],
    text: [
      {
        contentId: 1,
        content: 
          'Oblast medicine koja se bavi zdravljem žena, posebno reproduktivnim sistemom. Kao ginekolozi, posvećeni smo pružanju sveobuhvatne zdravstvene nege ženama u svim fazama njihovog života.'
      },
      {
        contentId: 2,
        content: 
          'Redovni ginekološki pregledi, dijagnostika i lečenje ginekoloških problema, kontracepcija, praćenje trudnoće i porođaja, kao i brigu o reproduktivnom zdravlju žena.'
      },
      {
        contentId: 3,
        content: 
          'Ginekolozi su obučeni da identifikuju, dijagnostikuju i leče širok spektar ginekoloških stanja, kao što su hormonalni disbalansi, infekcije, polipi, ciste, endometrioza, miomi, neplodnost i druge probleme koji mogu uticati na zdravlje žena.'
      }
    ]  
  },
  {
    serviceId: 29,
    serviceName: 'fizikalna medicina',
    employes: [
      {
        employeId: 1,
        employeName: 'Prof. dr Branka Marković - Specijalista fizikalne medicine i rehabilitacije',
        employeLink: '/branka-markovic'
      }
    ],
    text: [
      {
        contentId: 1,
        content: 
          'Fizikalna medicina je medicinska disciplina koja se bavi prevencijom, dijagnozom i tretmanom različitih fizičkih problema i povreda. Ova oblast medicine koristi širok spektar terapijskih tehnika i metoda kako bi se pacijentima pružila rehabilitacija i olakšanje.'
      },
      {
        contentId: 2,
        content: 
          'Fizikalna medicina obuhvata različite terapije i tehnike, uključujući fizioterapiju, elektroterapiju, masažu, lasersku terapiju i još mnogo toga. Svaki tretman se prilagođava individualnim potrebama svakog pacijenta, uzimajući u obzir njihovu dijagnozu, simptome i ciljeve oporavka.'
      }
    ]  
  },
  {
    serviceId: 30,
    serviceName: 'radiologija',
    employes: [
      {
        employeId: 1,
        employeName: 'Dr Jasenka Grebenarević',
        employeLink: '/jasenka-grebenarevic'
      },
      {
        employeId: 2,
        employeName: 'Dr Ana Golubović',
        employeLink: '/ana-golubovic'
      },
      {
        employeId: 3,
        employeName: 'Dr Borivoje Lukić',
        employeLink: '/borivoje-lukic'
      }
    ],
    text: [
      {
        contentId: 1,
        content: 
          'Radiologija je medicinska specijalnost koja se bavi dijagnostikom i lečenjem bolesti i povreda pomoću medicinskih slika. Koristi se različite tehnike, kao što su rendgenski snimci, CT (kompjuterizovana tomografija), MR (magnetna rezonanca) i ultrazvuk, kako bi se vizualizovali unutrašnji delovi tela.'
      },
      {
        contentId: 2,
        content: 
          'Optimizacija je važan aspekt radiologije koji se odnosi na postizanje najboljih rezultata uz minimalno izlaganje pacijenata zračenju. Radiolozi primenjuju posebne protokole i tehnike snimanja kako bi smanjili dozu zračenja, posebno kod rendgenskih snimaka i CT skenova. Takođe koriste zaštitne štitnike i uređaje koji smanjuju izlaganje zračenju tokom procedura.'
      },
      {
        contentId: 3,
        content: 
          'Pored dijagnostike, radiologija se koristi i za vođenje intervencionih procedura. Radiolozi koriste medicinske slike kako bi navigirali prilikom izvođenja minimalno invazivnih zahvata, kao što su biopsije, drenaže ili postavljanje stentova. Ove procedure omogućavaju pacijentima brži oporavak i manje rizika u poređenju sa tradicionalnom hirurgijom.'
      },
      {
        contentId: 4,
        content: 
          'Radiologija ima važnu ulogu u medicinskom timu, pružajući precizne dijagnostičke informacije i individualizovan pristup lečenju. Koristeći tehnologiju, stručnost i etičke principe, radiolozi doprinose poboljšanju zdravlja i blagostanja pacijenata.'
      }
    ]  
  },
  {
    serviceId: 31,
    serviceName: 'pedijatrija',
    text: [
      {
        contentId: 1,
        content: 
          'Pedijatrija je medicinska specijalnost koja se fokusira na zdravlje dece. Pedijatri dijagnostikuju, sprečavaju i leče bolesti i povrede kod dece svih uzrasta. Cilj pedijatrije je pružanje sveobuhvatne zdravstvene nege deci, uzimajući u obzir njihove specifične potrebe i razvojne faze.'
      },
      {
        contentId: 2,
        content: 
          'Pedijatri redovno pregledaju decu kako bi pratili njihov rast i razvoj, procenili fizičko i mentalno zdravlje i pravovremeno otkrili potencijalne zdravstvene probleme. Prevencija bolesti je ključna u pedijatriji, kroz imunizaciju, edukaciju o zdravom načinu života, pravilnu ishranu i redovne preglede.'
      },
      {
        contentId: 3,
        content: 'Najčešće bolesti u pedijatriji:',
        contentList: [
          {
            listItemId: 1,
            listItem: 
              'Infekcije disajnih puteva: Prehlada, grip, bronhitis, upala pluća, zapaljenje srednjeg uha.'
          },
          {
            listItemId: 2,
            listItem: 
              'Gastroenteritis: Infekcija digestivnog trakta koja uzrokuje dijareju, povraćanje, stomak bol.'
          },
          {
            listItemId: 3,
            listItem: 
              'Alergije: Alergijski rinitis (peludna groznica), atopijski dermatitis, alergije na hranu.'
          },
          {
            listItemId: 4,
            listItem: 
              'Astma: Hronično zapaljenje disajnih puteva, otežano disanje, kašalj, gušenje.'
          },
          {
            listItemId: 5,
            listItem: 
              'ADHD (poremećaj pažnje i hiperaktivnosti): Poremećaj pažnje, impulsivnost, hiperaktivnost kod dece.'
          },
          {
            listItemId: 6,
            listItem: 
              'Autizam spektralnih poremećaja: Razvojni poremećaj, socijalne interakcije, komunikacija, ponašanje.'
          },

          {
            listItemId: 7,
            listItem: 
              'Osipi kod dece: Boginje, ospice, rubeola, šarlah, ekcem.'
          },
          {
            listItemId: 8,
            listItem: 
              'Dečji dijabetes: Poremećaj metabolizma glukoze, praćenje šećera u krvi, terapija insulinom.'
          },
          {
            listItemId: 9,
            listItem: 
              'Infekcije urinarnog trakta: Upala mokraćnog mehura (cistitis), upala bubrega (pielonefritis).'
          },
          {
            listItemId: 10,
            listItem: 
              'Rast i razvojni poremećaji: Zaostajanje u rastu, pubertetski problemi, nedostatak vitamina, minerala, poremećaji endokrinog sistema.'
          },
        ]
      },
    ]  
  }
]

export default servicesData;