import React from "react";
import './blog-right-container.css';
import { Link } from "react-router-dom";

import BlogFieldCard from "../blog-field-card/blog-field-card.js";
import BlogSmallPost from "../../../components/blog/blog-small-post/blog-small-post.js";
import BlogMarketing from "../../../components/blog/blog-marketing/blog-marketing.js";

import fieldCardsData from '../../../assets/blog-data/field-cards-data.js';
import blogData from "../../../assets/blog-data/blog-data.js";
import blogMarketingData from '../../../assets/blog-data/blog-marketing-data.js';

export default function BlogRightContainer() {

  // this state determine which card has true value so it's content can be visible
  const [fieldCardsState, setFieldCardsState] = React.useState([
    {
      id: 1,
      isOn: false
    },
    {
      id: 2,
      isOn: false
    },
    {
      id: 3,
      isOn: false
    },
    {
      id: 4,
      isOn: false
    },
    {
      id: 5,
      isOn: false
    },
    {
      id: 6,
      isOn: false
    },
    {
      id: 7,
      isOn: false
    },
    {
      id: 8,
      isOn: false
    }
  ])

  // showCardContent function changing isOn value in fieldCardsState for card that is cliced and returning isOn: false for every other card
  const showCardContent = (id) => {
    setFieldCardsState((prevState) => {
      return prevState.map((fieldCard) => {
        if (fieldCard.id === id) {
          return {...fieldCard, isOn: !fieldCard.isOn};
        } else {
          return {...fieldCard, isOn: false};
        }
      })
    })
  }

  // small posts array that are filtered from blogData posts with smallPostId and sorted by ascending.
  const smallPosts = blogData.filter(post => post.hasOwnProperty('smallPostId')).sort((a, b) => a.smallPostId - b.smallPostId);

  // part of code that renderes
  return (
    <>

      {/* large screen blog right container */}
      <div className="ls-blog-right-container">

        {/* large screen blog right cards container */}
        <div 
          className="ls-blog-right-field-cards-container"
          style={{paddingBottom: fieldCardsState[fieldCardsState.length - 1].isOn === true ? 
            '' : '3.333vw' }}>
          {fieldCardsData.map((card, index) => {
            const lastCard = index === fieldCardsData.length - 1;
            const borderStyle = lastCard ? 'none' : '0.26vw solid';
            const paddingStyle = lastCard ? '' : '1.667vw'
            return (
              <BlogFieldCard 
                key={card.id}
                card={card}
                isOn={fieldCardsState[index].isOn}
                // passing showCardContent function to BlogFieldCard component so it can be used onClick
                showCardContent={() => showCardContent(card.id)}
                borderStyle={borderStyle}
                paddingStyle={paddingStyle}/>
            )
          })}
        </div>

        {/* large screen blog right small post marketing container */}
        <div className="ls-blog-right-small-posts-marketing-container">

          {/* large screen blog right small posts */}
          {smallPosts.slice(0, 2).map((post) => {
            return (
              <BlogSmallPost 
                key={post.smallPostId}
                post={post}/>
            )
          })}

          {/* large screen blog right horizontal rule */}
          <hr className="ls-blog-right-horizontal-rule"/>

          {/* large screen blog right marketing post container */}
          {blogMarketingData.slice(0, 1).map((marketingPost) => {
            return (
              <BlogMarketing 
                key={marketingPost.id}
                marketingPost={marketingPost}/>
            )
          })}

          {/* large screen blog right reservation button */}
          <Link to="/contact" style={{textDecoration: 'none'}}>
            <div className="ls-blog-right-reservation-button">
              <p>Zakažite pregled</p>
            </div>
          </Link>

          {/* large screen blog right horizontal rule */}
          <hr className="ls-blog-right-horizontal-rule"/>

          {/* large screen blog right small posts */}
          {smallPosts.slice(2, 6).map((post) => {
            return (
              <BlogSmallPost 
                key={post.smallPostId}
                post={post}/>
            )
          })}

          {/* large screen blog right marketing post container */}
          {blogMarketingData.slice(1, 2).map((marketingPost) => {
            return (
              <BlogMarketing 
                key={marketingPost.id}
                marketingPost={marketingPost}
                marginTop={1.667}/>
            )
          })}
        </div>
      </div>

    </>
  )
}