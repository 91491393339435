import React from "react";
import './blog-post.css';

import Post from "../../components/post/post";
import blogData from "../../assets/blog-data/blog-data.js";

export default function BlogPost3() {
  // Ensure blogData is defined and is an array
  const validBlogData = Array.isArray(blogData) ? blogData : [];

  // Render part of the code
  return (
    <>
      {validBlogData.map((post, index) => {
        if (post.postId === 3) {
          return (
            <Post
              key={post.postId}
              // Passing object info from blogData to Post component
              post={post} // Using post directly instead of blogData[index]
            />
          );
        } else {
          return null; // Explicitly return null for unmatched posts
        }
      })}
    </>
  );
}
