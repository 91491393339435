import React from "react";
import './ss-blog-post.css';
import { Link } from "react-router-dom";

export default function SsBlogPost({post}) {
  // part of code that renders
  return (
    <>
      {/* small screen blog post container */}
      <Link to={post.link} style={{textDecoration: 'none'}}>
        <div className="ss-blog-post-container">

          {/* small screen blog post image */}
          <img 
            src={post.image}
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt={post.alternative}/>

          {/* small screen blog post text container */}
          <div className="ss-blog-post-text-container">

            <p className="ss-blog-post-text-title">{post.title}</p>

            <p className="ss-blog-post-text-paragraph">{post.paragraph}</p>
          </div>
        </div>
      </Link>
    </>
  )
}