// list of field cards
const fieldCardsData = [
  {
    id: 1,
    name: 'neurohirurgija',
    content: [
      {
        id: 1,
        text: 'hirurgija tumora mozga'
      },
      {
        id: 2,
        text: 'hirurgija kičme'
      },
      {
        id: 3,
        text: 'cerebrovaskularna hirurgija'
      },
      {
        id: 4,
        text: 'hirurgija perifernih nerava'
      },
      {
        id: 5,
        text: 'hidrocefalus'
      },
      {
        id: 6,
        text: 'epilepsija'
      },
      {
        id: 7,
        text: 'trauma glave'
      },
      {
        id: 8,
        text: 'stereotaktička hirurgija'
      },
      {
        id: 9,
        text: 'neuroendoskopija'
      },
      {
        id: 10,
        text: 'degenerativne bolesti kičme'
      },
    ]
  },
  {
    id: 2,
    name: 'ortopedija sa traumatologijom',
    content: [
      {
        id: 1,
        text: 'hirurgija tumora mozga'
      },
      {
        id: 2,
        text: 'hirurgija kičme'
      },
      {
        id: 3,
        text: 'cerebrovaskularna hirurgija'
      },
      {
        id: 4,
        text: 'hirurgija perifernih nerava'
      },
      {
        id: 5,
        text: 'hidrocefalus'
      },
      {
        id: 6,
        text: 'epilepsija'
      },
      {
        id: 7,
        text: 'trauma glave'
      },
      {
        id: 8,
        text: 'stereotaktička hirurgija'
      },
      {
        id: 9,
        text: 'neuroendoskopija'
      },
      {
        id: 10,
        text: 'degenerativne bolesti kičme'
      },
    ]
  },
  {
    id: 3,
    name: 'otorinolaringologija',
    content: [
      {
        id: 1,
        text: 'hirurgija tumora mozga'
      },
      {
        id: 2,
        text: 'hirurgija kičme'
      },
      {
        id: 3,
        text: 'cerebrovaskularna hirurgija'
      },
      {
        id: 4,
        text: 'hirurgija perifernih nerava'
      },
      {
        id: 5,
        text: 'hidrocefalus'
      },
      {
        id: 6,
        text: 'epilepsija'
      },
      {
        id: 7,
        text: 'trauma glave'
      },
      {
        id: 8,
        text: 'stereotaktička hirurgija'
      },
      {
        id: 9,
        text: 'neuroendoskopija'
      },
      {
        id: 10,
        text: 'degenerativne bolesti kičme'
      },
    ]
  },
  {
    id: 4,
    name: 'opšta i abdominalna hirurgija',
    content: [
      {
        id: 1,
        text: 'hirurgija tumora mozga'
      },
      {
        id: 2,
        text: 'hirurgija kičme'
      },
      {
        id: 3,
        text: 'cerebrovaskularna hirurgija'
      },
      {
        id: 4,
        text: 'hirurgija perifernih nerava'
      },
      {
        id: 5,
        text: 'hidrocefalus'
      },
      {
        id: 6,
        text: 'epilepsija'
      },
      {
        id: 7,
        text: 'trauma glave'
      },
      {
        id: 8,
        text: 'stereotaktička hirurgija'
      },
      {
        id: 9,
        text: 'neuroendoskopija'
      },
      {
        id: 10,
        text: 'degenerativne bolesti kičme'
      },
    ]
  },
  {
    id: 5,
    name: 'vaskularna hirurgija',
    content: [
      {
        id: 1,
        text: 'hirurgija tumora mozga'
      },
      {
        id: 2,
        text: 'hirurgija kičme'
      },
      {
        id: 3,
        text: 'cerebrovaskularna hirurgija'
      },
      {
        id: 4,
        text: 'hirurgija perifernih nerava'
      },
      {
        id: 5,
        text: 'hidrocefalus'
      },
      {
        id: 6,
        text: 'epilepsija'
      },
      {
        id: 7,
        text: 'trauma glave'
      },
      {
        id: 8,
        text: 'stereotaktička hirurgija'
      },
      {
        id: 9,
        text: 'neuroendoskopija'
      },
      {
        id: 10,
        text: 'degenerativne bolesti kičme'
      },
    ]
  },
  {
    id: 6,
    name: 'torakalna hirurgija',
    content: [
      {
        id: 1,
        text: 'hirurgija tumora mozga'
      },
      {
        id: 2,
        text: 'hirurgija kičme'
      },
      {
        id: 3,
        text: 'cerebrovaskularna hirurgija'
      },
      {
        id: 4,
        text: 'hirurgija perifernih nerava'
      },
      {
        id: 5,
        text: 'hidrocefalus'
      },
      {
        id: 6,
        text: 'epilepsija'
      },
      {
        id: 7,
        text: 'trauma glave'
      },
      {
        id: 8,
        text: 'stereotaktička hirurgija'
      },
      {
        id: 9,
        text: 'neuroendoskopija'
      },
      {
        id: 10,
        text: 'degenerativne bolesti kičme'
      },
    ]
  },
  {
    id: 7,
    name: 'urologija',
    content: [
      {
        id: 1,
        text: 'hirurgija tumora mozga'
      },
      {
        id: 2,
        text: 'hirurgija kičme'
      },
      {
        id: 3,
        text: 'cerebrovaskularna hirurgija'
      },
      {
        id: 4,
        text: 'hirurgija perifernih nerava'
      },
      {
        id: 5,
        text: 'hidrocefalus'
      },
      {
        id: 6,
        text: 'epilepsija'
      },
      {
        id: 7,
        text: 'trauma glave'
      },
      {
        id: 8,
        text: 'stereotaktička hirurgija'
      },
      {
        id: 9,
        text: 'neuroendoskopija'
      },
      {
        id: 10,
        text: 'degenerativne bolesti kičme'
      },
    ]
  },
  {
    id: 8,
    name: 'maksilofacijalna hirurgija',
    content: [
      {
        id: 1,
        text: 'hirurgija tumora mozga'
      },
      {
        id: 2,
        text: 'hirurgija kičme'
      },
      {
        id: 3,
        text: 'cerebrovaskularna hirurgija'
      },
      {
        id: 4,
        text: 'hirurgija perifernih nerava'
      },
      {
        id: 5,
        text: 'hidrocefalus'
      },
      {
        id: 6,
        text: 'epilepsija'
      },
      {
        id: 7,
        text: 'trauma glave'
      },
      {
        id: 8,
        text: 'stereotaktička hirurgija'
      },
      {
        id: 9,
        text: 'neuroendoskopija'
      },
      {
        id: 10,
        text: 'degenerativne bolesti kičme'
      },
    ]
  },
]

export default fieldCardsData;