import React from "react";
import './doctors.css';
import { Helmet } from "react-helmet-async";

import HomeHeader from "../../components/home/home-header/home-header.js";
import HomeNavigationDropdown from "../../components/home/home-navigation-dropdown/home-navigation-dropdown.js";
import doctorsData from "../../assets/doctors-data/doctors-data.js";
import DoctorsServiceCard from "../../components/doctors/doctors-service-card/doctors-service-card.js";
import HomeFooter from "../../components/home/home-footer/home-footer.js";
import ArrowUpIcon from "../../components/arrow-up-icon/arrow-up-icon.js";

import decorationImage1 from '../../assets/services/una-bolnica-services-decoration-image-1.webp';
import decorationImage2 from '../../assets/services/una-bolnica-services-decoration-image-2.webp';
import phoneButton from '../../assets/services/una-bolnica-services-phone-button.svg';
import defaultThumbnailImage from '../../assets/home/una-bolnica-default-thumbnail-image.webp';

export default function Doctors() {

  // state value that determine navigation dropdown visibility
  const [dropdown, setDropdown] = React.useState(false)

  // function changes dropdown state value
  function showDropdown() {
    setDropdown((prevState) => {
      return (
        !prevState
      )
    })
  }

  // reload page function
  function reloadDoctorsPage() {
    window.location.reload();
  }

  // doctorsDataState state contains every card inforamtion that is passed to ServicesServiceCard component
  const [doctorsDataState, setDoctorsDataState] = React.useState([
    {
      cardId: 1,
      isOn: false
    },
    {
      cardId: 2,
      isOn: false
    },
    {
      cardId: 3,
      isOn: false
    },
    {
      cardId: 4,
      isOn: false
    },
    {
      cardId: 5,
      isOn: false
    },
    {
      cardId: 6,
      isOn: false
    },
    {
      cardId: 7,
      isOn: false
    },
    {
      cardId: 8,
      isOn: false
    },
    {
      cardId: 9,
      isOn: false
    },
    {
      cardId: 10,
      isOn: false
    },
    {
      cardId: 11,
      isOn: false
    },
    {
      cardId: 12,
      isOn: false
    },
    {
      cardId: 13,
      isOn: false
    },
    {
      cardId: 14,
      isOn: false
    }
  ])

  // showCardContent function changing isOn value in card state for card that is cliced and returning isOn: false for every other card
  const showCardContent = (id) => {
    setDoctorsDataState((prevState) => {
      return prevState.map((fieldCard) => {
        if (fieldCard.cardId === id) {
          return {...fieldCard, isOn: !fieldCard.isOn};
        } else {
          return {...fieldCard, isOn: false};
        }
      })
    })
  } 

  // part of code that renders
  return (

    <div>

      <Helmet>
        <title>Una bolnica Beograd, zakažite pregled na +381652024055</title>
        <meta
          name="description"
          content=
            "Opšta bolnica Una, spinalna hirurgija, plastična hirugija, ortopedija, ORL i druge. Vojvode Skopljanca 19, Zakažite pregled na +381652024055">
        </meta>
        <link rel="canonical" href="https://unabolnica.com/doctors"/>
        <meta property="og:image" content={defaultThumbnailImage} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>

      {/* small screen doctors container */}
      <div className="ss-doctors-container">

        <HomeHeader 
          showDropdown={showDropdown} // passing showDropdown function to HomeHeader component
          dropdownValue={dropdown} // passing dropdown state value to HomeHeader component
        />

        {dropdown && 
          <HomeNavigationDropdown
            reloadDoctorsPage={reloadDoctorsPage} // passing realodDoctorsPage to HomeNavigationDropdown component
          /> 
        }

        {/* small screen doctors decoration image 1 container */}
        <div className="ss-doctors-decoration-image-1-container">
          <img 
            src={decorationImage1}
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt="una-bolnica-dekoracija-slika"/>
        </div>  

        {/* small screen doctors title 1 container */}
        <div className="ss-doctors-title-1-container">
          <h1>Doktori</h1>
        </div>

        {/* small screen doctors title 2 container */}
        <div className="ss-doctors-title-2-container">
          <h2>Izaberite oblast specijalizacije</h2>
        </div>

        {/* small screen doctors service cards container */}
        <div className="ss-doctors-service-cards-container">
          {doctorsDataState.map((fieldCard, index) => {
            if (fieldCard.cardId === 2) {
              return (
                <DoctorsServiceCard 
                  key={fieldCard.cardId}
                  doctorsData={doctorsData[index]}
                  doctorsState={fieldCard}
                  showCardContent={() => showCardContent(fieldCard.cardId)}/>
              )
            }
            return (
              <DoctorsServiceCard 
                key={fieldCard.cardId}
                doctorsData={doctorsData[index]}
                doctorsState={fieldCard}
                showCardContent={() => showCardContent(fieldCard.cardId)}/>
            )
          })}
        </div>

        {/* small screen doctors phone button container */}
        <div className="ss-doctors-phone-button-container">
          <a href="tel:+3816520240555">
            <img 
              src={phoneButton}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-bolnica-telefon"/>
          </a>
        </div>
      </div>










      {/* large screen doctors container */}
      <div className="ls-doctors-container">
        
        <HomeHeader />

        {/* large screen doctors decoration image 1 container */}
        <div className="ls-doctors-decoration-image-1-container">
          <img 
            src={decorationImage2}
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt="una-bolnica-dekoracija-slika"/>
        </div>  

        {/* large screen doctors title 1 container */}
        <div className="ls-doctors-title-1-container">
          <h1>Doktori</h1>
        </div>

        {/* large screen doctors title 2 */}
        <h2 className="ls-doctors-title-2">Izaberite oblast specijalizacije</h2>

        {/* large screen doctors service cards container */}
        <div className="ls-doctors-service-cards-container">
          {doctorsDataState.map((fieldCard, index) => {
            return (
              <DoctorsServiceCard 
                key={fieldCard.cardId}
                doctorsData={doctorsData[index]}
                doctorsState={fieldCard}
                showCardContent={() => showCardContent(fieldCard.cardId)}/>
            )
          })}
        </div>
       
        {/* large screen doctors phone button container */}
        <div className="ls-doctors-phone-button-container">
          <a href="tel:+3816520240555">
            <img 
              src={phoneButton}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-bolnica-telefon"/>
          </a>
        </div>
      </div>
    
      <HomeFooter />

      <ArrowUpIcon />

    </div>

  )
}