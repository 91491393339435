import React from "react";
import './home-navigation-dropdown-social-media-icon.css';

export default function HomeNavigationDropdownSocialMediaIcon(props) {

  // part of code that renders
  return (

    // small screen home navigation dropdown social media icon
    <a href={props.url} target="_blank">
      <div className="ss-home-navigation-dropdown-social-media-icon">
        <img 
          src={props.source}
          onContextMenu={(e) => {e.preventDefault(e)}}
          alt={props.alternative}
          style={{width: props.width + 'vw'}} // passing width from HomeFooter component
        />
      </div>
    </a>
  
  )
}