import React from "react";
import { Link } from "react-router-dom";
import './home-navigation-dropdown.css';

import HomeNavigationDropdownSocialMediaIcon from "../home-navigation-dropdown-social-media-icon/home-navigation-dropdown-social-media-icon.js";

import facebookIcon from "../../../assets/home/una-bolnica-home-dropdown-facebook-icon.svg";
import instagramIcon from "../../../assets/home/una-bolnica-home-dropdown-instagram-icon.svg";
import youtubeIcon from "../../../assets/home/una-bolnica-home-dropdown-youtube-icon.svg";
import unknownIcon from "../../../assets/home/una-bolnica-home-dropdown-unknown-icon.svg";

export default function HomeNavigationDropdown(props) {

  // part of code that renders
  return (

    // small screen home navigation dropdown
    <div className="ss-home-navigation-dropdown">

      {/* small screen home navigation dropdown list */}
      <ul>
        <Link 
          to='/'
          style={{textDecoration: 'none'}}>
            <li onClick={props.reloadHomePage}>NASLOVNA</li> {/* passing reloadHomePage function from Home component trough HomePart1 component */}
        </Link> 

        <Link 
          to='/services'
          style={{textDecoration: 'none'}}>
            <li onClick={props.reloadServicesPage}>USLUGE</li> {/* passing reloadServicePage function from Service component */}
        </Link>

        <Link 
          to='/doctors'
          style={{textDecoration: 'none'}}>
            <li onClick={props.reloadDoctorsPage}>DOKTORI</li>
        </Link>

        <Link 
          to='/contact'
          style={{textDecoration: 'none'}}>
            <li onClick={props.reloadContactPage}>KONTAKT</li>
        </Link>

        <Link 
          to='/blog'
          style={{textDecoration: 'none'}}>
            <li onClick={props.reloadBlogPage}>BLOG</li>
        </Link>
      </ul>

      {/* small screen home navigation dropdown social media container */}
      <div className="ss-home-navigation-dropdown-social-media-container">
        <HomeNavigationDropdownSocialMediaIcon 
          source={facebookIcon}
          url='https://www.facebook.com/unabolnica'
          alternative='una-bolnica-facebook'
        />
        <HomeNavigationDropdownSocialMediaIcon 
          source={instagramIcon}
          url='https://www.instagram.com/unabolnica/'
          alternative='una-bolnica-instagram'
        />
        <HomeNavigationDropdownSocialMediaIcon 
          source={youtubeIcon}
          url='https://www.youtube.com/@unabolnicacom'
          alternative='una-bolnica-youtube'
        />
        <HomeNavigationDropdownSocialMediaIcon 
          source={unknownIcon}
          url=''
          alternative='una-bolnica-nepoznato'
        />
      </div>

    </div>

  )
}