import markoBumbasirevic from '../doctors/una-bolnica-marko-bumbasirevic.webp';
import ivicaMedenica from '../doctors/una-bolnica-ivica-medenica.webp';
import slavkoTomic from '../doctors/una-bolnica-slavko-tomic.webp';
import markoIlic from '../doctors/una-bolnica-marko-ilic.webp';
import dejanRistic from '../doctors/una-bolnica-dejan-ristic.webp';
import sladjanTimotijevic from '../doctors/una-bolnica-sladjan-timotijevic.webp';
import aleksandarRadunovic from '../doctors/una-bolnica-aleksandar-radunovic.webp';
import aleksandarVojvodic from '../doctors/una-bolnica-aleksandar-vojvodic.webp';
import mladenMaksic from '../doctors/una-bolnica-mladen-maksic.webp';
import vukVucic from '../doctors/una-bolnica-vuk-vucic.webp';

import anaMalivukovic from '../doctors/una-bolnica-ana-malivukovic.webp';
import igorMaljkovic from '../doctors/una-bolnica-igor-maljkovic.webp';
import biljanaMitric from '../doctors/una-bolnica-biljana-mitric.webp';
import milosGacevic from '../doctors/una-bolnica-milos-gacevic.webp';
import milosVucetic from '../doctors/una-bolnica-milos-vucetic.webp';
import simonPandjaitan from '../doctors/una-bolnica-simon-pandjaitan.webp';
import urosRadivojcevic from '../doctors/una-bolnica-uros-radivojcevic.webp';
import zeljkoCurcic from '../doctors/una-bolnica-zeljko-curcic.webp';
import zeljokoStanojlovic from '../doctors/una-bolnica-zeljko-stanojlovic.webp';

import ljubodragMinic from '../doctors/una-bolnica-ljubodrag-minic.webp';
import goranPavlicevic from '../doctors/una-bolnica-goran-pavlicevic.webp';
import nenadNovakovic from '../doctors/una-bolnica-nenad-novakovic.webp';
import miljanMihajlovic from '../doctors/una-bolnica-miljan-mihajlovic.webp';
import andrijaSavic from '../doctors/una-bolnica-andrija-savic.webp';
import radomirBenovic from '../doctors/una-bolnica-radomir-benovic.webp';

import nenadArsovic from '../doctors/una-bolnica-nenad-arsovic.webp';
import dusanBijelic from '../doctors/una-bolnica-dusan-bijelic.webp';
import ljubomirPavicevic from '../doctors/una-bolnica-ljubomir-pavicevic.webp';
import aleksandarDimic from '../doctors/una-bolnica-aleksandar-dimic.webp';
import ivanDjordjevic from '../doctors/una-bolnica-ivan-djordjevic.webp';
import ljiljanaCvorovic from '../doctors/una-bolnica-ljiljana-cvorovic.webp';
import milanErdoglija from '../doctors/una-bolnica-milan-erdoglija.webp';
import miloradAdzic from '../doctors/una-bolnica-milorad-adzic.webp';
import bojanPavlovic from '../doctors/una-bolnica-bojan-pavlovic.webp';


import boskoMilev from '../doctors/una-bolnica-bosko-milev.webp';
import miroslavIlic from '../doctors/una-bolnica-miroslav-ilic.webp';
import miroslavMitrovic from '../doctors/una-bolnica-miroslav-mitrovic.webp';
import zagorZagorac from '../doctors/una-bolnica-zagor-zagorac.webp';
import nebojsaMaric from '../doctors/una-bolnica-nebojsa-maric.webp';
import predragKovacevic from '../doctors/una-bolnica-predrag-kovacevic.webp';
import mladenMilosevic from '../doctors/una-bolnica-mladen-milosevic.webp';




import ivanLekovic from '../doctors/una-bolnica-ivan-lekovic.webp';
import bozinaRadevic from '../doctors/una-bolnica-bozina-radevic.webp';

import zivoradNikolic from '../doctors/una-bolnica-zivorad-nikolic.webp';
import srboljubStosic from '../doctors/una-bolnica-srboljub-stosic.webp';

import borkoJosifovski from '../doctors/una-bolnica-borko-josifovski.webp';

import goranPlavec from '../doctors/una-bolnica-goran-plavec.webp';

import nebojsaManojlovic from '../doctors/una-bolnica-nebojsa-manojlovic.webp';

import gordanMalivukovic from '../doctors/una-bolnica-gordan-malivukovic.webp';
import duskoJovanovic from '../doctors/una-bolnica-dusko-jovanovic.webp';

import slavicaPavlovic from '../doctors/una-bolnica-slavica-pavlovic.webp';
import predragDjurovic from '../doctors/una-bolnica-predrag-djurovic.webp';


import jasenkaGrebenarevic from '../doctors/una-bolnica-jasenka-grebenarevic.webp';
import anaGolubovic from '../doctors/una-bolnica-ana-golubovic.webp';
import borivojeLukic from '../doctors/una-bolnica-borivoje-lukic.webp';

import vojkanLekic from '../doctors/una-bolnica-vojkan-lekic.webp';
import brankaMarkovic from '../doctors/una-bolnica-branka-markovic.webp';
import draganCumic from '../doctors/una-bolnica-dragan-cumic.webp';


const doctorsData = [
  {
    fieldId: 1,
    fieldName: 'Ortopedija',
    employeList: [
      {
        employeId: 1,
        employeLink: '/marko-bumbasirevic',
        employeName: <>Prof. dr Marko<br/>Bumbaširević</>,
        employeDescription: 'ortoped',
        employeImage: markoBumbasirevic,
        employeAlternative: 'una-bolnica-marko-bumbaširević'
      },
      {
        employeId: 2,
        employeLink: '/ivica-medenica',
        employeName: <>dr Ivica<br/>Medenica</>,
        employeDescription: 'ortoped',
        employeImage: ivicaMedenica,
        employeAlternative: 'una-bolnica-ivica-medenica'
      },
      {
        employeId: 3,
        employeLink: '/slavko-tomic',
        employeName: <>Prof. dr Slavko<br/>Tomić</>,
        employeDescription: 'ortoped',
        employeImage: slavkoTomic,
        employeAlternative: 'una-bolnica-slavko-tomić'
      },
      {
        employeId: 4,
        employeLink: '/marko-ilic',
        employeName: <>dr Marko<br/>Ilić</>,
        employeDescription: 'ortoped',
        employeImage: markoIlic,
        employeAlternative: 'una-bolnica-marko-ilić'
      },
      {
        employeId: 5,
        employeLink: '/dejan-ristic',
        employeName: <>dr Dejan<br/>Ristić</>,
        employeDescription: 'ortoped',
        employeImage: dejanRistic,
        employeAlternative: 'una-bolnica-dejan-ristić'
      },
      {
        employeId: 6,
        employeLink: '/sladjan-timotijevic',
        employeName: <>Prim. dr Slađan<br/>Timotijević</>,
        employeDescription: 'specijalista ortopedije',
        employeImage: sladjanTimotijevic,
        employeAlternative: 'una-bolnica-slađan-timotijević'
      },
      {
        employeId: 8,
        employeLink: '/aleksandar-radunovic',
        employeName: <>dr Aleksandar<br/>Radunović</>,
        employeDescription: 'ortoped',
        employeImage: aleksandarRadunovic,
        employeAlternative: 'una-bolnica-aleksandar-radunović'
      },
      {
        employeId: 9,
        employeLink: '/aleksandar-vojvodic',
        employeName: <>dr Aleksandar<br/>Vojvodić</>,
        employeDescription: 'ortoped',
        employeImage: aleksandarVojvodic,
        employeAlternative: 'una-bolnica-alksandar-vojvodić'
      },
      {
        employeId: 10,
        employeLink: '/mladen-maksic',
        employeName: <>dr Mladen<br/>Maksić</>,
        employeDescription: 'ortoped',
        employeImage: mladenMaksic,
        employeAlternative: 'una-bolnica-mladen-maksić'
      },
      {
        employeId: 11,
        employeLink: '/vuk-vucic',
        employeName: <>dr Vuk<br/>Vučić</>,
        employeDescription: 'ortoped',
        employeImage: vukVucic,
        employeAlternative: 'una-bolnica-vuk-vučić'
      }
    ]
  },
  {
    fieldId: 2,
    fieldName: 'Estetska hirurgija',
    employeList: [
      {
        employeId: 1,
        employeLink: '/ana-malivukovic',
        employeName: <>dr Ana<br/>Malivuković</>,
        employeDescription: 'Specijalista plastične, rekonstruktivne i estetske hirurgije',
        employeImage: anaMalivukovic,
        employeAlternative: 'una-bolnica-ana-malivuković'
      },
      {
        employeId: 2,
        employeLink: '/igor-maljkovic',
        employeName: <>dr Igor<br/>Maljković</>,
        employeDescription: 'Specijalista plastične hirurgije',
        employeImage: igorMaljkovic,
        employeAlternative: 'una-bolnica-igor-maljković'
      },
      {
        employeId: 3,
        employeLink: '/biljana-mitric',
        employeName: <>dr Biljana<br/>Mitrić</>,
        employeDescription: 'Plastični i rekonstruktivni hirurg',
        employeImage: biljanaMitric,
        employeAlternative: 'una-bolnica-biljana-mitrić'
      },
      {
        employeId: 4,
        employeLink: '/milos-gacevic',
        employeName: <>dr Miloš<br/>Gačević</>,
        employeDescription: 'Plastični i rekonstruktivni hirurg',
        employeImage: milosGacevic,
        employeAlternative: 'una-bolnica-miloš-gačević'
      },
      {
        employeId: 5,
        employeLink: '/milos-vucetic',
        employeName: <>dr Miloš<br/>Vučetić</>,
        employeDescription: 'Plastični i rekonstruktivni hirurg',
        employeImage: milosVucetic,
        employeAlternative: 'una-bolnica-miloš-vučetić'
      },
      {
        employeId: 6,
        employeLink: '/simon-pandjaitan',
        employeName: <>dr Simon<br/>Pandjaitan</>,
        employeDescription: 'Plastični i rekonstruktivni hirurg',
        employeImage: simonPandjaitan,
        employeAlternative: 'una-bolnica-simon-pandjaitan'
      },
      {
        employeId: 7,
        employeLink: '/uros-radivojcevic',
        employeName: <>dr Uroš<br/>Radivojčević</>,
        employeDescription: 'Plastični i rekonstruktivni hirurg',
        employeImage: urosRadivojcevic,
        employeAlternative: 'una-bolnica-uroš-radivojčević'
      },
      {
        employeId: 8,
        employeLink: '/zeljko-curcic',
        employeName: <>dr Željko<br/>Ćurčić</>,
        employeDescription: 'Plastični i rekonstruktivni hirurg',
        employeImage: zeljkoCurcic,
        employeAlternative: 'una-bolnica-željko-ćurčić'
      },
      {
        employeId: 9,
        employeLink: '/zeljko-stanojlovic',
        employeName: <>dr Željko<br/>Stanojlović</>,
        employeDescription: 'Plastični i rekonstruktivni hirurg',
        employeImage: zeljokoStanojlovic,
        employeAlternative: 'una-bolnica-željko-stanojlovic'
      }
    ]
  },
  {
    fieldId: 3,
    fieldName: 'Neurohirurgija',
    employeList: [
      {
        employeId: 1,
        employeLink: '/ljubodrag-minic',
        employeName: <>Prof. dr Ljubodrag<br/>Minić</>,
        employeDescription: 'Specijalista neurohirurgije',
        employeImage: ljubodragMinic,
        employeAlternative: 'una-bolnica-ljubodrag-minić'
      },
      {
        employeId: 2,
        employeLink: '/goran-pavlicevic',
        employeName: <>dr Goran<br/>Pavlićević</>,
        employeDescription: 'Specijalista neurohirurgije',
        employeImage: goranPavlicevic,
        employeAlternative: 'una-bolnica-goran-pavlicevic'
      },
      {
        employeId: 3,
        employeLink: '/nenad-novakovic',
        employeName: <>dr Nenad<br/>Novaković</>,
        employeDescription: 'Specijalista neurohirurgije',
        employeImage: nenadNovakovic,
        employeAlternative: 'una-bolnica-nenad-novakovic'
      },
      {
        employeId: 4,
        employeLink: '/miljan-mihajlovic',
        employeName: <>Prim. dr sc. med.<br/>Miljan Mihajlović</>,
        employeDescription: 'Specijalista neurohirurgije',
        employeImage: miljanMihajlovic,
        employeAlternative: 'una-bolnica-miljan-mihajlović'
      },
      {
        employeId: 5,
        employeLink: '/andrija-savic',
        employeName: <>dr Andrija<br/>Savić</>,
        employeDescription: 'Specijalista neurohirurgije',
        employeImage: andrijaSavic,
        employeAlternative: 'una-bolnica-andrija-savić'
      },
      {
        employeId: 6,
        employeLink: '/radomir-benovic',
        employeName: <>dr Radomir<br/>Benović</>,
        employeDescription: 'neurohirurg',
        employeImage: radomirBenovic,
        employeAlternative: 'una-bolnica-radomir-benović'
      }
    ]
  },
  {
    fieldId: 4,
    fieldName: 'Otorinolaringologija',
    employeList: [
      {
        employeId: 1,
        employeLink: '/nenad-arsovic',
        employeName: <>Prof. dr Nenad<br/>Arsović</>,
        employeDescription: 'Specijalista otorinolaringologije',
        employeImage: nenadArsovic,
        employeAlternative: 'una-bolnica-nenad-arsović'
      },
      {
        employeId: 2,
        employeLink: '/dusan-bijelic',
        employeName: <>dr Dušan<br/>Bijelić</>,
        employeDescription: 'Specijalista otorinolaringologije',
        employeImage: dusanBijelic,
        employeAlternative: 'una-bolnica-dusan-bijelić'
      },
      {
        employeId: 3,
        employeLink: '/ljubomir-pavicevic',
        employeName: <>dr Ljubomir<br/>Pavićević</>,
        employeDescription: 'Specijalista otorinolaringologije',
        employeImage: ljubomirPavicevic,
        employeAlternative: 'una-bolnica-ljubomir-pavićević'
      },
      {
        employeId: 4,
        employeLink: '/aleksandar-dimic',
        employeName: <>dr Aleksandar<br/>Dimić</>,
        employeDescription: 'Specijalista otorinolaringologije',
        employeImage: aleksandarDimic,
        employeAlternative: 'una-bolnica-aleksandar-dimić'
      },
      {
        employeId: 5,
        employeLink: '/ivan-djordjevic',
        employeName: <>dr Ivan<br/>Đorđević</>,
        employeDescription: 'Specijalista otorinolaringologije',
        employeImage: ivanDjordjevic,
        employeAlternative: 'una-bolnica-ivan-đorđević'
      },
      {
        employeId: 6,
        employeLink: '/ljiljana-cvorovic',
        employeName: <>dr Ljiljana<br/>Čvorović</>,
        employeDescription: 'Specijalista otorinolaringologije',
        employeImage: ljiljanaCvorovic,
        employeAlternative: 'una-bolnica-ljiljana-čvorović'
      },
      {
        employeId: 7,
        employeLink: '/milan-erdoglija',
        employeName: <>dr Milan<br/>Erdoglija</>,
        employeDescription: 'Specijalista otorinolaringologije',
        employeImage: milanErdoglija,
        employeAlternative: 'una-bolnica-milan-erdoglija'
      },
      {
        employeId: 8,
        employeLink: '/milorad-adzic',
        employeName: <>dr Milorad<br/>Adžić</>,
        employeDescription: 'Specijalista otorinolaringologije',
        employeImage: miloradAdzic,
        employeAlternative: 'una-bolnica-milorad-adžić'
      },
      {
        employeId: 8,
        employeLink: '/bojan-pavlovic',
        employeName: <>dr Bojan<br/>Pavlović</>,
        employeDescription: 'Specijalista otorinolaringologije',
        employeImage: bojanPavlovic,
        employeAlternative: 'una-bolnica-bojan-pavlović'
      }
    ]
  },
  {
    fieldId: 5,
    fieldName: 'Opšta hirurgija',
    employeList: [
      {
        employeId: 1,
        employeLink: '/bosko-milev',
        employeName: <>dr Boško<br/>Milev</>,
        employeDescription: 'Specijalista opšte hirurgije',
        employeImage: boskoMilev,
        employeAlternative: 'una-bolnica-boško-milev'
      },
      {
        employeId: 2,
        employeLink: '/miroslav-ilic',
        employeName: <>dr Miroslav<br/>Ilić</>,
        employeDescription: 'Specijalista opšte hirurgije',
        employeImage: miroslavIlic,
        employeAlternative: 'una-bolnica-miroslav-ilić'
      },
      {
        employeId: 3,
        employeLink: '/miroslav-mitrovic',
        employeName: <>dr Miroslav<br/>Mitrović</>,
        employeDescription: 'Specijalista opšte hirurgije',
        employeImage: miroslavMitrovic,
        employeAlternative: 'una-bolnica-miroslav-mitrović'
      },
      {
        employeId: 4,
        employeLink: '/mladen-milosevic',
        employeName: <>dr Mladen<br/>Milošević</>,
        employeDescription: 'Specijalista opšte hirurgije',
        employeImage: mladenMilosevic,
        employeAlternative: 'una-bolnica-mladen-milošević'
      },
      {
        employeId: 5,
        employeLink: '/zagor-zagorac',
        employeName: <>dr Zagor<br/>Zagorac</>,
        employeDescription: 'opšti hirurg',
        employeImage: zagorZagorac,
        employeAlternative: 'una-bolnica-zagor-zagorac'
      },
      {
        employeId: 6,
        employeLink: '/predrag-kovacevic',
        employeName: <>Prof. dr Predrag<br/>Kovačević</>,
        employeDescription: 'opšti hirurg',
        employeImage: predragKovacevic,
        employeAlternative: 'una-bolnica-predrag-kovačević'
      },
      {
        employeId: 7,
        employeLink: '/nebojsa-maric',
        employeName: <>dr Nebojša<br/>Marić</>,
        employeDescription: 'opšti hirurg',
        employeImage: nebojsaMaric,
        employeAlternative: 'una-bolnica-nebojsa-maric'
      }
    ]
  },
  {
    fieldId: 6,
    fieldName: 'Vaskularna hirurgija',
    employeList: [
      {
        employeId: 1,
        employeLink: '/ivan-lekovic',
        employeName: <>dr Ivan<br/>Leković</>,
        employeDescription: 'Specijalista vaskularne i opšte hirurgije',
        employeImage: ivanLekovic,
        employeAlternative: 'una-bolnica-ivan-leković'
      },
      {
        employeId: 2,
        employeLink: '/bozina-radevic',
        employeName: <>Prof. dr Božina<br/>Radević</>,
        employeDescription: 'Specijalista vaskularne hirurgije',
        employeImage: bozinaRadevic,
        employeAlternative: 'una-bolnica-božina-radević'
      }
    ]
  },
  {
    fieldId: 7,
    fieldName: 'Maksilofacijalna hirurgija',
    employeList: [
      {
        employeId: 1,
        employeLink: '/zivorad-nikolic',
        employeName: <>dr Živorad<br/>Nikolić</>,
        employeDescription: 'Specijalista maksilofacijalne hirurgije',
        employeImage: zivoradNikolic,
        employeAlternative: 'una-bolnica-zivorad-nikolic'
      },
      {
        employeId: 2,
        employeLink: '/srboljub-stosic',
        employeName: <>Prof. dr Srboljub<br/>Stošić</>,
        employeDescription: 'Specijalista maksilofacijalne hirurgije',
        employeImage: srboljubStosic,
        employeAlternative: 'una-bolnica-srboljub-stošić'
      }
    ]
  },
  {
    fieldId: 8,
    fieldName: 'Kardiologija',
    employeList: [
      {
        employeId: 1,
        employeLink: '/borko-josifovski',
        employeName: <>dr Borko<br/>Josifovski</>,
        employeDescription: 'Specijalista kardiolog',
        employeImage: borkoJosifovski,
        employeAlternative: 'una-bolnica-borko-josifovski'
      },
    ]
  },
  {
    fieldId: 9,
    fieldName: 'Pulmologija',
    employeList: [
      {
        employeId: 1,
        employeLink: '/goran-plavec',
        employeName: <>Prof. dr Goran<br/>Plavec</>,
        employeDescription: 'Specijalista pulmolog',
        employeImage: goranPlavec,
        employeAlternative: 'una-bolnica-goran-plavec'
      },
    ]
  },
  {
    fieldId: 10,
    fieldName: 'Gastroenterologija',
    employeList: [

      {
        employeId: 2,
        employeLink: '/nebojsa-manojlovic',
        employeName: <>dr Nebojša<br/>Manojlović</>,
        employeDescription: '',
        employeImage: nebojsaManojlovic,
        employeAlternative: 'una-bolnica-nebojsa-manojlovic'
      },
    ]
  },
  {
    fieldId: 11,
    fieldName: 'Anesteziologija',
    employeList: [
      {
        employeId: 1,
        employeLink: '/gordan-malivukovic',
        employeName: <>Mr sc. med. dr<br/>Gordan Malivuković</>,
        employeDescription: 'Specijalista anesteziologije sa reanimatologijom',
        employeImage: gordanMalivukovic,
        employeAlternative: 'una-bolnica-gordan-malivuković'
      },
      {
        employeId: 2,
        employeLink: '/dusko-jovanovic',
        employeName: <>dr Duško<br/>Jovanović</>,
        employeDescription: 'Specijalista anesteziologije',
        employeImage: duskoJovanovic,
        employeAlternative: 'una-bolnica-duško-jovanović'
      }
    ]
  },
  {
    fieldId: 12,
    fieldName: 'Ginekologija',
    employeList: [
      {
        employeId: 1,
        employeLink: '/predrag-djurovic',
        employeName: <>dr Predrag<br/>Đurović</>,
        employeDescription: 'Specijalista ginekologije',
        employeImage: predragDjurovic,
        employeAlternative: 'una-bolnica-predrag-đurović'
      },
      {
        employeId: 2,
        employeLink: '/slavica-pavlovic',
        employeName: <>dr Slavica<br/>Pavlović</>,
        employeDescription: 'Specijalista ginekologije',
        employeImage: slavicaPavlovic,
        employeAlternative: 'una-bolnica-slavica-pavlović'
      },
    ]
  },
  {
    fieldId: 13,
    fieldName: 'Radiologija',
    employeList: [
      {
        employeId: 1,
        employeLink: '/jasenka-grebenarevic',
        employeName: <>dr Jasenka<br/>Grebenarević</>,
        employeDescription: 'Specijalista radiologije',
        employeImage: jasenkaGrebenarevic,
        employeAlternative: 'una-bolnica-jasenka-grebenarević'
      },
      {
        employeId: 2,
        employeLink: '/ana-golubovic',
        employeName: <>dr Ana<br/>Golubović</>,
        employeDescription: '',
        employeImage: anaGolubovic,
        employeAlternative: 'una-bolnica-ana-golubović'
      },
      {
        employeId: 3,
        employeLink: '/borivoje-lukic',
        employeName: <>dr Borivoje<br/>Lukić</>,
        employeDescription: 'radiolog',
        employeImage: borivojeLukic,
        employeAlternative: 'una-bolnica-borivoje-lukic'
      }
    ]
  },
  {
    fieldId: 14,
    fieldName: 'Ostale specijalizacije',
    employeList: [
      {
        employeId: 1,
        employeLink: '/vojkan-lekic',
        employeName: <>Prim. dr Vojkan<br/>Lekić</>,
        employeDescription: 'Specijalista pedijatrije',
        employeImage: vojkanLekic,
        employeAlternative: 'una-bolnica-vojkan-lekić'
      },
      {
        employeId: 2,
        employeLink: '/branka-markovic',
        employeName: <>Prof. dr Branka<br/>Marković</>,
        employeDescription: 'Specijalista fizikalne medicine i rehabilitacije',
        employeImage: brankaMarkovic,
        employeAlternative: 'una-bolnica-branka-marković'
      },
      {
        employeId: 3,
        employeLink: '/dragan-cumic',
        employeName: <>dr Dragan<br/>Čumić</>,
        employeDescription: 'Specijalista urologije',
        employeImage: draganCumic,
        employeAlternative: 'una-bolnica-dragan-čumić'
      }
    ]
  }
]

export default doctorsData;