import React from "react";
import './doctors-doctor-card.css';
import { Link } from "react-router-dom";

export default function DoctorsDoctorCard(props) {

  // part of code that renders
  return (

      <div>

        {/* small screen doctors doctor card container */} 
        <div className="ss-doctors-doctor-card-container">

          {/* small screen doctors doctor card image container */}
          <Link to={props.link} style={{textDecoration: 'none'}}>
            <div className="ss-doctors-doctor-card-image-container">
              <img
                src={props.source}
                onContextMenu={(e) => {e.preventDefault(e)}}
                alt={props.alternative}/>
            </div>
          </Link>

          {/* small screen doctors doctor text & button container */}
          <div className="ss-doctors-doctor-card-text-button-container">

            {/* small screen doctors doctor text 1 */}
            <Link to={props.link} style={{textDecoration: 'none'}}>
                <p className="ss-doctors-doctor-card-text-1">{props.name}</p>
            </Link>

            {/* small screen doctors doctor text 2 */}
            <Link to={props.link} style={{textDecoration: 'none'}}>
              <p className="ss-doctors-doctor-card-text-2">{props.description}</p>
            </Link>

            {/* small screen doctors doctor button */}
            <a href="tel:003816520240555">
              <div className="ss-doctors-doctor-card-button">
                <p>ZAKAŽITE PREGLED</p>
              </div>
            </a>

          </div>
        </div>










        {/* large screen doctors doctor card container */} 
        <div className="ls-doctors-doctor-card-container">

          {/* large screen doctors doctor card image container */}
          <Link to={props.link} style={{textDecoration: 'none'}}>
            <div className="ls-doctors-doctor-card-image-container">
              <img
                src={props.source}
                onContextMenu={(e) => {e.preventDefault(e)}}
                alt={props.alternative}/>
            </div>
          </Link>

          {/* large screen doctors doctor text & button container */}
          <div className="ls-doctors-doctor-card-text-button-container">

            {/* large screen doctors doctor text 1 */}
            <Link to={props.link} style={{textDecoration: 'none'}}>
                <p className="ls-doctors-doctor-card-text-1">{props.name}</p>
            </Link>  

            {/* large screen doctors doctor text 2 */}
            <Link to={props.link} style={{textDecoration: 'none'}}>
              <p className="ls-doctors-doctor-card-text-2">{props.description}</p>
            </Link>

            {/* large screen doctors doctor button */}
            <a href="tel:003816520240555">
              <div className="ls-doctors-doctor-card-button">
                <p>ZAKAŽITE PREGLED</p>
              </div>
            </a>

          </div>

        </div>

      </div>

  )
}