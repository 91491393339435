import React from "react";
import './home-footer.css';
import { Link } from "react-router-dom";

import HomeNavigationDropdownSocialMediaIcon from "../home-navigation-dropdown-social-media-icon/home-navigation-dropdown-social-media-icon.js";

import footerDecoration1 from '../../../assets/home/una-bolnica-home-footer-decoration-1.svg';
import footerDecoration2 from '../../../assets/home/una-bolnica-home-footer-decoration-2.svg';
import unaBolnicaLogo from '../../../assets/home/una-bolnica-logo-1.svg';
import facebookIcon from '../../../assets/home/una-bolnica-home-footer-facebook-icon.svg';
import instagramIcon from '../../../assets/home/una-bolnica-home-footer-instagram-icon.svg';
import youtubeIcon from '../../../assets/home/una-bolnica-home-footer-youtube-icon.svg';
import unknownIcon from '../../../assets/home/una-bolnica-home-footer-unknown-icon.svg';

export default function HomeFooter(props) {

  // part of code that renders
  return (

    <div>

      {/* small screen home footer container */}
      <div className="ss-home-footer-container">

        <div className="ss-home-footer-container-container">
        
          {/* small screen home footer decoration 1 container */}
          <div className="ss-home-footer-decoration-1-container">
            <img 
              src={footerDecoration1}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-bolnica-dekoracija"/>
          </div>

          {/* small screen home footer logo container */}
          <div className="ss-home-footer-logo-container">
            <img 
              src={unaBolnicaLogo}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-bolnica-dekoracija"
              onClick={props.reloadHomePage}/> {/* passing realodHomePage function from Home page */}
          </div>

          {/* small screen home footer social media container */}
          <div className="ss-home-footer-social-media-container">

            <p>pronađite nas na mrežama</p>

            <div className="ss-home-footer-social-media-icon-container">
              <HomeNavigationDropdownSocialMediaIcon 
                source={facebookIcon}
                width={8.888}
                url='https://www.facebook.com/unabolnica'
                alternative='una-bolnica-facebook' 
              />
              <HomeNavigationDropdownSocialMediaIcon 
                source={instagramIcon}
                width={8.888}
                url='https://www.instagram.com/unabolnica/'
                alternative='una-bolnica-instagram' 
              />
              <HomeNavigationDropdownSocialMediaIcon 
                source={youtubeIcon}
                width={8.888}
                url='https://www.youtube.com/@unabolnicacom'
                alternative='una-bolnica-youtube' 
              />
              <HomeNavigationDropdownSocialMediaIcon 
                source={unknownIcon}
                width={8.888}
                url=''
                alternative='una-bolnica-unknown' 
              />
            </div>

          </div>

          {/* small screen home footer text container */}
          <div className="ss-home-footer-text-container">
            <p>
              <Link 
                to='/'
                style={{textDecoration: 'none'}} 
                onClick={props.reloadHomePage}> {/* passing realodHomePage function from Home page */}
                NASLOVNA<br />
              </Link>
              
              <Link to='/services' style={{textDecoration: 'none'}}>
                USLUGE<br />
              </Link>

              <Link to='/doctors' style={{textDecoration: 'none'}}>
                DOKTORI<br />
              </Link>

              <Link to='/contact' style={{textDecoration: 'none'}}>
                KONTAKT<br />
              </Link>
            </p>

            <p>
              <a 
                href="https://www.google.com/maps/place/Una+bolnica/@44.7830382,20.4772767,17z/data=!3m1!4b1!4m6!3m5!1s0x475a70673a075c59:0x814445fbdeae7a0f!8m2!3d44.7830382!4d20.4798516!16s%2Fg%2F11c1q8s5hk?entry=ttu" 
                target="_blank">
                  VOJVODE SKOPLJANCA 19, BEOGRAD<br />
              </a>

              <a href="tel:+381117858777">
                +381 11 78 58 777<br />
              </a>

              <a href="tel:+381652024055">
                +381 65 20 24 05<br />
              </a>

              /Pon-Ned/ 00-24H
            </p>
          </div>

          {/* small screen home footer copyright container */}
          <div className="ss-home-footer-copyright-container">

            <p>
              Copyright © 2023 Una bolnica - Sva prava zadržana<br />
              <a href="https://oratorri.com">
                Oratorri Comunicazioni
              </a>
            </p> 

          </div>

        </div>

      </div>
      









      {/* large sceen home footer container */}
      <div 
        className="ls-home-footer-container"
        style={{marginTop: props.marginTop + 'vw'}}>

        {/* large screen home footer decoration 1 container */}
        <div className="ls-home-footer-decoration-1-container">
          <img 
            src={footerDecoration2}
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt="una-bolnica-dekoracija"/>
        </div>

        {/* large screen home footer container container */}
        <div className="ls-home-footer-container-contaier">
          
          {/* large screen home footer logo container */}
          <div className="ls-home-footer-logo-contaier">
            <img 
                src={unaBolnicaLogo}
                onContextMenu={(e) => {e.preventDefault(e)}}
                alt="una-bolnica-dekoracija"
                onClick={props.reloadHomePage}/> {/* passing realodHomePage function from Home page */}
          </div>

          
          {/* large screen home footer text 1 container */}
          <div className="ls-home-footer-text-1-contaier">
            <p>
                <Link 
                  to='/'
                  style={{textDecoration: 'none'}} 
                  onClick={props.reloadHomePage}> {/* passing realodHomePage function from Home page */}
                    NASLOVNA<br />
                </Link>
                
                <Link to='/services' style={{textDecoration: 'none'}}>
                  USLUGE<br />
                </Link>

                <Link to='/doctors' style={{textDecoration: 'none'}}>
                  DOKTORI<br />
                </Link>

                <Link to='/contact' style={{textDecoration: 'none'}}>
                  KONTAKT
                </Link>
              </p>
          </div>

          {/* large screen home footer text 2 container */}
          <div className="ls-home-footer-text-2-contaier">
            <p>
              <a 
                href="https://www.google.com/maps/place/Una+bolnica/@44.7830382,20.4772767,17z/data=!3m1!4b1!4m6!3m5!1s0x475a70673a075c59:0x814445fbdeae7a0f!8m2!3d44.7830382!4d20.4798516!16s%2Fg%2F11c1q8s5hk?entry=ttu" 
                target="_blank">
                  VOJVODE SKOPLJANCA 19, BEOGRAD<br />
              </a>

              <a href="tel:+381117858777">
                +381 11 78 58 777<br />
              </a>

              <a href="tel:+381652024055">
                +381 65 20 24 05<br />
              </a>

              /Pon-Ned/ 00-24H
            </p>
          </div>

          {/* large screen home footer social media container */}
          <div className="ls-home-footer-social-media-contaier">

            <p>pronađite nas na mrežama</p>
            
            <div className="ls-home-footer-social-media-icon-container">
              <HomeNavigationDropdownSocialMediaIcon 
                source={facebookIcon}
                width={2.5}
                url='https://www.facebook.com/unabolnica'
                alternative='una-bolnica-facebook' 
              />
              <HomeNavigationDropdownSocialMediaIcon 
                source={instagramIcon}
                width={2.5}
                url='https://www.instagram.com/unabolnica/'
                alternative='una-bolnica-instagram' 
              />
              <HomeNavigationDropdownSocialMediaIcon 
                source={youtubeIcon}
                width={2.5}
                url='https://www.youtube.com/@unabolnicacom'
                alternative='una-bolnica-youtube' 
              />
              <HomeNavigationDropdownSocialMediaIcon 
                source={unknownIcon}
                width={2.5}
                url=''
                alternative='una-bolnica-unknown' 
              />
            </div>

          </div>

        </div>

        {/* large screen home footer copyright container */}
        <div className="ls-home-footer-copyright-container">
          <p>
            <a href="https://oratorri.com">
              Oratorri Comunicazioni
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            Copyright © 2023 Una bolnica - Sva prava zadržana
          </p> 
        </div>

      </div>

    </div>

  )
}