import React from "react";
import './home.css';
import { Helmet } from "react-helmet-async";

import HomePart1 from "../../components/home/home-part-1/home-part-1.js";
import HomePart2 from "../../components/home/home-part-2/home-part-2.js";
import HomePart3 from "../../components/home/home-part-3/home-part-3.js";
import HomePart4 from "../../components/home/home-part-4/home-part-4.js";
import HomePart5 from "../../components/home/home-part-5/home-part-5.js";
import HomePart6 from "../../components/home/home-part-6/home-part-6.js";
import HomeForm from "../../components/home/home-form/home-form.js";
import HomeFooter from "../../components/home/home-footer/home-footer.js";
import ArrowUpIcon from "../../components/arrow-up-icon/arrow-up-icon.js";

import defaultThumbnailImage from '../../assets/home/una-bolnica-default-thumbnail-image.webp';

export default function Home() {

  // reload page function
  function reloadHomePage() {
    window.location.reload();
    window.scrollTo(0, 0);
  }

  /* part of code that renders */
  return (
    <>

      <Helmet>
        <title>Una bolnica Beograd, zakažite pregled na +381652024055</title>
        <meta
          name="description"
          content=
            "Opšta bolnica Una, spinalna hirurgija, plastična hirugija, ortopedija, ORL i druge. Vojvode Skopljanca 19, Zakažite pregled na +381652024055">
        </meta>
        <link rel="canonical" href="https://unabolnica.com"/>
        <meta property="og:image" content={defaultThumbnailImage} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>

      <div>
        
        <HomePart1 
          reloadHomePage={reloadHomePage} /> {/* passing reloadHomePage function to HomeHeader component trough HomePart1 component  */}

        <HomePart2 />

        <HomePart3 /> 
        
        <HomePart4 
          reloadHomePage={reloadHomePage}/> {/* passing reloadHomePage function to HomePart4 component */}
        
        <HomePart5 />
        
        <HomePart6 />

        <div className="ss-home-part-7-container">
          <HomeForm 
            reloadHomePage={reloadHomePage}/> {/* passing reloadHomePage function to HomeForm component */}
        </div>

        <div className="ls-home-part-7-container">
          <HomeForm 
            reloadHomePage={reloadHomePage}/> {/* passing reloadHomePage function to HomeForm component */}
        </div>

        <HomeFooter 
          reloadHomePage={reloadHomePage} // passing reloadHomePage function to HomeFooter component
        />

        <ArrowUpIcon />
      
      </div>

    </>

  )
}