import React from "react";
import './doctor-profile.css';
import { Helmet } from "react-helmet-async";

import HomeHeader from "../home/home-header/home-header.js";
import HomeNavigationDropdown from "../home/home-navigation-dropdown/home-navigation-dropdown.js";
import DoctorProfileSlideshow from "../doctor-profile-slideshow/doctor-profile-slideshow.js";
import HomeFooter from "../home/home-footer/home-footer.js";
import ArrowUpIcon from "../arrow-up-icon/arrow-up-icon.js";

import decorationImage1 from '../../assets/services/una-bolnica-services-decoration-image-1.webp';
import decorationImage2 from '../../assets/services/una-bolnica-services-decoration-image-2.webp';
import decorationImage3 from '../../assets/home/una-bolnica-home-decoration-2.svg'
import decorationImage4 from '../../assets/home/una-bolnica-home-decoration-3.svg'
import phoneButton from '../../assets/services/una-bolnica-services-phone-button.svg';

export default function DoctorProfile({ doctorProfileData }) {

  // state value that determine navigation dropdown visibility
  const [dropdown, setDropdown] = React.useState(false);

  // function changes dropdown state value
  function showDropdown() {
    setDropdown((prevState) => {
      return (
        !prevState
      )
    })
  }

  // state value that determine slideshow visibility
  const [slideshowVisibility, setSlideshowVisibility] = React.useState(false)
  // state value that determine which will be the first image in slideshow
  const [startIndex, setStartIndex] = React.useState(0);

  // function is changing slideshow visibility to true and passing image index to startIndex state which is passed to DoctorProfileSlideshow component
  function openSlide(parameter) {
    setSlideshowVisibility(true);
    setStartIndex(parameter);
  }

  // function is changing slideshow visibility to false
  function closeSlide() {
    setSlideshowVisibility((prevState) => {
      return (
        prevState = false
      )
    })
  }

  // converting fragment name from doctorPorfileData to string and removing <br /> tag and changing first letter to be lowercase.
  function changeName(oldName) {
    if (React.isValidElement(oldName)) {
      const childrenArray = React.Children.toArray(oldName.props.children);
      const newName = childrenArray
        .filter(child => typeof child === 'string') // Filter out non-string elements
        .map(child => child.replace(/<br\s?\/?>/g, ''))
        .join(' ') // Join cleaned strings together
        .replace(/^\w/, firstLetter => firstLetter.toLowerCase()); // Convert first letter to lowercase
  
      return newName;
    }
    return oldName;
  }

  const mergedGallery = doctorProfileData.gallery.flatMap(row => row.rowContent)

  // part of code that renders
  return (

    <>
      {doctorProfileData.seoInspector ? (
        <Helmet>
          <title>{doctorProfileData.seoInspector.title}</title>
          <meta
            name="description"
            content={doctorProfileData.seoInspector.metaContent}>
          </meta>
          <link rel="canonical" href={doctorProfileData.seoInspector.link}/>
          <meta property="og:image" content={doctorProfileData.seoInspector.thumbnailImage} />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </Helmet>) :

        (<Helmet>
          <title>Una bolnica Beograd, zakažite pregled na +381652024055</title>
          <meta
            name="description"
            content=
              "Opšta bolnica Una, spinalna hirurgija, plastična hirugija, ortopedija, ORL i druge. Vojvode Skopljanca 19, Zakažite pregled na +381652024055">
          </meta>
          <link rel="canonical" href={doctorProfileData.seoInspector.link}/>
        </Helmet>)
      }

      {doctorProfileData.seoInspector &&
        <h1 style={{display: 'none'}}>{doctorProfileData.seoInspector.h1}</h1>
      }

      {doctorProfileData.seoInspector &&
        <h2 style={{display: 'none'}}>{doctorProfileData.seoInspector.h2}</h2>
      }

      {/* small screen doctor profile container */}
      <div className="ss-doctor-profile-container">

        <HomeHeader 
          showDropdown={showDropdown} // passing showDropdown function to HomeHeader component
          dropdownValue={dropdown} // passing dropdown state value to HomeHeader component
        />

        { dropdown && <HomeNavigationDropdown /> }


        {/* small screen doctor profile title 1 container */}
        <div className="ss-doctor-profile-title-1-container">
          <p>Doktori</p>
        </div>

        {/* small screen doctor profile image 1 container */}  
        <div className="ss-doctor-profile-image-1-container">
          <img 
            src={doctorProfileData.profileImage}
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt={doctorProfileData.alternative}/>
        </div>

        {/* small screen doctor profile name container */}  
        <div className="ss-doctor-profile-name-container">
          <p>{doctorProfileData.name}</p>
        </div>

        {/* small screen doctor profile description container */}  
        <div className="ss-doctor-profile-description-container">
          <p>{doctorProfileData.description}</p>
        </div>

        {/* small screen doctor profile service container */}  
        <div className="ss-doctor-profile-service-container">
          <p>{doctorProfileData.service.toUpperCase()}</p>
        </div>

        {/* small screen doctor profile reservation container */}
        <a href="tel:+381117858777">  
          <div className="ss-doctor-profile-reservation-container">
            <p>ZAKAŽITE PREGLED</p>
          </div>
        </a>

        {/* small screen doctor profile horizontal rule */}
        <hr className="ss-doctor-profile-horizontal-rule" />

        {/* small screen doctor profile title 2 container */}
        {doctorProfileData.servicesList &&
        <div className="ss-doctor-profile-title-2-container">
          <p>Istaknute usluge</p>
        </div>
        }

        {/* small screen doctor profile services container */}
        {doctorProfileData.servicesList &&
        <div className="ss-doctor-profile-services-container">
          {doctorProfileData.servicesList.map((listItem) => {
              return (
                <div 
                  key={listItem.serviceId}
                  className="ss-doctor-profile-services-list-item">
                    {/* recognizing <br /> in serviceContent (doctor-profile-data.js) as break row tag */}
                    <p dangerouslySetInnerHTML={{ __html: listItem.serviceContent.toUpperCase().replace(/\n/g, '<br />') }}></p> 
                </div>
              )
          })}
        </div>
        }

        {/* small screen doctor profile title 3 container */}
        {doctorProfileData.biography &&
        <div 
          className="ss-doctor-profile-title-3-container"
          style={{marginTop: doctorProfileData.servicesList ? '8.889vw' : '6.667vw'}}>
          <p>Biografija</p>
        </div>
        }

        {/* small screen doctor profile biography container */}
        <div 
          className="ss-doctor-profile-biography-container"
          style={{marginTop: doctorProfileData.biography ? '4.444vw' : '0'}}>
            {doctorProfileData.biography &&
              doctorProfileData.biography.map((paragraph) => {
                return (
                  <p key={paragraph.paragraphId}>{paragraph.paragraphContent}</p>
                )
            })}
        </div>

        {/* small screen doctor profile title 4 container */}
        <div 
          className="ss-doctor-profile-title-4-container"
          style={{marginTop: doctorProfileData.biography ? '8.889vw' : '6.667vw'}}>
            <p>Galerija</p>
        </div>

        {/* small screen doctor profile horizontal rule */}
        <hr className="ss-doctor-profile-horizontal-rule" />

        {/* small screen doctor profile gallery container */}
        <table className="ss-doctor-profile-gallery-container">
          <tbody>
            {doctorProfileData.gallery.slice(0, 2).map((row) => {
              return (
                <tr key={row.rowId}>
                    {row.rowContent.map((image) => {
                      return (
                        <td key={image.imageId}>
                          <img 
                            src={image.image}
                            onContextMenu={(e) => {e.preventDefault(e)}}
                            alt={image.alternative}
                            onClick={() => openSlide(image.imageId)}/> {/* passing image index as parametar to startIndex state */}
                        </td>
                      )
                    })}
                </tr>
              )
            })}
          </tbody>
        </table>
        
        {slideshowVisibility &&
          <DoctorProfileSlideshow  
            doctorProfileData={doctorProfileData}
            closeSlide={closeSlide}
            startIndex={startIndex}/>
        }

        {/* small screen doctor profile phone button container */}
        <div className="ss-doctor-profile-phone-button-container">
          <a href="tel:+3816520240555">
            <img 
              src={phoneButton}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-bolnica-telefon"/>
          </a>
        </div>
      </div>










      {/* large screen doctor profile container */}
      <div className="ls-doctor-profile-container">

        <HomeHeader />

        {/* large screen doctor profile decoration image 1 container */}
        <div className="ls-doctor-profile-decoration-image-1-container">
          <img 
            src={decorationImage2}
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt="una-bolnica-dekoracija-slika"/>
        </div>

        {/* large screen doctor profile image 1 container */}  
        <div className="ls-doctor-profile-image-1-container">
          <img 
            src={doctorProfileData.profileImage}
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt={doctorProfileData.alternative}/>
        </div>

        {/* large screen doctor profile name container */}  
        <div className="ls-doctor-profile-name-container">
          <p>{changeName(doctorProfileData.name)}</p>
        </div>

        {/* large screen doctor profile description container */}  
        <div className="ls-doctor-profile-description-container">
          <p>{doctorProfileData.description}</p>
        </div>

        {/* large screen doctor profile service container */}  
        <div className="ls-doctor-profile-service-container">
          <p>{doctorProfileData.service.toUpperCase()}</p>
        </div>

        {/* large screen doctor profile reservation container */}
        <a href="tel:+381117858777">  
          <div className="ls-doctor-profile-reservation-container">
            <p>ZAKAŽITE PREGLED</p>
          </div>
        </a>

        {/* large screen doctor profile horizontal rule */}
        <hr 
          className="ls-doctor-profile-horizontal-rule" 
          style={{marginTop: '5vw'}}/>

        {/* large screen doctor profile title 2 container */}
        {doctorProfileData.servicesList &&
        <div className="ls-doctor-profile-title-2-container">
          <p>Istaknute usluge</p>
        </div>
        }

        {/* large screen doctor profile services container */}
        {doctorProfileData.servicesList &&
        <div className="ls-doctor-profile-services-container">
          {doctorProfileData.servicesList.map((listItem) => {
              return (
                <div 
                  key={listItem.serviceId}
                  className="ls-doctor-profile-services-list-item">
                    {/* recognizing <br /> in serviceContent (doctor-profile-data.js) as break row tag */}
                    <p dangerouslySetInnerHTML={{ __html: listItem.serviceContent.charAt(0).toUpperCase()+listItem.serviceContent.slice(1).replace(/<br\s?\/?>/g, ' ') }}></p> 
                </div>
              )
          })}
        </div>
        }

        {/* large screen doctor profile horizontal rule */}
        {doctorProfileData.servicesList &&
        <hr 
          className="ls-doctor-profile-horizontal-rule" 
          style={{marginTop: '10vw'}}/>
        }

        {/* large screen doctor profile title 2 container */}
        {doctorProfileData.biography &&
        <div 
          className="ls-doctor-profile-title-2-container">
          <p>Biografija</p>
        </div>
        }

        {/* large screen doctor profile biography container */}
        {doctorProfileData.biography &&
          <div className="ls-doctor-profile-biography-container">

            {/* large screen doctor profile biography decoration 1 container */}
            <div className="ls-doctor-profile-biography-decoration-1-container">
              <img 
                src={decorationImage4}
                onContextMenu={(e) => {e.preventDefault(e)}}
                alt="una-bolnica-dekoracija"/>
            </div>

            {/* large screen doctor profile biography text container */}
            {doctorProfileData.biography &&
              <div className="ls-doctor-profile-biography-text-container">

                <p className="ls-doctor-profile-biography-text-name-container">
                  {changeName(doctorProfileData.name)}
                </p>
                
                {doctorProfileData.biography &&
                  doctorProfileData.biography.map((paragraph) => {
                    return (
                      <p 
                        className="ls-doctor-profile-biography-text-text-container"
                        key={paragraph.paragraphId}>
                          {paragraph.paragraphContent}
                      </p>
                    )
                })}
              </div>
            }
              

            {/* large screen doctor profile biography decoration 2 container */}
            <div className="ls-doctor-profile-biography-decoration-2-container">
              <img 
                src={decorationImage3}
                onContextMenu={(e) => {e.preventDefault(e)}}
                alt="una-bolnica-dekoracija"/>
            </div>
          </div>
        }

        {/* large screen doctor profile horizontal rule */}
        {doctorProfileData.biography &&
        <hr 
          className="ls-doctor-profile-horizontal-rule" 
          style={{marginTop: '10vw'}}/>
        }

        {/* large screen doctor profile title 2 container */}
        <div 
          className="ls-doctor-profile-title-2-container">
          <p>Galerija</p>
        </div>

        {/* large screen doctor profile gallery container */}
        <table className="ls-doctor-profile-gallery-container">
          <tbody>
            <tr>
              {mergedGallery.slice(0, 4).map((image) => {
                return (
                  <td key={image.imageId}>
                    <img 
                      src={image.image}
                      onContextMenu={(e) => {e.preventDefault(e)}}
                      alt={image.alternative}
                      onClick={() => openSlide(image.imageId)}/> {/* passing image index as parametar to startIndex state */}
                  </td>
                )
              })}
            </tr>
            <tr>
              {mergedGallery.slice(4, 8).map((image) => {
                return (
                  <td key={image.imageId}>
                    <img 
                      src={image.image}
                      onContextMenu={(e) => {e.preventDefault(e)}}
                      alt={image.alternative}
                      onClick={() => openSlide(image.imageId)}/> {/* passing image index as parametar to startIndex state */}
                  </td>
                )
              })}
            </tr>
          </tbody>
        </table>

        {slideshowVisibility &&
          <DoctorProfileSlideshow  
            doctorProfileData={doctorProfileData}
            closeSlide={closeSlide}
            startIndex={startIndex}/>
        }
      </div>

      <HomeFooter />

      <ArrowUpIcon />

    </>

  )
}