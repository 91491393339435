import React from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";

import ScrollTop from "./components/scroll-top/scroll-top.js";
import Home from "./pages/home/home";
import Services from "./pages/services/services.js";
import Doctors from "./pages/doctors/doctors.js";
import Contact from "./pages/contact/contact.js";
import Blog from "./pages/blog/blog.js";

import MarkoBumbasirevic from "./pages/marko-bumbasirevic/marko-bumbasirevic.js";
import IvicaMedenica from "./pages/ivica-medenica/ivica-medenica.js";
import SlavkoTomic from "./pages/slavko-tomic/slavko-tomic.js";
import MarkoIlic from "./pages/marko-ilic/marko-ilic.js";
import DejanRistic from "./pages/dejan-ristic/dejan-ristic.js";
import SladjanTimotijevic from "./pages/sladjan-timotijevic/sladjan-timotijevic.js";
import AleksandarRadunovic from "./pages/aleksandar-radunovic/aleksandar-radunovic.js";
import AleksandarVojvodic from "./pages/aleksandar-vojvodić/aleksandar-vojvodić.js";
import MladenMaksic from "./pages/mladen-maksic/mladen-maksic.js";
import VukVucic from "./pages/vuk-vucic/vuk-vucic.js";
import VladimirVranic from "./pages/vladimir-vranic/vladimir-vranic.js";

import AnaMalivukovic from "./pages/ana-malivukovic/ana-malivukovic.js";
import IgorMaljkovic from "./pages/igor-maljkovic/igor-maljkovic.js";
import BiljanaMitric from "./pages/biljana-mitric/biljana-mitric.js";
import MilosGacevic from "./pages/milos-gacevic/milos-gacevic.js";
import MilosVucetic from "./pages/milos-vucetic/milos-vucetic.js";
import SimonPandjaitan from "./pages/simon-pandjaitan/simon-pandjaitan.js";
import UrosRadivojcevic from "./pages/uros-radivojcevic/uros-radivojcevic.js";
import ZeljkoCurcic from "./pages/zeljko-curcic/zeljko-curcic.js";
import ZeljkoStanojlovic from "./pages/zeljko-stanojlovic/zeljko-stanojlovic.js";

import LjubodragMinic from "./pages/ljubodrag-minic/ljubodrag-minic.js";
import GoranPavlicevic from "./pages/goran-pavlicevic/goran-pavlicevic.js";
import NenadNovakovic from "./pages/nenad-novakovic/nenad-novakovic.js";
import MiljanMihajlovic from "./pages/miljan-mihajlovic/miljan-mihajlovic.js";
import AndrijaSavic from "./pages/andrija-savic/andrija-savic.js";
import RadomirBenovic from "./pages/radomir-benovic/radomir-benovic.js";

import NenadArsovic from "./pages/nenad-arsovic/nenad-arsovic.js";
import DusanBijelic from "./pages/dusan-bijelic/dusan-bijelic.js";
import LjubomirPavicevic from "./pages/ljubomir-pavicevic/ljubomir-pavicevic.js";
import AleksandarDimic from "./pages/aleksandar-dimic/aleksandar-dimic.js";
import IvanDjordjevic from "./pages/ivan-djordjevic/ivan-djordjevic.js";
import LjiljanaCvorovic from "./pages/ljiljana-cvorovic/ljiljana-cvorovic.js";
import MilanErdoglija from "./pages/milan-erdoglija/milan-erdoglija.js";
import MiloradAdzic from "./pages/milorad-adzic/milorad-adzic.js";
import NikolaMircetic from "./pages/nikola-mircetic/nikola-mircetic.js";

import BoskoMilev from "./pages/bosko-milev/bosko-milev.js";
import MiroslavMitrovic from "./pages/miroslav-mitrovic/miroslav-mitrovic.js";
import ZagorZagorac from "./pages/zagor-zagorac/zagor-zagorac.js";

import IvanLekovic from "./pages/ivan-lekovic/ivan-lekovic.js";
import BozinaRadevic from "./pages/bozina-radevic/bozina-radevic.js";

import ZivoradNikolic from "./pages/zivorad-nikolic/zivorad-nikolic.js";
import SrboljubStosic from "./pages/srboljub-stosic/srboljub-stosic.js";

import BorkoJosifovski from "./pages/borko-josifovski/borko-josifovski.js";

import GoranPlavec from "./pages/goran-plavec/goran-plavec.js";

import JamalAlKiswani from "./pages/jamal-al-kiswani/jamal-al-kiswani.js";
import NebojsaManojlovic from "./pages/nebojsa-manojlovic/nebojsa-manojlovic.js";

import GordanMalivukovic from "./pages/gordan-malivukovic/gordan-malivukovic.js";
import DuskoJovanovic from "./pages/dusko-jovanovic/dusko-jovanovic.js";

import SlavicaPavlovic from "./pages/slavica-pavlovic/slavica-pavlovic.js";
import JovanRudic from "./pages/jovan-rudic/jovan-rudic.js";

import JasenkaGrebenarevic from "./pages/jasenka-grebenarevic/jasenka-grebenarevic.js";
import AnaGolubovic from "./pages/ana-golubovic/ana-golubovic.js";
import BorivojeLukic from "./pages/borivoje-lukic/borivoje-lukic.js";

import VojkanLekic from "./pages/vojkan-lekic/vojkan-lekic.js";
import BrankaMarkovic from "./pages/branka-markovic/branka-markovic.js";
import MarkoBogosavljevic from "./pages/marko-bogosavljevic/marko-bogosavljevic.js";

import blogData from "./assets/blog-data/blog-data.js";

function App() {

  return (

    <BrowserRouter>
      <ScrollTop />
        <Routes>

          <Route exact path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/doctors" element={<Doctors />} />
          <Route path="/contact" element={<Contact />} />
          <Route exact path="/blog" element={<Blog />} />

          <Route path="/marko-bumbasirevic" element={<MarkoBumbasirevic />} />
          <Route path="/ivica-medenica" element={<IvicaMedenica />} />
          <Route path="/slavko-tomic" element={<SlavkoTomic />} />
          <Route path="/marko-ilic" element={<MarkoIlic />} />
          <Route path="/dejan-ristic" element={<DejanRistic />} />
          <Route path="/sladjan-timotijevic" element={<SladjanTimotijevic />} />
          <Route path="/aleksandar-radunovic" element={<AleksandarRadunovic />} />
          <Route path="/aleksandar-vojvodic" element={<AleksandarVojvodic />} />
          <Route path="/mladen-maksic" element={<MladenMaksic />} />
          <Route path="/vuk-vucic" element={<VukVucic />} />
          <Route path="/vladimir-vranic" element={<VladimirVranic />} />

          <Route path="/ana-malivukovic" element={<AnaMalivukovic />} />
          <Route path="/igor-maljkovic" element={<IgorMaljkovic />} />
          <Route path="/biljana-mitric" element={<BiljanaMitric />} />
          <Route path="/milos-gacevic" element={<MilosGacevic />} />
          <Route path="/milos-vucetic" element={<MilosVucetic />} />
          <Route path="/simon-pandjaitan" element={<SimonPandjaitan />} />
          <Route path="/uros-radivojcevic" element={<UrosRadivojcevic />} />
          <Route path="/zeljko-curcic" element={<ZeljkoCurcic />} />
          <Route path="/zeljko-stanojlovic" element={<ZeljkoStanojlovic />} />

          <Route path="/ljubodrag-minic" element={<LjubodragMinic />} />
          <Route path="/goran-pavlicevic" element={<GoranPavlicevic />} />
          <Route path="/nenad-novakovic" element={<NenadNovakovic />} />
          <Route path="/miljan-mihajlovic" element={<MiljanMihajlovic />} />
          <Route path="/andrija-savic" element={<AndrijaSavic />} />
          <Route path="/radomir-benovic" element={<RadomirBenovic />} />

          <Route path="/nenad-arsovic" element={<NenadArsovic />} />
          <Route path="/dusan-bijelic" element={<DusanBijelic />} />
          <Route path="/ljubomir-pavicevic" element={<LjubomirPavicevic />} />
          <Route path="/aleksandar-dimic" element={<AleksandarDimic />} />
          <Route path="/ivan-djordjevic" element={<IvanDjordjevic />} />
          <Route path="/ljiljana-cvorovic" element={<LjiljanaCvorovic />} />
          <Route path="/milan-erdoglija" element={<MilanErdoglija />} />
          <Route path="/milorad-adzic" element={<MiloradAdzic />} />
          <Route path="/nikola-mircetic" element={<NikolaMircetic />} />

          <Route path="/bosko-milev" element={<BoskoMilev />} />
          <Route path="/miroslav-mitrovic" element={<MiroslavMitrovic />} />
          <Route path="/zagor-zagorac" element={<ZagorZagorac />} />

          <Route path="/ivan-lekovic" element={<IvanLekovic />} />
          <Route path="/bozina-radevic" element={<BozinaRadevic />} />

          <Route path="/zivorad-nikolic" element={<ZivoradNikolic />} />
          <Route path="/srboljub-stosic" element={<SrboljubStosic />} />

          <Route path="/borko-josifovski" element={<BorkoJosifovski />} />

          <Route path="/goran-plavec" element={<GoranPlavec />} />

          <Route path="/jamal-al-kiswani" element={<JamalAlKiswani />} />
          <Route path="/nebojsa-manojlovic" element={<NebojsaManojlovic />} />

          <Route path="/gordan-malivukovic" element={<GordanMalivukovic />} />
          <Route path="/dusko-jovanovic" element={<DuskoJovanovic />} />

          <Route path="/slavica-pavlovic" element={<SlavicaPavlovic />} />
          <Route path="/jovan-rudic" element={<JovanRudic />} />

          <Route path="/jasenka-grebenarevic" element={<JasenkaGrebenarevic />} />
          <Route path="/ana-golubovic" element={<AnaGolubovic />} />
          <Route path="/borivoje-lukic" element={<BorivojeLukic />} />

          <Route path="/vojkan-lekic" element={<VojkanLekic />} />
          <Route path="/branka-markovic" element={<BrankaMarkovic />} />
          <Route path="/marko-bogosavljevic" element={<MarkoBogosavljevic />} />

          {blogData.map((post) => {
            return (
              <Route path={post.link} element={post.fileName} key={post.postId}/>
            )
          })}

        </Routes>
    </BrowserRouter>
  
  );
}

export default App;
