import React from 'react';
import './blog-post.css';
import { Link } from "react-router-dom";

export default function BlogPost({post}) {

  // part of code that renders
  return (
    <>
      <Link to={post.link} style={{textDecoration: 'none', width: '0'}}>
        {/* large screen blog post container */}
        <div className="ls-blog-post-container">

          {/* large screen blog post image container */}
          <div className="ls-blog-post-image-container">
            <img 
              src={post.image} 
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt={post.alternative}/>
          </div>

          {/* large screen blog post text container */}
          <div className="ls-blog-post-text-container">
            <p className="ls-blog-post-text-title">
              {post.title}
            </p>
            <br/>
            <p className="ls-blog-post-text-paragraph">
              {post.paragraph}
            </p>
          </div>

        </div>
      </Link>
    </>
  )
}