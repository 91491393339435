import markoBumbasirevic from '../../assets/doctors/una-bolnica-marko-bumbasirevic.webp';
import ivicaMedenica from '../../assets/doctors/una-bolnica-ivica-medenica.webp';
import slavkoTomic from '../doctors/una-bolnica-slavko-tomic.webp';
import markoIlic from '../doctors/una-bolnica-marko-ilic.webp';
import dejanRistic from '../doctors/una-bolnica-dejan-ristic.webp';
import sladjanTimotijevic from '../doctors/una-bolnica-sladjan-timotijevic.webp';
import aleksandarRadunovic from '../doctors/una-bolnica-aleksandar-radunovic.webp';
import aleksandarVojvodic from '../doctors/una-bolnica-aleksandar-vojvodic.webp';
import mladenMaksic from '../doctors/una-bolnica-mladen-maksic.webp';
import vukVucic from '../doctors/una-bolnica-vuk-vucic.webp';
import vladimirVranic from '../doctors/una-bolnica-vladimir-vranic.webp';

import anaMalivukovic from '../doctors/una-bolnica-ana-malivukovic.webp';
import igorMaljkovic from '../doctors/una-bolnica-igor-maljkovic.webp';
import biljanaMitric from '../doctors/una-bolnica-biljana-mitric.webp';
import milosGacevic from '../doctors/una-bolnica-milos-gacevic.webp';
import milosVucetic from '../doctors/una-bolnica-milos-vucetic.webp';
import simonPandjaitan from '../doctors/una-bolnica-simon-pandjaitan.webp';
import urosRadivojcevic from '../doctors/una-bolnica-uros-radivojcevic.webp';
import zeljkoCurcic from '../doctors/una-bolnica-zeljko-curcic.webp';
import zeljokoStanojlovic from '../doctors/una-bolnica-zeljko-stanojlovic.webp';

import ljubodragMinic from '../doctors/una-bolnica-ljubodrag-minic.webp';
import goranPavlicevic from '../doctors/una-bolnica-goran-pavlicevic.webp';
import nenadNovakovic from '../doctors/una-bolnica-nenad-novakovic.webp';
import miljanMihajlovic from '../doctors/una-bolnica-miljan-mihajlovic.webp';
import andrijaSavic from '../doctors/una-bolnica-andrija-savic.webp';
import radomirBenovic from '../doctors/una-bolnica-radomir-benovic.webp';

import nenadArsovic from '../doctors/una-bolnica-nenad-arsovic.webp';
import dusanBijelic from '../doctors/una-bolnica-dusan-bijelic.webp';
import ljubomirPavicevic from '../doctors/una-bolnica-ljubomir-pavicevic.webp';
import aleksandarDimic from '../doctors/una-bolnica-aleksandar-dimic.webp';
import ivanDjordjevic from '../doctors/una-bolnica-ivan-djordjevic.webp';
import ljiljanaCvorovic from '../doctors/una-bolnica-ljiljana-cvorovic.webp';
import milanErdoglija from '../doctors/una-bolnica-milan-erdoglija.webp';
import miloradAdzic from '../doctors/una-bolnica-milorad-adzic.webp';
import nikolaMircetic from '../doctors/una-bolnica-nikola-mircetic.webp';

import boskoMilev from '../doctors/una-bolnica-bosko-milev.webp';
import miroslavMitrovic from '../doctors/una-bolnica-miroslav-mitrovic.webp';
import zagorZagorac from '../doctors/una-bolnica-zagor-zagorac.webp';

import ivanLekovic from '../doctors/una-bolnica-ivan-lekovic.webp';
import bozinaRadevic from '../doctors/una-bolnica-bozina-radevic.webp';

import zivoradNikolic from '../doctors/una-bolnica-zivorad-nikolic.webp';
import srboljubStosic from '../doctors/una-bolnica-srboljub-stosic.webp';

import borkoJosifovski from '../doctors/una-bolnica-borko-josifovski.webp';

import goranPlavec from '../doctors/una-bolnica-goran-plavec.webp';

import jamalAlKiswani from '../doctors/una-bolnica-jamal-al-kiswani.webp';
import nebojsaManojlovic from '../doctors/una-bolnica-nebojsa-manojlovic.webp';

import gordanMalivukovic from '../doctors/una-bolnica-gordan-malivukovic.webp';
import duskoJovanovic from '../doctors/una-bolnica-dusko-jovanovic.webp';

import slavicaPavlovic from '../doctors/una-bolnica-slavica-pavlovic.webp';
import jovanRudic from '../doctors/una-bolnica-jovan-rudic.webp';

import jasenkaGrebenarevic from '../doctors/una-bolnica-jasenka-grebenarevic.webp';
import anaGolubovic from '../doctors/una-bolnica-ana-golubovic.webp';
import borivojeLukic from '../doctors/una-bolnica-borivoje-lukic.webp';

import vojkanLekic from '../doctors/una-bolnica-vojkan-lekic.webp';
import brankaMarkovic from '../doctors/una-bolnica-branka-markovic.webp';
import markoBogosavljevic from '../doctors/una-bolnica-marko-bogosavljevic.webp';

import galleryAvatar from '../../assets/doctor-profile/una-bolnica-avatar.webp';

const doctorProfileData = [
  {
    doctorId: 'markoBumbasirevic',
    profileImage: markoBumbasirevic,
    alternative: 'una-bolnica-marko-bumbaširević',
    name: <>Prof. dr Marko<br />Bumbaširević</>,
    description: 'ortoped',
    service: 'ortopedija',
    seoInspector: {
      title: 'dr Marko Bumbaširević, ortoped, Una bolnica Beograd',
      metaContent: 'dr Marko Bumbaširević, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/marko-bumbasirevic',
      h1: 'dr Marko Bumbaširević, ortoped, Una bolnica Beograd',
      h2: 'dr Marko Bumbaširević, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: markoBumbasirevic,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'rekonstruktivna hirurgija'
      },
      {
        serviceId: 2,
        serviceContent: 'mikrohirurgija'
      },
      {
        serviceId: 3,
        serviceContent: 'ortopedske operacije kičme'
      },
      {
        serviceId: 4,
        serviceContent: 'operacije zglobova<br />(kuk, koleno, ramena)'
      },
      {
        serviceId: 5,
        serviceContent: 'rekontrukcija tetiva i ligamenata'
      },
      {
        serviceId: 6,
        serviceContent: 'lečenje sportskih povreda'
      },
      {
        serviceId: 7,
        serviceContent: 'hirurgija kostiju<br />(uključujući lečenje preloma)'
      },
      {
        serviceId: 8,
        serviceContent: 'artroskopija zglobova'
      },
      {
        serviceId: 9,
        serviceContent: 'operacija stopala i gležnja'
      },
      {
        serviceId: 10,
        serviceContent: 'plastična hirurgija ekstremiteta'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Sa preko 20 godina iskustva, on je sinonim za vrhunsku negu pacijenata i izvanredno znanje. Diplomirao je na Medicinskom fakultetu Univerziteta u Beogradu, gde je i doktorirao 1994. godine.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Prof. Bumbaširević je profesor ortopedske hirurgije i traumatologije, a od 2001. do 2014. godine bio je direktor Klinike za ortopedsku hirurgiju i traumatologiju Kliničkog centra Srbije. Danas je šef Katedre za rekonstruktivnu hirurgiju i mikrohirurgiju.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Njegovo bogato iskustvo obuhvata širok spektar ortopedskih operacija i tretmana. Kroz više stotina radova objavljenih u vodećim međunarodnim časopisima, međunarodnim udžbenicima i nacionalnim monografijama, Prof. Bumbaširević je dao značajan doprinos polju ortopedije.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Prof. Bumbaširević je takođe imao značajne funkcije u međunarodnim udruženjima. Bio je predsednik Evropskog udruženja mikrohirurga 2016. godine, što je svedočanstvo o njegovom priznanju i autoritetu u struci. Takođe je bio postavljen za predsednika Komiteta za ortopedsku mikrohirurgiju, gde je aktivno učestvovao u kreiranju programa, organizovanju okruglih stolova i strategija u ovoj oblasti medicine.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-bumbaširević'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-bumbaširević'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-bumbaširević'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-bumbaširević'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-bumbaširević'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-bumbaširević'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-bumbaširević'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-bumbaširević'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'ivicaMedenica',
    profileImage: ivicaMedenica,
    alternative: 'una-bolnica-ivica-medenica',
    name: <>Dr Ivica<br />Medenica</>,
    description: 'ortoped',
    service: 'ortopedija',
    seoInspector: {
      title: 'dr Ivica Medenica, ortoped, Una bolnica Beograd',
      metaContent: 'dr Ivica Medenica, ortoped, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/ivica-medenica',
      h1: 'dr Ivica Medenica, ortoped, Una bolnica Beograd',
      h2: 'dr Ivica Medenica, ortoped, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: ivicaMedenica,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'artroskopska hirurgija ramena'
      },
      {
        serviceId: 2,
        serviceContent: 'rekonstruktivna hirurgija ramena'
      },
      {
        serviceId: 3,
        serviceContent: 'hirurgija lakta'
      },
      {
        serviceId: 4,
        serviceContent: 'operacija kolena<br />(uključujući sportske povrede)'
      },
      {
        serviceId: 5,
        serviceContent: 'rekonstruktivna hirurgija i mikrohirurgija'
      },
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr Ivica Medenica je priznat stručnjak u oblasti ortopedije s više od 30 godina iskustva. Sa diplomom Medicinskog fakulteta u Sarajevu steknutom 1982. godine, Dr Medenica je nastavio svoje usavršavanje kroz specijalizaciju iz ortopedije i traumatologije na Vojnomedicinskoj akademiji u Beogradu od 1989. do 1993. godine. Fokusirajući se na svoje interesovanje za artroskopsku hirurgiju ramena, doktorirao je na Medicinskom fakultetu u Kragujevcu 2010. godine.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Dr Medenica ima izuzetno radno iskustvo koje obuhvata pozicije kao lekar opšte prakse u Banja Luci, kao i lekara na specijalizaciji u Vojnoj bolnici Split i Vojnomedicinskoj akademiji u Beogradu. Sada je angažovan kao šef Katedre za rekonstruktivnu hirurgiju i mikrohirurgiju, gde koristi svoje stručno znanje i veštine da pruži najbolju moguću negu pacijentima.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Dr Medenica je ponosni član renomiranih strukovnih udruženja kao što su Srpsko lekarsko društvo, Srpska ortopedsko traumatološka asocijacija, Srpska traumatološka asocijacija, Evropsko udruženje sportske traumatologije i hirurgije kolena i artroskopije, Međunarodna asocijacija za artroskopiju i hirurgiju, kao i Udruženje za hirurgiju ramena i lakta Srbije. Kroz svoje aktivno učešće na međunarodnim kongresima i sastancima, Dr Medenica je uvek u toku s najnovijim trendovima i dostignućima u ortopediji.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Njegova stručnost je takođe dokumentovana kroz brojne objavljene radove u vodećim međunarodnim časopisima, udžbenicima i monografijama iz ortopedije. Ovaj doprinos naučnoj zajednici dodatno potvrđuje njegovu predanost unapređenju kliničke prakse.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivica-medenica'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivica-medenica'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivica-medenica'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivica-medenica'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivica-medenica'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivica-medenica'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivica-medenica'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivica-medenica'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'slavkoTomic',
    profileImage: slavkoTomic,
    alternative: 'una-bolnica-slavko-tomić',
    name: <>Prof. dr Slavko<br />Tomić</>,
    description: 'ortoped',
    service: 'ortopedija',
    seoInspector: {
      title: 'dr Slavko Tomić, ortoped, +381652024055',
      metaContent: 'dr Slavko Tomić, ortoped, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/slavko-tomic',
      h1: 'dr Slavko Tomić, ortoped, +381652024055',
      h2: 'dr Slavko Tomić, ortoped, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: slavkoTomic,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Metoda Ilizarova za lečenje<br />različitih patologija'
      },
      {
        serviceId: 2,
        serviceContent: 'Rekonstruktivna ortopedija'
      },
      {
        serviceId: 3,
        serviceContent: 'Traumatologija lokomotornog sistema'
      },
      {
        serviceId: 4,
        serviceContent: 'Artroskopija zglobova'
      },
      {
        serviceId: 5,
        serviceContent: 'Zamena zglobova (kuk, koleno)'
      },
      {
        serviceId: 6,
        serviceContent: 'Rekonstrukcija ligamenata i tetiva'
      },
      {
        serviceId: 7,
        serviceContent: 'Korigovanje deformiteta kostiju<br />(skolioza, ravna stopala)'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Diplomirao je na Medicinskom fakultetu u Beogradu 1979. godine, a specijalizirao ortopedsku hirurgiju sa traumatologijom 1989. godine. Magistrirao je 1986. godine, a doktorirao 1993. godine.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Prof. Tomić je redovni profesor Hirurgije na Medicinskom fakultetu u Beogradu i trenutno obavlja funkciju šefa Katedre za ortopediju i traumatologiju. Takođe je načelnik Odeljenja rekonstruktivne ortopedije i traumatologije lokomotornog sistema Instituta "Banjica".'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Sa svojim značajnim naučno-stručnim doprinosom, Prof. Tomić je autor 150 radova koji su objavljeni u najpriznatijim međunarodnim i domaćim časopisima. Takođe je autor 12 stručnih knjiga i često se citira u međunarodnim stručnim knjigama i udžbenicima, što je jasno priznanje njegove stručnosti.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Prof. Tomić je posvetio mnogo vremena stručnom usavršavanju, a proveo je pet godina na subspecijalizaciji u Ruskom centru "Rekonstruktivna traumatologija i ortopedija - Ilizarov" u Kurganu. Poseduje sertifikate za lečenje različitih patologija lokomotornog sistema metodom Ilizarova. U svojoj bogatoj karijeri, izveo je više od 10.000 operacija iz domena rekonstruktivne ortopedije i traumatologije.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-slavko-tomić'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-slavko-tomić'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-slavko-tomić'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-slavko-tomić'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-slavko-tomić'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-slavko-tomić'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-slavko-tomić'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-slavko-tomić'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'markoIlic',
    profileImage: markoIlic,
    alternative: 'una-bolnica-marko-ilić',
    name: <>Dr Marko Ilić</>,
    description: 'ortoped',
    service: 'ortopedija',
    seoInspector: {
      title: 'dr Marko Ilić, ortoped, +381652024055',
      metaContent: 'Opšta bolnica Una, spinalna hirurgija, estetska hirugija, ortopedija, ORL i druge. Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/marko-ilic',
      h1: 'dr Marko Ilić, ortoped, +381652024055',
      h2: 'dr Marko Ilić, ortoped, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: markoIlic,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Operacije sportskih povreda'
      },
      {
        serviceId: 2,
        serviceContent: 'Hirurgija gornjih ekstremiteta'
      },
      {
        serviceId: 3,
        serviceContent: 'Ultrazvuk mekih tkiva i zglobova'
      },
      {
        serviceId: 4,
        serviceContent: 'Lečenje koštano-zglobne traume'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr. Marko Ilić je renomirani ortopedski hirurg s fokusom na sportske povrede i hirurgiju gornjeg ekstremiteta. Sa bogatim iskustvom i stručnim usavršavanjem, Dr. Ilić je vodeći stručnjak u svojoj oblasti.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Završio je studije i specijalizaciju iz ortopedske hirurgije sa traumatologijom u Beogradu. Sa radnim iskustvom na Klinici za ortopedsku hirurgiju i traumatologiju KCS, stekao je vredno iskustvo. Trenutno je asistent na Medicinskom fakultetu u Beogradu.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Dr. Ilić je i sportski lekar u FK Čukarički i HK Partizan. Pruža specijalističke preglede, ultrazvuk mekih tkiva i zglobova, te lečenje koštano-zglobne traume. Autor je preko 60 objavljenih radova i prezentacija.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Sa Dr. Markom Ilićem imate priliku za vrhunsku ortopedsku negu podržanu stručnim znanjem i iskustvom.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-ilić'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-ilić'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-ilić'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-ilić'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-ilić'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-ilić'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-ilić'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-ilić'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'dejanRistic',
    profileImage: dejanRistic,
    alternative: 'una-bolnica-dejan-ristić',
    name: <>Dr Dejan Ristić</>,
    description: 'ortoped',
    service: 'ortopedija',
    seoInspector: {
      title: 'dr Dejan Ristić, ortoped, +381652024055',
      metaContent: 'dr Dejan Ristić, ortoped, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/dejan-ristic',
      h1: 'dr Dejan Ristić, ortoped, +381652024055',
      h2: 'dr Dejan Ristić, ortoped, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: dejanRistic,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Artroskopske operacije kolena'
      },
      {
        serviceId: 2,
        serviceContent: 'Zamena zgloba kuka'
      },
      {
        serviceId: 3,
        serviceContent: 'Zamena zgloba kolena'
      },
      {
        serviceId: 4,
        serviceContent: 'Artroskopske operacije ramena'
      },
      {
        serviceId: 5,
        serviceContent: 'Lečenje degenerativnih promena'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr. Dejan Ristić je priznat ortoped sa širokim iskustvom u operativnom i neoperativnom lečenju lokomotornog sistema kod odraslih pacijenata. Specijalizovan je za degenerativne promene na kukovima, kolenima i ramenima, izvodi artroskopske operacije kolena i zbrinjava prelome.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Završio je osnovne studije, specijalizaciju iz ortopedije sa traumatologijom i magisterijum iz ortopedije sa traumatologijom na Medicinskom fakultetu Univerziteta u Prištini. Sa bogatim radnim iskustvom u Kliničko-bolničkom centru "Zemun" (2003-2012) i Kliničko-bolničkom centru "Bežanijska kosa" (od 2012), Dr. Ristić je stekao reputaciju stručnjaka visokog kalibra.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Dr. Ristić radi u Beogradu kako u državnoj tako i u privatnoj praksi, pružajući specijalističke preglede, ultrazvučni pregled zglobova i intraartikularnu aplikaciju lekova. Redovno učestvuje na kongresima i edukacijama kako u zemlji tako i u inostranstvu, a njegovi radovi iz oblasti ortopedije su objavljeni i priznati. Odlukom Ministarstva zdravlja, Dr. Ristić je dobio zvanje primarijusa 2011. godine, priznanje za njegov izuzetan doprinos medicini.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-dejan-ristić'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-dejan-ristić'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-dejan-ristić'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-dejan-ristić'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-dejan-ristić'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-dejan-ristić'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-dejan-ristić'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-dejan-ristić'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'sladjanTimotijevic',
    profileImage: sladjanTimotijevic,
    alternative: 'una-bolnica-slađan-timotijević',
    name: <>Prim. dr Slađan<br />Timotijević</>,
    description: 'specijalista ortopedije',
    service: 'ortopedija',
    seoInspector: {
      title: 'Slađan Timotijević, ortoped, +381652024055',
      metaContent: 'Slađan Timotijević, ortoped, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/sladjan-timotijevic',
      h1: 'Slađan Timotijević, ortoped, +381652024055',
      h2: 'Slađan Timotijević, ortoped, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: sladjanTimotijevic,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Artroskopija kolena'
      },
      {
        serviceId: 2,
        serviceContent: 'Artroskopija kuka'
      },
      {
        serviceId: 3,
        serviceContent: 'Punkcija zgloba'
      },
      {
        serviceId: 4,
        serviceContent: 'Operacija kuka'
      },
      {
        serviceId: 5,
        serviceContent: 'Operacija Ahilove tetive'
      },
      {
        serviceId: 6,
        serviceContent: 'Rekonstrukcija ligamenata kolena'
      },
      {
        serviceId: 7,
        serviceContent: 'Zamena zgloba kolena'
      },
      {
        serviceId: 8,
        serviceContent: 'Popravka oštećenih tetiva'
      },
      {
        serviceId: 9,
        serviceContent: 'Lečenje sportskih povreda'
      },
      {
        serviceId: 10,
        serviceContent: 'Lečenje fraktura i preloma kostiju'
      },
      {
        serviceId: 11,
        serviceContent: 'Ortopedska rehabilitacija i terapija'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr. Slađan Timotijević je specijalista ortopedije sa traumatologijom. Završio je Medicinski fakultet Univerziteta u Prištini 1993. godine, a specijalizaciju iz ortopedije je završio na Medicinskom fakultetu u Beogradu. Magistrirao je 1997. godine, a doktorsku titulu je stekao 2006. godine.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Dr. Timotijević je stekao bogato iskustvo kao asistent na predmetu hirurgija sa anesteziologijom na Medicinskom fakultetu u Prištini, kao i u radu kao ortoped i načelnik u KBC Bežanijska kosa. Posebno se specijalizovao za operacije kolena i kuka.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Dr. Slađan obavlja širok spektar pregleda i procedura, uključujući specijalističke preglede, artroskopiju, punkciju zgloba, operaciju kuka i operaciju Ahilove tetive.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Kroz kontinuirano usavršavanje i edukaciju širom Evrope, Dr. Timotijević je proširio svoje znanje i stručnost. Takođe je autor brojnih naučnih radova, doprinoseći medicinskoj zajednici novim saznanjima i istraživanjima.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-slađan-timotijević'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-slađan-timotijević'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-slađan-timotijević'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-slađan-timotijević'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-slađan-timotijević'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-slađan-timotijević'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-slađan-timotijević'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-slađan-timotijević'
          }
        ]
      }
    ]
  },





















  {
    doctorId: 'aleksandarRadunovic',
    profileImage: aleksandarRadunovic,
    alternative: 'una-bolnica-aleksandar-radunović',
    name: <>Dr Aleksandar Radunović</>,
    description: 'ortoped',
    service: 'ortopedija',
    seoInspector: {
      title: 'dr Aleksandar Radunović, +381652024055',
      metaContent: 'dr Aleksandar Radunović, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/aleksandar-radunovic',
      h1: 'dr Aleksandar Radunović, +381652024055',
      h2: 'dr Aleksandar Radunović, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: aleksandarRadunovic,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Ugradnja veštačkih kukova, kolena i ramena'
      },
      {
        serviceId: 2,
        serviceContent: 'Lečenje sportskih povreda'
      },
      {
        serviceId: 3,
        serviceContent: 'Regenerativna medicina (PRP terapija)'
      },
      {
        serviceId: 4,
        serviceContent: 'Lečenje deformiteta stopala'
      },
      {
        serviceId: 5,
        serviceContent: 'Lečenje preloma kostiju i mekotkivnih povreda'
      },
      {
        serviceId: 6,
        serviceContent: 'Lečenje komplikacija vaskularnih oboljenja na ekstremitetima'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr. Aleksandar Radunović je iskusni ortoped sa diplomom Medicinskog fakulteta Univerziteta u Beogradu i doktorskom disertacijom na Fakultetu medicinskih nauka Univerziteta u Kragujevcu. Ima bogato radno iskustvo, uključujući Kliniku za ortopedsku hirurgiju i traumatologiju na VMA u Beogradu, Bel Medic, Ambulantu Generalštaba Vojske Srbije i Ministarstva odbrane Srbije, kao i vojnu misiju pri UN u Čadu (MINURCAT II) i Tekvondo i karate savez Beograda.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Njegova ekspertiza obuhvata degenerativna oboljenja zglobova, ugradnju veštačkih kukova, kolena i ramena, lečenje sportskih povreda, regenerativnu medicinu (PRP), lečenje deformiteta stopala, preloma kostiju i mekotkivnih povreda, kao i lečenje komplikacija vaskularnih oboljenja na ekstremitetima.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Dr. Radunović je proširio svoje znanje putem stručnih usavršavanja u više zemalja. Autor je i koautor više od 30 radova i dobitnik nekoliko priznanja. Aktivno učestvuje na domaćim i internacionalnim kongresima. Član je Srpskog udruženja za hirurgiju stopala i Srpskog lekarskog društva.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-radunović'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-radunović'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-radunović'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-radunović'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-radunović'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-radunović'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-radunović'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-radunović'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'aleksandarVojvodic',
    profileImage: aleksandarVojvodic,
    alternative: 'una-bolnica-aleksandar-vojvodić',
    name: <>Dr Aleksandar Vojvodić</>,
    description: 'ortoped',
    service: 'ortopedija',
    seoInspector: {
      title: 'dr Aleksandar Vojvodić, ortoped, +381652024055',
      metaContent: 'dr Aleksandar Vojvodić, ortoped, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/aleksandar-vojvodic',
      h1: 'dr Aleksandar Vojvodić, ortoped, +381652024055',
      h2: 'dr Aleksandar Vojvodić, ortoped, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: aleksandarVojvodic,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Ugradnja veštačkih zglobova (kuka, kolena, ramena)'
      },
      {
        serviceId: 2,
        serviceContent: 'Lečenje sportskih povreda'
      },
      {
        serviceId: 3,
        serviceContent: 'Artroskopija zglobova (kolena, ramena)'
      },
      {
        serviceId: 4,
        serviceContent: 'Lečenje preloma kostiju imekotkivnih povreda'
      },
      {
        serviceId: 5,
        serviceContent: 'Rekonstrukcija ligamenata'
      },
      {
        serviceId: 6,
        serviceContent: 'Deformitetska hirurgija (popravka deformiteta kostiju i zglobova)'
      },
      {
        serviceId: 7,
        serviceContent: 'Terapija osteoartritisa (PRP terapija, intraartikularne injekcije)'
      },
      {
        serviceId: 8,
        serviceContent: 'Lečenje degenerativnih oboljenja zglobova'
      },
      {
        serviceId: 9,
        serviceContent: 'Operativno lečenje povreda i bolesti kičme'
      },
      {
        serviceId: 10,
        serviceContent: 'Hirurško lečenje neravnoteže i oboljenja stopala'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr. Aleksandar Vojvodić je iskusni ortoped sa diplomom Medicinskog fakulteta u Beogradu i specijalističkim studijama završenim takođe u Beogradu. Trenutno je na doktorskim studijama na Fakultetu medicinskih nauka Univerziteta u Kragujevcu.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Dr. Vojvodić je stekao radno iskustvo kao specijalista ortopedije na Odeljenju aloartroplastike, Instituta za ortopedsko-hirurške bolesti "Banjica" od 2007. do 2008. godine. Od 2008. godine radi kao hirurg na Odeljenju ortopedije i traumatologije, KBC Zemun, gde ima bogato kliničko iskustvo.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'On redovno učestvuje na domaćim i međunarodnim kongresima kao autor i koautor. Takođe, objavio je radove u međunarodnim časopisima International Journal of Current Advanced Research i EC Orthopaedics.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-vojvodić'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-vojvodić'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-vojvodić'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-vojvodić'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-vojvodić'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-vojvodić'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-vojvodić'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-vojvodić'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'mladenMaksic',
    profileImage: mladenMaksic,
    alternative: 'una-bolnica-mladen-maksić',
    name: <>Dr Mladen Maksić</>,
    description: 'ortoped',
    service: 'ortopedija',
    seoInspector: {
      title: 'dr Mladen Maksić, ortoped, +381652024055',
      metaContent: 'dr Mladen Maksić, ortoped, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/mladen-maksic',
      h1: 'dr Mladen Maksić, ortoped, +381652024055',
      h2: 'dr Mladen Maksić, ortoped, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: mladenMaksic,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr Mladen Maksić je specijalista ortopedske hirurgije u Beogradu sa bogatim iskustvom u traumatologiji gornjih i donjih ekstremiteta. Svoje osnovne studije i specijalizaciju iz ortopedije sa traumatologijom je završio na Medicinskom fakultetu u Beogradu.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Stekao je vredno iskustvo kao lekar KK Partizan, gde je tretirao sportske povrede i pružao medicinsku podršku sportistima.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Dr Maksić je takođe poznat kao autor i koautor nekoliko naučnih radova iz oblasti ortopedije sa traumatologijom. Redovan je učesnik kongresa kako u zemlji, tako i u inostranstvu, gde deli svoje znanje i iskustvo sa kolegama i stručnjacima iz ove oblasti.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-mladen-maksić'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-mladen-maksić'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-mladen-maksić'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-mladen-maksić'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-mladen-maksić'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-mladen-maksić'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-mladen-maksić'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-mladen-maksić'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'vukVucic',
    profileImage: vukVucic,
    alternative: 'una-bolnica-vuk-vučić',
    name: <>Dr Vuk Vučić</>,
    description: 'ortoped',
    service: 'ortopedija',
    seoInspector: {
      title: 'dr Vuk Vučić, ortoped, +381652024055',
      metaContent: 'dr Vuk Vučić, ortoped, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/vuk-vucic',
      h1: 'dr Vuk Vučić, ortoped, +381652024055',
      h2: 'dr Vuk Vučić, ortoped, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: vukVucic,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Zamena kuka'
      },
      {
        serviceId: 2,
        serviceContent: 'Zamena kolena'
      },
      {
        serviceId: 3,
        serviceContent: 'Revizija artroplastične hirurgije'
      },
      {
        serviceId: 4,
        serviceContent: 'Opšta hirurgija gornjih ekstremiteta'
      },
      {
        serviceId: 5,
        serviceContent: 'Opšta hirurgija donjih ekstremiteta'
      },
      {
        serviceId: 6,
        serviceContent: 'Akutna hirurška trauma'
      },
      {
        serviceId: 7,
        serviceContent: 'Posttraumatska rekonstrukcija kostiju'
      },
      {
        serviceId: 8,
        serviceContent: 'Posttraumatska rekonstrukcija zglobova'
      },
      {
        serviceId: 9,
        serviceContent: 'Minimalno invazivni postupci u ortopedskoj hirurgiji'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr Vuk Vučić je stručnjak iz oblasti ortopedske hirurgije. Završio je osnovne studije na Medicinskom fakultetu u Beogradu 2014. godine, dok je specijalizaciju uspešno okončao 2010. godine, takođe na Medicinskom fakultetu u Beogradu.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Specijalizovan je za različite oblasti ortopedske hirurgije, uključujući hiruršku zamenu kuka i kolena, reviziju artroplastične hirurgije, opštu hirurgiju gornjih i donjih ekstremiteta, akutnu hiruršku traumu i posttraumatsku rekonstrukciju kostiju i zglobova.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Dr Vučić stiče radno iskustvo od 2007. godine i trenutno radi na Institutu za ortopedsku hirurgiju "Banjica" u Beogradu. Njegova istorija rada u bolnici i zdravstvenoj industriji potvrđuje njegovo stručno znanje i veštine.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Kao iskusni ortopedski hirurg, Dr Vuk Vučić je kvalifikovan u traumatološkoj hirurgiji, zameni zglobova, ortopedskoj hirurgiji, minimalno invazivnim postupcima i traumatologiji. Ukoliko tražite vrhunsku negu i tretman za svoje ortopedske probleme, možete se pouzdati u njegovo iskustvo i stručnost.'
      },
      {
        paragraphId: 5,
        paragraphContent: 
          'Zakažite svoj termin kod Dr Vuka Vučića, vodećeg specijaliste ortopedske hirurgije u Beogradu. Posetite nas ili nas kontaktirajte kako biste dobili vrhunsku medicinsku negu za svoje ortopedske potrebe.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-vuk-vučić'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-vuk-vučić'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-vuk-vučić'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-vuk-vučić'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-vuk-vučić'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-vuk-vučić'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-vuk-vučić'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-vuk-vučić'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'vladimirVranic',
    profileImage: vladimirVranic,
    alternative: 'una-bolnica-vladimir-vranić',
    name: <>Dr Vladimir Vranić</>,
    description: 'ortoped',
    service: 'ortopedija',
    seoInspector: {
      title: 'dr Vladimir Vranić, ortoped, +381652024055',
      metaContent: 'Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/vladimir-vranic',
      h1: 'dr Vladimir Vranić, ortoped, +381652024055',
      h2: 'dr Vladimir Vranić, ortoped, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: vladimirVranic,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr. Vladimir Vranić, doktor medicine i ortopedski hirurg, ima bogato medicinsko obrazovanje i profesionalno iskustvo. Diplomirao je na Medicinskom fakultetu Univerziteta u Beogradu i specijalizirao iz ortopedske hirurgije na Klinici za ortopedsku hirurgiju i traumatologiju Vojnomedicinske akademije. Trenutno je na poziciji načelnika Odeljenja za traumatologiju na istoj klinici.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Dr. Vranić je takođe ostvario značajan doprinos u akademskom polju kao asistent profesor na Katedri za hirurgiju Vojnomedicinske akademije. Pored toga, ima i međunarodno iskustvo, jer je radio kao ortopedski hirurg u Misiji Ujedinjenih nacija u Centralnoj Afričkoj Republici.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Tokom svoje karijere, dr. Vranić je stekao stručna zvanja i sertifikate, uključujući sertifikat Srpske komore ortopedskih hirurga. Za svoj angažman u mirovnoj operaciji u Centralnoj Afričkoj Republici dobio je priznanja i medalje od Ujedinjenih nacija i Ministarstva odbrane Republike Srbije.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Njegov naučni rad je takođe impresivan. Objavio je nekoliko studija u uglednim časopisima, istražujući vezu između vitamina D i genetskog predispozicije za osteoartritis. Njegovi radovi su dobili priznanje u naučnoj zajednici i doprinose boljem razumevanju ove bolesti.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-vladimir-vranić'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-vladimir-vranić'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-vladimir-vranić'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-vladimir-vranić'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-vladimir-vranić'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-vladimir-vranić'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-vladimir-vranić'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-vladimir-vranić'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'anaMalivukovic',
    profileImage: anaMalivukovic,
    alternative: 'una-bolnica-ana-malivuković',
    name: <>Dr Ana Malivuković</>,
    description: 'specijalista plastične, rekonstruktivne i estetske hirurgije',
    service: 'estetska i rekonstruktivna hirurgija',
    seoInspector: {
      title: 'dr Ana Malivuković, estetski hirurg, Una bolnica Beograd',
      metaContent: 
        "dr Ana Malivuković, specijalista plastične i rekonstruktivne hirurgije, Una bolnica Beograd, +381652024055",
      link: 'https://unabolnica.com/ana-malivukovic',
      h1: 'dr Ana Malivuković, estetski hirurg, Una bolnica Beograd',
      h2: 'dr Ana Malivuković, specijalista plastične i rekonstruktivne hirurgije, Una bolnica Beograd, +381652024055',
      thumbnailImage: anaMalivukovic,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Rekonstrukcija dojke'
      },
      {
        serviceId: 2,
        serviceContent: 'Estetska hirurgija dojke (povećanje, smanjenje, podizanje dojke)'
      },
      {
        serviceId: 3,
        serviceContent: 'Rekonstrukcija glave i vrata'
      },
      {
        serviceId: 4,
        serviceContent: 'Estetska hirurgija lica (lifting, rinoplastika, blefaroplastika, liposukcija vrata itd.)'
      },
      {
        serviceId: 5,
        serviceContent: 'Rekonstrukcija kože (nakon opekotina, trauma, tumora)'
      },
      {
        serviceId: 6,
        serviceContent: 'Rekonstrukcija ruku i šake'
      },
      {
        serviceId: 7,
        serviceContent: 'Telooblikujuće procedure (abdominoplastika, liposukcija, podizanje zadnjice itd.)'
      },
      {
        serviceId: 8,
        serviceContent: 'Estetska hirurgija nosa (rinoplastika)'
      },
      {
        serviceId: 9,
        serviceContent: 'Estetska hirurgija ušiju (otoplastika)'
      },
      {
        serviceId: 10,
        serviceContent: 'Estetska hirurgija genitalnih regija'
      },
      {
        serviceId: 11,
        serviceContent: 'Botox i dermalni fileri'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr Ana Malivuković je stručnjak za plastičnu, rekonstruktivnu i estetsku hirurgiju. Diplomirala je na Medicinskom fakultetu Univerziteta u Beogradu 2012. godine. Nakon toga je završila specijalizaciju iz oblasti plastične, rekonstruktivne i estetske hirurgije na Vojnomedicinskoj akademiji.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Dr Ana Malivuković je takođe upisala doktorske studije na Medicinskom fakultetu u Beogradu 2014. godine, fokusirajući se na rekonstruktivnu hirurgiju. Njen rad obuhvata područja rekonstrukcije dojke, rekonstruktivne hirurgije glave i vrata, kao i estetske hirurgije tela i lica. Takođe je stekla mikrohirurški trening i veštine. Poseduje značajno iskustvo u antiaging medicini.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Dr Ana Malivuković je kontinuirano usavršavanje na polju plastične i rekonstruktivne hirurgije. Pohađala je specijalizovane kurseve i radila na klinikama i institutima u inostranstvu, uključujući Sant’Andrea Rim na Univerzitetu "Sapienza" u Italiji i Klinički centar Slovenije u Ljubljani.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Uz svoje profesionalno angažovanje, dr Ana Malivuković je učestvovala na mnogim kongresima, dobila stipendije i nagrade, te je autorka medicinskih publikacija.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-ana-malivukovic'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-ana-malivukovic'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-ana-malivukovic'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-ana-malivukovic'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-ana-malivukovic'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-ana-malivukovic'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-ana-malivukovic'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-ana-malivukovic'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'igorMaljkovic',
    profileImage: igorMaljkovic,
    alternative: 'una-bolnica-igor-maljković',
    name: <>Dr Igor Maljković</>,
    description: 'specijalista plastične hirurgije',
    service: 'estetska i rekonstruktivna hirurgija',
    seoInspector: {
      title: 'dr Igor Maljković, estetski hirurg, Una bolnica, +381652024055',
      metaContent: 'dr Igor Maljković, specijalista plastične hirurgije, Una bolnica Beograd, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/igor-maljkovic',
      h1: 'dr Igor Maljković, estetski hirurg, Una bolnica, +381652024055',
      h2: 'dr Igor Maljković, specijalista plastične hirurgije, Una bolnica Beograd, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: igorMaljkovic,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Liposukcija (usisavanje masnog tkiva)'
      },
      {
        serviceId: 2,
        serviceContent: 'Abdominoplastika (zatezanje trbuha)'
      },
      {
        serviceId: 3,
        serviceContent: 'Povećanje ili smanjenje dojki (implantati, mastektomija)'
      },
      {
        serviceId: 4,
        serviceContent: 'Podizanje dojki (mastopeksija)'
      },
      {
        serviceId: 5,
        serviceContent: 'Korekcija asimetrije dojki'
      },
      {
        serviceId: 6,
        serviceContent: 'Body lift (podizanje i zatezanje kože na telu)'
      },
      {
        serviceId: 7,
        serviceContent: 'Konturiranje tela (butt lift, lipofiling)'
      },
      {
        serviceId: 8,
        serviceContent: 'Rekonstrukcija dojke nakon mastektomije'
      },
      {
        serviceId: 9,
        serviceContent: 'Rekonstrukcija lica i vrata (nakon povreda ili urođenih deformiteta)'
      },
      {
        serviceId: 10,
        serviceContent: 'Rekonstrukcija ekstremiteta (ruke, noge)'
      },
      {
        serviceId: 11,
        serviceContent: 'Rekonstrukcija kože nakon opekotina ili tumora'
      },
      {
        serviceId: 12,
        serviceContent: 'Facelift (lifting lica)'
      },
      {
        serviceId: 13,
        serviceContent: 'Blefaroplastika (operacija kapaka)'
      },
      {
        serviceId: 14,
        serviceContent: 'Rinoplastika (korekcija nosa)'
      },
      {
        serviceId: 15,
        serviceContent: 'Korekcija usana (povećanje, smanjenje, oblikovanje)'
      },
      {
        serviceId: 16,
        serviceContent: 'Otoplastika (korekcija ušiju)'
      },
      {
        serviceId: 17,
        serviceContent: 'Korekcija ožiljaka'
      },
      {
        serviceId: 18,
        serviceContent: 'Tretmani protiv celulita'
      },
      {
        serviceId: 19,
        serviceContent: 'Transplatacija kose'
      },
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr Igor Maljković je specijalista plastične hirurgije sa impresivnim iskustvom koje traje već preko 20 godina. Svoje medicinsko obrazovanje započeo je na Medicinskom fakultetu u Beogradu, gde je uspešno završio studije 1997. godine. Nakon toga, posvetio se specijalizaciji iz plastične i rekonstruktivne hirurgije na Vojnomedicinskoj akademiji (VMA) u Beogradu.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Dr Maljković je aktivan član mnogih stručnih udruženja, uključujući Srpsko udruženje za plastičnu, rekonstruktivnu i estetsku hirurgiju (SRBPRAS), Balkansko udruženje za plastičnu, rekonstruktivnu i estetsku hirurgiju (BAPRAS), Lekarsku komoru Srbije i Srpsko lekarsko društvo.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Od 2017. godine, Dr Maljković radi u Opštoj bolnici Una, Beograd, gde obavlja funkciju specijaliste plastične i estetske hirurgije. Njegovo bogato iskustvo i stručnost čine ga pouzdanim stručnjakom za širok spektar plastičnih hirurških postupaka i procedura.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-igor-maljković'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-igor-maljković'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-igor-maljković'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-igor-maljković'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-igor-maljković'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-igor-maljković'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-igor-maljković'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-igor-maljković'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'biljanaMitric',
    profileImage: biljanaMitric,
    alternative: 'una-bolnica-biljana-mitrić',
    name: <>Dr Biljana Mitrić</>,
    description: 'plastični i rekonstruktivni hirurg',
    service: 'estetska i rekonstruktivna hirurgija',
    seoInspector: {
      title: 'dr Biljana Mitrić, estetski hirurg, Una bolnica, +381652024055',
      metaContent: 'Opšta bolnica Una, spinalna hirurgija, estetska hirugija, ortopedija, ORL i druge. Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/biljana-mitric',
      h1: 'dr Biljana Mitrić, estetski hirurg, Una bolnica, +381652024055',
      h2: 'dr Biljana Mitrić, plastični i rekonstruktivni hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: biljanaMitric,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Rekonstrukcija dojke'
      },
      {
        serviceId: 2,
        serviceContent: 'Rekonstrukcija lica'
      },
      {
        serviceId: 3,
        serviceContent: 'Rekonstrukcija vrata'
      },
      {
        serviceId: 4,
        serviceContent: 'Rekonstrukcija ekstremiteta'
      },
      {
        serviceId: 5,
        serviceContent: 'Liposukcija'
      },
      {
        serviceId: 6,
        serviceContent: 'Abdominoplastika'
      },
      {
        serviceId: 7,
        serviceContent: 'Povećanje i smanjenje dojki'
      },
      {
        serviceId: 8,
        serviceContent: 'Korekcija nosa'
      },
      {
        serviceId: 9,
        serviceContent: 'Podizanje lica i vrata'
      },
      {
        serviceId: 10,
        serviceContent: 'Korigovanje ožiljaka'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr Biljana Mitrić je priznati plastični i rekonstruktivni hirurg sa preko 15 godina iskustva. Sa diplomom iz plastične i rekonstruktivne hirurgije i brojnim specijalizacijama, ona je stručnjak koji se ističe svojom veštinom i posvećenošću pacijentima.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Sa iskustvom koje je stekla od 2006. godine, Dr Mitrić je posebno iskusna u rekonstrukciji dojke, lica, vrata i ekstremiteta. Takođe je stručnjak za estetske procedure kao što su liposukcija, abdominoplastika i povećanje/smanjenje dojki. Nudi i nehirurške tretmane za poboljšanje izgleda kože.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Kontaktirajte Dr Biljanu Mitrić danas kako biste zakazali konsultaciju i ostvarili svoje estetske ciljeve. Sa njenim bogatim iskustvom i stručnošću, možete biti sigurni da ste u dobrim rukama.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-biljana-mitrić'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-biljana-mitrić'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-biljana-mitrić'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-biljana-mitrić'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-biljana-mitrić'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-biljana-mitrić'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-biljana-mitrić'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-biljana-mitrić'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'milosGacevic',
    profileImage: milosGacevic,
    alternative: 'una-bolnica-miloš-gačević',
    name: <>Dr Miloš Gačević</>,
    description: 'plastični i rekonstruktivni hirurg',
    service: 'estetska i rekonstruktivna hirurgija',
    seoInspector: {
      title: 'dr Miloš Gačević, estetski hirurg, Una bolnica, +381652024055',
      metaContent: 'dr Miloš Gačević, plastični i rekonstruktivni hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/milos-gacevic',
      h1: 'dr Miloš Gačević, estetski hirurg, Una bolnica, +381652024055',
      h2: 'dr Miloš Gačević, plastični i rekonstruktivni hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: milosGacevic,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Osnovne akademske studije medicine završio je na Fakultetu medicinskih nauka u Kragujevcu 2010. godine, a specijalizaciju iz oblasti plastične, estetske i rekonstruktivne hirurgije na Vojnomedicinskoj akademiji.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Svoje profesionalno radno iskustvo stekao je radom u privatnim i državnim ustanovama.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Aktivno se edukuje u oblasti plastične hirurgije i redovno posećuje domaće i međunarodne kongrese od značaja.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-miloš-gačević'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-miloš-gačević'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-miloš-gačević'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-miloš-gačević'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-miloš-gačević'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-miloš-gačević'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-miloš-gačević'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-miloš-gačević'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'milosVucetic',
    profileImage: milosVucetic,
    alternative: 'una-bolnica-miloš-vučetić',
    name: <>Dr Miloš Vučetić</>,
    description: 'plastični i rekonstruktivni hirurg',
    service: 'estetska i rekonstruktivna hirurgija',
    seoInspector: {
      title: 'dr Miloš Vučetić, estetski hirurg, Una bolnica, +381652024055',
      metaContent: 'dr Miloš Vučetić, plastični i rekonstruktivni hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/milos-vucetic',
      h1: 'dr Miloš Vučetić, estetski hirurg, Una bolnica, +381652024055',
      h2: 'dr Miloš Vučetić, plastični i rekonstruktivni hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: milosVucetic,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Osnovne studije završio je na Medicinskom fakultetu u Beogradu, 2006. godine; Specijalističke akademske studije 2013. godine, a specijalizaciju 2017. godine, takođe na Medicinskom fakultetu u Beogradu.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Dominantne oblasti stručnog rada: plastična, rekonstruktivna i estetska hirurgija.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Član mnogobrojnih udruženja. Trenutno zaposlen u privatnoj bolnici Una.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-miloš-vučetić'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-miloš-vučetić'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-miloš-vučetić'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-miloš-vučetić'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-miloš-vučetić'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-miloš-vučetić'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-miloš-vučetić'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-miloš-vučetić'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'simonPandjaitan',
    profileImage: simonPandjaitan,
    alternative: 'una-bolnica-simon-pandjaitan',
    name: <>Dr Simon Pandjaitan</>,
    description: 'plastični i rekonstruktivni hirurg',
    service: 'estetska i rekonstruktivna hirurgija',
    seoInspector: {
      title: 'dr Simon Pandjaitan, estetski hirurg, Una bolnica, +381652024055',
      metaContent: 'dr Simon Pandjaitan, plastični i rekonstruktivni hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/simon-pandjaitan',
      h1: 'dr Simon Pandjaitan, estetski hirurg, Una bolnica, +381652024055',
      h2: 'dr Simon Pandjaitan, plastični i rekonstruktivni hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: simonPandjaitan,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          '1990. godine je završio Medicinski fakultet u Beogradu. 1996. godine je specijalizirao iz plastične i rekonstruktivne hirurgije na Vojnomedicinskoj akademiji u Beogradu. Stručno se usavršavao u Francuskoj, Austriji, Mađarskoj, Grčkoj, Italiji i Češkoj. Učestvovao u mnogim naučnim seminarima i kongresima u Hrvatskoj, Turskoj, Sloveniji, Rumuniji, Nemačkoj i Indoneziji (Bali).'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Član je IPRAS (Svetsko udruženje plastičnih hirurga) i SRBPRAS (Udruženje plastičnih hirurga Srbije). Takođenje osnivač i član upravnog odbora Srpskog udruženja za lečenje rana.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Od 1996. je glavni hirurg u privatnoj bolnici ST Medica u Beogradu, a 2008. godine postaje vlasnik svoje privatne klinike “Pandja i Maraš” u Beogradu. Trenutno zaposlen u Opštoj bolnici “Una”.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-simon-pandjaitan'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-simon-pandjaitan'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-simon-pandjaitan'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-simon-pandjaitan'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-simon-pandjaitan'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-simon-pandjaitan'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-simon-pandjaitan'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-simon-pandjaitan'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'urosRadivojcevic',
    profileImage: urosRadivojcevic,
    alternative: 'una-bolnica-uroš-radivojčević',
    name: <>Dr Uroš Radivojčević</>,
    description: 'plastični i rekonstruktivni hirurg',
    service: 'estetska i rekonstruktivna hirurgija',
    seoInspector: {
      title: 'dr Uroš Radivojčević, estetski hirurg, Una bolnica, +381652024055',
      metaContent: 'dr Uroš Radivojčević, plastični i rekonstruktivni hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/uros-radivojcevic',
      h1: 'dr Uroš Radivojčević, estetski hirurg, Una bolnica, +381652024055',
      h2: 'dr Uroš Radivojčević, plastični i rekonstruktivni hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: urosRadivojcevic,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Načelnik odeljenja opekotina u centru za plastičnu hirurgiju KC „Kragujevac“ i asistent na katedri „Hirurgija“ Fakulteta medicinskih nauka u Kragujevcu.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Specijalizaciju je obavio na klinici za plastičnu hirurgiju i opekotine „VMA“, uz konstantnu edukaciju u UKC „Ljubljana“, gde se usavršavao posebno iz oblasti rekonstruktivne mikrohirurgije i hirurgije dojke kao i na IOHB „Banjica“ za hirurgiju šake.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-uroš-radivojčević'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-uroš-radivojčević'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-uroš-radivojčević'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-uroš-radivojčević'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-uroš-radivojčević'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-uroš-radivojčević'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-uroš-radivojčević'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-uroš-radivojčević'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'zeljkoCurcic',
    profileImage: zeljkoCurcic,
    alternative: 'una-bolnica-željko-ćurčić',
    name: <>Dr Željko Ćurčić</>,
    description: 'plastični i rekonstruktivni hirurg',
    service: 'estetska i rekonstruktivna hirurgija',
    seoInspector: {
      title: 'dr Željko Ćurčić, estetski hirurg, Una bolnica, +381652024055',
      metaContent: 'dr Željko Ćurčić, plastični i rekonstruktivni hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/zeljko-curcic',
      h1: 'dr Željko Ćurčić, estetski hirurg, Una bolnica, +381652024055',
      h2: 'dr Željko Ćurčić, plastični i rekonstruktivni hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: zeljkoCurcic,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Studirao je na Medicinskom fakultetu u Beogtadu, gde je završio osnovne studije 1989. godine, specijalizirao 1997. godine i završio master studije završio je 2003. godine.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'U dodatne oblasti njegovog stručnog rada spadaju: hirurgija šake i rekonstruktivna hirurgija ekstremiteta'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Trenutno radi u privatnoj bolnici “Una“.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-željko-ćurčić'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-željko-ćurčić'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-željko-ćurčić'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-željko-ćurčić'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-željko-ćurčić'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-željko-ćurčić'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-željko-ćurčić'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-željko-ćurčić'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'zeljkoStanojlovic',
    profileImage: zeljokoStanojlovic,
    alternative: 'una-bolnica-željko-stanojlović',
    name: <>Dr Željko Stanojlović</>,
    description: 'plastični i rekonstruktivni hirurg',
    service: 'estetska i rekonstruktivna hirurgija',
    seoInspector: {
      title: 'dr Željko Stanojlović, estetski hirurg, +381652024055',
      metaContent: 'dr Željko Stanojlović, plastični i rekonstruktivni hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/zeljko-stanojlovic',
      h1: 'dr Željko Stanojlović, estetski hirurg, +381652024055',
      h2: 'dr Željko Stanojlović, plastični i rekonstruktivni hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: zeljokoStanojlovic,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Završio je studije medicine na Medicinskom fakultetu u Beogradu, gde je i specijalizirao iz oblasti plastične, rekonstruktivne i estetske hirurgije. U toku specijalizacije i tokom specijalističke prakse usavršavao je se na više domaćih klinika (Klinika za plastičnu hirurgiju KC Srbije, Institut za onkologiju i radiologiju Srbije, Institut za ortopediju Banjica, Univerzitetska Dečja Klinika u Tiršovoj) kao i svetskih Klinika (Evropski institut za onkologiju, Milano Italija, Klinika za plastičnu i rekonstruktivnu hirurgiju KC Ljubljana, Slovenija, AKH Klinika Beč, Assaf Harofeh Medical Center, Tel Aviv i Ella Clinic, Tel Aviv).'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Redovni je učesnik kongresa i radionica, kako domaćih tako i inostranih iz oblasti plastične, rekonstruktivne i estetske hirurgije, sa posebnim interesovanjem za oblast podmlađivanja lica, hirurgiju dojke i liposkulpture.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-željko-stanojlović'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-željko-stanojlović'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-željko-stanojlović'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-željko-stanojlović'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-željko-stanojlović'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-željko-stanojlović'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-željko-stanojlović'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-željko-stanojlović'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'ljubodragMinic',
    profileImage: ljubodragMinic,
    alternative: 'una-bolnica-ljubodrag-minić',
    name: <>Prof. dr Ljubodrag Minić</>,
    description: 'specijalista neurohirurgije',
    service: 'neurohirurgija',
    seoInspector: {
      title: 'dr Ljubodrag Minić, neurohirurg, spinalni hirurg, Una bolnica',
      metaContent: 
        "Prof. dr Ljubodrag Minić, specijalista neurohirurgije, pukovnik VMA, lumbalna diskus hernija, Una bolnica Beograd, +381652024055",
      link: 'https://unabolnica.com/ljubodrag-minic',
      h1: 'dr Ljubodrag Minić, neurohirurg, spinalni hirurg, Una bolnica',
      h2: 'Prof. dr Ljubodrag Minić, specijalista neurohirurgije, pukovnik VMA, lumbalna diskus hernija, Una bolnica Beograd, +381652024055',
      thumbnailImage: ljubodragMinic,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Hirurgija tumora mozga'
      },
      {
        serviceId: 2,
        serviceContent: 'Hirurgija kičme'
      },
      {
        serviceId: 3,
        serviceContent: 'Cerebrovaskularna hirurgija'
      },
      {
        serviceId: 4,
        serviceContent: 'Hirurgija perifernih nerava'
      },
      {
        serviceId: 5,
        serviceContent: 'Hidrocefalus'
      },
      {
        serviceId: 6,
        serviceContent: 'Epilepsija'
      },
      {
        serviceId: 7,
        serviceContent: 'Trauma glave'
      },
      {
        serviceId: 8,
        serviceContent: 'Stereotaktička hirurgija'
      },
      {
        serviceId: 9,
        serviceContent: 'Neuroendoskopija'
      },
      {
        serviceId: 10,
        serviceContent: 'Degenerativne bolesti kičme'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Prof. dr Ljubodrag Minić je priznati specijalista neurohirurgije i pukovnik Vojnomedicinske akademije sa bogatim iskustvom i impresivnom naučnom pozadinom. Osnovne studije je završio na Fakultetu medicinskih nauka Univerziteta u Kragujevcu 1983. godine, dok je specijalizaciju izvršio na Medicinskom fakultetu Vojnomedicinske Akademije 1991. godine.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Kao deo svog profesionalnog razvoja, pohađao je kurs neurohirurške primene UZ aspiratora na Univerzitetu u Pragu i usavršavao se iz oblasti cerebrovaskularne hirurgije na Kliničkom centru Ljubljana.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Prof. dr Ljubodrag Minić je stekao značajno radno iskustvo radeći kako u privatnim, tako i u državnim ustanovama. Njegovo stručno znanje prepoznato je kroz više od 33 naučna rada objavljena kako u zemlji, tako i u inostranstvu.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Pored svoje lekarske prakse, Prof. dr Ljubodrag Minić je posvećen i pedagoškom radu. Trenutno je redovni profesor na Medicinskom fakultetu Vojnomedicinske akademije, gde prenosi svoje znanje i iskustvo budućim generacijama lekara.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljubodrag-minić'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljubodrag-minić'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljubodrag-minić'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljubodrag-minić'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljubodrag-minić'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljubodrag-minić'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljubodrag-minić'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljubodrag-minić'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'goranPavlicevic',
    profileImage: goranPavlicevic,
    alternative: 'una-bolnica-goran-pavlićević',
    name: <>Dr Goran Pavlićević</>,
    description: 'specijalista neurohirurgije',
    service: 'neurohirurgija',
    seoInspector: {
      title: 'dr Goran Pavlićević, neurohirurg, Una bolnica, operacija kičme',
      metaContent: 
        "dr Goran Pavlićević, specijalista neurohirurgije, pukovnik VMA, operacija kičme, Una bolnica Beograd, +381652024055",
      link: 'https://unabolnica.com/goran-pavlicevic',
      h1: 'dr Goran Pavlićević, neurohirurg, Una bolnica, operacija kičme',
      h2: 
        'dr Goran Pavlićević, specijalista neurohirurgije, pukovnik VMA, operacija kičme, Una bolnica Beograd, +381652024055',
      thumbnailImage: goranPavlicevic,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Lumbalna diskus hernija'
      },
      {
        serviceId: 2,
        serviceContent: 'Cervikalna diskus hernija - Vratna diskus hernija'
      },
      {
        serviceId: 3,
        serviceContent: 'Spondilolisteza'
      },
      {
        serviceId: 4,
        serviceContent: 'Degenerativna lumbalna skolioza'
      },
      {
        serviceId: 5,
        serviceContent: 'Kifoza vratne kičme'
      },
      {
        serviceId: 6,
        serviceContent: 'Stenoza kičmenog kanala'
      },
      {
        serviceId: 7,
        serviceContent: 'Osteoporotični prelomi pršljenova'
      },
      {
        serviceId: 8,
        serviceContent: 'Povrede kičmenog stuba'
      },
      {
        serviceId: 9,
        serviceContent: 'Tumori kičme'
      },
      {
        serviceId: 10,
        serviceContent: 'Zapaljenski procesi na kičmenom stubu'
      },  
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'dr Goran Pavlićević je naš renomirani specijalista neurohirurgije, spinalni hirurg, pukovnik Vojnomedicinske akademije, načelnik klinike za neurohirurgiju VMA i nastavnik na Medicinskom fakultetu Vojnomedicinske akademije, Univerziteta odbrane.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
        'Rođen 1966. godine u Beogradu gde je završio osnovnu i srednju školu kao i medicinski fakultet. Specijalistički ispit položio 2001. godine u Vojnomedicinskoj akademiji, gde je i nastavio da radido 2011. godine kao lekar specijalista u klinici za neurohirurgiju, a od 2011. kao načelnik kabineta za neurohirurgiju a od 2017. godine kao načelnik klinike za neurohirurgiju. Doktorske studije završio 2012. godine na Medicinskom fakultetu Univerziteta u Kragujevcu, a od 2013. godine je nastavnik na Medicinskom fakultetu Vojnomedicinske akademije, Univerziteta odbrane.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
        'U više navrata se usavršavao iz oblasti spinalne hirurgije, stereotaksne i fukncionalne neurohirurgije kao i iz vaskularne neurohirurgijue u Nemačkoj, SAD, Švajcarskoj i Francuskoj.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
        'Obrazovanje: Osnovne studije - Medicinski fakultet Univerziteta u Beogradu 1993; Specijalizacija - Medicinski fakultet Vojnomedicinske akademije 2001; Doktorske studije - Medicinski fakultet Univerziteta u Kragujevcu 2012.'
      },
      {
        paragraphId: 5,
        paragraphContent: 
        'Radno iskustvo: Klinika za neurohirurgiju VMA od 2001. - specijalista; Kabinet za neurohirurgiju VMA od 2011. - načelnik; Klinika za neurohirurgiju VMA od 2017. načelnik'
      },
      {
        paragraphId: 6,
        paragraphContent: 
        'Članstvo: Udruženje neurohirurga Srbije od 2018 - član izvršnog odbora; Evropska asoscijacija neurohirurga od 2008 - član; Svetska federacija neurohirurških udruženja od 2008 član; AO SPINE od 2009 - član'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-goran-pavlićević'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-goran-pavlićević'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-goran-pavlićević'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-goran-pavlićević'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-goran-pavlićević'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-goran-pavlićević'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-goran-pavlićević'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-goran-pavlićević'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'nenadNovakovic',
    profileImage: nenadNovakovic,
    alternative: 'una-bolnica-nenad-novaković',
    name: <>Dr Nenad Novaković</>,
    description: 'specijalista neurohirurgije',
    service: 'neurohirurgija',
    seoInspector: {
      title: 'dr Nenad Novaković, neurohirurg, Una bolnica, operacija kičme',
      metaContent: 
        "dr Nenad Novaković, specijalista neurohirurgije, potpukovnik VMA, diksus hernija, Una bolnica Beograd, +381652024055",
      link: 'https://unabolnica.com/nenad-novakovic',
      h1: 'dr Nenad Novaković, neurohirurg, Una bolnica, operacija kičme',
      h2: 
        'dr Nenad Novaković, specijalista neurohirurgije, potpukovnik VMA, diksus hernija, Una bolnica Beograd, +381652024055',
      thumbnailImage: nenadNovakovic,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Medicinski fakultet Univerziteta u Beogradu završio je 2000. godine.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Nakon lekarskog staža u KBC – Zvezdara, u periodu od 2002. do 2008. godine specijalizirao je neurohirurgiju u Klinici za neurohirurgiju Vojnomedicinske Akademije u Beogradu. Stručno se usavršavao u oblastima hirurgije hidrocefalusa, endoskopske hirurgije diskus hernije i funkcionalne neurohirgije. Doktorske akademske studije iz oblasti Rekonstruktivne hirurgije završio je na Medicinskom fakultetu u Beogradu.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Uspešno je položio specjalistički ispit, posle čega je na neodređeno zaposlen u Klinici za neurohirurgiju Vojnomedicinske Akademije kao specijalista – neurohirurg. Od 2005. godine aktivni oficir Vojske Srbije, aprila 2016. godine unapređen je u čin potpukovnika.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Do sada je objavio šest radova u međunarodnim časopisima  i 4 rada štampana u izvodu u međunarodnim i nacionalnim časopisima.'
      },
      {
        paragraphId: 5,
        paragraphContent: 
          'Član je izvršnog odbora Udruženja neurohirurga Srbije (UNHS), kao i član neurohirurške sekcija Srpskog lekarskog Društva, udruženja neurohirurga jugoistočne Evrope (SeENS) i Lekarske Komora Srbije (LKS).'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-nenad-novaković'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-nenad-novaković'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-nenad-novaković'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-nenad-novaković'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-nenad-novaković'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-nenad-novaković'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-nenad-novaković'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-nenad-novaković'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'miljanMihajlovic',
    profileImage: miljanMihajlovic,
    alternative: 'una-bolnica-miljan-mihajlović',
    name: <>Prim. dr sc. med. Miljan<br />Mihajlović</>,
    description: 'specijalista neurohirurgije',
    service: 'neurohirurgija',
    seoInspector: {
      title: 'dr Miljan Mihajlović, neurohirurg, +381652024055',
      metaContent: 'dr Miljan Mihajlović, neurohirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/miljan-mihajlovic',
      h1: 'dr Miljan Mihajlović, neurohirurg, +381652024055',
      h2: 'dr Miljan Mihajlović, neurohirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: miljanMihajlovic,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'kompletno endoskopska interlaminarna - discektomija'
      },
      {
        serviceId: 2,
        serviceContent: 'tubularna mikrodiscektomija'
      },
      {
        serviceId: 3,
        serviceContent: 'lumbalna laminoforaminotomija'
      },
      {
        serviceId: 4,
        serviceContent: 
          'minimalno invazivna terapija bola (periradikularne infiltracije, infiltracije fasetnih zglobova, epiduralna terapija, kaudalni blok)'
      },
      {
        serviceId: 5,
        serviceContent: 'operativno lečenje tumora CNS i spinalnih tumora'
      },
      {
        serviceId: 6,
        serviceContent: 'operativno lečenje urgentnih stanja CNS (hidrocefalus, hematomi i dr.)'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Prim. Dr sci med. Miljan Mihajlović je istaknuti specijalista neurohirurgije sa bogatim radnim iskustvom. Trenutno je zaposlen kao Načelnik Neurohirurške Službe u KBC Zemun, od 2019. godine. Prethodno je radio na Univerzitetskoj dečjoj Klinici u Beogradu od 2003. do 2016. godine, a takođe je stekao iskustvo tokom specijalizacije iz oblasti neurohirurgije u Kliničkom Centru Srbije, Beograd, od 1997. do 2003. godine. Takođe je radio u Zdravstvenom Centru Kosovska Mitrovica od 1993. do 1997. godine.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Dr. Mihajlović je završio osnovne studije na Medicinskom fakultetu Univerziteta u Prištini 1993. godine, a specijalizaciju iz neurohirurgije uspešno je završio sa odličnom ocenom 2003. godine u Kliničkom Centru Srbije, Beograd. Pored toga, postigao je magisterijum iz neurohirurgije na Medicinskom fakultetu Univerziteta u Beogradu 2003. godine, a doktorat iz iste oblasti 2013. godine. Njegova stručna biografija obuhvata različite oblasti neurohirurgije, uključujući lečenje hroničnih subduralnih hematoma kod odraslih i probleme hirurškog lečenja hidrocefalusa kod prevremeno rođene dece.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Dr. Mihajlović je takođe dobio zvanje primarijusa 2013. godine, što potvrđuje njegovu visoku stručnost i posvećenost medicinskom polju. On je autor i koautor više stručno-naučnih radova, publikacija i prezentacija, objavljenih u internacionalnim i domaćim časopisima, kao i referisanih na međunarodnim i domaćim kongresima i simpozijumima.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Kao neurohirurg u bolnici Una, Dr. Mihajlović obavlja širok spektar procedura i operacija, uključujući kompletno endoskopsku interlaminarnu discektomiju, tubularnu mikrodiscektomiju, lumbalnu laminoforaminotomiju, minimalno invazivnu terapiju bola i operativno lečenje tumora CNS i spinalnih tumora. Takođe se bavi operativnim lečenjem urgentnih stanja CNS, poput hidrocefalusa i hematoma.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-miljan-mihajlović'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-miljan-mihajlović'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-miljan-mihajlović'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-miljan-mihajlović'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-miljan-mihajlović'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-miljan-mihajlović'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-miljan-mihajlović'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-miljan-mihajlović'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'andrijaSavic',
    profileImage: andrijaSavic,
    alternative: 'una-bolnica-andrija-savić',
    name: <>Dr Andrija Savić</>,
    description: 'specijalista neurohirurgije',
    service: 'neurohirurgija',
    seoInspector: {
      title: 'dr Andrija Savić, neurohirurg, Una bolnica',
      metaContent: 
        "dr Andrija Savić, specijalista neurohirurgije, povreda perifernih nerava, Una bolnica Beograd, +381652024055",
      link: 'https://unabolnica.com/andrija-savic',
      h1: 'dr Andrija Savić, neurohirurg, hirurgija perifernih nerava',
      h2: 
        'dr Andrija Savić, specijalista neurohirurgije, povreda perifernih nerava, Una bolnica Beograd, +381652024055',
      thumbnailImage: andrijaSavic,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Andrija Savić je vrhunski neurohirurg sa impresivnim obrazovanjem i obimnim profesionalnim iskustvom. Diplomirao je na Medicinskom Fakultetu Univerziteta u Beogradu stičući titulu doktora medicine. Od 2007. godine radi na Klinici za Neurohirurgiju Univerzitetskog Kliničkog Centra Srbije.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Njegovo stručno usavršavanje u oblasti neurohirurgije obuhvata subspecijalizaciju za hirurgiju brahijalnog pleksusa i perifernog nervnog sistema. Kroz stručna usavršavanja u Americi, Meksiku, Nemačkoj i Indiji, stekao je dragoceno iskustvo i ekspertizu u ovoj oblasti. Godine 2018. završio je specijalizaciju iz neurohirurgije.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Dr Savić je doktorsku disertaciju na temu "Distalni nervni transferi kod povreda i oboljenja perifernog nervnog sistema" uspešno odbranio 2019. godine, stekavši titulu doktora nauka. Njegovo stručno angažovanje se proširilo i na Medicinski Fakultet Univerziteta u Beogradu, gde je 2020. godine izabran za Kliničkog Asistenta na katedri Hirurgija sa Anesteziologijom.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Kroz svoju karijeru, dr Savić je prošao kroz različite oblike usavršavanja, uključujući fellowship iz hirurgije baze lobanje kod Prof. Marcosa Tatagibe u Tubingenu, Nemačka, i fellowship iz kompleksne hirurgije kičmenog stuba kod profesora Massima Balsana u Veroni, Italija. Njegovi stručni radovi u oblasti hirurgije perifernog nervnog sistema, kičmenog stuba i baze lobanje su višestruko nagrađivani od strane evropskog i svetskog udruženja neurohirurga.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-andrija-savić'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-andrija-savić'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-andrija-savić'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-andrija-savić'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-andrija-savić'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-andrija-savić'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-andrija-savić'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-andrija-savić'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'radomirBenovic',
    profileImage: radomirBenovic,
    alternative: 'una-bolnica-radomir-benović',
    name: <>Dr Radomir Benović</>,
    description: 'neurohirurg',
    service: 'neurohirurgija',
    seoInspector: {
      title: 'dr Radomir Benović, neurohirurg, Una bolnica Beograd, +381652024055',
      metaContent: 'dr Radomir Benović, neurohirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/radomir-benovic',
      h1: 'dr Radomir Benović, neurohirurg, Una bolnica Beograd, +381652024055',
      h2: 'dr Radomir Benović, neurohirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: radomirBenovic,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr. Radomir Benović diplomirao je na Medicinskom fakultetu Univerziteta u Beogradu 1991. godine, stekavši zvanje doktora medicine. Nakon specijalizacije iz neurohirurgije na Institutu za neurohirurgiju Kliničkog centra Srbije pod mentorstvom Prof. dr sci. med. Danice Grujičić, položio je završni ispit 2000. godine i postao specijalista neurohirurgije.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Od 2001. godine, dr. Benović je stalno zaposlen kao neurohirurg na Neurohirurškom odeljenju KBC Zemun u Beogradu. U tom periodu, aktivno je učestvovao na nacionalnim i međunarodnim skupovima neurohirurga, prezentujući svoje radove i doprinoseći razmeni znanja u ovoj oblasti.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Godine 2005. dobio je poziv za zaposlenje kao konsultant neurohirurg u Military Hospital of South Region u Kamish Mushaitu, Saudijska Arabija, gde je proveo godinu dana proširujući svoje iskustvo.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Nakon povratka u Srbiju, dr. Benović se ponovno zaposlio u KBC Zemun. 2010. godine stekao zvanje primarijusa 2013. Trenutno je na poziciji šefa Opšte neurohirurgije u KBC Zemun.'
      },
      {
        paragraphId: 5,
        paragraphContent: 
          'Osim toga, dr. Benović je i stalni sudski medicinski veštak od 2015. godine. Od 2018. godine radi kao neurohirurg na Klinici za neurohirurgiju Vojnomedicinske akademije (VMA) u Beogradu, a od 2019. godine dopunski radi u Opštoj bolnici Una.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-radomir-benović'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-radomir-benović'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-radomir-benović'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-radomir-benović'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-radomir-benović'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-radomir-benović'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-radomir-benović'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-radomir-benović'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'nenadArsovic',
    profileImage: nenadArsovic,
    alternative: 'una-bolnica-nenad-arsović',
    name: <>Prof. dr Nenad Arsović</>,
    description: 'specijalista otorinolaringologije',
    service: 'otorinolaringologija',
    seoInspector: {
      title: 'dr Nenad Arsović, otorinolaringolog, +381652024055',
      metaContent: 'dr Nenad Arsović, otorinolaringolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/nenad-arsovic',
      h1: 'dr Nenad Arsović, otorinolaringolog, +381652024055',
      h2: 'dr Nenad Arsović, otorinolaringolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: nenadArsovic,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Specijalista otorinolaringologije sa višegodišnjim radnim iskustvom. Svoju profesionalnu karijeru gradio je radom u privatnim i državnim ustanovama. Zaslužan je za uvođenje metode kohlearne implantacije u KCS za lečenje osoba sa veoma teškim oštećenjem sluha.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Osnovne studije i specijalizaciju završio je na Medicinskiom fakultetu Univerziteta u Beogradu.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Pored lekarske karijere dr Nenad Arsović je i profesor na Medicinskom fakultetu u Beogradu na katedri Otorinolaringologije. Radno iskustvo stiče i u Centru za sluh i ravnotežu (Specijalista); Klinika za ORL i MFH KCS od 2017 (Direktor). Aktivno se usavršava na domaćim i međunarodnim kongresima na kojima je često i predavač. Autor i koautor je preko 150 publikovanih naučnih radova. '
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Trenutno radi u Beogradu kao direktor Klinike za ORL i MFH KCS.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-nenad-arsović'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-nenad-arsović'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-nenad-arsović'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-nenad-arsović'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-nenad-arsović'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-nenad-arsović'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-nenad-arsović'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-nenad-arsović'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'dusanBijelic',
    profileImage: dusanBijelic,
    alternative: 'una-bolnica-dušan-bijelić',
    name: <>Dr Dušan Bijelić</>,
    description: 'specijalista otorinolaringologije',
    service: 'otorinolaringologija',
    seoInspector: {
      title: 'dr Dušan Bijelić, otorinolaringolog, +381652024055',
      metaContent: 'dr Dušan Bijelić, otorinolaringolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/dusan-bijelic',
      h1: 'dr Dušan Bijelić, otorinolaringolog, +381652024055',
      h2: 'dr Dušan Bijelić, otorinolaringolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: dusanBijelic,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Korekcija devijacije nosne pregrade'
      },
      {
        serviceId: 2,
        serviceContent: 'Rinoplastika (estetska operacija nosa)'
      },
      {
        serviceId: 3,
        serviceContent: 'Endoskopska sinusna hirurgija'
      },
      {
        serviceId: 4,
        serviceContent: 'Hirurgija glasnih žica i vokalnog aparata:'
      },
      {
        serviceId: 5,
        serviceContent: 'Fonosurgija (hirurgija vokalnih žica)'
      },
      {
        serviceId: 6,
        serviceContent: 'Operacija polipa na glasnim žicama'
      },
      {
        serviceId: 7,
        serviceContent: 'Hirurgija larinksa i traheje:'
      },
      {
        serviceId: 8,
        serviceContent: 'Endoskopska resekcija'
      },
      {
        serviceId: 9,
        serviceContent: 'Rekonstrukcija larinksa'
      },
      {
        serviceId: 10,
        serviceContent: 'Uklanjanje krajnika (adenotomija)'
      },
      {
        serviceId: 11,
        serviceContent: 'Uklanjanje tonzila (tonzilektomija)'
      },
      {
        serviceId: 12,
        serviceContent: 'Ugradnja tubalnih katetera za prevenciju upala uha'
      },
      {
        serviceId: 13,
        serviceContent: 'Uklanjanje cista i tumora u području glave i vrata'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr. Dušan Bijelić je istaknuti specijalista otorinolaringologije s impresivnim iskustvom u oblasti konzervativnog i hirurškog lečenja. Sa svojih 13 godina načelničke pozicije na ORL klinici Vojnomedicinske akademije (VMA), stekao je duboko znanje i stručnost u svim aspektima otorinolaringologije.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Rođen je 9. marta 1953. godine u Donjoj Slatini, Bosna i Hercegovina. Diplomirao je na Medicinskom fakultetu Univerziteta u Beogradu 1978. godine, a specijalistički ispit iz otorinolaringologije položio je 1990. godine sa odličnim uspehom. Pored opšteg medicinskog znanja, dr. Bijelić se ističe svojom stručnošću u funkcionalno-estetskoj hirurgiji nosa i sinusa, kao i u lečenju promena na glasnim žicama kod vokalnih solista, advokata, prosvetnih radnika, glumaca i drugih profesionalaca koji zavise od svojih glasova.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Dr. Bijelić može se pohvaliti sa preko 25.000 uspešno izvedenih operacija, među kojima se ističe veliki broj hirurških zahvata na nosu i sinusima, ali i kompleksne operacije larinksa i traheje. Stalno se usavršava kroz učešće na brojnim domaćim i međunarodnim kongresima, seminarima i drugim vidovima stručnog usavršavanja kako bi ostao u toku sa najnovijim dostignućima u otorinolaringologiji.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Dr. Dušan Bijelić je član mnogih prestižnih udruženja, uključujući AO Spine (udruženje spinalnih hirurga), Svetsku federaciju neurohirurških udruženja, Evropsku asocijaciju neurohirurga, Udruženje neurohirurga Srbije i Srpsko lekarsko društvo (SLD). Njegovo stručno angažovanje i posvećenost medicini čine ga cenjenim stručnjakom u oblasti otorinolaringologije.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-dušan-bijelić'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-dušan-bijelić'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-dušan-bijelić'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-dušan-bijelić'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-dušan-bijelić'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-dušan-bijelić'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-dušan-bijelić'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-dušan-bijelić'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'ljubomirPavicevic',
    profileImage: ljubomirPavicevic,
    alternative: 'una-bolnica-ljubomir-pavićević',
    name: <>Dr Ljubomir Pavićević</>,
    description: 'specijalista otorinolaringologije',
    service: 'otorinolaringologija',
    seoInspector: {
      title: 'dr Ljubomir Pavićević, otorinolaringolog, +381652024055',
      metaContent: 'dr Ljubomir Pavićević, otorinolaringolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/ljubomir-pavicevic',
      h1: 'dr Ljubomir Pavićević, Una bolnica Beograd, +381652024055',
      h2: 'dr Ljubomir Pavićević, otorinolaringolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: ljubomirPavicevic,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Hirurgija glave i vrata'
      },
      {
        serviceId: 2,
        serviceContent: 'Laringektomija - Uklanjanje grkljana'
      },
      {
        serviceId: 3,
        serviceContent: 'Fonohirurgija (LMS): Hirurško poboljšanje govorne funkcije'
      },
      {
        serviceId: 4,
        serviceContent: 'Septoplastika - Ispravljanje devijacije septuma nosa'
      },
      {
        serviceId: 5,
        serviceContent: 'Tonzilektomija - Uklanjanje krajnika'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr Ljubomir Pavićević je stručnjak iz oblasti otorinolaringologije. Sa završenim studijama na Prvom Moskovskom Medicinskom Institutu im. Sečenova i Medicinskom fakultetu u Beogradu, stekao je specijalizaciju iz otorinolaringologije na Vojnomedicinskoj Akademiji 1998. Godine 2008. završio je doktorske akademske studije u oblasti hirurgije. Od 2005. godine, obavlja funkciju načelnika laringološkog odeljenja ORL Klinike VMA.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Dr Pavićevićeva specijalnost obuhvata hirurgiju glave i vrata, laringektomije, fonohirurgiju (LMS), septoplastike i tonzilektomije.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Nastavio je sa usavršavanjem kroz brojne kurseve, uključujući Laringomikroskopsku hirurgiju sa H.K. Glantzom, UZ dijagnostiku vrata sa C. Arensom, FESS sa H. Štambergerom i A. Lojnigom, kao i Fonohirurgiju sa J. Abitbolom. Učestvovao je na mnogim domaćim i međunarodnim kongresima i simpozijumima.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Dr Pavićević je takođe koautor nekoliko radova objavljenih u domaćim i stranim stručnim časopisima. Član je Lekarske komore Srbije, Evropskog laringološkog društva, ORL sekcije SLD i takođe je član predsedništva Srpskog Aktiva Pedijatrijske Otorinolaringologije (SAPO).'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljubomir-pavićević'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljubomir-pavićević'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljubomir-pavićević'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljubomir-pavićević'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljubomir-pavićević'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljubomir-pavićević'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljubomir-pavićević'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljubomir-pavićević'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'aleksandarDimic',
    profileImage: aleksandarDimic,
    alternative: 'una-bolnica-aleksandar-dimić',
    name: <>Dr Aleksandar Dimić</>,
    description: 'specijalista otorinolaringologije',
    service: 'otorinolaringologija',
    seoInspector: {
      title: 'dr Aleksandar Dimić, otorinolaringolog, +381652024055',
      metaContent: 'dr Aleksandar Dimić, otorinolaringolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/aleksandar-dimic',
      h1: 'dr Aleksandar Dimić, otorinolaringolog, +381652024055',
      h2: 'dr Aleksandar Dimić, otorinolaringolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: aleksandarDimic,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Operacije krajnika (tonsilektomija, adenotomija)'
      },
      {
        serviceId: 2,
        serviceContent: 'Estetske operacije ušiju (otoplastika)'
      },
      {
        serviceId: 3,
        serviceContent: 'Estetske operacije nosa (rinoplastika)'
      },
      {
        serviceId: 4,
        serviceContent: 'Operacije devirane nosne pregrade (septoplastika)'
      },
      {
        serviceId: 5,
        serviceContent: 'Endoskopske operacije sinusa (endoskopska sinusektomija)'
      },
      {
        serviceId: 6,
        serviceContent: 'Laringomikroskopske intervencije (mikrolaringoskopija)'
      },
      {
        serviceId: 7,
        serviceContent: 'Operacije onkološke hirurgije grkljana i vrata'
      },
      {
        serviceId: 8,
        serviceContent: 'Uklanjanje polipa iz nosa (polipektomija)'
      },
      {
        serviceId: 9,
        serviceContent: 'Uklanjanje cista i tumora u regiji glave i vrata'
      },
      {
        serviceId: 10,
        serviceContent: 'Operacije nosnih školjki (konhoplastika)'
      },
      {
        serviceId: 11,
        serviceContent: 'Rekonstrukcija nosa posle traume'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr Aleksandar Dimić je specijalista otorinolaringologije sa bogatim iskustvom u državnoj i privatnoj praksi. Diplomirao je na Medicinskom fakultetu u Nišu 1999. godine, a specijalizaciju je završio na Vojnoj medicinskoj akademiji u Beogradu. Tokom svoje karijere, dr Dimić je radio kao načelnik u Sanitetu Zapadnobačkog okruga i na ORL odeljenju Vojnomedicinske akademije, a od 2013. godine je deo ORL klinike Vojnomedicinske akademije.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Dr Dimić se bavi različitim operativnim zahvatima, uključujući operacije krajnika, estetske operacije ušiju i nosa, operacije devirane nosne pregrade, endoskopske operacije sinusa, laringomikroskopske intervencije i operacije onkološkom hirurgijom grkljana i vrata. Takođe je autor i koautor više radova iz oblasti rinologije i laringologije.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-dimić'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-dimić'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-dimić'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-dimić'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-dimić'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-dimić'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-dimić'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-aleksandar-dimić'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'ivanDjordjevic',
    profileImage: ivanDjordjevic,
    alternative: 'una-bolnica-ivan-đorđević',
    name: <>Dr Ivan Đorđević</>,
    description: 'specijalista otorinolaringologije',
    service: 'otorinolaringologija',
    seoInspector: {
      title: 'dr Ivan Đorđević, otorinolaringolog, +381652024055',
      metaContent: 'dr Ivan Đorđević, otorinolaringolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/ivan-djordjevic',
      h1: 'dr Ivan Đorđević, otorinolaringolog, +381652024055',
      h2: 'dr Ivan Đorđević, otorinolaringolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: ivanDjordjevic,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr Ivan Đorđević je specijalista otorinolaringologije sa iskustvom u Beogradu i Podgorici. Diplomirao je na Medicinskom fakultetu Univerziteta u Prištini i završio specijalističke studije otorinolaringologije. Trenutno se subspecijalizuje iz oblasti onkologije.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Dr Đorđević se fokusira na otorinolaringologiju dece, rinologiju i onkologiju. Redovno se usavršava i učestvuje na domaćim i međunarodnim simpozijumima i kongresima.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Imao je radno iskustvo u Kliničko Bolničkom Centru Dr Dragiša Mišović Dedinje i obavljao je funkciju Načelnika odeljenja za prijem i zbrinjavanje urgentnih stanja. Trenutno je Upravnik Bolnice za otorinolaringologiju KBC Dr Dragiša Mišović Dedinje.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Dr Đorđević pruža usluge kao što su ORL pregledi, endoskopski pregled nosa i sinusa, provera sluha audiometrijom i timpanometrijom, estetske operacije nosa i uha, operacije krive nosne pregrade, operacija krajnika i trećeg krajnika ultrazvučnim nožem (Harmonic scalpel), ugradnja ventilacionih cevčica, kao i laringomikroskopski pregled larinksa.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivan-đorđević'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivan-đorđević'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivan-đorđević'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivan-đorđević'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivan-đorđević'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivan-đorđević'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivan-đorđević'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivan-đorđević'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'ljiljanaCvorovic',
    profileImage: ljiljanaCvorovic,
    alternative: 'una-bolnica-ljiljana-čvorović',
    name: <>Dr Ljiljana Čvorović</>,
    description: 'specijalista otorinolaringologije',
    service: 'otorinolaringologija',
    seoInspector: {
      title: 'dr Ljiljana Čvorović, otorinolaringolog, +381652024055',
      metaContent: 'dr Ljiljana Čvorović, otorinolaringolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/ljiljana-cvorovic',
      h1: 'dr Ljiljana Čvorović, otorinolaringolog, +381652024055',
      h2: 'dr Ljiljana Čvorović, otorinolaringolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: ljiljanaCvorovic,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Medicinski fakultet Univerziteta u Beogradu je završila 1999. godine sa prosečnom ocenom 9,27. Magistarski rad iz uže naučne oblasti otorinolaringologija pod nazivom: »Značaj ultrazvuka u otkrivanju metastaza karcinoma larinksa«, odbranila je na Medicinskom fakultetu Univerziteta u Beogradu, 2003. godine. Doktorsku tezu iz uže naučne oblasti otorinolaringologija pod nazivom: »Prognostički skor za procenu oporavka sluha kod akutne idiopatske senzorineuralne nagluvosti«, odbranila je na Medicinskom fakultetu Univerziteta u Beogradu, 2008. godine. Specijalista otorinolaringolog je od 2005. godine. Supspecijalista audiologije je od 2012. godine.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Angažovana u državnoj i privatnoj praksi. Po završenoj specijalizaciji, usavršavala se u Nemačkoj, Švajcarskoj i Velikoj Britaniji. Zaposlena je na Medicinskom fakultetu Univerziteta u Beogradu. U zvanju je docent na predmetu Otorinolaringologija sa maksilofacijalnom hirurgijom i sekretar je katedre za poslediplomsku nastavu. Zaposlena je i na Klinici za otorinolaringologiju i maksilofacijalnu hirurgiju, Kliničkog Centra Srbije kao lekar specijalista otorinolaringologije i supspecijalista audiologije.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Usluge koje dr Ljiljana Čvorović pruža su: pregled specjaliste za decu i odrasle; tonzilektomije (operacija krajnika); adenoidemtomina (vađenje trećeg krajnika); septoplastika (operacija devijacije nosne pregrade); ugradnja aeracionih cevčica; operacije uha kod dece i odraslih; zbrinjavanje urgentnih stanja u ORL.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Radno iskustvo: KBC Zemun od 2000. do 2014. godine kao sef Odeljenja otorinolaringologije; Klinicka za otorinolaringologiju i maksilofacijalnu hirurgiju KCS od 2014. godine kao specijalista otorinolaringologije i subspecijalista audiologije; Bel Medic od 2005. godine kao konsultant. ORL i Tinitus centar kao konsultant.'
      },
      {
        paragraphId: 5,
        paragraphContent: 
          'Autor je nekoliko radova objavljenih u domaćim i stranim stručnim časopisima.'
      },
      {
        paragraphId: 6,
        paragraphContent: 
          'Član je Lekarske komore Srbije. Član je Sekcije za Otorinolaringologiju SLD od 2000. Član je Predsedništva Sekcije i sekretar Sekcije za otorinolaringologiju u periodu 2008-2011. Član je Predsedništva Srpskog aktiva za pedijatrijsku otorinolaringologiju (SAPO) od 2011. Generalni sekretar je Srpskog Aktiva Otologa (SAO) od 2013. Član je Udruženja otologa i audiologa Srbije od 2005. Član je Politzer Society- International Society for Otologic Surgery and Science. Član je European Academy of Otology and Neurootology (EAONO).'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljiljana-čvorović'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljiljana-čvorović'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljiljana-čvorović'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljiljana-čvorović'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljiljana-čvorović'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljiljana-čvorović'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljiljana-čvorović'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-ljiljana-čvorović'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'milanErdoglija',
    profileImage: milanErdoglija,
    alternative: 'una-bolnica-milan-erdoglija',
    name: <>Dr Milan Erdoglija</>,
    description: 'specijalista otorinolaringologije',
    service: 'otorinolaringologija',
    seoInspector: {
      title: 'dr Milan Erdoglija, otorinolaringolog, +381652024055',
      metaContent: 'dr Milan Erdoglija, otorinolaringolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/milan-erdoglija',
      h1: 'dr Milan Erdoglija, otorinolaringolog, +381652024055',
      h2: 'Opšta bolnica Una, spinalna hirurgija, estetska hirugija, ortopedija, ORL i druge. Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: milanErdoglija,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Diplomirao na Medicinskom fakultetu u Beogradu, 1996. godine. Diploma iz alternativne akademske obrazovne mreže, životna sredina – izazov za nauku, tehnologiju i društvo, akademska godina 2001/2002. Specijalizovao se za operaciju uha, nosa i grla, 2004. godine.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Specijalista otorinolaringologije. Uže oblasti interesovanja: otologija, otohirurgija i audiologija i funkcionalna endoskopska sinus hirurgija'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Istorija zapošljavanja- Vojno medicinka akademija, Beograd, Klinika za grlo nosa, do 2000. godine do danas, kao lekar opšte prakse; Ambulanta- Beograd, 2 godine iskustva, 1998-2000; Škola rezervnih službenika – Beograd i Bar, jednogodišnje iskustvo, 1997/1998; Bolnica „Nikola Nikola“ – Kraljevo, polugodišnje iskustvo, 1997; Zdravstveni centar Študenica- Kraljevo, jednogodišnje iskustvo, 1996.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Učestvovao na većem broju kongresa u zemlji i inostranstvu, gde je izlagao svoje radove iz oblasti otologije-otohirurgije'
      },
      {
        paragraphId: 5,
        paragraphContent: 
          'U Londonu, Lajpcigu, Barseloni, Instambulu je bio na usavršavanju iz oblasti hirurgije uva dece i odraslih.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-milan-erdoglija'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-milan-erdoglija'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-milan-erdoglija'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-milan-erdoglija'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-milan-erdoglija'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-milan-erdoglija'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-milan-erdoglija'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-milan-erdoglija'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'miloradAdzic',
    profileImage: miloradAdzic,
    alternative: 'una-bolnica-milorad-adžić',
    name: <>Dr Milorad Adžić</>,
    description: 'specijalista otorinolaringologije',
    service: 'otorinolaringologija',
    seoInspector: {
      title: 'dr Milorad Adžić, otorinolaringolog, +381652024055',
      metaContent: 'dr Milorad Adžić, otorinolaringolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/milorad-adzic',
      h1: 'dr Milorad Adžić, otorinolaringolog, +381652024055',
      h2: 'dr Milorad Adžić, otorinolaringolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: miloradAdzic,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Otorinolaringolog, doktor medicinskih nauka, profesor na Stomatološkom fakultetu u Pančevu.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Dugogodišnji načelnik operativnog odeljenja bolnice za ORL, a pri kraju radnog staža u KBC „Dr Dragiša Mišović“, upravnik bolnice za ORL i zamenik direktora KBC-a.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Specijalistčki ispit iz Otorinolaringologije položio je maja meseca 1988. godine na Klinici za uvo, nos i grlo VMA Beograd sa odličnom ocenom. Posle diplomske studije završio je na Medicinskom fakuletu u Prištini gde je 25.maja, 1994. godine odbranio magistarski rad pod naslovom “Epidemiološke karakteristike perifernih paraliza facijalnog živca belog tipa”. Juna meseca 1999. godine odbranio je doktorsku disertaciju pod naslovom “Uporedna epidemiološko-klinička, imunološka, citološka i laboratorijska ispitivanja hroničnog sekretnog otitisa”.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Autor više od 30 naučniih radova, od kojih su neki objavljeni i u inostranim časopisima. Koautor udžbenika „Urgentna medicina“,FACIJALNOG ŽIVCA BELOVOG TIPA”, namenjenog za studente stomatologije.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-milorad-adžić'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-milorad-adžić'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-milorad-adžić'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-milorad-adžić'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-milorad-adžić'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-milorad-adžić'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-milorad-adžić'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-milorad-adžić'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'nikolaMircetic',
    profileImage: nikolaMircetic,
    alternative: 'una-bolnica-nikola-mirčetić',
    name: <>Dr Nikola Mirčetić</>,
    description: 'specijalista otorinolaringologije',
    service: 'otorinolaringologija',
    seoInspector: {
      title: 'dr Nikola Mirčetić, otorinolaringolog, +381652024055',
      metaContent: 'dr Nikola Mirčetić, otorinolaringolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/nikola-mircetic',
      h1: 'dr Nikola Mirčetić, otorinolaringolog, +381652024055',
      h2: 'dr Nikola Mirčetić, otorinolaringolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: nikolaMircetic,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr Nikola Mirčetić je specijalista otorinolaringologije iz Beograda. Nakon studija na Medicinskom fakultetu u Beogradu, dr Mirčetić je završio specijalizaciju iz otorinolaringologije. Od 2001. je 13 godina radio na Insitut za zdravstvenu zaštitu majke i deteta Srbije “Dr Vukan Čupić”, obavljajući preglede i operacije, a sada radi u privatnim klinikama ORL bolnica “Dr Žutić” i Pedijatrijska ordinacija “Dr Raketić”.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Dr Nikola Mirčetić je nakon studija i specijalizacije nastavio da se usavršava i unapređuje svoja znanja, pa je tako završio kurs Preparacije temporalne kosti na Vojnomedicinskoj akademiji (VMA) u Beogradu. Završio je takođe kurseve endoskopije i esktrakcije stranih tela disajnih organa kod dece, kao i kurs za Implantaciju BAHA aparata u Novom Sadu.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Dr Mirčetić se bavi dijagnostikom i operativnim lečenjem bolesti uha, grla i nosa kod dece. Pregledi koje obavlja: ORL pregled dece; Oštećenje sluha; Vrtoglavica.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Član je Srpskog lekarskog društva i Srpskog aktiva pedijatrijske otorinolaringologije.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-nikola-mirčetić'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-nikola-mirčetić'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-nikola-mirčetić'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-nikola-mirčetić'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-nikola-mirčetić'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-nikola-mirčetić'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-nikola-mirčetić'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-nikola-mirčetić'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'boskoMilev',
    profileImage: boskoMilev,
    alternative: 'una-bolnica-boško-milev',
    name: <>Dr Boško Milev</>,
    description: 'specijalista opšte hirurgije',
    service: 'opšta hirurgija',
    seoInspector: {
      title: 'dr Boško Milev, hirurg, Una bolnica, +381652024055',
      metaContent: 'dr Boško Milev, hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/bosko-milev',
      h1: 'dr Boško Milev, hirurg, Una bolnica, +381652024055',
      h2: 'dr Boško Milev, hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: boskoMilev,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Laparoskopska holecistektomija (uklanjanje žučne kese)'
      },
      {
        serviceId: 2,
        serviceContent: 'Laparoskopska apendektomija (uklanjanje slepog creva)'
      },
      {
        serviceId: 3,
        serviceContent: 'Laparoskopska resekcija debelog creva'
      },
      {
        serviceId: 4,
        serviceContent: 'Laparoskopska hernioplastika (popravka kila)'
      },
      {
        serviceId: 5,
        serviceContent: 'Laparoskopska resekcija želuca'
      },
      {
        serviceId: 6,
        serviceContent: 'Laparoskopska resekcija jetre'
      },
      {
        serviceId: 7,
        serviceContent: 'Laparoskopska resekcija pankreasa'
      },
      {
        serviceId: 8,
        serviceContent: 'Laparoskopska resekcija bubrega'
      },
      {
        serviceId: 9,
        serviceContent: 'Laparoskopska resekcija jajnika'
      },
      {
        serviceId: 10,
        serviceContent: 'Laparoskopska resekcija prostate'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr Boško Milev je renomirani specijalista opšte hirurgije čije je stručno usmerenje usmereno ka laparoskopskoj hirurgiji. Sa radnom bazom u Beogradu na prestižnoj Vojnomedicinskoj akademiji, Dr Milev se ističe kao lider u svom polju i priznati instruktor za laparoskopske procedure.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Dr Milev je svoju akademsku karijeru započeo diplomiranjem na Fakultetu medicinskih nauka Univerziteta u Kragujevcu, a kasnije je stekao diplomu Medicinskog fakulteta Vojnomedicinske Akademije. Njegovo neprestano usavršavanje obuhvata specijalizaciju iz oblasti hirurgije kolorektuma i karcinoma dojke, sa brojnim edukacijama i kursom širom sveta.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Sa više od 25 godina iskustva, Dr Milev se ističe po svom izuzetnom stručnom doprinosu u laparoskopskoj hirurgiji. Tokom svoje karijere obavio je preko 25.000 operacija, uključujući kompleksne zahvate na nosu, sinusima, larinksu i traheji. Njegov rad je prepoznat i kroz autorstvo preko 50 stručnih radova.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Dr Milev je član mnogih uglednih udruženja, uključujući Udruženje koloproktologa Srbije, Nacionalno laparoskopsko udruženje (JUEH), Udruženje hirurga i gastroenterologa Srbije, Udruženje onkoloških hirurga Srbije i Lekarske komore Srbije.'
      },
      {
        paragraphId: 5,
        paragraphContent: 
          'Kroz svoj predan pristup pacijentima, Dr Milev pruža vrhunsku negu i pažnju, koristeći najnovije tehnike u laparoskopskoj hirurgiji. Sa njim na čelu, možete imati poverenja da će vam pružiti vrhunske rezultate i voditi vas ka brzom oporavku i boljem zdravlju.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-boško-milev'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-boško-milev'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-boško-milev'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-boško-milev'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-boško-milev'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-boško-milev'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-boško-milev'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-boško-milev'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'miroslavMitrovic',
    profileImage: miroslavMitrovic,
    alternative: 'una-bolnica-miroslav-mitrović',
    name: <>Dr Miroslav Mitrović</>,
    description: 'specijalista opšte hirurgije',
    service: 'opšta hirurgija',
    seoInspector: {
      title: 'dr Miroslav Mitrović, hirurg, +381652024055',
      metaContent: 'dr Miroslav Mitrović, hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/miroslav-mitrovic',
      h1: 'dr Miroslav Mitrović, hirurg, +381652024055',
      h2: 'dr Miroslav Mitrović, hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: miroslavMitrovic,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Završio je osnovne studije na Medicinskom fakultetu, Univerziteta u Beogradu, kao i specijalizaciju iz opšte hirurgije.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Imao je razna uspešna kretanja u službi. Od 1992. godine, bio je lekar specijalista hirurg na Klinici za opštu hirurgiju, na VMA. Od 1986. do 1990. godine, bio je lekar na specijalizaciji iz opšte hirurgije, na VMA u Beogradu.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Oblasti istraživanja kojoj se bavi jeste Hepatobiliopankreasna hirurgija.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Stručno se usavršavao 2010. godine u Hanoveru, u Nemačkoj na Klinici za opštu i transplantacionu hirurgiju, odeljenje hepatobiliopankreasne hirurgije.'
      },
      {
        paragraphId: 5,
        paragraphContent: 
          'Član je Hirurške sekcije Srpskog lekarskog društva (SLD).'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-miroslav-mitrović'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-miroslav-mitrović'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-miroslav-mitrović'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-miroslav-mitrović'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-miroslav-mitrović'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-miroslav-mitrović'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-miroslav-mitrović'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-miroslav-mitrović'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'zagorZagorac',
    profileImage: zagorZagorac,
    alternative: 'una-bolnica-zagor-zagorac',
    name: <>Dr Zagor Zagorac</>,
    description: 'opšti hirurg',
    service: 'opšta hirurgija',
    seoInspector: {
      title: 'dr Zagor Zagorac, hirurg, +381652024055',
      metaContent: 'dr Zagor Zagorac, hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/zagor-zagorac',
      h1: 'dr Zagor Zagorac, hirurg, +381652024055',
      h2: 'Opšta bolnica Una, spinalna hirurgija, estetska hirugija, ortopedija, ORL i druge. Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: zagorZagorac,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'operacije kila prednjeg trbušnog zida'
      },
      {
        serviceId: 2,
        serviceContent: 'laparoskopska i klasična holecistektomija'
      },
      {
        serviceId: 3,
        serviceContent: 'operacije tumora debelog creva i rektuma laparoskopski i otvorenom tehnikom'
      },
      {
        serviceId: 4,
        serviceContent: 'operacije analne fissure (Botox, V-Y plastika, lateralna unutrašnja sfinkterotomija)'
      },
      {
        serviceId: 5,
        serviceContent: 'operacije perianalnih fistula (fistulotomija lay-open, advancement flap, LIFT, setton drenaža)'
      },
      {
        serviceId: 6,
        serviceContent: 'operacije hemoroida (klasična modifikovana ekscizija hemoroida po Miligan-Morganu, THD distalna dearterijalizacija, laser hemoroidektomija)'
      },
      {
        serviceId: 7,
        serviceContent: 'operacija pilonidalnog sinusa (cleft-lift tehnikom)'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr Zagor Zagorac je iskusni opšti hirurg sa impresivnim obrazovanjem i bogatim profesionalnim iskustvom. Njegova stručnost se posebno ističe u oblasti hirurgije digestivnog sistema, sa doktorskim studijama i specijalizacijom iz opšte hirurgije.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Pored svog formalnog obrazovanja, dr Zagorac se kontinuirano usavršava kroz različite kurseve i treninge širom Evrope. On je takođe autor i koautor nekoliko naučnih radova.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Sa višegodišnjim iskustvom u različitim medicinskim ustanovama, uključujući Kliniku za hirurgiju KBC Dr Dragiša Mišović, dr Zagorac je stekao duboko razumevanje i veštinu u oblasti opšte hirurgije.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Profesionalna udruženja kojima dr Zagorac pripada svedoče o njegovom angažovanju i predanosti medicinskoj struci.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-zagor-zagorac'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-zagor-zagorac'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-zagor-zagorac'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-zagor-zagorac'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-zagor-zagorac'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-zagor-zagorac'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-zagor-zagorac'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-zagor-zagorac'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'ivanLekovic',
    profileImage: ivanLekovic,
    alternative: 'una-bolnica-ivan-leković',
    name: <>Dr Ivan Leković</>,
    description: 'specijalista vaskularne hirurgije',
    service: 'vaskularna hirurgija',
    seoInspector: {
      title: 'dr Ivan Leković, vaskularni hirurg, +381652024055',
      metaContent: 'dr Ivan Leković, vaskularni hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/ivan-lekovic',
      h1: 'dr Ivan Leković, vaskularni hirurg, +381652024055',
      h2: 'dr Ivan Leković, vaskularni hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: ivanLekovic,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Diplomirao je na Medicinskom fakultetu u Beogradu 1998. godine. Specijalizaciju iz opšte hirurgije završio je 2005. godine na Vojnomedicinskoj akademiji u Beogradu. Od 2006. do 2008. bio je na subspecijalizaciji iz vaskularne hirurgije u SR Nemačkoj u Ulmu. Doktorske akademske studije završio je na Fakultetu medicinskih nauka u Kragujevcu 2011. godine. Završio je međunarodni kurs za primenu vakuma u lečenju rana.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Član je transplantacionog tima VMA kao I tima za endovaskularnu hirurgiju VMA.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Član je više domaćih i međunarodnih udruženja: SLD-a, Udruženja flebologa Srbije, Nemačkog društva hirurga.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Koautor je nekoliko stručnih radova na temu vaskularne hirurgije.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivan-leković'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivan-leković'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivan-leković'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivan-leković'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivan-leković'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivan-leković'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivan-leković'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-ivan-leković'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'bozinaRadevic',
    profileImage: bozinaRadevic,
    alternative: 'una-bolnica-božina-radević',
    name: <>Prof. dr Božina Radević</>,
    description: 'specijalista vaskularne hirurgije',
    service: 'vaskularna hirurgija',
    seoInspector: {
      title: 'dr Božina Radević, vaskularni hirurg, +381652024055',
      metaContent: 'dr Božina Radević, vaskularni hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/bozina-radevic',
      h1: 'dr Božina Radević, vaskularni hirurg, +381652024055',
      h2: 'dr Božina Radević, vaskularni hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: bozinaRadevic,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Medicinski fakultet u Beogradu završio je 1962. godine, a specijalizaciju iz oblasti opšte hirurgije na Medicinskom fakultetu Vojnomedicinske akademije 1970. godine. Na Institutu kliničke i eksperimentalne medicine u Pragu, 1977. završio je subspecijalizaciju iz vaskularne hirurgije,a iste godine, odbranom rada u Srajevu, postao je doktor medicinskih nauka.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Profesionalno radno iskustvo stekao je kao redovni profesor hirurgije na fakultetima u Beogradu i Tuzli, kao i u privatnim i državnim bolnicama. Bio je direktor hirurške klinike Kliničkog centra u Tuzli i IKVB Dedinje, rukovodilac programa transplantacije u IKVB Dedinje, organizator stručnog programa i realizator prvih transplantacija jetre od kadavera i živog davaoca.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Autor je 3 knjiga, koautor u 5 knjiga, više od 200 njegovih radova objavljenio je u domaćim i stranim časopisima. Mentor za 8 doktorskih disertacija, 9 magistratura i subspecijalizacija.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Član je nekoliko domaćih i inostranih profesionalnih specijalističkih i subspecijalističkih udruženja.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-božina-radević'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-božina-radević'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-božina-radević'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-božina-radević'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-božina-radević'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-božina-radević'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-božina-radević'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-božina-radević'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'zivoradNikolic',
    profileImage: zivoradNikolic,
    alternative: 'una-bolnica-živorad-nikolić',
    name: <>Dr Živorad Nikolić</>,
    description: 'specijalista maksilofacijalne hirurgije',
    service: 'maksilofacijalna hirurgija',
    seoInspector: {
      title: 'dr Živorad Nikolić, maksilofacijalni hirurg, Una bolnica',
      metaContent: 'dr Živorad Nikolić, maksilofacijalni hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/zivorad-nikolic',
      h1: 'dr Živorad Nikolić, maksilofacijalni hirurg, +381652024055',
      h2: 'dr Živorad Nikolić, maksilofacijalni hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: zivoradNikolic,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Rekonstruktivna mikrohirurgija'
      },
      {
        serviceId: 2,
        serviceContent: 'Kraniofacijalna hirurgija'
      },
      {
        serviceId: 3,
        serviceContent: 'Estetska hirurgija glave i vrata'
      },
      {
        serviceId: 4,
        serviceContent: 'Implantologija: Postavljanje dentalnih implantata'
      },
      {
        serviceId: 5,
        serviceContent: 'Operacija tumora lobanje'
      },
      {
        serviceId: 6,
        serviceContent: 'Ugradnja veštačkih kostiju'
      },
      {
        serviceId: 7,
        serviceContent: 'Rekonstrukcija funkcionalnosti i izgleda mekih tkiva'
      },
      {
        serviceId: 8,
        serviceContent: 'Korekcija asimetrije lica'
      },
      {
        serviceId: 9,
        serviceContent: 'Rekonstrukcija vilica'
      },
      {
        serviceId: 10,
        serviceContent: 'Tretman traume lica i vilica'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr Živorad Nikolić je specijalista maksilofacijalne hirurgije sa bogatim iskustvom. Diplomirao je na Stomatološkom fakultetu 1986. godine, a zatim je diplomirao na Medicinskom fakultetu u Beogradu 1993. godine. Pored toga, magistrirao je 1992. godine, a doktorsku disertaciju odbranio je 2006. godine.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Sa preko 20 godina iskustva, dr Živorad Nikolić uspešno se bavi plastičnom i maksilofacijalnom hirurgijom na glavi i vratu. Izveo je više od 6000 operacija u opštoj anesteziji. Posebno se interesuje za rekonstruktivnu mikrohirurgiju i kraniofacijalnu hirurgiju, što potvrđuju njegovi radovi objavljeni u domaćim i međunarodnim časopisima. Takođe, uspešno se bavi estetskom hirurgijom glave i vrata, kao i implantologijom.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Dr Živorad Nikolić je autor i prezentator preko 130 naučnih i stručnih radova u inostranim i domaćim časopisima, kao i na međunarodnim i domaćim skupovima. Održao je više od 30 predavanja u zemlji i inostranstvu. Takođe je koautor u knjizi "Hirurgija tumora lobanje" i monografiji "Rana dijagnostika kancera epitelnog tkiva".'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Udruženja kojima je član su Udruženje Maksilofacijalnih Hirurga Srbije, Evropsko Udruženje za Kranio-Maksilofacijalnu Hirurgiju i Internacionalno Udruženje za Oralnu i Maksilofacijalnu Hirurgiju.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-živorad-nikolić'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-živorad-nikolić'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-živorad-nikolić'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-živorad-nikolić'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-živorad-nikolić'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-živorad-nikolić'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-živorad-nikolić'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-živorad-nikolić'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'srboljubStosic',
    profileImage: srboljubStosic,
    alternative: 'una-bolnica-srboljub-stošić',
    name: <>Prof. dr Srboljub Stošić</>,
    description: 'spacijalista maksilofacijalne hirurgije',
    service: 'maksilofacijalna hirurgija',
    seoInspector: {
      title: 'dr Srboljub Stošić, maksilofacijalni hirurg, +381652024055',
      metaContent: 'dr Srboljub Stošić, maksilofacijalni hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/srboljub-stosic',
      h1: 'dr Srboljub Stošić, maksilofacijalni hirurg, +381652024055',
      h2: 'dr Srboljub Stošić, maksilofacijalni hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: srboljubStosic,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Rekonstrukcija vilice'
      },
      {
        serviceId: 2,
        serviceContent: 'Operacije ugradnje dentalnih implantata'
      },
      {
        serviceId: 3,
        serviceContent: 'Lečenje koštanih deformacija vilice'
      },
      {
        serviceId: 4,
        serviceContent: 'Korekcija anomalija lica i vilice'
      },
      {
        serviceId: 5,
        serviceContent: 'Hranična hirurgija (operacije mekih tkiva oko vilice)'
      },
      {
        serviceId: 6,
        serviceContent: 'Operacije tumoroznih promena na licu i vilici'
      },
      {
        serviceId: 7,
        serviceContent: 'Operacije traumatskih povreda lica i vilice'
      },
      {
        serviceId: 8,
        serviceContent: 'Korekcija problema sa zagrižajem (ortognatske operacije)'
      },
      {
        serviceId: 9,
        serviceContent: 'Korekcija defekata mekih tkiva lica i vilice'
      },
      {
        serviceId: 10,
        serviceContent: 'Lečenje disfunkcija temporomandibularnog zgloba (TMJ)'
      },
      {
        serviceId: 11,
        serviceContent: 'Rekonstrukcija nosa i septoplastika'
      },
      {
        serviceId: 12,
        serviceContent: 'Operacije uklanjanja cista i tumora vilice'
      },
      {
        serviceId: 13,
        serviceContent: 'Lečenje akutnih i hroničnih infekcija vilice i okolnih tkiva'
      },
      {
        serviceId: 14,
        serviceContent: 'Korekcija nepravilnosti vilice kod dece i odraslih'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Prof. dr Srboljub Stošić je specijalista maksilofacijalne hirurgije sa bogatim akademskim i profesionalnim iskustvom. Diplomirao je na Medicinskom fakultetu Univerziteta u Beogradu 1982. godine, a specijalistički ispit je položio na Medicinskom fakultetu Vojnomedicinske Akademije 1989. godine. Doktorske studije je završio na Medicinskom fakultetu Vojnomedicinske Akademije 1998. godine.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Nakon sticanja osnovnih i specijalističkih diploma u Srbiji, nastavio je dalje usavršavanje i edukaciju u Londonu, gde je boravio i radio u St. Mary\'s Hospitalu.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Od 2009. godine je redovni profesor na Medicinskom fakultetu Vojnomedicinske Akademije, a od 2013. godine je načelnik Klinike za Maksilofacijalnu hirurgiju na Vojnomedicinskoj Akademiji. Trenutno je zaposlen u Beogradu, u bolnici Una.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Prof. dr Srboljub Stošić je član brojnih domaćih i stranih udruženja. On je takođe autor i koautor velikog broja naučnih radova objavljenih u medicinskim časopisima u zemlji i inostranstvu. Aktivno učestvuje na domaćim i međunarodnim kongresima, gde često ima i ulogu predavača po pozivu.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-srboljub-stošić'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-srboljub-stošić'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-srboljub-stošić'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-srboljub-stošić'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-srboljub-stošić'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-srboljub-stošić'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-srboljub-stošić'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-srboljub-stošić'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'borkoJosifovski',
    profileImage: borkoJosifovski,
    alternative: 'una-bolnica-borko-josifovski',
    name: <>Dr Borko Josifovski</>,
    description: 'specijalista kardiolog',
    service: 'kardiologija',
    seoInspector: {
      title: 'dr Borko Josifovski, kardiolog, +381652024055',
      metaContent: 'dr Borko Josifovski, kardiolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/borko-josifovski',
      h1: 'dr Borko Josifovski, kardiolog, +381652024055',
      h2: 'dr Borko Josifovski, kardiolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: borkoJosifovski,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Kardiološki pregled i dijagnostika'
      },
      {
        serviceId: 2,
        serviceContent: 'Elektrokardiogram (EKG)'
      },
      {
        serviceId: 3,
        serviceContent: 'Holter monitoring'
      },
      {
        serviceId: 4,
        serviceContent: 'Ehokardiografija'
      },
      {
        serviceId: 5,
        serviceContent: 'Stres testovi'
      },
      {
        serviceId: 6,
        serviceContent: 'Koronarna angiografija'
      },
      {
        serviceId: 7,
        serviceContent: 'Balonska angioplastika'
      },
      {
        serviceId: 8,
        serviceContent: 'Stentiranje koronarnih arterija'
      },
      {
        serviceId: 9,
        serviceContent: 'Kardioverzija'
      },
      {
        serviceId: 10,
        serviceContent: 'Implantacija pejsmejkera'
      },
      {
        serviceId: 11,
        serviceContent: 'Implantacija defibrilatora'
      },
      {
        serviceId: 12,
        serviceContent: 'Ablacija srčanih aritmija'
      },
      {
        serviceId: 13,
        serviceContent: 'Kardiološka rehabilitacija'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr Borko Josifovski je specijalista kardiologije sa bogatim iskustvom i obrazovanjem. Diplomirao je na Medicinskom fakultetu Univerziteta u Beogradu 1981. godine. U toku karijere, usavršavao se u mnogim evropskim gradovima i pohađao specijalne kurseve za hitnu medicinsku pomoć i reanimaciju.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Sa dugogodišnjim radnim iskustvom u privatnim i državnim ustanovama, Dr Josifovski je stekao značajno iskustvo u oblasti kardiologije. Nekadašnje radno mesto obuhvata Gradski zavod za hitnu medicinsku pomoć u Beogradu, Kardiohirurški Centar u Kartumu, Sudan, Specijalnu Bolnicu Ostrog u Beogradu, i Medicinsku akademiju u Beogradu.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Pored svoje kliničke prakse, Dr Josifovski je takođe angažovan kao docent na Medicinskoj akademiji u Beogradu od 2009. godine. Njegovo stručno znanje i iskustvo čine ga kompetentnim i pouzdanim kardiologom.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-borko-josifovski'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-borko-josifovski'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-borko-josifovski'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-borko-josifovski'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-borko-josifovski'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-borko-josifovski'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-borko-josifovski'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-borko-josifovski'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'goranPlavec',
    profileImage: goranPlavec,
    alternative: 'una-bolnica-goran-palvec',
    name: <>Prof. dr Goran Plavec</>,
    description: 'spacijalista pulmolog',
    service: 'pulmologija',
    seoInspector: {
      title: 'dr Goran Plavec, pulmolog, +381652024055',
      metaContent: 'dr Goran Plavec, pulmolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/goran-pavlec',
      h1: 'dr Goran Plavec, pulmolog, +381652024055',
      h2: 'dr Goran Plavec, pulmolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: goranPlavec,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Dijagnostika i lečenje plućnih bolesti'
      },
      {
        serviceId: 2,
        serviceContent: 'Dijagnostika i lečenje pleuralnih izliva'
      },
      {
        serviceId: 3,
        serviceContent: 'Endoskopske procedure pluća'
      },
      {
        serviceId: 4,
        serviceContent: 'Bronhoskopija'
      },
      {
        serviceId: 5,
        serviceContent: 'Biopsija pluća'
      },
      {
        serviceId: 6,
        serviceContent: 'Spirometrija (ispitivanje funkcije pluća)'
      },
      {
        serviceId: 7,
        serviceContent: 'Terapija astme i hronične opstruktivne bolesti pluća (HOBP)'
      },
      {
        serviceId: 8,
        serviceContent: 'Lečenje alergijskih respiratornih bolesti'
      },
      {
        serviceId: 9,
        serviceContent: 'Praćenje i lečenje pacijenata sa plućnom hipertenzijom'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Prof. dr Goran Plavec je renomirani pulmolog sa bogatim iskustvom u dijagnostici i lečenju plućnih bolesti. Diplomirao je na Medicinskom fakultetu u Beogradu i stekao master studije iz Endokrinologije. Specijalizaciju iz Pneumoftiziologije je završio u Vojnomedicinskoj Akademiji u Beogradu.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Prof. dr Plavec je autor brojnih naučnih radova i monografija u oblasti pulmologije, posebno se ističe njegov rad u dijagnostici pleuralnih izliva. Aktivno je uključen u međunarodna udruženja, poput Evropskog Respiratornog Društva (ERS), i često drži predavanja na relevantnim skupovima.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Sa stručnošću i predanim pristupom, prof. dr Goran Plavec pruža vrhunsku medicinsku negu pacijentima sa plućnim bolestima. Njegovo iskustvo i angažovanje u istraživanju čine ga cenjenim stručnjakom u polju pulmologije.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-goran-plavec'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-goran-plavec'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-goran-plavec'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-goran-plavec'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-goran-plavec'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-goran-plavec'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-goran-plavec'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-goran-plavec'
          }
        ]
      }
    ]
  },





















  {
    doctorId: 'nebojsaManojlovic',
    profileImage: nebojsaManojlovic,
    alternative: 'una-bolnica-nebojša-manojlović',
    name: <>Dr Nebojša Manojlović</>,
    description: '',
    service: 'gastroenterologija',
    seoInspector: {
      title: 'dr Nebojša Manojlović, gastroenterolog, +381652024055',
      metaContent: 'dr Nebojša Manojlović, gastroenterolog, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/nebojsa-manojlovic',
      h1: 'dr Nebojša Manojlović, gastroenterolog, +381652024055',
      h2: 'dr Nebojša Manojlović, gastroenterolog, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: nebojsaManojlovic,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Endoskopska dijagnostika i terapija gastrointestinalnih bolesti'
      },
      {
        serviceId: 2,
        serviceContent: 'Kolonoskopija i gastroskopija'
      },
      {
        serviceId: 3,
        serviceContent: 'Biopsija gastrointestinalnog trakta'
      },
      {
        serviceId: 4,
        serviceContent: 'Endoskopsko uklanjanje polipa'
      },
      {
        serviceId: 5,
        serviceContent: 'ERCP (endoskopska retrogradna holangiopankreatografija)'
      },
      {
        serviceId: 6,
        serviceContent: 'Uklanjanje polipa debelog creva hirurškim putem (resekcija polipa)'
      },
      {
        serviceId: 7,
        serviceContent: 'Laparoskopska hirurgija gastrointestinalnog trakta (uključujući holecistektomiju, apendektomiju, hernioplastiku, resekciju creva)'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr Nebojša Manojlović je stručnjak u oblasti gastroenterohepatologije i onkologije. Sa preko 28 godina radnog iskustva, trenutno radi kao Gastroenterohepatolog onkolog u Klinici za Gastroenterologiju i hepatologiju. Pored kliničkog rada, Dr Manojlović aktivno učestvuje u naučnim projektima, sa ukupno 27 radova sa SCI liste.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Dr Manojlović je stekao svoje obrazovanje kroz osnovne studije završene 1992. godine, magistrirao 2000. godine, doktorirao 2009. godine, a specijalizacije iz oblasti Interne medicine, Gasronterohepatologije i Onkologije završio je tokom godina. Kroz svoje usavršavanje, dobio je ESGE Grant 2004. godine za digestivnu endoskopiju u Rikshospitaletu u Oslu, Norveška, i ESMO Grant 2009. godine za medikalnu onkologiju i tumore digestivnog sistema u Ospedale San Martino, Italija.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Dr Nebojša Manojlović se ističe svojim stručnim znanjem, bogatim iskustvom i angažovanjem u naučnoj zajednici. Njegova posvećenost i stručnost čine ga cenjenim stručnjakom u oblasti gastroenterohepatologije i onkologije.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-nebojša-manojlović'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-nebojša-manojlović'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-nebojša-manojlović'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-nebojša-manojlović'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-nebojša-manojlović'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-nebojša-manojlović'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-nebojša-manojlović'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-nebojša-manojlović'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'gordanMalivukovic',
    profileImage: gordanMalivukovic,
    alternative: 'una-bolnica-gordan-malivuković',
    name: <>Mr sc. med. dr Gordan<br />Malivuković</>,
    description: 'specijalista anesteziologije sa reanimatologijom',
    service: 'anesteziologija',
    seoInspector: {
      title: 'dr Gordan Malivuković, anesteziolog, +381652024055',
      metaContent: 'dr Gordan Malivuković, anesteziolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/gordan-malivukovic',
      h1: 'dr Gordan Malivuković, anesteziolog, +381652024055',
      h2: 'dr Gordan Malivuković, anesteziolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: gordanMalivukovic,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Osnovne studije na Medicinskom fakultetu. Specijalizacija iz Anesteziologije sa reanimatologijom na Vojno-medicinskoj akademiji u Beogradu.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Sa dugogodišnjim radnim iskustvom, Dr Malivuković je radio u Bolnici "Studenica" u Kraljevu od 1984. do 1994. godine. Nakon toga je bio anesteziolog u bolnici "Dr Dragiša Mišović" u Beogradu od 1995. do 2005. godine.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Autor je magistarske teze pod nazivom "Ventilatorna funkcija pluća kod torakalne periduralne anestezije korišćene za operacije u gornjem abdomenu" i objavio je mnoge naučne radove u stručnoj medicinskoj literaturi.'
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Od 2007. godine, Dr Malivuković je osnivač i anesteziolog u privatnoj Opštoj bolnici "UNA".'
      },
      {
        paragraphId: 5,
        paragraphContent: 
          'Ovaj SEO optimizovani tekst ističe ključne informacije o Dr Gordanu Malivukoviću, njegovom obrazovanju, iskustvu i dostignućima u anesteziologiji.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-gordan-malivuković'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-gordan-malivuković'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-gordan-malivuković'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-gordan-malivuković'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-gordan-malivuković'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-gordan-malivuković'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-gordan-malivuković'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-gordan-malivuković'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'duskoJovanovic',
    profileImage: duskoJovanovic,
    alternative: 'una-bolnica-duško-jovanović',
    name: <>Dr Duško Jovanović</>,
    description: 'specijalista anesteziologije',
    service: 'anesteziologija',
    seoInspector: {
      title: 'dr Duško Jovanović, anesteziolog, +381652024055',
      metaContent: 'dr Duško Jovanović, anesteziolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/dusko-jovanovic',
      h1: 'dr Duško Jovanović, anesteziolog, +381652024055',
      h2: 'Opšta bolnica Una, spinalna hirurgija, estetska hirugija, ortopedija, ORL i druge. Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: duskoJovanovic,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Medicinski fakultet u Beogradu završio je 1986. godine. Specijalizaciju u oblasti anesteziologije i reanimatologije je završio na Vojno medicinskoj akademiji 2000. godine. Doktor kliničke medicine postaje 2013. godine.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Radno iskustvu stiče u Šapcu od 1986 do 1996. godine, zatim na Vojno medicinskoj akademiji u Beogradu od 1996. do 2014. godine, a od 2015. godine radi u privatnoj bolnici Una, u Beogradu.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-duško-jovanović'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-duško-jovanović'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-duško-jovanović'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-duško-jovanović'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-duško-jovanović'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-duško-jovanović'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-duško-jovanović'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-duško-jovanović'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'slavicaPavlovic',
    profileImage: slavicaPavlovic,
    alternative: 'una-bolnica-slavica-pavlović',
    name: <>Dr Slavica Pavlović</>,
    description: 'specijalista ginekologije',
    service: 'ginekologija',
    seoInspector: {
      title: 'dr Slavica Pavlović, ginekolog, +381652024055',
      metaContent: 'dr Slavica Pavlović, ginekolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/slavica-pavlovic',
      h1: 'dr Slavica Pavlović, ginekolog, +381652024055',
      h2: 'dr Slavica Pavlović, ginekolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: slavicaPavlovic,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Medicinski fakultet Unuverziteta u Beogradu završila je 1985. godine. Specijalizaciju iz ginekologije i akušerstva završila na Institutu za ginekologiju i akušerstvo 1995. godine.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'U periodu od 1996. do 1999. godine radila je u poliklinici „Tedi“ u Obrenovcu. Od 1999. do 2000. godine radila je u poliklinici „Marbo“ u Beogradu. Godine 2000. počinje sa radom u ginekološko-akušerskoj ordinaciji „Natal“ u Beogradu. Trenutno zaposlena u bolnici Una.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-slavica-pavlović'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-slavica-pavlović'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-slavica-pavlović'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-slavica-pavlović'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-slavica-pavlović'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-slavica-pavlović'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-slavica-pavlović'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-slavica-pavlović'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'jovanRudic',
    profileImage: jovanRudic,
    alternative: 'una-bolnica-jovan-rudić',
    name: <>Dr Jovan Rudić</>,
    description: 'specijalista ginekologije',
    service: 'ginekologija',
    seoInspector: {
      title: 'dr Jovan Rudić, ginekolog, +381652024055',
      metaContent: 'dr Jovan Rudić, ginekolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/jovan-rudic',
      h1: 'dr Jovan Rudić, ginekolog, +381652024055',
      h2: 'dr Jovan Rudić, ginekolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: jovanRudic,
    },
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr. Jovan Rudić je stručnjak iz oblasti ginekologije i akušerstva sa sedištem u Beogradu. Pored aktivne prakse, Dr. Rudić je takođe aktivan član brojnih domaćih i međunarodnih udruženja u oblasti medicine. Njegovo stručno znanje i iskustvo prepoznati su kroz autorske i koautorske radove koje je objavio u relevantnim medicinskim časopisima.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Dr. Rudić redovno prisustvuje značajnim domaćim i međunarodnim kongresima, gde ne samo da se informiše o najnovijim dostignućima u polju, već često ima i priliku da deli svoje znanje kao predavač. '
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Kroz svoju karijeru, Dr. Rudić je stekao značajno iskustvo radeći u renomiranim zdravstvenim ustanovama kao što su GAK Narodni Front, Medical Centar i Ordinacija Radojčić. '
      },
      {
        paragraphId: 4,
        paragraphContent: 
          'Dr. Jovan Rudić je ponosni član Srpskog lekarskog društva i Lekarske komore Srbije. '
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-jovan-rudić'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-jovan-rudić'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-jovan-rudić'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-jovan-rudić'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-jovan-rudić'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-jovan-rudić'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-jovan-rudić'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-jovan-rudić'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'jasenkaGrebenarevic',
    profileImage: jasenkaGrebenarevic,
    alternative: 'una-bolnica-jasenka-grebenarević',
    name: <>Dr Jasenka Grebenarević</>,
    description: 'specijalista radiologije',
    service: 'radiologija',
    seoInspector: {
      title: 'dr Jasenka Grebenarević, radiolog, +381652024055',
      metaContent: 'dr Jasenka Grebenarević, radiolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/jasenka-grebenarevic',
      h1: 'dr Jasenka Grebenarević, radiolog, +381652024055',
      h2: 'dr Jasenka Grebenarević, radiolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: jasenkaGrebenarevic,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Ultrazvuk abdomena'
      },
      {
        serviceId: 2,
        serviceContent: 'Ultrazvuk dojke'
      },
      {
        serviceId: 3,
        serviceContent: 'Ultrazvuk mekih tkiva'
      },
      {
        serviceId: 4,
        serviceContent: 'Ultrazvuk štitne  žlezde'
      },
      {
        serviceId: 5,
        serviceContent: 'Ultrazvuk testisa'
      },
      {
        serviceId: 6,
        serviceContent: 'Ultrazvučne preglede u kućnim uslovima'
      },
      {
        serviceId: 7,
        serviceContent: 'Doppler krvnih sudova ruku, nogu i vrata'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Dr Jesenka Grebenarević je iskusna specijalistkinja radiologije sa preko 20 godina rada u državnim i privatnim ustanovama. Nudi širok spektar usluga uključujući ultrazvuk abdomena, dojke, mekih tkiva, štitne žlezde i testisa. Takođe obavlja Doppler pregled krvnih sudova ruku, nogu i vrata, kao i čitanje i tumačenje rendgenskih snimaka i CT snimaka.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Dr Grebenarević se redovno usavršava na domaćim i međunarodnim kongresima kako bi pružala najnovije i najkvalitetnije usluge.'
      },
      {
        paragraphId: 3,
        paragraphContent: 
          'Njeno obrazovanje uključuje završene osnovne i specijalističke studije na Medicinskom fakultetu Univerziteta u Beogradu, kao i školu skenera i ultrazvuka. Kroz svoju karijeru, dr Grebenarević je stekla bogato radno iskustvo u KBC Zvezdara, Institutu za Ortopediju i Hirurške bolesti "Banjica". Ona je takođe član Srpskog lekarskog društva (SLD).'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-jasenka-grebenarević'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-jasenka-grebenarević'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-jasenka-grebenarević'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-jasenka-grebenarević'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-jasenka-grebenarević'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-jasenka-grebenarević'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-jasenka-grebenarević'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-jasenka-grebenarević'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'anaGolubovic',
    profileImage: anaGolubovic,
    alternative: 'una-bolnica-ana-golubović',
    name: <>Dr Ana Golubović</>,
    description: '',
    service: 'radiologija',
    seoInspector: {
      title: 'dr Ana Golubović, radiolog, +381652024055',
      metaContent: 'dr Ana Golubović, radiolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/ana-golubovic',
      h1: 'dr Ana Golubović, radiolog, +381652024055',
      h2: 'dr Ana Golubović, radiolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: anaGolubovic,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Opšta radiologija'
      },
      {
        serviceId: 2,
        serviceContent: 'CT (kompjuterska tomografija)'
      },
      {
        serviceId: 3,
        serviceContent: 'Ultrazvuk'
      },
      {
        serviceId: 4,
        serviceContent: 'MR (magnetna rezonanca)'
      },
      {
        serviceId: 5,
        serviceContent: 'Mamografija'
      },
      {
        serviceId: 6,
        serviceContent: 'Dijagnostika dojke'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Ana Golubović je radiolog sa bogatim iskustvom. Tokom studija, radila je kao demonstrator biohemije tokom tri godine. Od 2006. do 2011. godine, radila je u hitnoj službi. Učestvovala je na različitim međunarodnim i nacionalnim kongresima, stičući vredno iskustvo. Prošla je obuku za rad sa osobama koje boluju od AIDS-a. Specijalizacija dr Ane iz radiologije, sa fokusom na opštu radiologiju i dijagnostiku kao što su CT, ultrazvuk, MR, mamografija i dijagnostika dojke.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Ana Golubović je trenutno zaposlena kao radiolog u Opštoj bolnici Bor. Sa preko 7 godina iskustva u tom poslu, pruža vrhunsku medicinsku negu i dijagnostiku. Ana je diplomirala na Medicinskom fakultetu Univerziteta u Nišu, stekavši titulu Doktora medicine.'
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-ana-golubović'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-ana-golubović'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-ana-golubović'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-ana-golubović'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-ana-golubović'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-ana-golubović'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-ana-golubović'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-ana-golubović'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'borivojeLukic',
    profileImage: borivojeLukic,
    alternative: 'una-bolnica-borivoje-lukić',
    name: <>Dr Borivoje Lukić</>,
    description: 'specijalista radiologije',
    service: 'radiologija',
    seoInspector: {
      title: 'dr Borivoje Lukić, radiolog, +381652024055',
      metaContent: 'dr Borivoje Lukić, radiolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/borivoje-lukic',
      h1: 'dr Borivoje Lukić, radiolog, +381652024055',
      h2: 'dr Borivoje Lukić, radiolog, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: borivojeLukic,
    },
    servicesList: [
      {
        serviceId: 1,
        serviceContent: 'Radiološka dijagnostika bolesti srca'
      },
      {
        serviceId: 2,
        serviceContent: 'Radiološka dijagnostika bolesti krvnih sudova'
      },
      {
        serviceId: 3,
        serviceContent: 'Interventna radiologija'
      }
    ],
    biography: [
      {
        paragraphId: 1,
        paragraphContent: 
          'Sa bogatim obrazovanjem i radnim iskustvom, dr Lukić je stručnjak za dijagnostiku bolesti srca i krvnih sudova. Aktivan je član brojnih udruženja lekara i neprestano se usavršava kroz edukacije širom Evrope. Autor je i koautor nekoliko relevantnih radova.'
      },
      {
        paragraphId: 2,
        paragraphContent: 
          'Dr Borivoje Lukić posvećuje se minimalno invazivnim procedurama za dijagnostiku i lečenje srčanih i vaskularnih oboljenja. '
      }
    ],
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-borivoje-lukić'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-borivoje-lukić'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-borivoje-lukić'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-borivoje-lukić'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-borivoje-lukić'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-borivoje-lukić'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-borivoje-lukić'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-borivoje-lukić'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'vojkanLekic',
    profileImage: vojkanLekic,
    alternative: 'una-bolnica-vojkan-lekić',
    name: <>Prim. dr Vojkan Lekić</>,
    description: 'specijalista pedijatrije',
    service: 'ostale specijalizacije',
    seoInspector: {
      title: 'dr Vojkan Lekić, pedijatar, +381652024055',
      metaContent: 'dr Vojkan Lekić, pedijatar, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/vojkan-lekic',
      h1: 'dr Vojkan Lekić, pedijatar, +381652024055',
      h2: 'dr Vojkan Lekić, pedijatar, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: vojkanLekic,
    },
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-vojkan-lekić'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-vojkan-lekić'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-vojkan-lekić'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-vojkan-lekić'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-vojkan-lekić'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-vojkan-lekić'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-vojkan-lekić'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-vojkan-lekić'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'brankaMarkovic',
    profileImage: brankaMarkovic,
    alternative: 'una-bolnica-branka-marković',
    name: <>Prof. dr Branka Marković</>,
    description: 'specijalista fizikalne medicine i rehabilitacije',
    service: 'ostale specijalizacije',
    seoInspector: {
      title: 'dr Branka Marković, fizijatar, +381652024055',
      metaContent: 'dr Branka Marković, fizijatar, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/branka-markovic',
      h1: 'dr Branka Marković, fizijatar, +381652024055',
      h2: 'dr Branka Marković, fizijatar, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: brankaMarkovic,
    },
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-branka-marković'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-branka-marković'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-branka-marković'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-branka-marković'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-branka-marković'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-branka-marković'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-branka-marković'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-branka-marković'
          }
        ]
      }
    ]
  },










  {
    doctorId: 'markoBogosavljevic',
    profileImage: markoBogosavljevic,
    alternative: 'una-bolnica-marko-bogosavljević',
    name: <>Dr Marko Bogosavljević</>,
    description: 'specijalista dečije hirurgije',
    service: 'ostale specijalizacije',
    seoInspector: {
      title: 'dr Marko Bogosavljević, dečiji hirurg, +381652024055',
      metaContent: 'dr Marko Bogosavljević, dečiji hirurg, Opšta bolnica Una, Vojvode Skopljanca 19, +381652024055',
      link: 'https://unabolnica.com/marko-bogosavljevic',
      h1: 'dr Marko Bogosavljević, dečiji hirurg, +381652024055',
      h2: 'Opšta bolnica Una, spinalna hirurgija, estetska hirugija, ortopedija, ORL i druge. Vojvode Skopljanca 19, +381652024055',
      thumbnailImage: markoBogosavljevic,
    },
    gallery: [
      {
        rowId: 1,
        rowContent: [
          {
            imageId: 0,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-bogosavljević'
          },
          {
            imageId: 1,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-bogosavljević'
          }
        ]
      },
      {
        rowId: 2,
        rowContent: [
          {
            imageId: 2,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-bogosavljević'
          },
          {
            imageId: 3,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-bogosavljević'
          }
        ]
      },
      {
        rowId: 3,
        rowContent: [
          {
            imageId: 4,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-bogosavljević'
          },
          {
            imageId: 5,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-bogosavljević'
          }
        ]
      },
      {
        rowId: 4,
        rowContent: [
          {
            imageId: 6,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-bogosavljević'
          },
          {
            imageId: 7,
            image: galleryAvatar,
            alternative: 'una-bolnica-marko-bogosavljević'
          }
        ]
      }
    ]
  },
]

export default doctorProfileData;