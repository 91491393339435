import React from "react";
import './services-service-card.css';
import { Link } from "react-router-dom";

import serviceCardIcon1 from '../../../assets/services/una-bolnica-services-card-icon-1.svg';
import serviceCardIcon2 from '../../../assets/services/una-bolnica-services-card-icon-2.svg';

export default function ServicesServiceCard({showCardContent, stateData, servicesData}) {


  // part of code that renders
  return (

    <>
      {/* small screen services service card container */}
      <div className="ss-services-service-card-container">
        
        {/* small screen services service card title container */}
        <div 
          className="ss-services-service-card-title-container"
          onClick={showCardContent}
          style={{background: stateData.background, backgroundImage: stateData.backgroundImage, color: stateData.color}}>

            <h3>{stateData.title}</h3>
            
            {/* small screen services service card icon container */}
            <div className="ss-services-service-card-icon-container">
              <img 
                src={stateData.isOn ? serviceCardIcon2 : serviceCardIcon1}/>
            </div>

        </div>

        {stateData.isOn && // rendering content container based on isOn value passed from Services component
        /* small screen services service card content container */
        <div className="ss-services-service-card-content-container">
          {/* small screen services service card content container container */} 
          <div className="ss-services-service-card-content-container-container">

            {servicesData.employes && <p className="ss-services-service-card-content-text1">Doktori</p>}

            <ul className="ss-services-service-card-content-text2">
              {servicesData.employes && 
                servicesData.employes.map((employe) => {
                  return (
                    <Link 
                      key={employe.employeId}
                      to={employe.employeLink} 
                      style={{textDecoration: 'none'}}>
                        <li key={employe.employeId} style={{color: '#7A0020'}}>{employe.employeName}</li>
                    </Link>
                  )
                })}
            </ul>

            {servicesData.text &&
              servicesData.text.map((content) => {
                return (
                  <div key={content.contentId}>
                    <p className="ss-services-service-card-content-text3">{content.content}</p>
                    <ul>
                    {content.contentList &&
                      content.contentList.map((listItem) => {
                        return (
                          <li className="ss-services-service-card-content-text2" key={listItem.listItemId}>{listItem.listItem}</li>
                        )
                      })}
                    </ul>
                  </div>
                )
              })}

          </div>
        </div>
        }

      </div>










      {/* large screen services service card container */}
      <div className="ls-services-service-card-container">
        
        {/* large screen services service card title container */}
        <div 
          className="ls-services-service-card-title-container"
          onClick={showCardContent}
          style={{background: stateData.background, backgroundImage: stateData.backgroundImage, color: stateData.color}}>

            <h3>{stateData.title}</h3>
            
            {/* large screen services service card icon container */}
            <div className="ls-services-service-card-icon-container">
              <img 
                src={stateData.isOn ? serviceCardIcon2 : serviceCardIcon1}/>
            </div>
        </div>

        {stateData.isOn && // rendering content container based on isOn value passed from Services component
        /* large screen services service card content container */
        <div className="ls-services-service-card-content-container">
          {/* large screen services service card content container container */} 
          <div className="ls-services-service-card-content-container-container">

            {servicesData.employes && <p className="ls-services-service-card-content-text1">Doktori</p>}

            <ul className="ls-services-service-card-content-text2">
              {servicesData.employes && 
                servicesData.employes.map((employe) => {
                  return (
                    <Link 
                      key={employe.employeId}
                      to={employe.employeLink} 
                      style={{textDecoration: 'none'}}>
                        <li key={employe.employeId} style={{color: '#7A0020'}}>{employe.employeName}</li>
                    </Link>
                  )
                })}
            </ul>

            {servicesData.text &&
              servicesData.text.map((content) => {
                return (
                  <div key={content.contentId}>
                    <p className="ls-services-service-card-content-text3">{content.content}</p>
                    <ul>
                    {content.contentList &&
                      content.contentList.map((listItem) => {
                        return (
                          <li className="ls-services-service-card-content-text2" key={listItem.listItemId}>{listItem.listItem}</li>
                        )
                      })}
                    </ul>
                  </div>
                )
              })}

          </div>
        </div>
        }

      </div>
    </>

  )
}