import React from "react";
import './doctors-service-card.css';

import doctorsServiceCardIcon1 from '../../../assets/services/una-bolnica-services-card-icon-1.svg';
import doctorsServiceCardIcon2 from '../../../assets/services/una-bolnica-services-card-icon-2.svg';
import doctorsServiceCardArrowDown from '../../../assets/services/una-bolnica-services-card-arrow-down.svg'
import doctorsServiceCardArrowUp from '../../../assets/services/una-bolnica-services-card-arrow-up.svg'

import DoctorsDoctorCard from "../doctors-doctor-card/doctors-doctor-card.js";

export default function DoctorsServiceCard({doctorsData, doctorsState, showCardContent, fontSize}) {

  let pairs = [];

  function sortingEmployes(employesArray) {
    for (let i = 0; i < employesArray.length -1; i += 2) {
      pairs.push([employesArray[i], employesArray[i + 1]]);
    }
    const lastSingle = employesArray.length % 2 === 0 ? [] : [employesArray[employesArray.length - 1]];
    if (lastSingle.length > 0) {
      pairs.push(lastSingle);
    }
  }

  sortingEmployes(doctorsData.employeList)

  // part of code that renders
  return (

    <div>

      {/* small screen doctors service card container */}
      <div className="ss-doctors-service-card-container">
        
        {/* small screen doctors service card title container */}
        <div 
          className="ss-doctors-service-card-title-container"
          onClick={showCardContent}
          >
            <h3 style={{fontSize: fontSize || '3.200vw'}}>{doctorsData.fieldName}</h3>
            
            {/* small screen doctors service card icon container */}
            <div className="ss-doctors-service-card-icon-container">
              <img 
                src={doctorsState.isOn ? doctorsServiceCardIcon2 : doctorsServiceCardIcon1}/>
            </div>

        </div>

        {doctorsState.isOn &&
          // small screen doctors service card content container 
          <div className="ss-doctors-service-card-content-container">
            
            {doctorsData.employeList.map((employe) => {
              return (
                <DoctorsDoctorCard 
                  key={employe.employeId}
                  link={employe.employeLink}
                  source={employe.employeImage}
                  alternative={employe.employeAlternative}
                  name={employe.employeName}
                  description={employe.employeDescription}/>
              )
            })}

          </div>
        }

      </div>








      {/* large screen doctors service card container */}
      <div className="ls-doctors-service-card-container">
        
        {/* large screen doctors service card title container */}
        <div 
        className="ls-doctors-service-card-title-container"
        onClick={showCardContent}
        >
          <h3>{doctorsData.fieldName}</h3>
          
          {/* large screen doctors service card icon container */}
          <div className="ls-doctors-service-card-icon-container">
            <img 
              src={doctorsState.isOn ? doctorsServiceCardArrowUp : doctorsServiceCardArrowDown}/>
          </div>

        </div>

        {doctorsState.isOn &&
          // large screen doctors service card content container 
          <div className="ls-doctors-service-card-content-container">
            
            {pairs.map((row, index) => {
              return (
                <div
                  className="ls-doctors-service-card-content-row-container" 
                  key={index}>
                    {row.map((employe) => {
                      return (
                        <DoctorsDoctorCard 
                          key={employe.employeId}
                          link={employe.employeLink}
                          source={employe.employeImage}
                          alternative={employe.employeAlternative}
                          name={employe.employeName}
                          description={employe.employeDescription}/>
                      )
                    })}
                </div>
              )
            })}

          </div>
        }

      </div>

      

    </div>

  )
}