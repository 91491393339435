import React from "react";
import './home-part-2.css';
import { Link } from "react-router-dom";

import HomeServiceCard from "../home-service-card/home-service-card.js";

export default function HomePart2() {

  // part of code that renders
  return (

    <div>

      {/* small screen home part 2 container */}
      <div className="ss-home-part-2-container">
        {/* small screen home part 2 title */}
        <h1 className="ss-home-part-2-title">Usluge</h1>
        
        {/* small screen home part 2 row 1 container */}
        <div className="ss-home-part-2-row-1-container">
          <HomeServiceCard 
            title='Plastična, estetska i rekonstruktivna hirurgija'/>
          <HomeServiceCard 
            title='Neurologija'/>
        </div>

        {/* small screen home part 2 row 2 container */}
        <div className="ss-home-part-2-row-2-container">
          <HomeServiceCard 
            title='Otorinolaringologija'/>
          <HomeServiceCard 
            title='Ortopedija'/>
        </div>

        {/* small screen home part 2 button */}
        <Link to='/services' style={{textDecoration: 'none'}}>
          <div className="ss-home-part-2-button">
            <p>Sve usluge</p>
          </div>
        </Link>
      </div>










      {/* large screen home part 2 container */}
      <div className="large-home-part-2-container">

        {/* large screen home part 2 title */}
        <h1 className="ls-home-part-2-title">Usluge</h1>

        {/* large screen home part 2 cards container */}
        <div className="ls-home-part-2-cards-container">

          <HomeServiceCard 
              title='Plastična, estetska i rekonstruktivna hirurgija'/>

          <HomeServiceCard 
              title='Neurohirurgija'/>

          <HomeServiceCard 
              title='Otorinolaringologija'/>

          <HomeServiceCard 
              title='Ortopedija'/>

        </div>

        {/* large screen home part 2 text */}
        <Link to='/services' style={{textDecoration: 'none'}}>
            <p className="ls-home-part-2-text">Sve usluge</p>
        </Link>

      </div>

    </div>

  )
}