import React from "react";
import './blog.css';
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import BlogHeader from "../../components/blog/blog-header/blog-header.js";
import BlogPost from "../../components/blog/blog-post/blog-post.js";
import BlogRightContainer from "../../components/blog/blog-right-container/blog-right-container.js";
import HomeFooter from "../../components/home/home-footer/home-footer.js";
import ArrowUpIcon from "../../components/arrow-up-icon/arrow-up-icon.js";
import HomeHeader from '../../components/home/home-header/home-header.js';
import HomeNavigationDropdown from "../../components/home/home-navigation-dropdown/home-navigation-dropdown.js";
import SsBlogPost from "../../components/ss-blog/ss-blog-post/ss-blog-post.js";

import decorationImage1 from '../../assets/services/una-bolnica-services-decoration-image-2.webp';
import decorationImage2 from '../../assets/services/una-bolnica-services-decoration-image-1.webp';
import defaultThumbnailImage from "../../assets/home/una-bolnica-default-thumbnail-image.webp";
import funnelIcon from '../../assets/blog/una-bolnica-funnel-icon.svg';

import blogData from '../../assets/blog-data/blog-data.js';

export default function Blog() {

  // this state determines which posts will be visible at specific time
  const [field, setField] = React.useState('all fields');

  // array of objects that every object represent filter option
  const [fieldFilter, setFieldFilter] = React.useState([
    {
      fieldId: 1,
      fieldName: 'neurohirurgija'
    },
    {
      fieldId: 2,
      fieldName: 'estetska, plastična i rekonstruktivna hirurgija'
    },
    {
      fieldId: 3,
      fieldName: 'urologija'
    },
    {
      fieldId: 4,
      fieldName: 'maksilofacijalna hirurgija'
    },
    {
      fieldId: 5,
      fieldName: 'ortopedija sa traumatologijom'
    },
    {
      fieldId: 6,
      fieldName: 'interna medicina'
    },
    {
      fieldId: 7,
      fieldName: 'otorinolaringologija'
    },
    {
      fieldId: 8,
      fieldName: 'pedijatrija'
    },
    {
      fieldId: 9,
      fieldName: 'ginekologija'
    },
  ])

  // this function devide parameter array into more arrays and every of those arrays contain some number of objects which is defined with chunkSize.
  function devideArray(array, chunkSize) {
    const chunks = [];
    let id = 1; // giving id to every group of objects (row) so we can map over them.
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push({
        id: id++,
        data: array.slice(i, i + chunkSize)
      })
    }
    return chunks;
  }

  // this const contains arrays with 3 objects devided from fieldFilter array.
  const fieldFilterDevided = devideArray(fieldFilter, 3);

  // changing field state value and activeOption state value depending on which filter option is cliced and returning value 'all fields' to field state and empty string to activeOption state if option is cliced twice in row.
  function activateOption(parameter) {
    if (field === parameter) {
      setField('all fields');
      setActiveOption('');
    } else {
      setField(parameter);
      setActiveOption(parameter)
    }
  }

  // this const contains filtered array posts depending of which filter option is cliced.
  const filterPosts = blogData.filter((post) => {
    if (field === 'all fields') { // field in this row is state in begining of code
      return !post.field.includes('hidden');
    } else {
      return post.field.includes(field); // first field in row is filed from blog-data and second is state in begining of code
    }
  })

  // this const changes depending on which filter option is clicked, and is used to change style of a filter button.
  const [activeOption, setActiveOption] = React.useState('');

  // state value that determine navigation dropdown visibility
  const [dropdown, setDropdown] = React.useState(false);

  // function changes dropdown state value
  function showDropdown() {
    setDropdown((prevState) => {
      return (
        !prevState
      )
    })
  }

  // state value that determines visibility of filter list
  const [filterList, setFilterList] = React.useState(false);

  // function changes filterList state value
  function showFilterList() {
    setFilterList((prevState) => {
      return (
        !prevState
      )
    })
  }

  // reload page function
  function reloadBlogPage() {
    window.location.reload();
    window.scrollTo(0, 0);
  }
  
  // part of code that renders
  return(
    <>

      <Helmet>
        <title>Una bolnica Beograd, zakažite pregled na +381652024055</title>
        <meta
          name="description"
          content=
            "Opšta bolnica Una, spinalna hirurgija, plastična hirugija, ortopedija, ORL i druge. Vojvode Skopljanca 19, Zakažite pregled na +381652024055">
        </meta>
        <link rel="canonical" href="https://unabolnica.com"/>
        <meta property="og:image" content={defaultThumbnailImage} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>

      {/* large screen blog container */}
      <div className="ls-blog-container">

        <BlogHeader />

        {/* large screen blog decoration image 1 container */}
        <div className="ls-blog-decoration-image-1-container">
          <img 
            src={decorationImage1}
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt="una-bolnica-dekoracija-slika"/>
        </div> 

        {/* large screen blog title 1 container */}
        <div className="ls-blog-title-1-container">
          <p>Blog sekcija</p>
        </div>
        
        {/* large screen blog container container */}
        <div className="ls-blog-container-container">

          {/* large screen blog left container */}
          <div className="ls-blog-left-container">

            {/* large screen blog left container path */}
            <p className="ls-blog-left-container-path">
              <Link to='/' style={{textDecoration: 'none', color: 'black'}}>Naslovna</Link> 
              &nbsp;/&nbsp; 
              <span onClick={reloadBlogPage} style={{cursor: 'pointer'}}>Blog sekcija</span>
            </p>

            {/* large screen blog left filter container */}
            <div className="ls-blog-left-filter-container">
              
                {/* large screen blog left filter title */}
                <p className="ls-blog-left-filter-title">Izaberite kategoriju</p>

                {/* large screen blog left filter options container */}
                <div className="ls-blog-left-filter-options-container">
                  {fieldFilterDevided.map((optionsRow) => {
                    return (
                      <div
                        className="ls-blog-left-filter-options-row-container"
                        key={optionsRow.id}>
                          {optionsRow.data.map((option) => {
                            return (
                              <div 
                                className={activeOption === option.fieldName ?
                                  'ls-blog-left-filter-option-active-button' : 'ls-blog-left-filter-option-button'}
                                key={option.fieldId}
                                onClick={() => activateOption(option.fieldName)}>
                                <p>
                                  {option.fieldName.charAt(0).toUpperCase()+option.fieldName.slice(1)}
                                </p>
                              </div>
                            )
                          })}
                      </div>
                    )
                  })}
                </div>
            </div>
            
            {/* large screen blog left post list container */}
            <div className="ls-blog-left-post-list-container">
            {filterPosts.map((post) => {
                return (
                  <BlogPost 
                    key={post.postId}
                    post={post}/>
                )
              })}
            </div>
          </div>

          <BlogRightContainer />
        </div>
      </div>
      









      {/* small screen blog container */}
      <div className="ss-blog-container">
        
        <HomeHeader 
          showDropdown={showDropdown} // passing showDropdown function to HomeHeader component
          dropdownValue={dropdown} // passing dropdown state value to HomeHeader component
        />

        {dropdown &&
        <HomeNavigationDropdown 
          reloadBlogPage={reloadBlogPage} // passing reloadBlogPage function to HomeHeader component
        />
        }

        {/* small screen blog decoration image 1 container */}
        <div className="ss-blog-decoration-image-1-container">
          <img
            src={decorationImage2}
            onContextMenu={(e) => {e.preventDefault(e)}}
            alt="una-bolnica-dekoracija-slika"/>
        </div>

        {/* small screen blog title 1 container */}
        <div className="ss-blog-title-1-container">
          
          <p>Blog sekcija</p>

          {/* small screen blog filter icon container */}
          <div className="ss-blog-filter-icon-container">
            <img 
              src={funnelIcon}
              onContextMenu={(e) => {e.preventDefault(e)}}
              alt="una-bolinica-filter-ikonica"
              onClick={showFilterList}
            />
          </div>
        </div>

        {filterList &&
        // small screen filter list container
        <div className="ss-filter-list-container">
          
          <p>Izaberite kategoriju:</p>

          {fieldFilter.map((option) => {
            return (
              <div 
                className="ss-filter-list-container-container"
                key={option.fieldId}
                onClick={() => activateOption(option.fieldName)}>
                  <div className={activeOption === option.fieldName ?
                    "ss-filter-list-checkbox-active" :
                    "ss-filter-list-checkbox"
                  }></div>
                  <p>{option.fieldName}</p>
              </div>
            )
          })}
          
        </div>
        }   

        {/* small screen blog post list constainer */}
        <div className="ss-blog-post-list-container">

          {filterPosts.map((post) => {
            return (
              <SsBlogPost 
                key={post.postId}
                post={post}/>
            )
          })}
        </div>   
      </div>

      <HomeFooter 
        marginTop={1.667}/>

      <ArrowUpIcon />

    </>
  )
}